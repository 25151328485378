import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Settings';
import { json, useLocation, useNavigate } from 'react-router-dom';
// import { Table, TableBody, TableCell, TableContainer, TableHead } from '@mui/material';


const ActiveChargePoint = ({ handleRefreshPieChart }) => { // Add handleRefreshPieChart as a prop

  let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
  // const [AfterEditJson, setAfterEditJson] = useState(false); // Track the visibility of the form
  const { state } = useLocation();

  let valuesInitialState;
  let CPguidJson = JSON.parse(localStorage.getItem('ChargePointJson'));
  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;
  }
  else {
    valuesInitialState = Json;
  }
console.log("active chargepoint json",CPguidJson)

  const [tableData, setTableData] = useState(CPguidJson);
  const { t } = useTranslation();

    var token = Cookies.get('token');
   
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");




  useEffect(() => {
    const fetchChargePoints = async () => {
      try {
        let cpLocationGuid = CPguidJson?.cpLocation || valuesInitialState?.guid || Json?.guid;
        //let cpLocationGuid = Json?.guid || CPguidJson[0]?.cpLocation || valuesInitialState?.cpLocation;
        console.log("AC {0} - {1} - {2}",Json?.guid, CPguidJson[0]?.cpLocation, valuesInitialState?.cpLocation)
        console.log("cpLocationGuid {0} ",cpLocationGuid)
        const response = await axios.get(
          `${BASE_URL}/ChargePoint/chargePointsLocation/${guid}/${cpLocationGuid}/${token}`
        );
        const data = response.data;
        console.log("data received from the server:", data); // Check the data received

        // Set the available property for each item in data based on the JSON data
        const updatedData = data.map((item) => ({
          ...item,
          available: CPguidJson.some((cp) => cp.guid === item.guid && cp.available),
        }));
        setTableData(updatedData);
        localStorage.setItem('ChargePointJson', JSON.stringify(data));
      } catch (error) {
        console.log(error);
      }
    };
    fetchChargePoints();
  }, [guid, Json.rowKey, token]);

  // Function to toggle the status of the name
  const toggleNameStatus = (index, item) => {
    const updatedCPguidJson = CPguidJson.map((cp) =>
      cp.guid === item.guid ? { ...cp, available: !cp.available } : cp
    );
    setTableData((prevData) =>
      prevData.map((prevItem) => (prevItem.guid === item.guid ? { ...prevItem, available: !prevItem.available } : prevItem))
    );

    try {
      const updatedNewChargePoint = {
        ...item,
        available: !item.available,
      };
      axios
        .put(`${BASE_URL}/ChargePoint/UpdateChargePoint/${guid}/${item.guid}/${token}`, updatedNewChargePoint)
        .then(function (response) {
          console.log("response.data", response.data);
          handleRefreshPieChart();

          // refresh();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } catch (error) {
      console.log('Error handling new CP save:', error);
    }
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const TableContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
  }));

  const Table = styled('table')(({ theme }) => ({
    width: '100%',
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: theme.spacing(2),
      textAlign: 'center',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

  const TableRow = styled('tr')(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.mode === 'dark' ? '#5faa39' : '#f5f5f5',
    },
  }));
  const GreenTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: '#5faa39', // Set background color to green
    color: 'white', // Set text color to white
  }));
  return (
    <Box>

{/* <Grid container spacing={5}>
      <Grid item xs={12}>
        <Item>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Ladepunkt Beschreibung')}</TableCell>
                  <TableCell>{t('Status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item, index) => (
                  <TableRow key={item.guid}>
                    <TableCell>
                      <div className={`scrollbar ${item.available ? 'active' : 'inactive'}`} onClick={() => toggleNameStatus(index, item)}>
                        <div className="thumb" />
                      </div>
                    </TableCell>
                    <TableCell>{item.name || ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Item>
      </Grid>
    </Grid> */}
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Item>
            <TableContainer>
              <Table>
                <thead>
                  <GreenTableRow>
                    <th>{t('Ladepunkt Beschreibung')}</th>
                    <th>{t('Status')}</th>
                  </GreenTableRow>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <TableRow key={item.guid}>
                      
                      <td>
                        <div
                          className={`scrollbar ${item.available ? 'active' : 'inactive'}`}
                          onClick={() => toggleNameStatus(index, item)}
                        >
                          <div className="thumb" />
                        </div>
                      </td> <td>{item.name || ''}</td>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActiveChargePoint;
