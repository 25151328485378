import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios from 'axios';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "./css/ChargeLocation.css";
import { Box } from '@mui/material';
import { Col , Row} from 'antd';
import { BASE_URL } from './Settings';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import { isCompanyContextAtom } from './Header';

import AccountMenu from './components/Account/AccountMenu';

function ChargeLocation() {
  const [cookies, setCookie] = useCookies();
    var token = Cookies.get("token");
    var userGuid = Cookies.get("ownerGuid");
    const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
    console.log("ownerGuid", userGuid);
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    localStorage.setItem('activePage', 'ChargeLocation');

  const [data, setData] = useState([]);
  const [Cpdata, setCpData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedChargepointRow, setChargepointdRow] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: "asc",
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
        .get(`${BASE_URL}/CpoLocation/Locations/${guid}/${token}`)
      .then(function (response) {
        console.log("chargelocation",response)
        setData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isCompanyContext]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
  
    navigate("/Wizard", { state: row });

  };

  // below logic is to get chargepoint value
  useEffect(() => {
    axios
        .get(`${BASE_URL}/CpoLocation/Locations/${guid}/${token}`)
      .then(function (response) {
        console.log("chargelocation",response)
        setCpData(response.Cpdata);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleRowfromChargepointClick = (row) => {
    setChargepointdRow(row);
    navigate("/Wizard", { state: row });

  };

  //#region  below logic is for only create guid and cluid

  const Createcluid = (event) => {
      axios.put(`${BASE_URL}/CpoLocation/CreateLocation/${guid}/${token}`)
    .then(function (Createcluid) {
        // handle success
        console.log("CreateCluid",Createcluid)
        const CreateNewCluid = Createcluid.data; // Extract CreateNewCluid value from the response
     
        setData(Createcluid.data);
       
        localStorage.setItem('CpLocationJson', JSON.stringify(Createcluid.data));
        Createcpguid(CreateNewCluid); // Pass CreateNewCluid to Createcpguid function
        navigate("/Wizard");
    })
    .catch(function (error) {
        // handle error
        localStorage.removeItem('CpLocationJson');
        console.log(error);
    })
    .finally(function () {
        // always executed
    });
  };
  //#endregion

//#region n Below logic is create new CPPoint
const Createcpguid = (CreateNewCluid, event) => {
    axios.put(`${BASE_URL}/ChargePoint/CreateNewChargePoint/${guid}/${CreateNewCluid.guid}/${token}`)
  .then(function (Createcpguid) {
      // handle success
      
      setCpData(Createcpguid.data);
      localStorage.setItem('ChargePointJson', JSON.stringify(Createcpguid.data));
      
      navigate("/Wizard");
  })
  .catch(function (error) {
      // handle error
      //localStorage.removeItem('JsoncPguid');
      console.log(error);
  })
  .finally(function () {
      // always executed
  });
};
//#endregion



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleColumnSort = (column) => {
    const direction = sortOrder.column === column && sortOrder.direction === "asc" ? "desc" : "asc";
    setSortOrder({
      column,
      direction,
    });
  };

  const sortedData = data.length > 0 ? data.sort((a, b) => {
    if (sortOrder.column && sortOrder.direction === "asc") {
      return a[sortOrder.column] > b[sortOrder.column] ? 1 : -1;
    } else if (sortOrder.column && sortOrder.direction === "desc") {
      return a[sortOrder.column] < b[sortOrder.column] ? 1 : -1;
    }
    return 0;
  }) : [];
  
  // const sortedData = data.sort((a, b) => {
  //   if (sortOrder.column && sortOrder.direction === "asc") {
  //     return a[sortOrder.column] > b[sortOrder.column] ? 1 : -1;
  //   } else if (sortOrder.column && sortOrder.direction === "desc") {
  //     return a[sortOrder.column] < b[sortOrder.column] ? 1 : -1;
  //   }
  //   return 0;
  // });

  const indexOfLastRow = page * pageSize;
  const indexOfFirstRow = indexOfLastRow - pageSize;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <table style={{ width: "100%" }} >
        <Row>
      <Col xs={24} md={5}>
                <AccountMenu initialSelectedPage="Ladestationen"  ></AccountMenu>
					</Col>
         <Col xs={24} md={19}>
        <Box component="div" sx={{ backgroundColor: "#f5f5f5", margin: "5% 10%", border: "1px solid #e0e0e0", borderRadius: "1%" }}>
             
       
  
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead sx={{backgroundColor:"#5faa39" ,border:"1px solid #e0e0e0",borderRadius:"1%" }}>
          <TableRow>
            <TableCell align="left" onClick={() => handleColumnSort("address")} style={{color:"white",fontWeight:'bold'}} >
            {t('CP Name')}{sortOrder.column === "address" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
            <TableCell align="left" onClick={() => handleColumnSort("zipCode")} style={{color:"white",fontWeight:'bold'}}>
            {t('Zip Code')}{sortOrder.column === "zipCode" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
            <TableCell align="left" onClick={() => handleColumnSort("city")} style={{color:"white",fontWeight:'bold'}}>
            {t('Ort')}{sortOrder.column === "city" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
            <TableCell align="left" onClick={() => handleColumnSort("street")} style={{color:"white",fontWeight:'bold'}}>
            {t('street')}{sortOrder.column === "street" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
            {/* <TableCell align="left" onClick={() => handleColumnSort("firm")} style={{color:"white",fontWeight:'bold'}}>
              Firma? {sortOrder.column === "firm" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell> */}
            <TableCell align="left" onClick={() => handleColumnSort("lat")} style={{color:"white",fontWeight:'bold'}}>
            {t('Latitude')} {sortOrder.column === "lat" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
            <TableCell align="left" onClick={() => handleColumnSort("lon")} style={{color:"white",fontWeight:'bold'}}>
            {t('Longitude')}{sortOrder.column === "lon" && (sortOrder.direction === "asc" ? "▲" : "▼")}
            </TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRows.map((row) => (
            <TableRow
              key={row.name}
              className={`row ${selectedRow === row ? 'selected' : ''}`}
              onClick={() => handleRowClick(row)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 },cursor: 'pointer' }}
           
            >
              <TableCell align="left" style={row.address2 == '' ? { color: 'red ' } : {}}>{row.address2}</TableCell>
              <TableCell align="left"  style={row.zipCode == '0' ? { color: 'red ' } : {}}>{row.zipCode}</TableCell>
              <TableCell align="left" style={row.city == '' ? { color: 'red ' } : {}}>{row.city}</TableCell>
              <TableCell align="left" style={row.street == '' ? { color: 'red ' } : {}}>{row.street}</TableCell>
              {/* <TableCell align="left" style={row.city == '' ? { color: 'red ' } : {}}>{row.city}</TableCell> */}
              <TableCell align="left"  style={row.lat == '0' ? { color: 'red ' } : {}}>{row.lat}</TableCell>
              <TableCell align="left"  style={row.lon == '0' ? { color: 'red ' } : {}}>{row.lon}</TableCell>
             
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={8} align="left">
              <Stack direction="row" spacing={2}>
                <Pagination
                  count={Math.ceil(data.length / pageSize)}
                  page={page}
                  onChange={handleChangePage}
                  shape="rounded"
                />
                <select value={pageSize} onChange={handleChangePageSize}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={8} align="left">
              <Button onClick={Createcluid} variant="contained" type="primary" htmlType="submit" color="primary">
              {t('Neue Ladestation anlegen')}
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  
    </Box>
                </Col>
            </Row>
        </table>
        
  );
}

export default ChargeLocation;
