const PlugType = {

	/// <summary>
	/// SchucoPlugType
	/// </summary>
	Schuco : 0x1,
	/// <summary>
	/// Type1Plug
	/// </summary>
	Type1 : 0x2,
	/// <summary>
	/// Type2Plug
	/// </summary>
	Type2 : 0x4,
	/// <summary>
	/// CssPlugType
	/// </summary>
	Css : 0x8,
	/// <summary>
	/// ChademoPlugType
	/// </summary>
	Chademo : 0x10,
};

export default PlugType;
