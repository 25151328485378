import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Settings";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import AccountMenu from '../Account/AccountMenu';
import Cookies from "js-cookie";
import AccessProfileGuid from '../services/session';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Box,
	Button,

} from "@material-ui/core";
import { useNavigate } from 'react-router-dom';

const AccessProfiles = () => {
	var token = Cookies.get("token");
	const [userGroups, setUserGroups] = useState([]);
	const { t } = useTranslation();
	localStorage.setItem('activePage', 'AccessProfiles');

	const navigate = useNavigate();



	var ownerGuid = Cookies.get("ownerGuid");

	useEffect(() => {
		console.log("Effect is running");
		const api = axios.create({
			baseURL: BASE_URL,
		});

		const fetchData = async () => {
			try {
				const response = await api.get(
					`AccessProfile/AccessProfiles/${ownerGuid}/${token}`
				);
				console.log("response AccessProfile", response.data);
				AccessProfileGuid.setaccessProfileGuid(response.data.guid)
				if (response.data === undefined) {
					// Display a proper message when response.data is null
					console.log("No access profiles found");
				} else {
					setUserGroups(response.data);
				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, [ownerGuid, token]);

	useEffect(() => {
	});

	var getBitFromMask = function (value, mask) {
		return value & mask;
	};

	// var pureValue = getBitFromMask(userGroups[0].groupType,GroupTypeMask);


	const accessProfileTitles = [
		"Name",
		t("Days"),
		// "External Stations",
	];
	//   const allUserGroupNames = [...userGroupNames, ...(userGroups.map(group => group.name))];

	const iconValues = {
		true: "✅", // You can replace these with appropriate icons
		false: "❌",
	};

	const weekdays = (weekdayBitmask) => {
		if (weekdayBitmask == 31) { return t('Mo') + "-" + t('Fr'); }
		if (weekdayBitmask == 96) { return t('Sa') + "," + t('Su'); }
		if (weekdayBitmask == 127) { return t('Mo') + "-" + t('Su'); }
		const weekdayValues = {
			Mo: 0x1,
			Tu: 0x2,
			We: 0x4,
			Th: 0x8,
			Fr: 0x10,
			Sa: 0x20,
			Su: 0x40
		};
		var weekdayResult = "";
		var first = true
		Object.entries(weekdayValues).forEach(([key, mask]) => {
			if (getBitFromMask(weekdayBitmask, mask) !== 0) {
				if (!first) { weekdayResult += ","; }
				first = false;
				weekdayResult += t(key);
			}
		});

		return weekdayResult;
	}

	const hours = [];
	const hoursOfDay = () => {
		for (var i = 0; i < 24; i++) {
			hours[i] = 1 << i;
		}
		return hours;
	}
	hoursOfDay();
	const hasHour = (idx, hour) => {
		return (hour & idx) > 0;
	}

	const timeSlotCss = (idx, hour) => {
		console.log("{0}, {1}, {2}", idx, hour, (idx & hour));
		if (hasHour(idx, hour)) {
			return "timeslot selected";
		}
		return "timeslot";
	}


	const displayBitmaskValues = (bitmask) => {
		const bitmaskValues = {
			PublicGroup: 0x1,
			HasPrice: 0x2,
			PayByApp: 0x4,
			Invoice: 0x8,
			GenerateReport: 0x10,
			ExternalStations: 0x20,
			RequiresSubscription: 0x40,
			MonthlyOverview: 0x80,
		};

		const displayedValues = {};

		Object.entries(bitmaskValues).forEach(([key, mask]) => {
			displayedValues[key] = getBitFromMask(bitmask, mask) !== 0;
		});

		return displayedValues;
	};

	const currentRows = userGroups; // Assuming userGroups is the data for your table
	async function createUserGroup() {
		try {

		} catch (error) {
			console.error('Error creating user group:', error);
			// Handle error appropriately
		}
	}
	const [triggerApiCall, setTriggerApiCall] = useState(false);

	const callApi = async () => {
		const api = axios.create({
			baseURL: BASE_URL,
			headers: {
				'Content-Type': 'application/json', // Explicitly setting Content-Type header
			},
		});
		console.log("api", api)
		try {
			const data = {
				Name: "",
				RowKey: "",
				Description: "",
				PartitionKey: ""
			};
			const response = await api.post(`AccessProfile/CreateUserGroup/${ownerGuid}/${token}`, JSON.stringify(data));
			console.log("response of create userGroup", response.data)
			return response.data;
			// Reset trigger to avoid repeated calls
			setTriggerApiCall(false);

			// Optional: Additional logic after successful API call
		} catch (error) {
			console.error('Error in API call:', error);
			// Handle the error appropriately
		}
	};

	const handleRowClick = (row) => {
		console.log("Row clicked:", row);
		AccessProfileGuid.setaccessProfileGuid(row.guid);

		// Redirect to AccessProfileDetails with the row data
		navigate(`/AccessProfileDetails`);

	};

	const handleClick = () => {
		callApi();
		navigate("AccessProfileDetails");
	};
	const handleCreateAccessProfile = async () => {
		callApiCreate();
		
	};
	const callApiCreate = async () => {
        const api = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json', // Explicitly setting Content-Type header
            },
        });
        console.log("api", api)
        try {
            const data = {
                Name: "",
                RowKey: "",
                PartitionKey: ""
               

            };
            console.log("data", data)

            const response = await api.post(`AccessProfile/CreateAccessProfile/${ownerGuid}/${token}`, JSON.stringify(data));
            console.log("response of create invite", response.data)
			// setUserGroups(response.data);
			setUserGroups(prevValues => [...prevValues, response.data]);

            return response.data;

            // Optional: Additional logic after successful API call
        } catch (error) {
            console.error('Error in API call:', error);
            // Handle the error appropriately
        }
    };
	
	return (

		<div style={{
			width: '100%',


		}}>
			<Row>
				<Col xs={24} md={5}>


					<AccountMenu initialSelectedPage="AccessProfiles"  ></AccountMenu>

				</Col>
				<Col xs={24} md={19} >
					<div style={{
						width: "100%",
						border: "none",
						margin: " 5% 10%  "

					}} >
						<TableContainer component={Paper}>
							<Table size="small" aria-label="a dense table">
								<TableHead
									className="list-table-header"
									sx={{
										backgroundColor: "#5faa39",
										border: "1px solid #e0e0e0",
										borderRadius: "1%",
										
									}}
								>
									<TableRow>
										{accessProfileTitles.map((value, index) => (
											<TableCell key={index} align="left">
												{value.includes("(") ? (
													<>
														<div>{value.split(" ")[0]}</div>
														<div>({value.split("(")[1].split(")")[0]})</div>
													</>
												) : (
													value
												)}
											</TableCell>
										))}
										<TableCell colSpan="6" class="timeslotHeader">0</TableCell>
										<TableCell colSpan="6" class="timeslotHeader">6</TableCell>
										<TableCell colSpan="6" class="timeslotHeader" style={{ paddingLeft:"0.2%" } }>12</TableCell>
										<TableCell colSpan="5" class="timeslotHeader" style={{ paddingLeft: "0.2%" }}>18</TableCell>
										<TableCell class="timeSlotHeaderEnd" style={{ paddingLeft: "0.2%" }}>23</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{currentRows.map((row, rowIndex) => (
										<TableRow key={rowIndex}>
											{accessProfileTitles.map((value, colIndex) => (
												<TableCell key={colIndex} align="left" onClick={() => handleRowClick(row)} // Call the handleRowClick function
													style={{ cursor: 'pointer', borderBottom: "1px solid #e0e0e0", }}


												>
													{value === "Name" && row.name}
													{colIndex === 1 &&
														weekdays(row.weekdays)}
												</TableCell>
											))}
											{hours.map((value,index) => (
												<TableCell class={timeSlotCss(value, row.hours)} title={`${index}-${index + 1}`} align="left" onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }}>
													{hasHour(value, row.hours)}&#xa0;
												</TableCell>
											))}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>

						<Divider />

						<div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
		
							<Button
								variant="contained"
								style={{ backgroundColor: "#5faa39", color: "white" }}
								type="primary"
								color="primary"
								onClick={handleCreateAccessProfile}>
								{t("Create Opening Hours Profile")}
							</Button>
						</div>
					</div>

				</Col>
			</Row>
		</div>
	);
};

export default AccessProfiles;
