import React, { useState, useEffect } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { GOOGLE_API_KEY } from '../../Settings';

const MapContainer2 = ({ google, setLatLng, zipcode, streetaddress,streetnumber }) => {
    /*let Json = JSON.parse(localStorage.getItem('CpLocationJson'));*/

    console.log("zipcode in mapcontainer", zipcode);

    console.log("address in mapcontainer", streetaddress);
    /*const [latLng, setMapLatLung] = useState({ lat: null, lng: null });*/
    const [latLng, setMapLatLung] = useState({ lat: null, lng: null });
    const [mapCenter, setMapCenter] = useState({ lat: null, lng: null });


    const [zipCode, setZipCode] = useState(zipcode);
    const [streetAddress, setStreetAddress] = useState( streetaddress );

  


    const handleMapClick = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setLatLng({ lat, lng });
        setMapLatLung({ lat, lng });
        setMapCenter({ lat, lng });
       
    };

    const fetchCoordinatesByZipCode = async (zipCode, streetAddress ,streetNumber) => {
        try {
            console.log("zipcode in mapcontainer in fetch ", zipCode);
            const address = `${streetAddress} ${streetNumber}`;
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${address},${zipCode}&key=${GOOGLE_API_KEY}` );
            console.log("test for address ",response.data.status)
            

            if (
                response.data.status === 'OK' &&
                response.data.results.length > 0
            ) {
                const { lat, lng } = response.data.results[0].geometry.location;
               
                console.log("test for address for lat", {lat,lng})
                setMapLatLung({ lat, lng });
               
                setLatLng({ lat, lng });
                

                
            } else {
                console.log('No results found.');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
            if (error.response) {
                console.log('Response data:', error.response.data);
                console.log('Response status:', error.response.status);
            } else if (error.request) {
                console.log('Request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        }
    };
    

    useEffect(() => {
        // Fetch initial coordinates based on initial ZIP code
       
        fetchCoordinatesByZipCode(zipcode, streetaddress,streetnumber);
        { console.log("latLnguseeffect", latLng) }

    }, [zipcode, streetaddress]);


    
   


    return (
        <div>
            {console.log("latLng111", latLng)}
            {latLng.lat && latLng.lng && (
                <>
                    {console.log("latLng77777",latLng) }
            <Map
                google={google}
                debugger
                        zoom={latLng.lat !== null && latLng.lng !== null ? 20 : 10}
                        center={{ lat: latLng.lat, lng: latLng.lng }}
                mapType="satellite"
                
                onClick={handleMapClick}
            >
                
                        <Marker position={{ lat: latLng.lat, lng: latLng.lng }} />
               
                    </Map> </>)}

            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={latLng.lat || ''}*/}
            {/*    placeholder="Latitude"*/}
            {/*    readOnly*/}
            {/*/>*/}
            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={latLng.lng || ''}*/}
            {/*    placeholder="Longitude"*/}
            {/*    readOnly*/}
            {/*/>*/}

            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={zipCode}*/}
            {/*    placeholder="ZIP Code"*/}
            {/*    onChange={(e) => setZipCode(e.target.value)}*/}
            {/*/>*/}
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
})(MapContainer2);
