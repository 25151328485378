import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next'; 

// Register the required elements
ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ refreshPieChart }) => {

  let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
  // const [AfterEditJson, setAfterEditJson] = useState(false); // Track the visibility of the form
  const { state } = useLocation();
  const { t } = useTranslation();
  
  let valuesInitialState;
  let CPguidJson = JSON.parse(localStorage.getItem('ChargePointJson'));
  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;
  }
  // else if(AfterEditJson) {
  //   valuesInitialState = AfterEditJson;
  // }
  else {
    valuesInitialState = Json;
  }
  var token = Cookies.get('token');
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: [],
      },
    ],
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Chart valuesInitialState.rowKey",valuesInitialState.rowKey)
        const response = await axios.get(`${BASE_URL}/ChargePoint/chargePointsLocation/${guid}/${valuesInitialState.rowKey}/${token}`);

        const apiData = response.data;
        console.log("apiData", apiData);

        const labels = ['Active', 'InActive'];
        const dataValues = [
          apiData.filter(data => data.available === true).length,
          apiData.filter(data => data.available === false).length
        ];
        const backgroundColors = ['#5faa39', '#4080FF'];
        const hoverBackgroundColors = ['#5faa39', '#4080FF'];

        const updatedChartData = {
          ...chartData,
          labels: labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: dataValues,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: hoverBackgroundColors,
            },
          ],
        };
        setChartData(updatedChartData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [refreshPieChart]);

  return (
    <Box >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Item>
          <div style={{height: "650px"}}>
              <h2>{t('Total Charge Points vs Active Charge Points')}</h2>
              <Pie data={chartData} options={options} />
            </div>
          </Item>
        </Grid>   
      </Grid>
    </Box>
  );
};

export default PieChart;
