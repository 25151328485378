import React from 'react';
import App from './App';
import OAuthLogin from './OAuthLoginButton';
import GotoAppStore from './components/App/GotoAppStore';

import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';


const ConditionalRoute = () => {

	const params = useParams();
	const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
	//console.log("params test", guidPattern.test(params['*']));
	if (guidPattern.test(params['*'])) {
		return <GotoAppStore />;
	}

	// Check if the token exists
	var token = Cookies.get("token");
	return token ? <App /> : <OAuthLogin />;
};

export default ConditionalRoute;
