const WeekDays = {

    Mo: 0x1,
    Di: 0x2,
    Mi: 0x4,
    Do: 0x8,
    Fr: 0x10,
    Sa: 0x20,
    So: 0x40
    };
    
    export default WeekDays;
    