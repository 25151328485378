
import { MenuItem, MenuList, Stack, Paper, Box } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BASE_URL } from '../../Settings';
import { Button } from '@mui/material';
import '../../css/ChargeLocation.css';
import { useTranslation } from 'react-i18next';
import AccountMenu from "./AccountMenu";
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { isCompanyContextAtom } from '../../Header';
import { atom, useAtom, useSetAtom } from 'jotai';


const Stripe = () => {
	var token = Cookies.get("token");
	localStorage.setItem('activePage', 'Stripe');
	var decoded = jwt_decode(token);
	var ownerGuid = Cookies.get("ownerGuid");
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
	const [connectedByMobile, setConnectedByMobile] = useState(false);
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});

		const fetchData = async () => {
			try {
				const customerApi = `/Customer/GetCustomer/${ownerGuid}/${token}`;
				const companyApi = `/Company/GetCompany/${ownerGuid}/${token}`;

				const response = await api.get(isCompanyContext ? companyApi : customerApi);
				
				// Handle the response here
				console.log("response", response)
				setData(response.data);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const handleChargeLocationClick = () => {
		if (data.id) {
			navigate('/ChargeLocation');
			setConnectedByMobile(true);

		}
		navigate("/ConnectMobile");

	};
	

	return (
		<>

			<table style={{ width: "100%" }}>
				<Row>
					<Col xs={24} md={5}>
						<AccountMenu initialSelectedPage="Zahlungskonto"  ></AccountMenu>
					</Col>
					<Col xs={24} md={16}>
						
						<Box
							component="div"
							sx={{
								backgroundColor: '#f5f5f5',
								margin: "5% 10%",
								border: '1px solid #e0e0e0',
								borderRadius: '1vw',
							}}
						>
							<Col style={{ width: "90%" }}>   <div style={{
								padding: "0% 5vw",
								width: 600,
								maxWidth: "100%"
							}}>
								<h1 style={{
									fontSize: "1.5rem",
									fontWeight: 10,
									lineHeight: 1,
								}} >{t('Zahlungskonto')}</h1>



								{!data.stripeId ? (
									<>
										<p style={{ fontSize: "larger", fontFamily: "Segoe UI semibold" }}>{t('stripeInfo')}

										</p>
										<p>
											<a href={data.stripeRegisterLink} className="connect-button">
												<span>{t('Connect+ mit Stripe')}</span>
											</a>
										</p>
										
									</>
								) : (

										<>
											<p style={{ fontSize: "larger", fontFamily:"Segoe UI semibold" }}>
												<span>{t('Your account is connected to our payment service provider Stripe')}</span>
										</p>
										<p>
											<a target="_blank" href="https://dashboard.stripe.com/login" className="connect-button">
													<span> {t('Log in to Stripe')}</span>
											</a>
										</p>
										<div className="row">
											<p></p>
											<p></p>
										</div>
										<div className="row">
											<p></p>
											<p></p>
										</div>
										<div className="row text-center">
											<div className="col-md-12" style={{ marginBottom: "12px" }}>
													{t("You've already done it! As a final step, you can enter your station details here.")}
											</div>
										</div>
										<div className="row text-center">
											<div className="col-md-12 right" >
												{/* <button id="register_chargepoint-button" type="submit" className="connect-button" onClick={handleChargeLocationClick} >Weiter zu Ladestation anlegen</button>
		                 */}
												{connectedByMobile ? (<Button onClick={handleChargeLocationClick} id="register_chargepoint-button" variant="contained" type="primary" htmlType="submit" color="primary">
														{t('Continue to the charging station')}
												</Button>) : (<Button onClick={handleChargeLocationClick} id="register_chargepoint-button" variant="contained" type="primary" htmlType="submit" color="primary">
													{t('Save And Next')}
												</Button>)}

											</div>
										</div>
									</>

								)}



							</div>

							</Col>
							</Box>
						
					</Col>
				</Row>

			</table>


		</>
	);
};

export default Stripe;