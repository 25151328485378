import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { AUTHORIZATION_HOMESITE_ENDPOINT, REDIRECT_URI,CLIENT_ID } from './Settings';
import { BASE_URL } from './Settings';
import axios, { isCancel, AxiosError } from 'axios';
const OAuthCaFHome = () => {
	const [codeChallenge, setCodeChallenge] = useState('');
	const [nonce, setNonce] = useState('');
	const [state, setState] = useState('');
	const [tokenResult, setTokenResult] = useState('');




	const [data, setData] = useState(null);
	// const token = Cookies.get("token");
	console.log("AUTHORIZATION_HOMESITE_ENDPOINT", AUTHORIZATION_HOMESITE_ENDPOINT)

	

	useEffect(() => {
		handleLogin();
		// const url = window.location.href;
		// const tokenResultParam = url.match(/tokenResult=([^&]+)/);
		// const api = axios.create({
		// 	baseURL: BASE_URL
		// });

		// const fetchData = async () => {
		// 	if (tokenResultParam) {
		// 		const tokenResultValue = decodeURIComponent(tokenResultParam[1]);
		// 		Cookies.set('token', tokenResultParam[1]);
		// 		setTokenResult(tokenResultValue);
		// 		const decoded = jwt_decode(tokenResultParam[1]);
		// 		const guid = decoded.jti;

		// 		try {
		// 			const response = await api.get(`/Customer/GetCustomer/${guid}/${tokenResultParam[1]}`);
		// 			// Handle the response here
		// 			console.log("Provider in app 2", response.data);

		// 			setData(response.data);

		// 			console.log(data);
					
		// 				// Redirect to the home page or perform any necessary action

		// 				//   window.location.href = "/Provider";

		// 			// console.log("test", response.data?.wizardStep !== null && response.data?.wizardStep < 64 && window.location.replace("/Wizard2"))
		// 			// 	// {data?.wizardStep !==null && data?.wizardStep < 64 && <Navigate to="/Wizard2" replace />}
		// 			// { response.data?.wizardStep !== null && response.data?.wizardStep < 64 && window.location.replace("/Wizard2") }

		// 			      // Redirect if needed
		// 			if (response.data?.wizardStep !== null && response.data?.wizardStep < 64) {
		// 					window.location.href = "/Wizard2";
		// 				}else
		// 				{
		// 					window.location.href = "/Provider";

		// 				}
			
		// 		} catch (error) {
		// 			console.error("Error fetching data:", error);
		// 		}
		// 	} else {
		// 		handleLogin();
		// 	}
		// };

		// fetchData();
	}, []);

	

	const generateCodeChallenge = (codeVerifier) => {
		const encoder = new TextEncoder();
		const data = encoder.encode(codeVerifier);
		return window.crypto.subtle.digest('SHA-256', data)
			.then(buffer => {
				const hashArray = Array.from(new Uint8Array(buffer));
				const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
				return hashHex;
			})
			.catch(error => {
				console.error('Error generating code challenge:', error);
			});
	};

	const generateCodeVerifier = () => {
		const codeVerifierLength = 64;
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

		let codeVerifier = '';
		for (let i = 0; i < codeVerifierLength; i++) {
			codeVerifier += characters.charAt(Math.floor(Math.random() * characters.length));
		}

		return codeVerifier;
	};

	const handleLogin = () => {
		const authorizationEndpoint = AUTHORIZATION_HOMESITE_ENDPOINT;
		
		const clientId = CLIENT_ID
		const redirectUri = REDIRECT_URI;
		console.log("redirectUri {0}", redirectUri)
		const scopes = ['openid', 'profile'];
		const newNonce = uuidv4();
		setNonce(newNonce);
		const state = uuidv4(); // Generate a random UUID as the state
		const codeVerifier = generateCodeVerifier();
		const codeChallengePromise = generateCodeChallenge(codeVerifier);

		codeChallengePromise.then(codeChallenge => {
			setCodeChallenge(codeChallenge);

			const params = {
				response_type: 'code',
				client_id: CLIENT_ID,
				redirect_uri: redirectUri,
				scope: scopes.join(' '),
				nonce: newNonce,
				state: state,
				code_challenge: codeChallenge,
				CAFHomePage : "CAF",

			};

			const url = `${authorizationEndpoint}?${new URLSearchParams(params)}`;

			window.location.href = url;
		});
	};

	return (
		<div>
			{/* <p>Token Result: {tokenResult}</p> */}

		</div>

	);
};

export default OAuthCaFHome;
