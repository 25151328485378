import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Switch, Typography, Box } from '@mui/material';
import { Divider, Col, Row } from 'antd';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import axios from "axios";
import { BASE_URL } from "../../Settings";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SetGetUserGroupName from '../services/session';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../src/css/baseDataMenu.css';

const InviteList = (userGuid1) => {
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    const [values, setValues] = useState();
    const { state } = useLocation()
    console.log("state.guid", state?.guid)
    console.log("userGuid1", userGuid1)

    console.log("values", values)
    useEffect(() => {
        console.log("Effect is running");
        const api = axios.create({
            baseURL: BASE_URL,
        });

        const fetchData = async () => {
            try {
                let userGroupGuid = "c72b71c0-7046-4906-b9a9-20975cd37cfc"//state?.guid || userGuid1.userGuid1;
                const response = await api.get(
                    `UserGroup/Users/${ownerGuid}/${userGroupGuid}/${token}`
                );
                console.log("members page", response.data);
                if (response.data === undefined) {
                    // Display a proper message when response.data is null
                    console.log("No user groups found");
                } else {
                    setValues(response.data);

                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    const handleRemoveGroupMember = (CustomerGuid) => {

        console.log("handleRemoveGroupMember", CustomerGuid)
        updateUserGroup(CustomerGuid);
    };

    const updateUserGroup = async (CustomerGuid) => {
        try {
            const response = await axios.delete(`${BASE_URL}/UserGroup/RemoveGroupMember/${ownerGuid}/${CustomerGuid}/${token}`);

            console.log("Access profile updated successfully", response.data);

        } catch (error) {
            console.error("Error updating access profile", error);
        }
    };
    return (
        <div>

            <div style={{
                width: "100%",
                border: "none",
                margin: '3% 0'

            }} >

                <Box
                    component="div"
                    sx={{
                        margin: '1% 2%',
                    }}
                >
                    <Divider></Divider>

                    <TextField
                        id="Name"
                        label={t("Name")}
                        color="success"
                        focused
                        disabled
                        value={SetGetUserGroupName.getUserGroupsName()}
                    // onChange={handleTextboxChange}
                    />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead sx={{
                                backgroundColor: "#5faa39",
                                border: "1px solid white", borderRadius: "1%"
                            }} >
                                <TableRow>
                                    <TableCell sx={{ width: ' 40%', color: "white", borderRight: "1px solid white" }}>Email</TableCell>
                                    {/* <TableCell sx={{ width: '30 %', color: "white", borderRight: "1px solid white" }}>Firstname</TableCell>
                                    <TableCell sx={{ width: '30 %', color: "white", borderRight: "1px solid white" }}>Lastname</TableCell> */}
                                    <TableCell sx={{ width: '30 %', color: "white", borderRight: "1px solid white" }}>Actions</TableCell>
                                </TableRow>
                            </TableHead >
                            <TableBody sx={{

                                border: "1px solid white", borderRadius: "1%"
                            }}>
                                {values && values.map((value, index) => (

                                    <TableRow key={value.id}>
                                        <TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>{value.email}</TableCell>
                                        {/* <TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)', }}>{values.firstname}</TableCell>
                                        <TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)', }}>{values.lastname}</TableCell> */}
                                        <TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                            {/* <IconButton
                                            color="secondary"                                
                                            onClick={() => handleRemoveGroupMember(value.customerGuid)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveGroupMember(value.customerGuid)}>
                                                ❌
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </div>
    );
};
export default InviteList;