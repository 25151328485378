import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'antd';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Pagination,
  Stack,
  Button, TextField
} from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Paper } from '@mui/material';
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Input, Checkbox } from 'antd';
import { useDropzone } from 'react-dropzone';
import './WelcomePage.css'; // Import your custom CSS file for styling

const PartnerAdmin = () => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  // const [editableRow, setEditableRow] = useState((null));
  const [image, setImage] = useState(null);
  const [Available, setAvailable] = useState(false);


  console.log("data", data);
  console.log("image", image);
  console.log("Available", Available);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState({
    column: null,
    direction: "asc",
  });
  const navigate = useNavigate();

  useEffect(() => {
    refresh();
  }, []);


  const refresh = () => {
    axios
      .get(`${BASE_URL}/Public/GetAllPartner`)
      .then(function (response) {
        console.log("GetAllPartner", response.data)
        setData(response.data);
        setAvailable(response.data.available);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const CreateNewPartner = () => {
    setEditMode(true);
    // setData({
    //   Available: false,
    //   Description: '',
    //   Domain: '',
    //   Name: '',
    //   ThumbnailUrl: '',
    // });
  };
  const handleViewClick = (row) => {
    // navigate("/Partner", { state: row });
    console.log("handleViewClick",row.data.domain)
    navigate(`/Partner/${row.data.domain}`);
  };
  const handleEditClick = (row) => {
    setEditMode(true);
    setAvailable(row.data.available);
    setData(row.data);
    setImage(null);

  };

  const handleDownloadQrCodeClick = async (row) => {
    try {
      console.log("handleDownloadQrCodeClick", row.data);
  
      const response = await axios.put(`${BASE_URL}/Public/GetQrCodeforPartner`, row.data, {
        responseType: 'arraybuffer', // Set the response type to arraybuffer
      });
  
      // Create a Blob from the arraybuffer
      const blob = new Blob([response.data], { type: 'application/pdf' });
  
      // Create a link element
      const link = document.createElement('a');
      const filename = `${row.data.domain}.pdf`;
      // Set the download attribute and create a data URL from the Blob
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
  
      // Append the link to the document body
      document.body.appendChild(link);
  
      // Trigger a click on the link to start the download
      link.click();
  
      // Remove the link from the document
      document.body.removeChild(link);
  
    } catch (error) {
      console.error(error);
    }
  };
  
  
  const handleCancelEdit = () => {
    setEditMode(false);
    setImage(null);
    refresh();
  };

  const handleSaveEdit = async () => {
    const updatedData = {
      ...data,  // spread editableRow first to get default values
      Available: Available ? true : false,
      Description: data && data.description !== undefined ? data.description : data.description,
      Domain: data && data.domain !== undefined ? data.domain : data.domain,
      Name: data && data.name !== undefined ? data.name : data.name,
      ThumbnailUrl: image ? image.name : data.thumbnailUrl,
      // rowKey: data && data.rowKey !== undefined && data.rowKey !== '' ? data.rowKey : null,
      // PartitionKey: data && data.partitionKey !== undefined && data.partitionKey !== '' ? data.partitionKey : null,

    };


    console.log("handleSaveEdit", updatedData)
    // Make a PUT request to the API endpoint

    
    const response = await axios.put(`${BASE_URL}/Public/UpdatePartner/`, updatedData);

    console.log('Partner updated successfully:', response.data.guid);

    // Assuming uploadPartnerPhoto is asynchronous, you can use await here
    await uploadPartnerPhoto(response.data.guid, image);

    // axios.put(`${BASE_URL}/Public/UpdatePartner/`, updatedData)
    //   .then(response => {
    //     // Handle the response if needed
    //     console.log('Partner updated successfully:', response.data.guid);
    //     const response =  await uploadPartnerPhoto(response.data.guid, image);
    //     refresh();
    //   })
    //   .catch(error => {
    //     // Handle the error if needed
    //     console.error('Error updating partner:', error);
    //   });
    refresh();
    setEditMode(false);
    setImage(null);
  };

  //#region page sorting logic start here
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleColumnSort = (column) => {
    const direction = sortOrder.column === column && sortOrder.direction === "asc" ? "desc" : "asc";
    setSortOrder({
      column,
      direction,
    });
  };

  const sortedData = data.length > 0 ? data.sort((a, b) => {
    if (sortOrder.column && sortOrder.direction === "asc") {
      return a[sortOrder.column] > b[sortOrder.column] ? 1 : -1;
    } else if (sortOrder.column && sortOrder.direction === "desc") {
      return a[sortOrder.column] < b[sortOrder.column] ? 1 : -1;
    }
    return 0;
  }) : [];

  const indexOfLastRow = page * pageSize;
  const indexOfFirstRow = indexOfLastRow - pageSize;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  //#endregion page sorting logic End here


  const handleChange = (input) => (e) => {
    const value = e.target.value || '';
    console.log("handlechange", value)
    setData({ ...data, [input]: value });
  };



  const uploadPartnerPhoto = async (updatedData, selectedImage) => {
    try {
      let guid = updatedData
      console.log("guid", updatedData)

      let formData = new FormData();
      formData.append('fileData', selectedImage, selectedImage.name); // Append a File object with the correct name
      const response = await axios.put(`${BASE_URL}/Public/UploadPartnerPhoto/${guid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the correct content type
        },
      });
      console.log("uploadphoto", response.data);
      return response.data;
      } catch (error) {
      console.error(error);
    }
  };


  const handleImageUpload = (input) => (e) => {

  };
  const onDrop = (acceptedFiles) => {
    const selectedImage = acceptedFiles[0];
    setImage(selectedImage);

    // If you need to perform an action immediately on image selection, you can call a function here.
    handleImageUpload(selectedImage);

  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const [hoveredRow, setHoveredRow] = useState(null);
  console.log("hoveredRow", hoveredRow)

  const handleRowHover = (row) => {
    console.log("handleRowHover", row)
    setHoveredRow(row);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div style={{ width: '100%' }}>
      <Box
        component="div"
        sx={{
          backgroundColor: '#f5f5f5',
          margin: '1% 0',
          border: '1px solid #e0e0e0',
          borderRadius: '1vw',
        }}
      >
        <Row>

          <Col xs={24} md={25}>
            <div style={{ padding: '0% 1vw' }}>

              <div style={{ padding: '2%' }}>

                <div >
                  {!editMode ? (
                    // Editable page

                    <div>

                      <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                          <TableHead sx={{ backgroundColor: "#5faa39", border: "1px solid #e0e0e0", borderRadius: "1%" }}>
                            <TableRow>
                              <TableCell align="left" onClick={() => handleColumnSort("description")} style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Description')}{sortOrder.column === "description" && (sortOrder.direction === "asc" ? "▲" : "▼")}
                              </TableCell>
                              <TableCell align="left" onClick={() => handleColumnSort("domain")} style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Domain')}{sortOrder.column === "domain" && (sortOrder.direction === "asc" ? "▲" : "▼")}
                              </TableCell>
                              <TableCell align="left" onClick={() => handleColumnSort("name")} style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Name')}{sortOrder.column === "name" && (sortOrder.direction === "asc" ? "▲" : "▼")}
                              </TableCell>
                              {/* <TableCell align="left" onClick={() => handleColumnSort("firm")} style={{color:"white",fontWeight:'bold'}}>
          Firma? {sortOrder.column === "firm" && (sortOrder.direction === "asc" ? "▲" : "▼")}
        </TableCell> */}
                              <TableCell align="left" onClick={() => handleColumnSort("thumbnailUrl")} style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Partner Logo')} {sortOrder.column === "thumbnailUrl" && (sortOrder.direction === "asc" ? "▲" : "▼")}
                              </TableCell>
                              <TableCell align="left" style={{ color: "white", fontWeight: 'bold' }}>
                                {t('DownloadQrCode')} {sortOrder.column === "DownloadQrCode"}
                              </TableCell>
                              <TableCell align="left" style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Edit')} {sortOrder.column === "Edit"}
                              </TableCell>
                              <TableCell align="left" style={{ color: "white", fontWeight: 'bold' }}>
                                {t('Preview')} {sortOrder.column === "View"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {currentRows.map((row) => (

                              <TableRow
                                key={row.guid}

                                className="table-row-container"
                                // onMouseEnter={() => handleRowHover(row)}
                                // onMouseLeave={handleRowLeave}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell align="left" className="img-thumbnail" style={row.description == '0' ? { color: 'red ' } : {}}>{row.description}</TableCell>
                                <TableCell align="left" style={row.domain == '' ? { color: 'red ' } : {}}>{row.domain}</TableCell>
                                <TableCell align="left" style={row.name == '' ? { color: 'red ' } : {}}>{row.name}</TableCell>
                                {/* <TableCell align="left" style={row.thumbnailUrl == '0' ? { color: 'red ' } : {}}>{row.thumbnailUrl}</TableCell> */}
                                <TableCell align="left" style={row.name == '' ? { color: 'red ' } : {}}>
                                  {/* <img src={row.thumbnailUrl} a style={{ height: '50px', width: '50px' }} /> */}

                                  <div alt="PartnerLogo" style={{
              marginRight: '0px',
              marginTop: '0px',
              height: '50px',
              width: '50px',
              backgroundImage: `url(${row.thumbnailUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPositionY: 'center',
            }}></div>
                                </TableCell>
                                <TableCell align="left"  style={{ cursor: 'pointer' }}>
                                  <QrCodeIcon variant="contained"
                                    color="secondary"
                                    style={{ backgroundColor: '#5faa39', color: 'white' }} onClick={() => handleDownloadQrCodeClick({ data: row })}>{t('DownloadQrCode')}</QrCodeIcon>

                                </TableCell>
                                <TableCell align="left">
                                  <Button variant="contained"
                                    color="secondary"
                                    style={{ backgroundColor: '#5faa39', color: 'white' }} onClick={() => handleEditClick({ data: row })}>{t('Edit')}</Button>

                                </TableCell>
                                <TableCell align="left">
                                  <Button variant="contained"
                                    color="secondary"
                                    style={{ backgroundColor: '#5faa39', color: 'white' }} onClick={() => handleViewClick({ data: row })}>{t('View')}</Button>

                                </TableCell>
                              </TableRow>
                            ))}
                            {/*                             
                            {hoveredRow && (
                              <div className={`popup-container ${hoveredRow ? 'active' : ''}`}>
                                <img
                                  className="img-thumbnail"
                                  src={hoveredRow?.thumbnailUrl}
                                  alt="Thumbnail"
                                  style={{ height: '100px', width: '150px' }}
                                />
                              </div>
                            )} */}

                          </TableBody>

                          <TableFooter>
                            <TableRow>
                              <TableCell colSpan={8} align="left">
                                <Stack direction="row" spacing={2}>
                                  <Pagination
                                    count={Math.ceil(data.length / pageSize)}
                                    page={page}
                                    onChange={handleChangePage}
                                    shape="rounded"
                                  />
                                  <select value={pageSize} onChange={handleChangePageSize}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                  </select>
                                </Stack>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={8} align="left">
                                <Button variant="contained"
                                  color="secondary"
                                  style={{ backgroundColor: '#5faa39', color: 'white' }} onClick={CreateNewPartner}>
                                  {t('Neue Partner')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </div>


                  ) : (


                    <div>
                      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                        {!data.description ? (t('Create New Partner.')) : (t('Edit Partner Details'))}
                      </h1>


                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <TextField
                            type="text"
                            name="Description"
                            value={data?.description}
                            onChange={handleChange('description')}
                            placeholder="Description"
                            style={{ marginBottom: '15px', marginRight: '20px' }}
                            fullWidth

                          />
                        </Col>
                      </Row>

                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <TextField
                            type="text"
                            name="Domain"
                            value={data?.domain}
                            onChange={handleChange('domain')}

                            placeholder="Domain"
                            style={{ marginBottom: '10px' }}
                            fullWidth

                          />
                        </Col>
                      </Row>



                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <TextField
                            type="text"
                            name="Name"
                            value={data?.name}
                            onChange={handleChange('name')}
                            placeholder="Name"
                            style={{ marginBottom: '10px', marginRight: '20px' }}
                            fullWidth

                          />
                        </Col>
                      </Row>
                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <img className="img-thumbnail" src={data?.thumbnailUrl} a style={{ height: '100px', width: '100px' }} />
                        </Col>
                      </Row>
                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
            
                          <div>
                            <div {...getRootProps()} style={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
                              <input {...getInputProps()} />
                              <p>Drag 'n' drop an image here, or click to select an image</p>
                            </div>
                            {image && (
                              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                <img src={URL.createObjectURL(image)} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                              </div>
                            )}
                            <Row gutter={[10, 25]}>
                              <Col xs={24} md={24}>
   
                                {image ? image.name : ''}
                              </Col>
                            </Row>
                            {/* <Button variant="contained" type="button" color="primary" onClick={() => handleImageUpload(image)}>
        Upload Image
      </Button> */}
                          </div>
                        </Col>
                      </Row>


                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <Checkbox
                            name="Available"
                            checked={Available}
                            onChange={(e) => setAvailable(e.target.checked)}
                          >
                            Available
                          </Checkbox>
                        </Col>
                      </Row>

                      <Row gutter={[10, 25]}>
                        <Col xs={24} md={24}>
                          <Button variant="contained"
                            color="secondary"
                            style={{ backgroundColor: '#5faa39', color: 'white', marginRight: '10px' }} onClick={handleSaveEdit}>{t('Save')}</Button>

                          <Button variant="contained"
                            color="secondary"
                            style={{ backgroundColor: '#5faa39', color: 'white' }} onClick={handleCancelEdit}>{t('Cancel')}</Button>
                        </Col>
                      </Row>

                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Box></div>
  );
};

export default PartnerAdmin;
