import React, { useState, useEffect, forwardRef } from 'react';
import {
    Form,
    Divider,
    Col,
    Row,
   
} from 'antd';
import Switch from '@material-ui/core/Switch';
import { TextField, Box, Button, Checkbox } from '@mui/material';
import axios from 'axios';
import {  useLocation } from 'react-router-dom';

import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import "../../css/ChargeLocation.css";
import Slider from './Slider';

import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import ChargingorParkingValue from '../services/session';
import StateButton2 from './StateButton2';
import LockIcon from '@material-ui/icons/Lock';


const Parking2 = forwardRef((isMounted, ref) => {


    // below code is for getting new cpguid
    let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
    let valuesInitialState =  Json;
    const [values, setValues] = useState(valuesInitialState);

    const [cookies, setCookie] = useCookies();
    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    // below logic is for textbox and change box
    const [is_AdHoc, setIsAdHoc] = useState(false)
    const [AddEcoTariff, setAddEcoTariff] = useState(false);


    const [isSelected, setIsSelected] = useState(false || ChargeLocationType.AdHoc);


  
    const [isEcoTariffEnabled, setIsEcoTariffEnabled] = useState(false);

    const handleOptionChange = () => {
        setAddEcoTariff(!AddEcoTariff);
        setIsEcoTariffEnabled(!isEcoTariffEnabled)
        //updateButtonState(!AddEcoTariff ? ChargeLocationType.WithEcoTarif : 0);
       /* updateServer(!AddEcoTariff ? ChargeLocationType.WithEcoTarif : 0, ChargeLocationType.WithEcoTarif)*/

    };




    // Below logic is set bitmask value and visible button and update button logic
    const ParkingMask = ChargeLocationType.Parking | ChargeLocationType.ChargingByKWh;// | ChargeLocationType.AdHoc  | ChargeLocationType.WithEcoTarif;

    const [activeParking, setActiveParking] = useState(false);
    const [activesChargingByKWh, setActiveCharging] = useState(false);
    const [showOnlineDiv, setShowOnlineDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    //Set parking and charging value and using this value in review page.-
    ChargingorParkingValue.setChargingByKWhValue(activesChargingByKWh);
    ChargingorParkingValue.setParkingValue(activeParking);
    const commonButtonStyles = {

        width: "100%",
        height: 60
        // Add padding to the button for spacing
    };
    // Define the styles for the "is_AdHoc" button
    const is_AdHocButtonStyles = {
        backgroundColor: !is_AdHoc ? '#5faa39' : '#cccccc',
        color: !is_AdHoc ? 'white' : 'white', // Always white text
        display: 'flex',
        alignItems: 'center', // Vertically center content
        justifyContent: 'space-between', // Add space between text and icon
        ...commonButtonStyles,
    };
    // Define the styles for the "Eco Tariff" button
   

    //useEffect(() => {
    //    const fetchToggleValue = async () => {
    //        try {
    //            const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
    //            const data = response.data;

    //            localStorage.removeItem('CpLocationJson');


    //            localStorage.setItem(
    //                'CpLocationJson',
    //                JSON.stringify(response.data)
    //            );
    //            setValues(response.data);
    //            valuesInitialState = response.data;



    //        } catch (error) {
    //            console.error('Error fetching toggle value:', error);
    //        }
    //    };

    //    fetchToggleValue();
    //}, []);
    var getBitFromMask = function (value, mask) {

        return value & mask;
    }
    React.useEffect(() => {
        setValues(Json); // Set the initial state once Json is fetched
        // var mainButtonValue = getBitFromMask(Json?.type,ParkingMask);// | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
        // updateButtonState(mainButtonValue);
        var ecoTariffValue = getBitFromMask(Json?.type || valuesInitialState.type, ChargeLocationType.WithEcoTarif);// | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
        updateEcoTariffState(ecoTariffValue);

        var adhocValue = getBitFromMask(Json?.type || valuesInitialState.type, ChargeLocationType.AdHoc);// | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
        updateAdhocState(adhocValue);

        var mainButtonValue = getBitFromMask(Json?.type || valuesInitialState.type, ParkingMask);// type ? 129 => pureVluae ? 128
        updateButtonState(mainButtonValue);

    }, []);

    var updateButtonState = function (bitValue) {
        if (bitValue == 0) {
            return;
        }
        setActiveParking(false);
        setActiveCharging(false);
        setShowOnlineDiv(true);

        switch (bitValue) {

            case ChargeLocationType.ChargingByKWh: setActiveCharging(true); setActiveParking(false); break;
            case ChargeLocationType.Parking: setActiveParking(true); setActiveCharging(false); break;
            case ChargeLocationType.AdHoc: setIsAdHoc(false); break;
            case ChargeLocationType.WithEcoTarif: setAddEcoTariff(true); break;
        }
    }
    // var updateButtonState = function (bitValue) {
    //   //debugger;
    //   if (bitValue == 0) {
    //     return;
    //   }
    //   setActiveParking(false);
    //   setActiveCharging(false);
    //   setShowOnlineDiv(true);

    //   switch (bitValue) {

    //     case ChargeLocationType.ChargingByKWh: setActiveCharging(true); break;
    //     case ChargeLocationType.Parking: setActiveParking(true); break;
    //     case ChargeLocationType.AdHoc: setIsAdHoc(true); break;

    //     case ChargeLocationType.WithEcoTarif: setAddEcoTariff(true); break;

    //   }
    // }


    var updateEcoTariffState = function (bitValue) {
        //debugger;
        if (bitValue == 0) {
            return;
        }

        switch (bitValue) {
            case ChargeLocationType.WithEcoTarif: setAddEcoTariff(true); break;
        }
    }
    var updateAdhocState = function (bitValue) {
        //debugger;
        if (bitValue == 0) {
            return;

        }

        switch (bitValue) {
            case ChargeLocationType.AdHoc: setIsAdHoc(true); break;

        }
    }
    const [clickedParking, setClickedParking] = useState(null);
    const [clickedChargingByKWh, setChargingByKWh] = useState(null);

    const handleClickParkingButton = (input) => (e) => {

        updateButtonState(input);
        //if (activesChargingByKWh) { setChargingByKWh(input); } else if (activeParking) {
        //    setClickedParking(input);
        //}

        /*updateServer(input, ParkingMask);*/
        //setAddEcoTariff(e.target.checked);

        //updateServer(bitValue, MeterMask);

    };



    //Below logic is for update boxes
    const updateServer = (type, mask) => {
      
      
       
           let wizardStep = 0;
        
        let clguid = Json?.guid || valuesInitialState?.guid || values.guid;

        axios.put(`${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${clguid}/${token}/${type}/${mask}?wizardStep=${wizardStep}`)
            .then(function (updateServer) {
                // handle success
              
                updateTariff(updateServer.data.type)

              
                console.log("log of update server ",updateServer.data.type)

                valuesInitialState = updateServer.data;


            })
            .catch(function (error) {
               
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };


    // End Below logic is set bitmask value and visible button and update button logic

    // Below logic is for text filed update code

    // Server update function
    function updateTariff(typeValue,filds) {

        let wizardStep = 64;
        const updatedValues = {
            ...values,
            type: typeValue,
           
        };
        console.log("values of parking and billing page", updatedValues)
        localStorage.removeItem('CpLocationJson');
        //UpdateFields(fields);
        // Perform the server update here using the fields object
        fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${guid}/${valuesInitialState.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),
        }).then((response) => {
            console.log("response of upadating parking state ",response)

            if (response.ok) {


                localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));
                Json = JSON.parse(localStorage.getItem('CpLocationJson'));

                // Server update was successful
            } else {
                console.error('There was an error!');
                // Handle error response from the server
            }
        });
    }
    // function isEuroCurrencyValid(value) {
    //     // Euro currency regex pattern (e.g., €1,000.00 or €1000)
    //     const euroCurrencyPattern = /^€\d{1,3}(,\d{3})*(\.\d{2})?$/;
    //   console.log("eurp",value)
    //     return euroCurrencyPattern.test(value);
    //   }
    //   const handleChange = (input) => (e) => {
    //     console.log("input",input)
    //     const value = e.target.value || ''


    //     if (isEuroCurrencyValid(value)) {

    //       setErrors(false);
    //     } else {
    //         setErrors(true);
    //     }
    //     setValues({ ...values, [input]: value });
    //   };
    const handleChange = (input) => (e) => {
        const rawValue = e.target.value || ''; // Set an empty string if the value is undefined
        const cleanedValue = rawValue.replace(",", "."); // Replace commas with dots

        // Convert cleanedValue to a numeric type
        const value = parseFloat(cleanedValue);
        if (value !== '' && (isNaN(value) || value < 1 || value > 100)) {
            setErrors({ ...errors, [input]: true });
        } else {
            setErrors({ ...errors, [input]: false });
        }

        setValues({ ...values, [input]: cleanedValue });



        // debouncedUpdateServer({ ...values, [input]: value });
    };



   

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};
        console.log("activesChargingByKWh:", activesChargingByKWh);
        console.log("activeParking:", activeParking);
        console.log("values.moonShineRate", values.moonShineRate);
        console.log("values.moonShineParkingRate", values.moonShineParkingRate);

        // Check if any of the button states is not active
        if (!activesChargingByKWh && !activeParking) {
            isValid = false;
            newErrors.meterType = t('Please select a billing type.');
        }

        // Check if moonShineRate or moonShineParkingRate is empty depending on active states
        if (activesChargingByKWh && !values.moonShineRate) {
            isValid = false;
            newErrors.moonShineRate = t('Please enter tarif.');
        }
        if (activesChargingByKWh) {
            if (!values.moonShineRate) {
                isValid = false;
                newErrors.moonShineRate = t('Please enter tarif.');
            } else {
                if (values.moonShineRate.toString().includes('.')) {
                    isValid = false;
                    newErrors.moonShineRate = t('please enter full cent amounts,no decimals allowed.');
                } else if (values.moonShineRate <= 0 || values.moonShineRate >= 100) {
                    isValid = false;
                    newErrors.moonShineRate = t('Please enter a tarif between 0 and 100.');
                }
            }
        }

        if (activeParking && !values.moonShineParkingRate) {
            isValid = false;
            newErrors.moonShineParkingRate = t('Please enter rate.');
        }
        if (activeParking && !(values?.moonShineParkingRate > 0 && values?.moonShineParkingRate < 50)) {
            isValid = false;
            newErrors.moonShineParkingRate = t('Please enter rate.');
        }

        setErrors(newErrors);
        console.log("isValid", isValid)
        return isValid;
    };
    const onNext = (callback) => {
        // Add your onNext logic here
        console.log(" ")
        // Check if all textfield values are provided


        // Submit data
        if (activesChargingByKWh) {
            updateServer(ChargeLocationType.ChargingByKWh, ParkingMask);
          /*  updateServer(!isEcoTariffEnabled ? ChargeLocationType.WithEcoTarif : 0, ChargeLocationType.WithEcoTarif)*/
           /* updateTariff();*/
        } else if (activeParking) {
            updateServer(ChargeLocationType.Parking, ParkingMask);
            
            //updateServer(!isEcoTariffEnabled ? ChargeLocationType.WithEcoTarif : 0, ChargeLocationType.WithEcoTarif);
            /*updateTariff();*/
        }
        //} else if (AddEcoTariff) {
        //    updateServer( ChargeLocationType.WithEcoTarif , ChargeLocationType.WithEcoTarif)

        //} else if (AddEcoTariff) {
        //    updateServer(0, ChargeLocationType.WithEcoTarif)

        //}


        callback();
    };
    React.useImperativeHandle(ref, () => ({
        onNext: onNext,
        validateForm: validateForm
    }));

   
    // End logic for update textfiled code
    //if (isLoading) {
    //    return <div style={{
    //        padding: '2% 5vw'
    //    }}> <h2>Loading...</h2>
    //    </div>;
    //}
    return (
        <React.Fragment>
            <form ref={ref}>

                <Box
                    component="div"
                    sx={{
                        backgroundColor: '#f5f5f5',
                        margin: '1% 0',
                        border: '1px solid #e0e0e0',
                        borderRadius: '1vw',
                    }}
                >

                    <div style={{ padding: '0% 4vw' }}>

                        <div style={{ padding: '2%' }}>
                           
                            <Col xs={24} md={10}  >
                                <Row gutter={[1, 5]} justify="left" align="middle">
                                    <StateButton2 onClickCallback={handleClickParkingButton(ChargeLocationType.ChargingByKWh)} isDisabled={activesChargingByKWh} isActive={activesChargingByKWh} buttonTextPlaceholder={t('Billing by kWh')} popupContentPlaceholder='This option is suitable for calibration law-compliant stations or, with restrictions, for stations with visible MID-compliant meters when the operator and the user are on site.' />
                                    <StateButton2 onClickCallback={handleClickParkingButton(ChargeLocationType.Parking)} isDisabled={activeParking} isActive={activeParking} buttonTextPlaceholder={t('Parking')}  popupContentPlaceholder='Billing based on time.' />
                                    {errors.meterType && <p style={{ color: "red" }} className="error">{errors.meterType}</p>}

                                </Row>
                            </Col>
                            
                            {showOnlineDiv && (
                                <div>
                                    <div style={{
                                        padding: "0% 2%"
                                    }}>
                                        <Divider />

                                        <div style={{ width: "100%" }}>
                                            <Row gutter={[1, 5]}>
                                                <Col xs={24} md={25}>
                                                    <Row gutter={[1,5]} align="middle">
                                                        {/*<Col xs={24} md={8}>*/}

                                                        {/*  <TextField*/}
                                                        {/*    id="Address2"*/}
                                                        {/*    label={t('Ladeplatz')}*/}
                                                        {/*    color="success"*/}
                                                        {/*    value={values?.address2 || valuesInitialState?.address2 || ''}*/}
                                                        {/*    onChange={handleChange('address2')}*/}
                                                        {/*    onBlur={handleBlur}*/}
                                                        {/*    helperText={values.address2 && values.address2.length > 12 ? 'Ladepunkt Beschreibung should be 12 characters' : ''}*/}
                                                        {/*    error={values.address2 && values.address2.length > 12}*/}
                                                        {/*    fullWidth*/}
                                                        {/*    focused*/}

                                                        {/*  />*/}

                                                        {/*</Col>*/}



                                                    </Row>
                                                    {activesChargingByKWh &&
                                                        <>

                                                        <Row gutter={[1, 5]}>
                                                                <Col xs={24} md={10}>
                                                                    <TextField
                                                                        id="moonShineRate"
                                                                        label={t('Netz/Nacht-Tarif (Ct)')}
                                                                        color="success"
                                                                        value={values?.moonShineRate || Json?.moonShineRate || ''}
                                                                        onChange={handleChange('moonShineRate')}
                                                                      
                                                                    error={!!errors.moonShineRate} // Set the error prop based on the specific field's error state
                                                                        helperText={errors.moonShineRate} // Display the error message if there's an error
                                                                        fullWidth
                                                                        focused


                                                                    />

                                                                </Col>

                                                                {/*<Col xs={24} md={10}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        id="Power"*/}
                                                                {/*        label={t('Leistung (kW)')}*/}
                                                                {/*        color="success"*/}
                                                                {/*        value={values?.power || Json?.power || valuesInitialState?.power || ''}*/}
                                                                {/*        onChange={handleChange('power')}*/}
                                                                     
                                                                {/*        fullWidth*/}
                                                                {/*        focused*/}

                                                                {/*    />*/}
                                                                {/*</Col>*/}

                                                            </Row>

                                                            

                                                           
                                                           
                                                      {/*  <Divider />
                                                        <Row gutter={[1, 5]} >

                                                            <Col xs={24} md={10} style={{ marginBottom: '10px' }}>
                                                                <div style={{ height: '100%' }}>
                                                                    <Switch
                                                                        checked={isEcoTariffEnabled}
                                                                        color="primary"
                                                                        onChange={handleOptionChange}

                                                                    />
                                                                    {t('with ECO-TARIF')}
                                                                    <Popover
                                                                        placement="rightTop"
                                                                        title={t('With ECO-TARIF')}


                                                                        arrowPointAtCenter

                                                                        content={<div
                                                                            style={{
                                                                                maxWidth: '100%', // Initially, use full width
                                                                                overflow: 'auto',
                                                                                whiteSpace: 'pre-wrap', // Preserve line breaks in the content
                                                                            }}
                                                                        >{t('The Eco Tariff serves as the second price point. This can be a more favorable time window for charging for e-car drivers and at the same time cover the hours when the charging current is particularly favorable for the provider, for example at times when there is a surplus of PV power.')}</div>}

                                                                        trigger="hover" // Show popover on hover

                                                                    >
                                                                        <InfoCircleOutlined


                                                                        />
                                                                    </Popover>

                                                                </div>
                                                            </Col>
                                                            <Col xs={24} md={10}>

                                                                <TextField
                                                                    id="sunShineRate"
                                                                    label={t('Tages/PV-Tarif (Ct)')}
                                                                    value={values?.sunShineRate || Json?.sunShineRate || valuesInitialState?.sunShineRate || ''}
                                                                    color="success"
                                                                    onChange={handleChange('sunShineRate')}
                                                                    disabled={!AddEcoTariff}
                                                                    error={errors.sunShineRate}
                                                                    helperText={errors.sunShineRate && 'Please enter a numeric value between 1 and 100.'}
                                                                    fullWidth
                                                                    focused
                                                                />


                                                            </Col>
                                                        </Row>*/}





                                                        </>

                                                    }
                                                    {activeParking &&

                                                        <>

                                                            <Row gutter={[1, 5]}>
                                                                <Col xs={24} md={8}>
                                                                    <TextField
                                                                        id="moonShineParkingRate"
                                                                        label={t('Standard Tarif (€/h)')}
                                                                        color="success"
                                                                        value={values?.moonShineParkingRate || Json?.moonShineParkingRate || valuesInitialState?.moonShineParkingRate || ''}
                                                                        defaultValue={4.95} // Set the default value here
                                                                        onChange={handleChange('moonShineParkingRate')}

                                                                    error={!!errors.moonShineParkingRate} // Set the error prop based on the specific field's error state
                                                                        helperText={errors.moonShineParkingRate } // Display the error message if there's an error
                                                                        fullWidth
                                                                        focused

                                                                    />


                                                                </Col>

                                                                {/*<Col xs={24} md={8}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        id="Power"*/}
                                                                {/*        label={t('Leistung (kW)')}*/}
                                                                {/*        color="success"*/}
                                                                {/*        value={values?.power || Json?.power || valuesInitialState?.power || ''}*/}
                                                                {/*        onChange={handleChange('power')}*/}
                                                                       
                                                                {/*        fullWidth*/}
                                                                {/*        focused*/}

                                                                {/*    />*/}
                                                                {/*</Col>*/}

                                                            </Row>


                                                        {/*<Row gutter={[1, 5]}>
                                                        //        <Col xs={24} md={10} style={{ marginBottom: '10px' }}>
                                                        //             <Switch
                                                        //                checked={isEcoTariffEnabled}
                                                        //                color="primary"
                                                        //                onChange={handleOptionChange}
                                                                          
                                                        //               / >
                                                        //                {t('with ECO-TARIF')}
                                                        //                <Popover
                                                        //                    placement="rightTop"
                                                        //                    title={t('With ECO-TARIF')}


                                                        //                    arrowPointAtCenter

                                                        //                    content={<div
                                                        //                        style={{
                                                        //                            maxWidth: '100%', // Initially, use full width
                                                        //                            overflow: 'auto',
                                                        //                            whiteSpace: 'pre-wrap', // Preserve line breaks in the content
                                                        //                        }}
                                                        //                    >{t('The Eco Tariff serves as the second price point. This can be a more favorable time window for charging for e-car drivers and at the same time cover the hours when the charging current is particularly favorable for the provider, for example at times when there is a surplus of PV power.')}</div>}

                                                        //                    trigger="hover" // Show popover on hover

                                                        //                >
                                                        //                    <InfoCircleOutlined


                                                        //                    />
                                                        //                </Popover>
                                                                   
                                                        //        </Col>
                                                        //        <Col xs={24} md={10}>
                                                                    
                                                        //                <TextField
                                                        //            id="sunShineParkingRate"
                                                        //            label={t('Eco-Tarif (€/h)')}
                                                        //            color="success"
                                                        //            value={values?.sunShineParkingRate || Json?.sunShineParkingRate || valuesInitialState?.sunShineParkingRate || ''}
                                                        //            defaultValue={3.30} // Set the default value here
                                                        //            onChange={handleChange('sunShineParkingRate')}
                                                        //            disabled={!AddEcoTariff}
                                                        //            error={errors.sunShineParkingRate} // Set the error prop based on the specific field's error state
                                                        //            helperText={errors.sunShineParkingRate && 'Please enter a numeric value between 1 and 100.'} // Display the error message if there's an error
                                                        //            fullWidth
                                                        //            focused
                                                        //            style={{backgroundColor:"grey"}}
                                                        //              />

                                                                    
                                                        //        </Col>
                                                        //    </Row>
                                                        //    <Divider />*/}
                                                        





                                                        </>
                                                    }


                                                </Col>

                                            </Row>

                                        </div>

                                       

                                    </div>
                                    {/*<div className="map-container-wrapper" style={{ width: '100%' }}>*/}
                                    {/*    <h1 style={{ fontSize: '1.5rem', padding: '0%' }}>{ }*/}
                                    {/*        {AddEcoTariff ? t('SliderOpeningHours') : t('Opening hours')}</h1>*/}

                                    {/*    <Slider AddEcoTariff={AddEcoTariff} />*/}
                                    {/*</div>*/}
                                </div>

                            )}</div>

                    </div>
                </Box>
               </form>
        </React.Fragment>
    );
});


export default Parking2;
