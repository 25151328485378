import React, { useState, useEffect } from "react";
import { Form, Divider, Col, Row } from "antd";

import { TextField, Box, Button, Checkbox } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import axios from "axios";
import { json, useLocation } from "react-router-dom";

import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
import "../../css/ChargeLocation.css";
import Slider from "./Slider";

import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from "../../Settings";
import { useTranslation } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import ChargingorParkingValue from "../services/session";
import StateButton from "./StateButton";
import FlexPriceDisplay from "../Flex/FlexPriceDisplay";


function Parking() {
  // below code is for getting new cpguid
  let Json = JSON.parse(localStorage.getItem("CpLocationJson"));
  const { state } = useLocation();
  let valuesInitialState = state || Json;

  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;
  } else {
    valuesInitialState = Json;
    console.log("state is null or undefined");
  }
  const [values, setValues] = useState(valuesInitialState);

  console.log("Json,values", Json, values);

  const [cookies, setCookie] = useCookies();
  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
  // var guid = decoded.jti;

  var guid = Cookies.get("ownerGuid");
  const { t } = useTranslation();

  // below logic is for textbox and change box
  const [is_AdHoc, setIsAdHoc] = useState(false);
  const [AddEcoTariff, setAddEcoTariff] = useState(false);

  const [isSelected, setIsSelected] = useState(
    false || ChargeLocationType.AdHoc
  );

  const toggleSelection = () => {
    setIsAdHoc(!is_AdHoc);
    //updateButtonState(!is_AdHoc ? ChargeLocationType.AdHoc : 0);
    updateServer(
      !is_AdHoc ? ChargeLocationType.AdHoc : 0,
      ChargeLocationType.AdHoc
    );
  };

  const handleOptionChange = () => {
    setAddEcoTariff(!AddEcoTariff);
    //updateButtonState(!AddEcoTariff ? ChargeLocationType.WithEcoTarif : 0);
    updateServer(
      !AddEcoTariff ? ChargeLocationType.WithEcoTarif : 0,
      ChargeLocationType.WithEcoTarif
    );
  };

  // Below logic is set bitmask value and visible button and update button logic
  const ParkingMask =
    ChargeLocationType.Parking | ChargeLocationType.ChargingByKWh; // | ChargeLocationType.AdHoc  | ChargeLocationType.WithEcoTarif;

  const [activeParking, setActiveParking] = useState(false);
  const [activesChargingByKWh, setActiveCharging] = useState(false);
  const [showOnlineDiv, setShowOnlineDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedPrice, setSelectedPrice] = useState('');


  const [activeFix, setActiveFix] = useState(false);
  const [activeFlex, setActiveFlex] = useState(false);

  //Set parking and charging value and using this value in review page.-
  ChargingorParkingValue.setChargingByKWhValue(activesChargingByKWh);
  ChargingorParkingValue.setParkingValue(activeParking);

  const isMobile = window.innerWidth <= 768;
  const isTablet = window.innerWidth <= 1024 && window.innerWidth > 768;

  const commonButtonStyles = {

    width: "100%",
    height: isMobile ? 100 : isTablet ? 80 : 60,

    // Add padding to the button for spacing
  };
  // Define the styles for the "is_AdHoc" button
  const is_AdHocButtonStyles = {
    backgroundColor: !is_AdHoc ? "#5faa39" : "#cccccc",
    color: !is_AdHoc ? "white" : "white", // Always white text
    display: "flex",
    alignItems: "center", // Vertically center content
    justifyContent: "space-between", // Add space between text and icon
    ...commonButtonStyles,
  };
  // Define the styles for the "Eco Tariff" button
  const ecoTariffButtonStyles = {
    backgroundColor: AddEcoTariff ? "#5faa39" : "#cccccc",
    color: AddEcoTariff ? "white" : "white", // Always white text
    display: "flex",
    alignItems: "center", // Vertically center content
    justifyContent: "space-between", // Add space between text and icon
    ...commonButtonStyles,
  };

  useEffect(() => {
    const fetchToggleValue = async () => {
      console.log("valuesInitialState.guid",valuesInitialState.guid)
      console.log("parkingguid",guid)

      try {
        const response = await axios.get(
          `${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`
        );
        const data = response.data;
        console.log("data",data)

        localStorage.removeItem("CpLocationJson");

        localStorage.setItem("CpLocationJson", JSON.stringify(response.data));
        console.log("response.data", response.data);
        setValues(response.data);

        valuesInitialState = response.data;

        // let tarrifType = Json?.tarrifType || valuesInitialState.tarrifType ;
        let tarrifType = Json?.tarrifType ?? valuesInitialState.tarrifType ?? "Fix";

        setSelectedPrice(tarrifType);
        // Determine which button should be active or disabled based on the tarrifType
        if (tarrifType === 'Fix') {
          setActiveFix(true); // Activate 'Fix' button
          setActiveFlex(false); // Deactivate 'Flex' button
        } else if (tarrifType === 'Flex') {
          setActiveFix(false); // Deactivate 'Fix' button
          setActiveFlex(true); // Activate 'Flex' button
        } else {
          setActiveFix(false); // Deactivate 'Fix' button
          setActiveFlex(false); // Deactivate 'Flex' button
        }



      } catch (error) {
        console.error("Error fetching toggle value:", error);
      }
    };

    fetchToggleValue();
  }, []);
  var getBitFromMask = function (value, mask) {
    return value & mask;
  };
  React.useEffect(() => {
    setValues(Json); // Set the initial state once Json is fetched
    // var mainButtonValue = getBitFromMask(Json?.type,ParkingMask);// | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
    // updateButtonState(mainButtonValue);
    var ecoTariffValue = getBitFromMask(
      Json?.type || valuesInitialState.type,
      ChargeLocationType.WithEcoTarif
    ); // | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
    updateEcoTariffState(ecoTariffValue);

    var adhocValue = getBitFromMask(
      Json?.type || valuesInitialState.type,
      ChargeLocationType.AdHoc
    ); // | ChargeLocationType.WithEcoTarif | ChargeLocationType.AdHoc);// type ? 129 => pureVluae ? 128
    updateAdhocState(adhocValue);

    var mainButtonValue = getBitFromMask(
      Json?.type || valuesInitialState.type,
      ParkingMask
    ); // type ? 129 => pureVluae ? 128
    updateButtonState(mainButtonValue);



  }, []);

  var updateButtonState = function (bitValue) {
    if (bitValue == 0) {
      return;
    }
    setActiveParking(false);
    setActiveCharging(false);
    setShowOnlineDiv(true);

    switch (bitValue) {
      case ChargeLocationType.ChargingByKWh:
        setActiveCharging(true);
        setActiveParking(false);
        break;
      case ChargeLocationType.Parking:
        setActiveParking(true);
        setActiveCharging(false);
        break;
      case ChargeLocationType.AdHoc:
        setIsAdHoc(false);
        break;
      case ChargeLocationType.WithEcoTarif:
        setAddEcoTariff(true);
        break;
    }
  };
  // var updateButtonState = function (bitValue) {
  //   //debugger;
  //   if (bitValue == 0) {
  //     return;
  //   }
  //   setActiveParking(false);
  //   setActiveCharging(false);
  //   setShowOnlineDiv(true);

  //   switch (bitValue) {

  //     case ChargeLocationType.ChargingByKWh: setActiveCharging(true); break;
  //     case ChargeLocationType.Parking: setActiveParking(true); break;
  //     case ChargeLocationType.AdHoc: setIsAdHoc(true); break;

  //     case ChargeLocationType.WithEcoTarif: setAddEcoTariff(true); break;

  //   }
  // }

  var updateEcoTariffState = function (bitValue) {
    //debugger;
    if (bitValue == 0) {
      return;
    }

    switch (bitValue) {
      case ChargeLocationType.WithEcoTarif:
        setAddEcoTariff(true);
        break;
    }
  };
  var updateAdhocState = function (bitValue) {
    //debugger;
    if (bitValue == 0) {
      return;
    }

    switch (bitValue) {
      case ChargeLocationType.AdHoc:
        setIsAdHoc(true);
        break;
    }
  };

  const handleClickParkingButton = (input) => (e) => {
   
    updateButtonState(input);
    updateServer(input, ParkingMask);
    setActiveFix(true);
    setActiveFlex(false);
    setSelectedPrice("Fix");
    updateServertarrifType("Fix");
    //setAddEcoTariff(e.target.checked);

    //updateServer(bitValue, MeterMask);
  };

  //Below logic is for update boxes
  const updateServer = (type, mask) => {
    setIsLoading(true);
    let clguid = Json?.guid || valuesInitialState?.guid || values.guid;
    axios
      .put(
        `${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${clguid}/${token}/${type}/${mask}`
      )
      .then(function (updateServer) {
        // handle success
        setIsLoading(false);
        localStorage.removeItem("CpLocationJson");
        setValues(updateServer.data);

        localStorage.setItem(
          "CpLocationJson",
          JSON.stringify(updateServer.data)
        );
        Json = JSON.parse(localStorage.getItem("CpLocationJson"));
        valuesInitialState = updateServer.data;
      })
      .catch(function (error) {
        // handle error
        localStorage.removeItem("CreateJsoncpguid");
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  // End Below logic is set bitmask value and visible button and update button logic

  // Below logic is for text filed update code

  // Server update function
  function updateTariff(filds) {
    localStorage.removeItem("CpLocationJson");

    console.log("testtest", values)
    //UpdateFields(fields);
    // Perform the server update here using the fields object
    fetch(
      `${BASE_URL}/CpoLocation/UpdateCLocation/${guid}/${valuesInitialState.guid}/${token}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    ).then((response) => {
      if (response.ok) {
        localStorage.setItem("CpLocationJson", JSON.stringify(values));
        Json = JSON.parse(localStorage.getItem("CpLocationJson"));

        // Server update was successful
      } else {
        console.error("There was an error!");
        // Handle error response from the server
      }
    });
  }
  // function isEuroCurrencyValid(value) {
  //     // Euro currency regex pattern (e.g., €1,000.00 or €1000)
  //     const euroCurrencyPattern = /^€\d{1,3}(,\d{3})*(\.\d{2})?$/;
  //   console.log("eurp",value)
  //     return euroCurrencyPattern.test(value);
  //   }
  //   const handleChange = (input) => (e) => {
  //     console.log("input",input)
  //     const value = e.target.value || ''

  //     if (isEuroCurrencyValid(value)) {

  //       setErrors(false);
  //     } else {
  //         setErrors(true);
  //     }
  //     setValues({ ...values, [input]: value });
  //   };
  //   const handleChange = (input) => (e) => {
  //     const value = e.target.value || ""; // Set an empty string if the value is undefined
  //     if (value !== "" && (isNaN(value) || value < 1 || value > 100)) {
  //       setErrors({ ...errors, [input]: true });
  //     } else {
  //       setErrors({ ...errors, [input]: false });
  //     }

  //     setValues({ ...values, [input]: value });

  //     // debouncedUpdateServer({ ...values, [input]: value });
  //   };

  const handleChange = (input) => (e) => {
    const rawValue = e.target.value || ""; // Set an empty string if the value is undefined
    const cleanedValue = rawValue.replace(",", "."); // Replace commas with dots

    // Convert cleanedValue to a numeric type
    const value = parseFloat(cleanedValue);

    if (cleanedValue !== "" && (isNaN(value) || value < 1 || value > 100)) {
      setErrors({ ...errors, [input]: true });
    } else {
      setErrors({ ...errors, [input]: false });
    }

    setValues({ ...values, [input]: cleanedValue });
  };

  const handleBlur = () => {
    updateTariff();
  };
  // End logic for update textfiled code
  const selectedLanguage = ChargingorParkingValue.getSelectedLanguageValue();
  console.log("selectedLanguage", selectedLanguage);


  const getFormattedValue = (value) => {
    if (!isNaN(value)) {
      console.log("getFormattedValue", value.toLocaleString(selectedLanguage));
      return value.toLocaleString(selectedLanguage);
    }
    return "";
  };

  /// Below code is for tarrif type like flx or flex
  const [activeButton, setActiveButton] = useState('');

  const handleSelectPrice = (tarrifType) => {
    setSelectedPrice(tarrifType);
    setActiveButton(tarrifType); // Set the active button
    if (tarrifType === 'Fix') {
      setActiveFix(true);
      setActiveFlex(false);
    } else if (tarrifType === 'Flex') {
      setActiveFix(false);
      setActiveFlex(true);
    }

    const cleanedValue = tarrifType.trim(); // Remove leading and trailing spaces
    if (cleanedValue === "") {

      setErrors({ ...errors, tarrifType: true });
    } else {

      setErrors({ ...errors, tarrifType: false });
    }

    updateServertarrifType(tarrifType);
  };

  const updateServertarrifType = (tarrifType) => {
    let clguid = Json?.guid || valuesInitialState?.guid || values.guid;

    axios
      .put(
        `${BASE_URL}/CpoLocation/UpdateTarrifType/${guid}/${clguid}/${tarrifType}/${token}`
      )
      .then(function (updateServertarrifType) {
        // handle success
        setIsLoading(false);
        localStorage.removeItem("CpLocationJson");
        setValues(updateServertarrifType.data);

        localStorage.setItem(
          "CpLocationJson",
          JSON.stringify(updateServertarrifType.data)
        );
        Json = JSON.parse(localStorage.getItem("CpLocationJson"));
        valuesInitialState = updateServertarrifType.data;
      })
      .catch(function (error) {
        // handle error
        localStorage.removeItem("CreateJsoncpguid");
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };


  if (isLoading) {
    return (
      <div
        style={{
          padding: "2% 5vw",
        }}
      >
        {" "}
        <h2>Loading...</h2>
      </div>
    );
  }
  return (
    <React.Fragment>
      <form>
        <Box
          component="div"
          sx={{
            backgroundColor: "#f5f5f5",
            margin: "1% 0",
            border: "1px solid #e0e0e0",
            borderRadius: "1vw",
          }}
        >
          <div style={{ padding: "0% 4vw" }}>
            <div style={{ padding: "2%" }}>
              <h1
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  lineHeight: 1,
                }}
              >
                {t("Time/Power/Tariffs")}
              </h1>

              <Col xs={24} md={10}>
                <Row gutter={[25, 25]} justify="left" align="middle">
                  <StateButton
                    onClickCallback={handleClickParkingButton(
                      ChargeLocationType.ChargingByKWh
                    )}
                    isDisabled={activesChargingByKWh}
                    isActive={activesChargingByKWh}
                    buttonTextPlaceholder="Billing by kWh"
                    popupContentPlaceholder="This option is suitable for calibration law-compliant stations or, with restrictions, for stations with visible MID-compliant meters when the operator and the user are on site."
                  />
                  <StateButton
                    onClickCallback={handleClickParkingButton(
                      ChargeLocationType.Parking
                    )}
                    isDisabled={activeParking}
                    isActive={activeParking}
                    buttonTextPlaceholder="Parking"
                    popupContentPlaceholder="Billing based on time."
                  />
                </Row>
              </Col>
              <Divider></Divider>
              <Col xs={24} md={10}>
                <Row gutter={[25, 25]} justify="left" align="middle">
                  <Col xs={24} md={12}>
                    <StateButton
                      onClickCallback={() => handleSelectPrice('Fix')}
                      isDisabled={activeFix}
                      isActive={activeFix}
                      buttonTextPlaceholder="Fix"
                   
                    />
                  </Col>
                  {!activeParking && 
                  <Col xs={24} md={12}>
                    <StateButton
                      onClickCallback={() => handleSelectPrice('Flex')}
                      isDisabled={activeFlex}
                      isActive={activeFlex}
                      buttonTextPlaceholder="Flex"
                   

                    />
                  </Col>}
                </Row>
              </Col>{console.log("FlexPriceDisplay", values)}
              {selectedPrice === 'Flex' && !activeParking && <FlexPriceDisplay values={values} setValues={setValues}/>}

              <br />
              {selectedPrice === 'Fix' && showOnlineDiv && (
                <div>
                  <div
                    style={{
                      padding: "0% 2%",
                    }}
                  >
                    <Divider />

                    <div style={{ width: "100%" }}>
                      <Row>
                        <Col xs={24} md={25}>
                          <Row gutter={[25, 25]} align="middle">
                            {/*<Col xs={24} md={8}>*/}

                            {/*  <TextField*/}
                            {/*    id="Address2"*/}
                            {/*    label={t('Ladeplatz')}*/}
                            {/*    color="success"*/}
                            {/*    value={values?.address2 || valuesInitialState?.address2 || ''}*/}
                            {/*    onChange={handleChange('address2')}*/}
                            {/*    onBlur={handleBlur}*/}
                            {/*    helperText={values.address2 && values.address2.length > 12 ? 'Ladepunkt Beschreibung should be 12 characters' : ''}*/}
                            {/*    error={values.address2 && values.address2.length > 12}*/}
                            {/*    fullWidth*/}
                            {/*    focused*/}

                            {/*  />*/}

                            {/*</Col>*/}
                          </Row>
                          {activesChargingByKWh && (
                            <>
                              <Row gutter={[25, 25]}>
                                <Col xs={24} md={10}>
                                  {/* <TextField
                                    id="moonShineRate"
                                    label={t("Netz/Nacht-Tarif (Ct)")}
                                    color="success"
                                    value={
                                      values?.moonShineRate ||
                                      Json?.moonShineRate ||
                                      ""
                                    }
                                    onChange={handleChange("moonShineRate")}
                                    onBlur={handleBlur}
                                    error={errors.moonShineRate} // Set the error prop based on the specific field's error state
                                    helperText={
                                      errors.moonShineRate &&
                                      "Please enter a numeric value between 1 and 100."
                                    } // Display the error message if there's an error
                                    fullWidth
                                    focused
                                  /> */}
                                  <TextField
                                    id="moonShineRate"
                                    label={t("Netz/Nacht-Tarif (Ct)")}
                                    color="success"
                                    value={getFormattedValue(
                                      values?.moonShineRate ||
                                      ""
                                    )}
                                    //   value={values?.moonShineRate.toLocaleString(ChargingorParkingValue.getSelectedLanguageValue()) || Json?.moonShineRate.toLocaleString(ChargingorParkingValue.getSelectedLanguageValue()) || ""}
                                    onChange={handleChange("moonShineRate")}
                                    onBlur={handleBlur}
                                    error={errors.moonShineRate} // Set the error prop based on the specific field's error state
                                    helperText={
                                      errors.moonShineRate &&
                                      "Please enter a numeric value between 1 and 100."
                                    } // Display the error message if there's an error
                                    fullWidth
                                    focused
                                  />
                                </Col>

                                <Col xs={24} md={10}>
                                  <TextField
                                    id="Power"
                                    label={t("Leistung (kW)")}
                                    color="success"
                                    value={getFormattedValue(
                                      values?.power ||
                                      
                                      ""
                                    )}
                                    onChange={handleChange("power")}
                                    onBlur={handleBlur}
                                    fullWidth
                                    focused
                                  />
                                </Col>
                              </Row>

                              <Divider />

                              <Row gutter={[25, 25]}>
                                <Col
                                  xs={24}
                                  md={5}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <div style={{ height: "100%" }}>
                                    <Button
                                      type="primary"
                                      onClick={() => handleOptionChange()}
                                      style={ecoTariffButtonStyles}
                                    >
                                      {t("With ECO-TARIF")}
                                      <Popover
                                        placement="rightTop"
                                        title={t("With ECO-TARIF")}
                                        arrowPointAtCenter
                                        content={
                                          <div
                                            style={{
                                              maxWidth: "100%", // Initially, use full width
                                              overflow: "auto",
                                              whiteSpace: "pre-wrap", // Preserve line breaks in the content
                                            }}
                                          >
                                            {t(
                                              "The Eco Tariff serves as the second price point. This can be a more favorable time window for charging for e-car drivers and at the same time cover the hours when the charging current is particularly favorable for the provider, for example at times when there is a surplus of PV power."
                                            )}
                                          </div>
                                        }
                                        trigger="hover" // Show popover on hover
                                      >
                                        <InfoCircleOutlined />
                                      </Popover>
                                    </Button>
                                  </div>
                                </Col>
                                <Col xs={24} md={5}>
                                  {AddEcoTariff && (
                                    <TextField
                                      id="sunShineRate"
                                      label={t("Tages/PV-Tarif (Ct)")}
                                      value={getFormattedValue(
                                        values?.sunShineRate || ""
                                      )}
                                      color="success"
                                      onChange={handleChange("sunShineRate")}
                                      onBlur={handleBlur}
                                      error={errors.sunShineRate}
                                      helperText={
                                        errors.sunShineRate &&
                                        "Please enter a numeric value between 1 and 100."
                                      }
                                      fullWidth
                                      focused
                                    />
                                  )}
                                </Col>
                              </Row>
                              {/* <Divider />
                             <Row gutter={[25, 25]} xs={24} md={4}>
                                <Col xs={24} md={5}>
                                  <div style={{ height: "100%" }}>
                                    <Button
                                      onClick={toggleSelection}
                                      style={is_AdHocButtonStyles}
                                    >
                                      {t("Station is reservable")}
                                      <Popover
                                        placement="rightTop"
                                        title={t("Station is reservable")}
                                        arrowPointAtCenter
                                        content={
                                          <div
                                            style={{
                                              maxWidth: "100%", // Initially, use full width
                                              overflow: "auto",
                                              whiteSpace: "pre-wrap", // Preserve line breaks in the content
                                            }}
                                          >
                                            {t(
                                              "A station can be reserved, but the parking space must be guaranteed to be available. If a station cannot be reserved, it can still be loaded using a QR scan."
                                            )}
                                          </div>
                                        }
                                        trigger="hover" // Show popover on hover
                                      >
                                        <InfoCircleOutlined />
                                      </Popover>
                                    </Button>
                                  </div>
                                </Col>
                              </Row> */}
                            </>
                          )}
                          {activeParking && (
                            <>
                              <Row gutter={[25, 25]}>
                                <Col xs={24} md={8}>
                                  <TextField
                                    id="moonShineParkingRate"
                                    label={t("Standard Tarif (€/h)")}
                                    color="success"
                                    value={getFormattedValue(
                                      values?.moonShineParkingRate ||
                                      Json?.moonShineParkingRate ||
                                      valuesInitialState?.moonShineParkingRate ||
                                      ""
                                    )}
                                    defaultValue={4.95} // Set the default value here
                                    onChange={handleChange(
                                      "moonShineParkingRate"
                                    )}
                                    onBlur={handleBlur}
                                    error={errors.moonShineParkingRate} // Set the error prop based on the specific field's error state
                                    helperText={
                                      errors.moonShineParkingRate &&
                                      "Please enter a numeric value between 1 and 100."
                                    } // Display the error message if there's an error
                                    fullWidth
                                    focused
                                  />
                                </Col>

                                <Col xs={24} md={8}>
                                  <TextField
                                    id="Power"
                                    label={t("Leistung (kW)")}
                                    color="success"
                                    value={getFormattedValue(
                                      values?.power ||
                                      Json?.power ||
                                      valuesInitialState?.power ||
                                      ""
                                    )}
                                    onChange={handleChange("power")}
                                    onBlur={handleBlur}
                                    fullWidth
                                    focused
                                  />
                                </Col>
                              </Row>

                              <Divider />

                              <Row gutter={[25, 25]}>
                                <Col
                                  xs={24}
                                  md={4}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Button
                                    type="primary"
                                    onClick={() => handleOptionChange()}
                                    style={ecoTariffButtonStyles}
                                  >
                                    {t("with ECO-TARIF")}
                                    <Popover
                                      placement="rightTop"
                                      title={t("With ECO-TARIF")}
                                      arrowPointAtCenter
                                      content={
                                        <div
                                          style={{
                                            maxWidth: "100%", // Initially, use full width
                                            overflow: "auto",
                                            whiteSpace: "pre-wrap", // Preserve line breaks in the content
                                          }}
                                        >
                                          {t(
                                            "The Eco Tariff serves as the second price point. This can be a more favorable time window for charging for e-car drivers and at the same time cover the hours when the charging current is particularly favorable for the provider, for example at times when there is a surplus of PV power."
                                          )}
                                        </div>
                                      }
                                      trigger="hover" // Show popover on hover
                                    >
                                      <InfoCircleOutlined />
                                    </Popover>
                                  </Button>
                                </Col>
                                <Col xs={24} md={4}>
                                  {AddEcoTariff && (
                                    <TextField
                                      id="sunShineParkingRate"
                                      label={t("Eco-Tarif (€/h)")}
                                      color="success"
                                      value={getFormattedValue(
                                        values?.sunShineParkingRate ||
                                        Json?.sunShineParkingRate ||
                                        valuesInitialState?.sunShineParkingRate ||
                                        ""
                                      )}
                                      defaultValue={3.3} // Set the default value here
                                      onChange={handleChange(
                                        "sunShineParkingRate"
                                      )}
                                      onBlur={handleBlur}
                                      error={errors.sunShineParkingRate} // Set the error prop based on the specific field's error state
                                      helperText={
                                        errors.sunShineParkingRate &&
                                        "Please enter a numeric value between 1 and 100."
                                      } // Display the error message if there's an error
                                      fullWidth
                                      focused
                                    />
                                  )}
                                </Col>
                              </Row>
                              {/* <Divider />
                              <Row gutter={[25, 25]} xs={24} md={4}>
                                <Col xs={24} md={4}>
                                  <Button
                                    onClick={toggleSelection}
                                    style={is_AdHocButtonStyles}
                                  >
                                    {t("Station is reservable")}
                                    <Popover
                                      placement="rightTop"
                                      title={t("Station is reservable")}
                                      arrowPointAtCenter
                                      content={
                                        <div
                                          style={{
                                            maxWidth: "100%", // Initially, use full width
                                            overflow: "auto",
                                            whiteSpace: "pre-wrap", // Preserve line breaks in the content
                                          }}
                                        >
                                          {t(
                                            "A station can be reserved, but the parking space must be guaranteed to be available. If a station cannot be reserved, it can still be loaded using a QR scan."
                                          )}
                                        </div>
                                      }
                                      trigger="hover" // Show popover on hover
                                    >
                                      <InfoCircleOutlined />
                                    </Popover>
                                  </Button>
                                </Col>
                              </Row> */}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>

                    <Divider />
                  </div>
                  <div
                    className="map-container-wrapper"
                    style={{ width: "100%" }}
                  >
                    <h1 style={{ fontSize: "1.5rem", padding: "0%" }}>
                      { }
                      {AddEcoTariff
                        ? t("SliderOpeningHours")
                        : t("Opening hours")}
                    </h1>

                   
                  </div>
                </div>
              )} <Slider AddEcoTariff={AddEcoTariff} />
            </div>
          </div>
        </Box>
        <Divider />
      </form>
    </React.Fragment>
  );
}

export default Parking;
