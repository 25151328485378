
import { MenuItem as MuiMenuItem, MenuList, Stack, Paper, Box } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BASE_URL } from '../../Settings';
import ConnectImg from "./Smartphoneconnect.png";
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountMenu from "./AccountMenu";
import MenuLeft from "../Nav/MenuLeft";
import '../../../src/css/App.css';

const ConnectMobile = () => {
	const [qrCodeData, setQrCodeData] = useState('');
	localStorage.setItem('activePage', 'ConnectMobile');
	const [cookies, setCookie] = useCookies();
	var token = Cookies.get("token");
	var decoded = jwt_decode(token);
	var guid = decoded.jti;
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		const fetchData = async () => {
			try {

				console.log("1");
				setQrCodeData(`${BASE_URL}/Sticker/GetQrCode/${guid}/${token}`);
				//console.log(qrCodeData);
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const handleNextClick = () => {
		navigate('/ChargeLocation');
	};
	// useEffect(() => {
	//   const api = axios.create({
	//     baseURL: BASE_URL
	//   });

	//   const fetchData = async () => {
	//     try {
	//       const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
	//       // Handle the response here
	//       setData(response.data);
	//     } catch (error) {
	//       // Handle any errors that occur during the request
	//       console.error(error);
	//     }
	//   };

	//   fetchData();











	return (
		<>

			<table style={{ width: "100%" }}>
				<Row>
					<Col xs={24} md={5}>
						<AccountMenu initialSelectedPage="Smartphone" ></AccountMenu>
					</Col>


					<Col xs={24} md={16}>
						<Box
							component="div"
							sx={{
								backgroundColor: '#f5f5f5',
								margin: "5% 10%",
								border: '1px solid #e0e0e0',
								borderRadius: '1vw'
							}}
								
						>
						<div style={{ padding: '2%' }}>
							<Col style={{ width: "90%"}}>
								<div style={{
									padding: "0% 5vw",
									width: 600,
									maxWidth: "100%"
								}}>
									<h1 style={{
										fontSize: "1.5rem",
										fontWeight: 50,
										lineHeight: 1,
									}} ><b>{t('Smartphone Registrierung')}</b>
									</h1>
									<b> ChargeAtFriends App {t('installieren')}</b><br></br><br></br>

									{t('ScanQRCodeText')}

									{/* Render the QR code or use the `qrCodeData` as needed */}
									<Divider></Divider>
									<img style={{ width: '230px' }} src={ConnectImg} alt="QR Code" />
									{/* <img style={{ width: '230px' }}  src={img}/> */}
								</div>
								<Divider></Divider>
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										
										variant="contained"
										type="submit"
										color="primary"
										onClick={handleNextClick}
									>
										{t('Continue to the charging station')}
									</Button>
								</div>


						    </Col>
						</div>
					</Box>
					</Col>
			</Row>

			</table>


		</>
	);
};

export default ConnectMobile;