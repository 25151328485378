import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios, { isCancel, AxiosError } from 'axios';
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { Divider, Col, Row } from 'antd';
const Admins = () => {
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    const [admins, setAdmins] = useState([]);
    const [role, setRole] = useState({ name: "", value: 0 });
   
    const navigate = useNavigate();
    useEffect(() => {
        const api = axios.create({
            baseURL: BASE_URL
        });

        const fetchData = async () => {
            try {
                const response = await api.get(`User/GetAdmins/${ownerGuid}/${token}`);

                console.log("Company", response.data);
                setAdmins(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []); // This effect updates the admins state
    useEffect(() => {
        // Use this effect to set the role when admins change
        const adminWithRolesBitMask6 = admins.find(admin => admin.rolesBitMask === 6);

        if (adminWithRolesBitMask6) {
            setRole({ name: "Owner", value: 4 });
        }
    }, [admins]); // This effect depends on the admins state
  
 
    

    const handleRemoveAdmin = async (role ,guid) => {
        const api = axios.create({
            baseURL: BASE_URL
        });
       
        try {
            const response = await api.delete(`Company/RemoveRole/${ownerGuid}/${guid}/${role}/${token}`);
            console.log("Company", response.data);
            const updatedAdmins = admins.filter((admin) => admin.rolesBitMask !== role);
            setAdmins(updatedAdmins);
            const fetchData = async () => {
                try {
                    const response = await api.get(`User/GetAdmins/${ownerGuid}/${token}`);

                    console.log("Company", response.data);
                    setAdmins(response.data);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchData();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
        <div style={{ width: '90%', padding:"3%"}}>

            
                <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                    {t('List of admins')}
                </h1>
           
        <TableContainer component={Paper}>
            <Table>
                            <TableHead sx={{
                                backgroundColor: "#5faa39",
                                border: "1px solid white", borderRadius: "1%"
                            }} >
                    <TableRow>
                                    <TableCell sx={{ width: ' 40%', color: "white", borderRight: "1px solid white"  }}>Account</TableCell>
                                    <TableCell sx={{ width: '30 %', color: "white", borderRight: "1px solid white" }}>Role</TableCell>
                                    <TableCell sx={{ width: '30 %', color: "white", borderRight: "1px solid white" }}>Actions</TableCell>
                    </TableRow>
                </TableHead >
                            <TableBody sx={{
                               
                                border: "1px solid white", borderRadius: "1%"
                            }}>
                                {admins.map((admin, index) => (
                        <TableRow key={admin.id}>
                                        <TableCell sx={{ borderRight: "1px solid white", width: ' 40%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>{admin.email}</TableCell>
                                        <TableCell sx={{ borderRight: "1px solid white", width: ' 30%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)', }}>{admin.rolesBitMask === 6 ? role.name : admin.roles}</TableCell>
                            <TableCell align="center" sx={{ width: '30 %', display: 'flex', alignItems: 'center', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ backgroundColor: '#FF3333', color: 'white', width: '100%' }}
                                                onClick={() => handleRemoveAdmin( admin.rolesBitMask === 6 ? role.value : admin.rolesBitMask ,admin.guid )}
                                >
                                                Remove {admin.rolesBitMask === 6 ? role.name : admin.roles}
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            </div>   

                <Divider />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    type="primary"
                    color="primary"
                    onClick={() => {
                       
                        navigate("/CompanyUsers")

                       
                    }}
                  
                   
                >
                    {t("Add User")}
                    </Button>
                </div>
                
        </>
       
    );
};

export default Admins;
