import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Nouislider from 'nouislider-react';
import '../../../node_modules/nouislider-react/node_modules/nouislider/distribute/nouislider.min.css';
import '../../../node_modules/nouislider-react/node_modules/nouislider/distribute/nouislider.css';
import wNumb from 'wnumb';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button } from '@mui/material';
// import { Button } from '@mui/material';

import "../../css/ChargeLocation.css";
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import NewSliderJson from "../services/slider.json";
import NewSliderNoEcoTarriffJson from "../services/sliderNoEcoTariff.json";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
const Slider = (HasEcoTariff) => {

	console.log("HasEcoTariff", HasEcoTariff)

	const [checkboxes, setCheckboxes] = useState([]);
	const { t } = useTranslation();
	console.log("HasEcoTariff.AddEcoTariff", HasEcoTariff.AddEcoTariff)
	// let initialData;
	// if (HasEcoTariff.AddEcoTariff === false) {
	//   initialData = NewSliderNoEcoTarriffJson;
	// } else {
	//   initialData = NewSliderJson;
	// }
	// console.log("initialData",initialData)

	// const [sliders, setSliders] = useState(0); // Number of sliders
	// const format = wNumb({ decimals: 2, mark: ':' });

	// const [isButtonDisabled, setButtonDisabled] = useState(false);
	// const [isButtonClicked, setButtonClicked] = useState(false);
	// const weekday = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

	// const [newAPdata, setData] = useState([]);
	// const [apValues, setAccessProfile] = useState([NewSliderJson]);
	// const [checkboxValues, setCheckboxValues] = useState(newAPdata);


	const [sliders, setSliders] = useState(0); // Number of sliders
	const format = wNumb({ decimals: 2, mark: ':' });
	const [apValues, setAccessProfile] = useState([]);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isButtonClicked, setButtonClicked] = useState(false);
	const weekday = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
	const [newAPdata, setData] = useState([NewSliderJson]);
	const [checkboxValues, setCheckboxValues] = useState(newAPdata);
	console.log("apValues", apValues);
	console.log("newAPdata", newAPdata);
	console.log("sliders", sliders);
	// Based on token decrypted guid 
	var token = Cookies.get('token');
	var decoded = jwt_decode(token);
	var guid = Cookies.get("ownerGuid");
	var weekdays = 0;
	var remainingDays = 127;
	//End Based on token decrypted guid 

	// below code is for getting new cpguid
	let Json = JSON.parse(localStorage.getItem('CpLocationJson'));


	let SliderJson;

	const { state } = useLocation();
	let valuesInitialState = state || Json;

	if (state) {
		const { partitionKey: guid, rowKey: clguid } = state;
		valuesInitialState = state;
	} else {
		valuesInitialState = Json;
		console.log("state is null or undefined");
	}

	console.log("initial Slider Json", Json)


	//#region  Load initial Json and retrive data from AccessProfile/AccessProfilesByCl API
	useEffect(() => {
		console.log("valuesInitialState.guid", Json.guid)
		axios
			.get(`${BASE_URL}/AccessProfile/AccessProfilesByCl/${Json.guid}/${token}`)
			.then(function (response) {
				// localStorage.setItem('SliderJson', JSON.stringify(response.data));
				// SliderJson = JSON.parse(localStorage.getItem('SliderJson'));
				console.log("useEffect accessProfile", response.data)
				// setAfterEditJson(CPguidJson);
				UpdateAccessProfile(response.data);

			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	//#endregion

	//#region  Update Access Profile and set AccessProfile also set Remaining Days in Default Json

	const UpdateAccessProfile = (accessProfiles) => {
		// Clear the state before setting new data
		console.log("update accessProfiles", accessProfiles)
		if (HasEcoTariff.AddEcoTariff === false) {
			console.log("1")
			setData([NewSliderNoEcoTarriffJson]);
		}
		else {
			console.log("2")
			setData([NewSliderJson]);
		}

		setAccessProfile(accessProfiles);
		weekdays = accessProfiles.reduce((wd, accessProfile) => wd | accessProfile.weekday, 0);
		console.log("weekdays ", weekdays);
		remainingDays = 127 - weekdays;
		console.log("remainingDays ", remainingDays);
		SetDefaultJson(remainingDays);

		setButtonDisabled(remainingDays <= 0); // Disable button if remainingDays is less than or equal to 0


	};

	//#endregion

	//#region  Set Default Json and update remainingDays
	const SetDefaultJson = (remainingDays) => {

		// Set the new data
		setData(prevData => {
			const newData = [...prevData];
			newData['0'].clGuid = valuesInitialState.guid;
			newData['0'].partitionKey = valuesInitialState.guid;
			newData['0'].weekday = remainingDays;

			return newData;
		});
		console.log("SetDefaultJson", newAPdata)
		setSliders(newAPdata);
	}
	//#endregion

	const getTime = (seconds) => {
		return Math.round(seconds / 3600);
	}



	const getSliderValuesFromAP = (accessProfile) => {

		// if (HasEcoTariff.AddEcoTariff === false) {
		//   accessProfile = NewSliderNoEcoTarriffJson;
		// } else {
		//   accessProfile = accessProfile;
		// }
		console.log("accessProfile initial", accessProfile)
		var tSlots = new Array();
		var tConnect = [false];
		var hitFirst = false;
		var starts = accessProfile.starts;
		var pvS = accessProfile.pvS;
		var pvE = accessProfile.pvE;
		var ends = accessProfile.ends;
		if (starts != null) {

			if (starts != -1) {
				tSlots.push(getTime(starts));
				hitFirst = true;
			}
			if (pvS != -1) {
				if (hitFirst) { tConnect.push(true); }
				tSlots.push(getTime(pvS));
				hitFirst = true;
			}
			if (pvE != -1) {
				tConnect.push(true);
				tSlots.push(getTime(pvE));
			}
			if (ends != -1) {
				tConnect.push(true);
				tSlots.push(getTime(ends));
			}
			tConnect.push(false);
		} else {
			tSlots = [0, 10, 18, 24];
			tConnect = [false, true, true, true, false];
		}
		return tSlots;
	}
	const getConnectArray = (numHandles) => {
		const connectArray = new Array(numHandles - 1).fill(true);
		connectArray.unshift(false);
		connectArray.push(false);
		return connectArray;
	};

	// #region  commented getSliderValuesFromAP old code



	// #region  handleslide events and call API for update the value 
	//const handleSlide = async (e, updatedAccessProfile, rowIndex) => {

	//  console.log("handleSlide: ", e, updatedAccessProfile);
	//  updatedAccessProfile.starts = e[0] * 3600;
	//  updatedAccessProfile.pvS = e[1] * 3600;
	//  updatedAccessProfile.pvE = e[2] * 3600;
	//  updatedAccessProfile.ends = e[3] * 3600;
	//  ServerUpdate(updatedAccessProfile);

	//  };
	// const handleSlide = async (e, updatedAccessProfile, rowIndex) => {
	//     console.log("handleSlide: ", e, updatedAccessProfile);
	//     updatedAccessProfile.starts = e[0] * 3600;
	//     updatedAccessProfile.pvS = e[1] * 3600;
	//     updatedAccessProfile.pvE = e[2] * 3600;
	//     updatedAccessProfile.ends = e[3] * 3600;

	//     updatedAccessProfile = {
	//         ...updatedAccessProfile,
	//         starts: e[0] * 3600,
	//         pvS: isNaN(updatedAccessProfile.pvS) ? -1 : e[1] * 3600,
	//         pvE: isNaN(updatedAccessProfile.pvE) ? -1 : e[2] * 3600,
	//         ends: e[1] * 3600,
	//     };
	//     console.log(" updatedAccessProfile  ", updatedAccessProfile);
	//     ServerUpdate(updatedAccessProfile);



	// };
	const handleSlide = async (e, updatedAccessProfile, rowIndex) => {
		console.log("load handleSlide: ", e, updatedAccessProfile);
		console.log("HasEcoTariff.AddEcoTariff", HasEcoTariff.AddEcoTariff)

		if (!HasEcoTariff.AddEcoTariff) {
			updatedAccessProfile = {
				...updatedAccessProfile,
				starts: e[0] * 3600,
				pvS: isNaN(updatedAccessProfile.pvS) ? -1 : updatedAccessProfile.pvS,
				pvE: isNaN(updatedAccessProfile.pvE) ? -1 : updatedAccessProfile.pvE,
				ends: e[1] * 3600,
			};
		}
		else {
			updatedAccessProfile.starts = e[0] * 3600;
			updatedAccessProfile.pvS = e[1] * 3600;
			updatedAccessProfile.pvE = e[2] * 3600;
			updatedAccessProfile.ends = e[3] * 3600;
		}

		console.log("after handleSlide  ", updatedAccessProfile);
		ServerUpdate(updatedAccessProfile);



	};


	//#endregion

	//#endregion



	//#region  Handle checkbox change events and call API for AccessProfile/UpdateAccessProfileByCl
	const handleCheckboxChange = async (e, accessProfile, rowIndex) => {

		const isChecked = e.target.checked;
		const value = e.target.value;
		var day = 2 ** rowIndex;

		// disable and enable logic start here  
		const updatedCheckboxValues = [...checkboxValues];
		updatedCheckboxValues[rowIndex] = e.target.checked;
		setCheckboxValues(updatedCheckboxValues);

		if (!isChecked) {
			accessProfile.weekday = (accessProfile.weekday | day) - day;
		}
		else {
			accessProfile.weekday = accessProfile.weekday | day;
		}
		// API call
		console.log("handleCheckboxChange accessProfile ", accessProfile)
		setSliders(accessProfile);
		setData([accessProfile]);
		ServerUpdate(accessProfile);

	};

	//#endregion

	//#region Below code is for update value in database with AccessProfile/UpdateAccessProfileByCl API

	const ServerUpdate = async (accessProfile) => {
		// API call
		let clGuid = accessProfile.clGuid || Json.guid
		console.log("clGuid", clGuid)
		try {
			console.log("ServerUpdate accessProfile", accessProfile)
			const response = await axios.put(`${BASE_URL}/AccessProfile/UpdateAccessProfileByCl/${guid}/${clGuid}/${token}`, accessProfile)
				.then(function (response) {
					// localStorage.setItem('SliderJson', JSON.stringify(response.data));
					// SliderJson = JSON.parse(localStorage.getItem('SliderJson'));
					console.log("Chargepoint database Json", response.data)
					UpdateAccessProfile(response.data);
					console.log("avalues", apValues)
				});
		} catch (error) {
			// Handle any error that occurred during the API call
			console.error(error);
		}
	}
	// #endregion


	const CheckboxLogic = (accessProfile, dayIndex) => {
		var day = 2 ** dayIndex;
		// console.log("accessProfile.weekday & day == day;",(accessProfile.weekday & day) == day )
		// console.log("accessProfile;",accessProfile );

		return (accessProfile.weekday & day) == day;
	}


	const handleNewSlide = async (e, updatedNewAccessProfile, rowIndex) => {
		console.log(" handleNewSlide  ", e, updatedNewAccessProfile);
		if (!HasEcoTariff.AddEcoTariff) {
			updatedNewAccessProfile = {
				...updatedNewAccessProfile,
				starts: e[0] * 3600,
				pvS: -1,
				pvE: -1,
				ends: e[1] * 3600,
			};
		}
		else {
			updatedNewAccessProfile.starts = e[0] * 3600;
			updatedNewAccessProfile.pvS = e[1] * 3600;
			updatedNewAccessProfile.pvE = e[2] * 3600;
			updatedNewAccessProfile.ends = e[3] * 3600;
		}
		console.log("after handlenewslide", updatedNewAccessProfile)
		setSliders(updatedNewAccessProfile);
		// ServerUpdate(updatedNewAccessProfile);

	};

	const handleNeueButtonClick = (e, value, index) => {

		console.log("value,index", e, value, index);
		console.log("handleNeueButtonClick sliders", sliders);

		const weekdaysum = (sliders[0]?.weekday || sliders.weekday);

		let UpdateData = {
			...sliders[index], // Copy the existing object properties
			weekday: weekdaysum, // Update the "starts" property by converting hours to seconds
			// clGuid: Json.guid,
			// partitionKey: Json.guid,
			// guid: "00000000-0000-0000-0000-000000000000"
		};

		console.log("updatedDataNewButton:", sliders);
		setButtonClicked(true);
		ServerUpdate(sliders);
	};
	// Function to create formatters array for the tooltips
	const getTooltipsFormat = (numHandles) => {
		const tooltipsFormat = new Array(numHandles).fill(format);
		return tooltipsFormat;
	};

	return (
		<>

			<Table style={{ width: '100%' }}>
				<TableHead style={{ display: 'flex', flexDirection: 'row' }}>
					<TableRow>
						<TableCell style={{ width: '1px' }}>{t('Mo')}</TableCell>
						<TableCell style={{ width: '1px' }}>{t('Di')}</TableCell>
						<TableCell style={{ width: '1px' }}>{t('Mi')}</TableCell>
						<TableCell style={{ width: '1px' }}>{t('Do')}</TableCell>
						<TableCell style={{ width: '1px' }}>{t('Fr')}</TableCell>
						<TableCell style={{ width: '1px' }}>{t('Sa')}</TableCell>
						<TableCell >So</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{console.log("apValues", apValues)}
					{apValues.map((value, index) => (
						<TableRow >
							<TableCell key={index}>
								<div style={{ display: 'flex', flexDirection: 'row' }}>
									{weekday.map((day, index) => (
										<div key={index} style={{ width: '100%' }}>
											<Checkbox
												value={2 ** index}
												checked={CheckboxLogic(value, index)}
												// disabled={checkboxes[index]?.disabled}
												onChange={(e) => handleCheckboxChange(e, value, index)}
												// checked={!checkboxes.find((checkbox) => checkbox.day === day)}
												// disabled={checkboxes[index]?.disabled}
												// onChange={(e) => handleCheckboxChange(e, index)}
												key={index}
												name={String(index)} // Convert the index to a string.
											/>
										</div>
									))}
								</div>
							</TableCell>
							<TableCell style={{ width: '100%' }}>
								<Nouislider
									start={getSliderValuesFromAP(value)}
									range={{ min: 0, max: 24 }}
									step={1}
									connect={getConnectArray(getSliderValuesFromAP(value).length)} // Use getConnectArray to set the correct connect array
									tooltips={getTooltipsFormat(getSliderValuesFromAP(value).length)}
									onEnd={(e) => handleSlide(e, value, index)} // Pass the checkbox index to handleSlide if needed
									//onSlide={(e) => handleSlide(e, value, index)} // Pass the checkbox index to handleSlide if needed
								/>
							</TableCell>
						</TableRow>))
					}

					{newAPdata.map((value, index) => {
						{ console.log("new Value", value) }
						if (newAPdata[0].weekday > 0) {
							return (
								<TableRow key={index}>
									<TableCell>
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											{weekday.map((day, index) => (
												<div key={index} style={{ width: '100%' }}>
													<Checkbox
														value={2 ** index}
														checked={CheckboxLogic(value, index)}
														disabled={!checkboxValues[index]}
														name={String(index)} // Convert the index to a string.
													/>
												</div>
											))}
										</div>
									</TableCell>
									<TableCell style={{ width: '100%' }}>
										<Nouislider
											start={getSliderValuesFromAP(value)}
											step={1}
											range={{ min: 0, max: 24 }}
											connect={getConnectArray(getSliderValuesFromAP(value).length)} // Use getConnectArray to set the correct connect array
											tooltips={getTooltipsFormat(getSliderValuesFromAP(value).length)}
											onSlide={(e) => handleNewSlide(e, value, index)}
										/>
									</TableCell>
									<TableCell>
										<Button
											onClick={(e) => handleNeueButtonClick(e, value, index)}
											disabled={isButtonDisabled}
											variant="contained"
											type="button"
											htmlType="submit"
											color="primary"
										>{t('Save')}

										</Button>
									</TableCell>
								</TableRow>
							);
						}
						return null;
					})}
				</TableBody>
			</Table>
		</>
	);
};
export default Slider;
