import React, { useEffect, forwardRef,useRef } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, MenuItem , MenuList, Stack, Box } from '@mui/material';
import jwt_decode from "jwt-decode";
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GOOGLE_API_KEY } from '../../Settings';
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios, { isCancel, AxiosError } from 'axios';
import '../../../src/css/App.css';
import { useState } from "react";
import { Button } from '../../../node_modules/@mui/material/index';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import PhoneInput from 'react-phone-input-2'
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import AccountMenu from "./AccountMenu";
const Provider2 = forwardRef((isMounted, ref, currentStep) => {
	const inputRef = useRef();
	const [cookies, setCookie] = useCookies();
	localStorage.setItem('activePage', 'Provider');
	var token = Cookies.get("token");
	console.log("currentStep",currentStep);
	var decoded = jwt_decode(token);
	var guid = decoded.jti;
	const [data, setData] = useState([]);
	const [phone, setPhone] = useState('');
	const { t } = useTranslation();
	const navigate = useNavigate()
	const [connectedByMobile, setConnectedByMobile] = useState(false);
	const [address, setAddress] = useState('');
	const [selectedAddress, setSelectedAddress] = useState('');
	const [errors, setErrors] = useState('');
	const [connectedToStripe, setConnectedToStripe] = useState(false);
	const [hasCompany, setHasCompany] = useState(false);
	const [useAccountData, setUseAccountData] =useState(false);
	const [isValid, setIsValid] = useState(true);
	const [subjectToVAT, setSubjectToVAT] = useState(false);
	console.log("guid", guid);
	console.log("token", token);
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});

		const fetchData = async () => {
			try {
				const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
				// Handle the response here
				console.log("Provider", response.data)
				setSubjectToVAT(!response.data.isSmallBusiness)
				//setData(response.data);
				setPhone(response.data.phone);
				setHasCompany(response.data.hasCompany);
				console.log(data);
				setUseAccountData(true)
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchData();
	}, [useAccountData]);

	const handleCreateCompany = (event) => {
		navigate("../Company/Register");
	}
	


	const handlePlaceChanged = () => {
		const [place] = inputRef.current.getPlaces();
		if (place) {
			console.log("place", place.formatted_address);
			console.log("place details", place);
			updateFields(place.address_components);
			setAddress(place.formatted_address);
		}

	};

	//const validateForm = () => {
	//	let isValid = true;
	//	/*event.preventDefault();*/
	//	// Check for errors in each input field
	//	const hasFirstNameError = data.firstName === '' || (data.firstName && data.firstName.length > 40);
	//	const hasLastNameError = data.lastName === '' || (data.lastName && data.lastName.length > 40);
	//	const hasZipCodeError = data.zipCode === '' || (data.zipCode && data.zipCode.length > 8);
	//	const hasCityError = data.city === '' || (data.city && data.city.length > 40);
	//	const hasStreetError = data.street === '' || (data.street && data.street.length > 40);
	//	const hasStreetNumberError = data.streetNumber === '' || (data.streetNumber && data.streetNumber.length > 5);

	//	// If any of the fields have errors, do not proceed with the form submission
	//	if (hasFirstNameError || hasLastNameError || hasZipCodeError || hasCityError || hasStreetError || hasStreetNumberError) {
	//		console.log('Form has errors. Please check the input fields.');
	//		isValid = false;
	//		// Optionally, you can show an error message to the user here if needed.

	//	}
	//	return isValid;


	//}


	const updateFields = (addressComponents) => {
		

		let street = '';
		let postcode = '';
		let city = '';
		let streetNum = '';
		let countrySearch = '';
		// Loop through address components to find relevant fields
		addressComponents.forEach(component => {
			const types = component.types;
			if (types.includes('route')) {
				street = component.long_name;
			} else if (types.includes('postal_code')) {
				postcode = component.long_name;
			} else if (types.includes('locality')) {
				city = component.long_name;
			} else if (types.includes('street_number')) {
				streetNum = component.long_name;
			} else if (types.includes('country')) {
				countrySearch = component.long_name;
			}
		});
		setData({
			...data,
			street: street,
			zipCode: postcode,
			city: city,
			streetNumber: streetNum,
			country: countrySearch
			// You can add other fields here if needed
		});

		// Optionally, you can update the selectedAddress state with the formatted address
		// setSelectedAddress({...selectedAddress, formatted_address: formattedAddress});
	};


	const validateForm = (callback) => {
		let isValid = true;
		const newErrors = {};
		if (!data.firstName) {
			newErrors.firstName = t('Please enter your first name');
			isValid = false;
		}
		if (!data.lastName) {
			newErrors.lastName = t('Please enter your last name');
			isValid = false;
		}

		if (!data.zipCode) {
			newErrors.zipCode = t('Please enter the zip code');
			isValid = false;
		}

		if (!data.city) {
			newErrors.city = t('Please enter the city');
			isValid = false;
		}

		if (!data.street) {
			newErrors.street = t('Please enter the street');
			isValid = false;
		}

		if (!data.streetNumber) {
			newErrors.streetNumber = t('Please enter the house number');
			isValid = false;
		}

		if (!data.country) {
			newErrors.country = t('Please enter the country');
			isValid = false;
		}

		
		if (!address ) {
			
			newErrors.address = t('Please enter full address of your station');
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;

	};
	const handleSubmit = () => {
		/*event.preventDefault();*/
		// Check for errors in each input field
		const hasFirstNameError = data.firstName === '' || (data.firstName && data.firstName.length > 40);
		const hasLastNameError = data.lastName === '' || (data.lastName && data.lastName.length > 40);
		const hasZipCodeError = data.zipCode === '' || (data.zipCode && data.zipCode.length > 8);
		const hasCityError = data.city === '' || (data.city && data.city.length > 40);
		const hasStreetError = data.street === '' || (data.street && data.street.length > 40);
		const hasStreetNumberError = data.streetNumber === '' || (data.streetNumber && data.streetNumber.length > 5);

		// If any of the fields have errors, do not proceed with the form submission
		if (hasFirstNameError || hasLastNameError || hasZipCodeError || hasCityError || hasStreetError || hasStreetNumberError) {
			console.log('Form has errors. Please check the input fields.');
			
			// Optionally, you can show an error message to the user here if needed.
			return;
		}
		// If data.name is blank, replace it with null
		if (data.name === '') {
			data.name = '';
			console.log("name", data.name)
		}
		if (data.vatId === '') {
			data.vatId = '';
		}

		onFinish(data);


	};

	const onNext = (callback) => {
		// Add your onNext logic here
		console.log("hello wizard 23 ")
		// Check if all textfield values are provided


		// Submit data

		handleSubmit();

		callback();
	};
	React.useImperativeHandle(ref, () => ({
		onNext: onNext,
		validateForm: validateForm
	}));
	//const onNext = (callback) => {
	//	// Add your onNext logic here
	//	console.log("hello wizard 23 ")
	//	// Check if all textfield values are provided
	//	const allFieldsProvided = values.zipCode && values.city && values.street && values.streetNumber && values.address2;
	//	if (allFieldsProvided) {
	//		// Submit data
	//		onFinish();
	//	} else {
	//		// Show error message or handle incomplete form
	//		console.log("All textfield values are not provided.");
	//	}
	//	callback();
	//};



	//logic for googleapi search address
	const handleChange2 = (value) => {
		setAddress(value);
		setUseAccountData(true)

	};
	const handleStreetSelect = async (value) => {
		setAddress(value);
		
		try {
			const results = await geocodeByAddress(value);
			const selectedAddress = results[0];
			setSelectedAddress(selectedAddress);
			updateFields(selectedAddress);
		} catch (error) {
			console.error('Error fetching location', error);
		}
	};
	

	const onFinish = async (data) => {


		let formData = new FormData();
		let wizardStep = 2;
		formData.append('Name', data.name);
		formData.append('FirstName', data.firstName);
		formData.append('LastName', data.lastName);
		formData.append('ZipCode', data.zipCode);
		formData.append('City', data.city);
		formData.append('Street', data.street);
		formData.append('StreetNumber', data.streetNumber);
		formData.append('VatId', data.vatId);
		formData.append('Phone', phone);
		formData.append('Country', data.country);
		formData.append('IsSmallBusiness', subjectToVAT ? 0 : 1);
		const config = {
			headers: { 'content-type': 'multipart/form-data' }
		};

		try {
			console.log("BASE_URL", BASE_URL)
			const response = await axios.put(`${BASE_URL}/customer/UpdateAndCreateCustomer/${guid}/${token}?wizardStep=${wizardStep}`, formData, config);
			// Handle the response here
			console.log('Updated at:', response.data);
			console.log(data.stripeId, data.id);
			if (data.stripeId) {
				if (data.id) {
					/*navigate('/ChargeLocation');*/
					setConnectedByMobile(true);
					setConnectedToStripe(true);
				} else {
					/*navigate("/ConnectMobile");*/
					setConnectedToStripe(false);
				}
			} else {
				/*navigate("/Stripe");*/
			}
		} catch (error) {
			// Handle any errors that occur during the request
			console.error('There was an error!', error);
		}
	};
	//React.useImperativeHandle(ref, () => ({
	//	onNext: onNext
	//}));
	const [loading, setLoading] = useState(false);

	return (

		<>
			<div ref={ref} style={{ width: '100%' }}>
				<Row>


					<Col xs={24} md={24}>
						<div style={{ padding: '0% 1vw' }}>
							
								<div>
									

									<form >
										<TextField id="fullWidth" fullWidth label={t('Firma')} color="success" focused value={data.name}

											onChange={(e) => setData({ ...data, name: e.target.value })}
										/>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={12}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Vorname') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.firstName}
													onChange={(e) => setData({ ...data, firstName: e.target.value })}
												error={!!errors.firstName}
												helperText={errors.firstName}// Add error prop for both empty and length check
												/>
											</Col>
										<Col xs={24} md={12}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Nachname') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.lastName}
													onChange={(e) => setData({ ...data, lastName: e.target.value })}
												error={!!errors.lastName}
												helperText={errors.lastName} // Add error prop for both empty and length check
												/>
											</Col>
									</Row>
									<Divider />
									<Row>


											<LoadScript
												googleMapsApiKey="AIzaSyAAHm7DEwE1mM01dLkR8vzJLq4QcSHMdRM"
												libraries={["places"]}
										><Col xs={24} md={24}>
											<StandaloneSearchBox
												
													onLoad={ref => (inputRef.current = ref)}
													onPlacesChanged={handlePlaceChanged}
												style={{ width: '100%' }}
											>
												<TextField
													
													label={t('Address Search')}
														fullWidth
														focused
														error={!!errors.address}
														helperText={errors.address}
														placeholder="Search here for your full address"
													/>
												

												</StandaloneSearchBox>
											</Col>


											</LoadScript>

											
									</Row>

									<Divider />
									{data.street && (
										<>
										
				
										<Row gutter={[10, 25]}>
											<Col xs={24} md={16}>
												<TextField
													id="fullWidth"
													fullWidth
													label={t('Straße') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.street}
													onChange={(e) => setData({ ...data, street: e.target.value })}
														error={!!errors.street}
														helperText={errors.street} // Add error prop for both empty and length check
												/>

											</Col>
											<Col xs={24} md={8}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Hausnummer') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.streetNumber}
													onChange={(e) => setData({ ...data, streetNumber: e.target.value })}
														error={!!errors.streetNumber}
														helperText={errors.streetNumber} // Add error prop for both empty and length check
												/>
											</Col>
											
										</Row>

											<Divider />


											<Row gutter={[10, 25]}>
												<Col xs={24} md={8}>
													<TextField
														id="standard-basic"
														fullWidth
														label={t('Postleitzahl') + '*'} // Add an asterisk (*) to indicate it's a required field
														color="success"
														focused
														value={data.zipCode}
														onChange={(e) => setData({ ...data, zipCode: e.target.value })}
														error={!!errors.zipCode}
														helperText={errors.zipCode} // Add error prop for both empty and length check
													/>

												</Col>
												<Col xs={24} md={8}>
													<TextField
														id="fullWidth"
														fullWidth
														label={t('Ort') + '*'} // Add an asterisk (*) to indicate it's a required field
														color="success"
														focused
														value={data.city}
														onChange={(e) => setData({ ...data, city: e.target.value })}
														error={!!errors.city}
														helperText={errors.city} // Add error prop for both empty and length check
													/>

												</Col>
												<Col xs={24} md={8}>
													<TextField
														id="standard-basic"
														fullWidth
														label={t('Country') + '*'} // Add an asterisk (*) to indicate it's a required field
														color="success"
														focused
														value={data.country}
														onChange={(e) => setData({ ...data, country: e.target.value })}
														error={!!errors.country}
														helperText={errors.country} // Add error prop for both empty and length check
													/>

												</Col>
											</Row>
											<Divider />
											</>)}
									

										<Row gutter={[10, 25]}>
											<Col xs={24} md={8}>
												 
												<Checkbox
													checked={subjectToVAT}
													onChange={(e) => setSubjectToVAT(e.target.checked)}
													style={{ fontSize: '12px' }}
												/>
		<span style={{ marginLeft: '8px', fontSize: '14px' }}>{t('SubjectToVAT') + '**'}</span>

											</Col>
											<Col xs={24} md={8}>
											<TextField id="fullWidth" fullWidth label={t('USt.Id*') + '*'} color="success" focused value={data.vatId}
												onChange={(e) => setData({ ...data, vatId: e.target.value })} disabled={!subjectToVAT}
												/>
										</Col>
										
											<Col xs={24} md={8}>
												<PhoneInput
													fullWidth
													specialLabel={t('Phone')}
													country={'de'}
													value={data.phone}
													onChange={setPhone}
												/>
											
										</Col>

										</Row>

									<Divider />
									<Row>
										<Col xs={24} md={12}>
											<p>  {t('*Mandatory Field')}</p>
										</Col>
										<Col xs={24} md={12}>
											<p>  {t('VATInfo')}</p>
										</Col>
									</Row>
									</form>
								</div>
							

						</div>
					</Col>
				</Row>
			</div>


		</>
	);
});

export default Provider2;