
import { MenuItem, MenuList, Stack, Paper, Box } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import React, { useEffect, useState, forwardRef } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BASE_URL } from '../../Settings';
import { Button } from '@mui/material';
import '../../css/ChargeLocation.css';
import { useTranslation } from 'react-i18next';
import AccountMenu from "./AccountMenu";
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { isCompanyContextAtom } from '../../Header';
import { atom, useAtom, useSetAtom } from 'jotai';
import { Navigate } from 'react-router-dom';



const Stripe2 = forwardRef((isMounted, ref) => {
	var token = Cookies.get("token");
	
	localStorage.setItem('activePage', 'Stripe');
	var decoded = jwt_decode(token);
	var ownerGuid = Cookies.get("ownerGuid");
	const [data, setData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
	const [connectedByMobile, setConnectedByMobile] = useState(false);
	const [errors, setErrors] = useState({});

	let OCPPJson = JSON.parse(localStorage.getItem('CpLocationJson'));
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});
		
		const fetchData = async () => {
			try {
				const customerApi = `/Customer/GetCustomer/${ownerGuid}/${token}`;
				const companyApi = `/Company/GetCompany/${ownerGuid}/${token}`;

				const response = await api.get(isCompanyContext ? companyApi : customerApi);
				
				// Handle the response here
				console.log("response", response)
				setData(response.data);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchData();
	}, [data.stripeId]);
	const payoutLaterInStripe = (callback) => {
		console.log("console log in payoutLaterInStripe")
		let wizardStep = 1024;
		
		fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${ownerGuid}/${OCPPJson.guid}/${token}?wizardStep=${wizardStep}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(OCPPJson),
		}).then((response) => {
			if (response.ok) {


				localStorage.setItem('CpLocationJson', JSON.stringify(OCPPJson));
				

				// Server update was successful
			} else {
				console.error('There was an error!');
				// Handle error response from the server
			}
		});
		window.location.href = '/cockpit';
		callback();
	};

	const handleChargeLocationClick = () => {
		if (data.id) {
			navigate('/ChargeLocation');
			setConnectedByMobile(true);

		}
		navigate("/ConnectMobile");

	};
	const validateForm = () => {
		let isValid = true;
		let newErrors = {};

		// Check if any of the button states is not active

		if (!data.stripeId || data.stripeId == 'null') {
			console.log("data.stripe", data.stripeId)
			isValid = false;
			newErrors.meterType = t('please connect to stripe account for billing purpose');
			
		}
		



        setErrors(newErrors);
		return isValid;
	};
	const onNext = (callback) => {
		// Add your onNext logic here
		console.log(" wizard stripe ")
		
		callback();
	};
	React.useImperativeHandle(ref, () => ({
		onNext: onNext,
		validateForm: validateForm,
		payoutLaterInStripe: payoutLaterInStripe
	}));
	

	return (
		<>

			<table ref={ref}  style={{ width: "100%" }}>
				<Row>
					
					<Col xs={24} md={24}>
						
						<Box
							component="div"
							sx={{
								backgroundColor: '#f5f5f5',
								
								border: '1px solid #e0e0e0',
								borderRadius: '1vw',
							}}
						>
							<Col style={{ width: "90%" }}>   <div style={{
								padding: "0% 5vw",
								width: 600,
								maxWidth: "100%"
							}}>
								<h1
									style={{ fontSize: "1.5rem", fontFamily: "Segoe UI semibold", lineHeight: 3}}
									 >{t('Zahlungskonto')}</h1>

								{console.log("data.stripeId",data.stripeId) }

								{!data.stripeId || data.stripeId =='null' ? (
									<>
										<p style={{
											fontSize: "1rem",
											fontWeight: 10,
											
										}}>{t('stripeInfoOne')}

										</p>
										<br></br>
										<p style={{
											fontSize: "1rem",
											fontWeight: 10,											
										}}>{t('stripeInfoTwo')}

										</p>
										<br></br>
										<p>
											<a href={data.stripeRegisterLink} className="connect-button">
												<span>{t('Connect+ mit Stripe')}</span>
											</a>
										</p>
										{errors.meterType && <p style={{ color: "red" }} className="error">{errors.meterType}</p>}
										
									</>
								) : (

										<>
											<p style={{
												fontSize: "1rem",
												fontWeight: 10,
											}}>
												<span>{t('Your account is connected to our payment service provider Stripe')}</span>
											</p>
<br></br>
										<p>
											<a target="_blank" href="https://dashboard.stripe.com/login" className="connect-button">
													<span> {t('Log in to Stripe')}</span>
											</a>
										</p>
										<div className="row">
											<p></p>
											<p></p>
										</div>
										<div className="row">
											<p></p>
											<p></p>
										</div>
										
									</>

								)}



							</div>

							</Col>
							</Box>
						
					</Col>
				</Row>

			</table>


		</>
	);
});

export default Stripe2;