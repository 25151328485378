import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Switch, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import "../../css/UsersTable.css";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import AccountMenu from '../Account/AccountMenu';
import BackButton from '../Elements/BackButton';

const CompanyUsersProperties = () => {

    const navigate = useNavigate();
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const [memberships, setMemberships] = useState(null);
    const { state } = useLocation();
    const [selectedUser, setSelectedUser] = useState(state.user);
    console.log("selecteduser in prop ", selectedUser);
    const [roleState, setRoleState] = useState(null);
    const [userMemberships, setUserMemberships] = useState([]);
    const [loading, setLoading] = useState(true);
    const [adminSwitch, setAdminSwitch] = useState(0);
    const [ownerSwitch, setOwnerSwitch] = useState(0);
    const UserRoleState = async (user) => {
        console.log("user in role", user)
        // Update switch values based on user.bitMaskRole
        console.log("user.bitMaskRole", user.rolesBitMask)
        if (user.rolesBitMask === 6) {
            setAdminSwitch(1);
            setOwnerSwitch(1);
        } else if (user.rolesBitMask === 2) {
            setAdminSwitch(1);
            setOwnerSwitch(0);
        } else if (user.rolesBitMask === 4) {
            setAdminSwitch(0);
            setOwnerSwitch(1);
        } else {
            setAdminSwitch(0);
            setOwnerSwitch(0);
        }

    };
   
    const getUserMemberships = async (selectedUser) => {
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        try {
            // Make an API request to fetch user memberships
            const response = await api.get(`UserGroup/UserGroupMemberships/${ownerGuid}/${selectedUser.guid}/${token}`);
            setUserMemberships(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    const handleSwitchToggle = async (membership, user) => {
        console.log("user update switch", user)
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        if (membership.isMember) {

            const response = await api.delete(`User/RemoveGroupMembership/${ownerGuid}/${user}/${membership.rowKey}/${token}`)
            setMemberships(false)

        } else {
            const response = await api.post(`User/AddGroupMembership/${ownerGuid}/${user}/${membership.rowKey}/${token}`)
            setMemberships(true)
        }
        // Update the local state to reflect the new status
        getUserMemberships(selectedUser);
    };

    const handleSwitchChange = async (switchName, newValue, selectedUser) => {
       
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        let newAdminSwitch = adminSwitch;
        let newOwnerSwitch = ownerSwitch;

        if (switchName === 'admin') {
            newAdminSwitch = newValue;
        } else {
            newOwnerSwitch = newValue;
        }

        let newRole = 0;

        if (newAdminSwitch === 1) {
            newRole += 2;
            setRoleState(newRole);
        }

        if (newOwnerSwitch === 1) {
            newRole += 4;
            setRoleState(newRole);
        }
        console.log("user.guid", selectedUser)

        try {
            // Update the API endpoint and payload as needed
            const response = await api.put(`User/UpdateRole/${ownerGuid}/${selectedUser}/${newRole}/${token}`)
            // Handle the response as needed
        } catch (error) {
            console.error(error);
        }

        setAdminSwitch(newAdminSwitch);
        setOwnerSwitch(newOwnerSwitch);
    };
    useEffect(() => {


        UserRoleState(selectedUser);
        getUserMemberships(selectedUser)
    }, []);





    return (
        <div >
            <Row  >
                <Col xs={24} md={5}
                >

                    <AccountMenu initialSelectedPage="Company"  ></AccountMenu>

                </Col>
                <Col xs={24} md={19} >

                    <>
                        <div style={{
                            width: "50%",
                          
                            margin: '1% 2%',
                        }} >
                            <Row>
                                <BackButton navigateLink={"/CompanyUsers"}></BackButton>
                            </Row>
                            <Divider></Divider>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        value={selectedUser.firstName}
                                        disabled
                                    />
                                    <div style={{ marginBottom: '16px' }}></div>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        value={selectedUser.lastName}
                                        disabled
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        value={selectedUser.email}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{
                            width: "50%",
                           
                            margin: '1% 2%',
                        }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                    <Typography>Admin</Typography>
                                    <Switch
                                        checked={adminSwitch}
                                        onChange={(e) => {
                                            const newValue = e.target.checked ? 1 : 0;
                                            handleSwitchChange('admin', newValue, selectedUser.guid);
                                            console.log("selectedUser.guid", selectedUser.guid);
                                        }}
                                    />

                                    <div style={{ marginBottom: '16px' }}></div>
                                    <Typography>Owner</Typography>
                                    <Switch
                                        checked={ownerSwitch}
                                        onChange={(e) => {
                                            const newValue = e.target.checked ? 1 : 0;
                                            handleSwitchChange('owner', newValue, selectedUser.guid);
                                            console.log("selectedUser.guid", selectedUser.guid);
                                        }}
                                    />
                                </div>



                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <table>
                                        <tbody>
                                            {loading ? (
                                                <tr>
                                                    <td>Loading...</td>
                                                </tr>
                                            ) : (
                                                userMemberships.map((membership) => (
                                                    <tr key={membership.id}>
                                                        <td>{membership.name}</td>
                                                        <td>
                                                            <Switch
                                                                checked={membership.isMember}
                                                                onChange={() => handleSwitchToggle(membership, selectedUser.guid)}
                                                                name="membershipSwitch"
                                                                inputProps={{ 'aria-label': 'Membership switch' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>
                </Col>
            </Row>
        </div>


    )
}
export default CompanyUsersProperties;