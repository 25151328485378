import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Settings";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Alert, Snackbar } from '@mui/material';
import { Divider, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import '../../../src/css/baseDataMenu.css';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom';
import { isCompanyContextAtom } from '../../Header';
import { useAtom, useAtomValue } from 'jotai';


import { Icon } from '@mui/material';
import BackButton from "../Elements/BackButton";
import AccountMenu from "../Account/AccountMenu";
const ToggleSwitch = ({ label, isEnabled, onToggle }) => (
	<div>
		<span>{label}</span>
		<label>
			<input
				type="checkbox"
				checked={isEnabled}
				onChange={onToggle}
			/>
			<span>{isEnabled ? 'On' : 'Off'}</span>
		</label>
	</div>
);

const PriceInput = ({ label, value, onChange }) => (
	<div>
		<label>
			{label}
			<input
				type="number"
				value={value}
				onChange={e => onChange(e.target.value)}
			/>
		</label>
	</div>
);
const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
}));
const InviteProperties = function (params) {
	const { state } = useLocation();
	const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
	const classes = useStyles();
	const [selectedInvite, setSelectedInvite] = useState(null);
	// const invite = params.invite;
	const [invite, setinvite] = useState(state.invite);
	console.log("InviteProperties ", invite)
	const InviteGuid = invite.id;
	var token = Cookies.get("token");
	var ownerGuid = Cookies.get("ownerGuid");
	const [userGroups, setUserGroups] = useState([]);
	const [isDomain, setIsDomain] = useState(state.invite.isDomainOnly);
	const [isGenericOrList, setIsGenericOrList] = useState(state.invite.usesList);


	const [isList, setIsList] = useState(false);
	const { t } = useTranslation();
	const [triggerApiCall, setTriggerApiCall] = useState(false);
	const [selectedUserGroup, setSelectedUserGroup] = useState(null);
	const [values, setValues] = useState();
	console.log("values", values)
	const [email, setEmail] = useState('');
	const [isInputValid, setIsInputValid] = useState(true);
	const [successMessage, setSuccessMessage] = useState(null);
	const [emailList, setEmailList] = useState([]);



	useEffect(() => {
		console.log("Effect is running");
		const api = axios.create({
			baseURL: BASE_URL,
		});

		const fetchData = async () => {
			try {
				console.log("InviteGuid ", InviteGuid)

				const response = await api.get(
					`Invite/InviteMembers/${ownerGuid}/${InviteGuid}/${token}`
				);
				console.log("members page", response.data);
				if (response.data === undefined) {
					// Display a proper message when response.data is null
					console.log("No user groups found");
				} else {
					setValues(response.data);

				}
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, []);

	//setSelectedInvite(invite);

	const callApiRead = async () => {
		const api = axios.create({
			baseURL: BASE_URL,
			headers: {
				'Content-Type': 'application/json', // Explicitly setting Content-Type header
			},
		});
		console.log("api", api)
		try {
			const data = {
				Name: "",
				RowKey: "",
				Description: "",
				PartitionKey: ""
			};
			const response = await api.get(`Invite/Invite/${ownerGuid}/${InviteGuid}/${token}`, JSON.stringify(data));

			console.log("response of read invite", response.data)
			setTriggerApiCall(false);
			//
			return response.data;
			// Reset trigger to avoid repeated calls

			// Optional: Additional logic after successful API call
		} catch (error) {
			console.error('Error in API call:', error);
			// Handle the error appropriately
		}

	}

	// Placeholder functions for API calls
	// const handleToggle = (setter) => (event) => {

	//  console.log("test",event.target.checked);
	// 	setter(event.target.checked);
	// 	setIsDomain(event.target.checked);
	// 	// callApiReadUserGroups();
	// 	// Here you will call your API to update the setting
	// };

	const handleToggle = (event) => {
		console.log("test", event.target.checked);
		setIsDomain(event.target.checked);
		// Clear the email value
		setEmail('');
	};
	const handleToggleGeneric = (event) => {
		console.log("test", event.target.checked);
		setIsGenericOrList(event.target.checked);
		const newValue = event.target.checked;
		console.log("handleToggleGeneric", event.target.checked);
		setinvite((prevValues) => {
			const textboxValue = {
				...prevValues,
				usesList: newValue, // XOR to toggle the bit
			};

			updateInviteAPI(textboxValue);
			return textboxValue; // Make sure to return the updated values
		});


	};

	const callApiReadUserGroups = async () => {
		const api = axios.create({
			baseURL: BASE_URL,
			headers: {
				'Content-Type': 'application/json', // Explicitly setting Content-Type header
			},
		});
		try {
			const response = await api.get(
				`UserGroup/GetUserGroups/${ownerGuid}/${token}`
			);
			console.log("callApiReadUserGroupsresponse", response.data);
			if (response.data === undefined) {
				// Display a proper message when response.data is null
				console.log("No user groups found");
			} else {

				setTriggerApiCall(false);
				setUserGroups(response.data);
				// setSelectedUserGroup(response.data);
			}
		} catch (error) {
			console.error(error);
		}
		console.log("UserGroup api", api)
		//try {
		//	const data = {
		//		Name: "",
		//		RowKey: "",
		//		Description: "",
		//		PartitionKey: ""
		//	};
		//	const response = await api.get(`UserGroup/GetUserGroups/${ownerGuid}/${token}`, JSON.stringify(data));
		//	setTriggerApiCall(false);
		//	if (response.data === undefined) {
		//		// Display a proper message when response.data is null
		//		console.log("No user groups found");
		//	} else {
		//		setUserGroups(response.data);
		//	}
		//	// Reset trigger to avoid repeated calls

		//	// Optional: Additional logic after successful API call
		//} catch (error) {
		//	console.error('Error in API call:', error);
		//	// Handle the error appropriately
		//}
	}

	const apiCallUpdateInvite = async () => {
		const api = axios.create({
			baseURL: BASE_URL,
			headers: {
				'Content-Type': 'application/json', // Explicitly setting Content-Type header
			},
		});
		try {
			const response = await api.put(`Invite/Update/${ownerGuid}/${token}`, JSON.stringify(invite));
			console.log("response", response.data);
			if (response.data === undefined) {
				// Display a proper message when response.data is null
				console.log("Invite not found");
			} else {

				setTriggerApiCall(false);
				invite = response.data;
				setinvite(response.data);

				callApiReadUserGroups();
			}
		} catch (error) {
			console.error(error);
		}
		console.log("UserGroup api", api)
		//try {
		//	const data = {
		//		Name: "",
		//		RowKey: "",
		//		Description: "",
		//		PartitionKey: ""
		//	};
		//	const response = await api.get(`UserGroup/GetUserGroups/${ownerGuid}/${token}`, JSON.stringify(data));
		//	setTriggerApiCall(false);
		//	if (response.data === undefined) {
		//		// Display a proper message when response.data is null
		//		console.log("No user groups found");
		//	} else {
		//		setUserGroups(response.data);
		//	}
		//	// Reset trigger to avoid repeated calls

		//	// Optional: Additional logic after successful API call
		//} catch (error) {
		//	console.error('Error in API call:', error);
		//	// Handle the error appropriately
		//}
	}

	const handleSwitchToggle = async (userGroup) => {
		console.log("handleSwitchToggle", userGroup);
		setSelectedUserGroup(userGroup);

		if (invite.userGroupId !== userGroup.guid) {
			// Only call handleClickSelect if userGroupId is different
			handleClickSelect(userGroup);
		}

		console.log("Updated selectedUserGroup:", selectedUserGroup);
		// Additional logic if needed
	};

	const handleClickSelect = function (userGroup) {
		console.log("handleClickSelect", userGroup.guid)
		console.log("invite.userGroupId", invite.userGroupId)

		if (invite.userGroupId == userGroup.guid) { return; }
		invite.userGroupId = userGroup.guid;
		apiCallUpdateInvite();
		//navigate("/UserGroupsMenu");
		//setEditInvite(true)
	};

	// var handleClickSelect = function (userGroup) {
	// 	if (invite.userGroupId == userGroup.guid) { return; }
	// 	invite.userGroupId = userGroup.guid;
	// 	apiCallUpdateInvite();
	// 	//navigate("/UserGroupsMenu");
	// 	//setEditInvite(true)
	// };

	// const handleSwitchToggle = async (userGroup) => {
	// 	console.log("handleSwitchToggle", userGroup);

	// 	try {
	// 	  const api = axios.create({
	// 		baseURL: BASE_URL,
	// 	  });

	// 	  const updatedUserGroups = userGroups.map((group) =>
	// 		group.id === userGroup.id
	// 		  ? { ...group, isMember: !group.isMember }
	// 		  : group
	// 	  );

	// 	  setUserGroups(updatedUserGroups);
	// 	} catch (error) {
	// 	  console.error("Error updating switch:", error);
	// 	}
	//   };

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		if (!isNaN(date.getTime())) {
			const day = date.getDate().toString().padStart(2, '0');
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const year = date.getFullYear();
			return `${day}/${month}/${year}`;
		}
		return '';
	};

	useEffect(() => {
		callApiReadUserGroups();

	}, []);

	const updateInviteAPI = async (updatedValue) => {
		try {
			console.log("updatedValue", updatedValue)

			const response = await axios.put(`${BASE_URL}/Invite/Update/${ownerGuid}/${token}`, updatedValue);

			console.log("Access profile updated successfully", response.data);

		} catch (error) {
			console.error("Error updating access profile", error);
		}
	};

	const handleTextboxBezeichungChange = (event) => {
		const newValue = event.target.value;
		console.log("newValue", event.target.value)
		setinvite((prevValues) => {
			const textboxValue = {
				...prevValues,
				name: newValue, // XOR to toggle the bit
			};

			updateInviteAPI(textboxValue);
			return textboxValue; // Make sure to return the updated values
		});
	};

	const handleTextboxmaxcountChange = (event) => {
		const newValue = event.target.value;
		console.log("handleTextboxmaxcountChange", event.target.value)

		setinvite((prevValues) => {
			const textboxValue = {
				...prevValues,
				maxNum: newValue, // XOR to toggle the bit
			};
			updateInviteAPI(textboxValue);
			return textboxValue; // Make sure to return the updated values
		});
	};
	// const handleExpiryDateChange = (event) => {
	// 	const newValue = event.target.value;
	// 	console.log("handleExpiryDateChange", event.target.value)

	// 	setinvite((prevValues) => {
	// 		const textboxExpValue = {
	// 			...prevValues,
	// 			expiryDate: newValue, // XOR to toggle the bit
	// 		};
	// 		updateInviteAPI(textboxExpValue);
	// 		return textboxExpValue; // Make sure to return the updated values
	// 	});
	// };

	const handleExpiryDateChange = (event) => {
		const newValue = event.target.value;
		console.log("handleExpiryDateChange", event.target.value);

		setinvite((prevValues) => {
			const textboxExpValue = {
				...prevValues,
				expiryDate: new Date(newValue).toISOString(), // Convert back to ISO string for storage
			};
			updateInviteAPI(textboxExpValue);
			return textboxExpValue; // Make sure to return the updated values
		});
	};

	const handleRemoveGroupMember = async (value) => {

		console.log("handleRemoveGroupMember", value)
		try {
			const response = await axios.delete(`${BASE_URL}/Invite/RemoveMember/${ownerGuid}/${value.rowKey}/${token}`);
			console.log("Member delete successfully", response.data);
			setSuccessMessage("Member delete successfully");
			setTimeout(() => {
				setSuccessMessage(null);
			}, 5000); // Adjust the delay as needed
			// Add response.data and remove the selected value
			setValues(prevValues => [...prevValues.filter(item => item !== value)]);


		} catch (error) {
			console.error("Failed to delete member", error);
		}

	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handleAddMember = async () => {
		try {
			console.log("InviteGuid", invite)
			let emailwithDomain;
			if (isDomain) {
				emailwithDomain = email + '@' + invite.domain;

			}
			else {
				emailwithDomain = email;
			}
			console.log("emailwithDomain", emailwithDomain)
			// Check if the email already exists in the values array
			const emailExists = values.some(value => value.eMail.toLowerCase() === emailwithDomain.toLowerCase());

			console.log("emailExists", emailExists)

			if (emailExists) {
				console.log("Email already exists in values array");
				setSuccessMessage("Email already exists, Please try with another Email Address!");
				setTimeout(() => {
					setSuccessMessage(null);
				}, 5000); // Adjust the delay as needed
				// Handle the case where the email already exists (e.g., show an error message)
				return;
			}


			const response = await axios.post(`${BASE_URL}/Invite/AddMember/${ownerGuid}/${InviteGuid}/${emailwithDomain}/${token}`);
			console.log("Member added successfully", response.data);
			setSuccessMessage("Member added successfully");
			setTimeout(() => {
				setSuccessMessage(null);
			}, 5000); // Adjust the delay as needed
			// Update the state with the new member data
			setValues(prevValues => [...prevValues, response.data]);
			setEmail('');
		} catch (error) {
			console.error("Failed to add member", error);
		}

	};
	const handleWithDomainEmailChange = (event) => {
		const inputValue = event.target.value;

		// Use a regular expression to check for letters only
		const isValidInput = /^[^@]+$/.test(inputValue);

		// Update the validation state
		setIsInputValid(isValidInput || inputValue === '');

		// If the input is valid, update the state
		if (isValidInput || inputValue === '') {
			setEmail(inputValue);
		}
	};

	const handleOpenInviteMail = async () => {

		try {
			const response = await axios.get(`${BASE_URL}/Invite/OpenInviteMail/${ownerGuid}/${InviteGuid}/${token}`);
			console.log("OpenInviteMail", response.data);

			if (typeof response.data === 'string' && response.data.trim() !== '') {
				// Open the email client window with the mailto link
				window.open(response.data, '_blank');
			} else {
				setSuccessMessage("Open Invite Mail not successfully");
				setTimeout(() => {
					setSuccessMessage(null);
				}, 5000); // Adjust the delay as needed
				console.error("API response is not a valid mailto link.");
			}

		} catch (error) {
			console.error("Failed to OpenInviteMail member", error);
		}

	};
	const handleCopyInviteMail = async () => {
		try {
			const response = await axios.get(`${BASE_URL}/Invite/CopyInviteLink/${ownerGuid}/${InviteGuid}/${token}`);
			const inviteLink = response.data;
			console.log("inviteLink", inviteLink);
			if (typeof response.data === 'string' && response.data.trim() !== '') {
				// Attempt to use the Clipboard API
				try {
					await navigator.clipboard.writeText(inviteLink);
					// alert('Invite link copied to clipboard!');
					setSuccessMessage("Invite link copied to clipboard!");
					setTimeout(() => {
						setSuccessMessage(null);
					}, 5000); // Adjust the delay as needed
				}
				catch (clipboardError) {
					console.error('Clipboard API error:', clipboardError);

					// Fallback: Use document.execCommand for browsers that don't support Clipboard API
					const textarea = document.createElement('textarea');
					textarea.value = inviteLink;
					document.body.appendChild(textarea);
					textarea.select();
					document.execCommand('copy');
					document.body.removeChild(textarea);

				}
			}
			else {
				setSuccessMessage("Invite link Not copied to clipboard!");
				setTimeout(() => {
					setSuccessMessage(null);
				}, 5000); // Adjust the delay as needed
				console.error("API response is not a valid mailto link.");
			}
		} catch (error) {
			console.error('Failed to copy invite link', error);
		}
	};
	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				const content = e.target.result;
				const emails = content.split('\n').map((email) => email.trim());
				setEmailList(emails);
			};

			reader.readAsText(file);
		}

		// Reset the file input value
		event.target.value = null;
	};

	// const handleUploadMail = async () => {
	// 	try {

	// 		for (const email of emailList) {
	// 			const emailwithDomain = email; // Modify this based on your email structure
	// 			const response = await axios.post(`${BASE_URL}/Invite/AddMember/${ownerGuid}/${InviteGuid}/${emailwithDomain}/${token}`);
	// 			setValues(prevValues => [...prevValues, response.data]);


	// 		}
	// 		// Clear emailList after uploading
	// 		setEmailList([]);
	// 	} catch (error) {
	// 		// Handle errors
	// 		console.error(error);
	// 	}
	// };

	const handleUploadMail = async () => {
		try {
			// Convert existing values emails to lowercase for case-insensitive comparison
			const lowercasedValues = values.map(value => value.eMail.toLowerCase());

			for (const email of emailList) {
				const lowercasedEmail = (email || '').toLowerCase().trim(); // Convert uploaded email to lowercase after trimming
				// Check if the email is not null, not empty, and does not consist only of whitespace
				if (lowercasedEmail !== '') {
					// Check if the email already exists in the values array
					if (!lowercasedValues.includes(lowercasedEmail)) {
						// Email does not exist, proceed with the API call
						const emailwithDomain = email; // Modify this based on your email structure
						const response = await axios.post(`${BASE_URL}/Invite/AddMember/${ownerGuid}/${InviteGuid}/${emailwithDomain}/${token}`);
						setValues(prevValues => [...prevValues, response.data]);
					}
				}
			}

			// Clear emailList after uploading
			setEmailList([]);
		} catch (error) {
			setEmailList([]);
			// Handle errors
			console.error(error);
		}
	};


	// const databaseDateFormat = "2023-11-28T10:14:32.204747+00:00";
	// const formattedDate = new Date(invite.expiryDate).toLocaleDateString('en-CA'); // Adjust the locale as needed
	const formattedDate = new Intl.DateTimeFormat('en-CA', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'UTC',
	}).format(new Date(invite.expiryDate));

	// const formattedDate = new Date(invite.expiryDate).toISOString().split("T")[0];
	// console.log("formattedDate",formattedDate)
	console.log("expiryDate", invite.expiryDate)
	const [hovered, setHovered] = useState(false);

	const handleMouseEnter = () => {
		setHovered(true);
	};

	const handleMouseLeave = () => {
		setHovered(false);

	};

	return (
		<div style={{
			width: "100%"

		}} ><Row>
			<Col xs={24} md={5}>
				<AccountMenu initialSelectedPage="Invites"  ></AccountMenu>
			</Col>
			<Col xs={24} md={19} >
					<div style={{
						width: "100%",
						border: "none",
						margin: '3% 0'

					}} >
			<Box
			component="div"
			sx={{
				margin: '1% 2%',
							}}>

							<Row > <BackButton 
								navigateLink={"/Invites"}></BackButton></Row>
							<Divider></Divider>
				<Snackbar
					open={!!successMessage}
					autoHideDuration={6000}
					onClose={() => setSuccessMessage(null)}
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
					<Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
						{successMessage}
					</Alert>
				</Snackbar>
				
						
				<Row>
					
					<Col style={{ width: "30%" }}>
						<div>
							<TextField
								label={t('Bezeichung')}
								variant="outlined"
								value={invite.name}
								onChange={handleTextboxBezeichungChange}

							/>
							{/*<div style={{ marginBottom: '16px' }}></div>*/}
							{/*<TextField*/}
							{/*	label={t('max count')}*/}
							{/*	variant="outlined"*/}
							{/*	value={invite.maxNum}*/}
							{/*	onChange={handleTextboxmaxcountChange}*/}

							{/*/>*/}
							<Row>
								<Col>
									<div style={{ padding: '20px' }} >
										<Typography>{t('Any/Domain')}</Typography>
										<Switch

											checked={isDomain}
											onChange={handleToggle}
											// onChange={handleToggle(setIsDomain)}
											color="Primary" // This is specific to Material-UI
										/>
									</div>
									<div>

									</div>
								</Col>

							</Row>
							<div style={{ marginBottom: '16px' }}>
								<TextField
									label={t('Domain')}
									variant="outlined"
									value={invite.domain}
									disabled={!isDomain} // Disable TextField if isDomain is false
									onChange={(e) => setinvite({ ...invite, domain: e.target.value })}

								/>
							</div>

							{/*<div style={{ marginBottom: '16px' }}>*/}
							{/*	<TextField*/}
							{/*		id="date"*/}
							{/*		label={t('Expires')}*/}
							{/*		type="date"*/}
							{/*		variant="outlined"*/}
							{/*		onChange={handleExpiryDateChange}*/}
							{/*		defaultValue={formattedDate}*/}
							{/*		InputLabelProps={{*/}
							{/*			shrink: true,*/}
							{/*		}}*/}
							{/*	/>*/}
							{/*</div>*/}
							<div style={{ padding: '20px' }} >
								<Typography>{t('Inactive / Active ')}</Typography>
								<Switch
									// checked={isDomain}
									onChange={handleToggleGeneric}
									// onChange={handleToggle(setIsDomain)}
									color="Primary" // This is specific to Material-UI
								/>
							</div>
						</div>

					</Col>
								<Col style={{ width: "30%" }}>
									{isCompanyContext &&( <div>
										<Typography style={{ color: 'green' }}>{t('Generic or list ')}</Typography>
										<Switch
											checked={isGenericOrList}
											onChange={handleToggleGeneric}
											color="Primary" // This is specific to Material-UI
										/>
									</div>)}
						
						<table className="coloredBorder">
							<tbody>
								<Typography style={{ color: 'green' }}>	Usergroup (single select)</Typography>

								{userGroups.map((userGroup) => (
									<tr key={userGroup.id}>
										<td onClick={() => handleClickSelect(userGroup)}>
											{userGroup.name}
										</td>
										<td>
											<Switch
												color="primary" // Use "primary" instead of "Primary"
												onChange={() => handleSwitchToggle(userGroup)}
												checked={invite.userGroupId === userGroup.guid}
												name="membershipSwitch"
												inputProps={{ 'aria-label': 'Membership switch' }}
											/>
										</td>
									</tr>
								))}


							</tbody>
						</table>
					</Col>
					<Col style={{ width: "40%" }}>
						{isGenericOrList ? (
							<>
								<Typography style={{ color: 'green' }}>	List</Typography>
								<div style={{ height: '300px', overflowY: 'auto' }}>
									<Table>
										<TableHead sx={{
											backgroundColor: "#5faa39",
											border: "1px solid white", borderRadius: "1%"
										}} >
											<TableRow>
												<TableCell sx={{ width: ' 40%', color: "white", borderRight: "1px solid white" }}>Email</TableCell>
												<TableCell sx={{ width: '30%', color: "white", borderRight: "1px solid white" }}>Actions</TableCell>
											</TableRow>
										</TableHead >
										<TableBody sx={{

											border: "1px solid white", borderRadius: "1%"
										}}>
											{values &&
												values
													.sort((a, b) => a.eMail.localeCompare(b.eMail))
													.map((value, index) => (

														<TableRow key={value.id} style={{ height: '5px' }}>
															<TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>{value.eMail}</TableCell>
															<TableCell sx={{ borderRight: "1px solid white", width: ' 20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>

																<IconButton
																	style={{ height: '5px' }}
																	color="secondary"
																	onClick={() => handleRemoveGroupMember(value)}>
																	<DeleteIcon fontSize="small"></DeleteIcon>
																</IconButton>
															</TableCell>
														</TableRow>
													))}
										</TableBody>
									</Table>
								</div>
								<div style={{ marginBottom: '16px' }}>
									{isDomain ? (
										<Table>
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography style={{ color: 'green' }}>Domain:</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>
														<TextField
															label={t('Email')}
															variant="outlined"
															value={email}
															fullWidth
															onChange={handleWithDomainEmailChange}
															error={!isInputValid}
															helperText={!isInputValid ? 'Must not contain “@”' : ''}
														/>
													</TableCell>
													<TableCell>@{invite.domain}</TableCell>
													<TableCell>
														<Button
															variant="contained"
															style={{ backgroundColor: "#5faa39", color: "white" }}
															onClick={handleAddMember}
														>
															{t('add')}
														</Button>
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									) : <Table>
										<TableHead>
											<TableRow>
												<TableCell>
													<Typography style={{ color: 'green' }}>Any:</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>
													<TextField
														label={t('Email')}
														variant="outlined"
														value={email}
														fullWidth
														onChange={handleEmailChange}
													/>
												</TableCell>
												<TableCell>
													<Button
														variant="contained"
														style={{ backgroundColor: "#5faa39", color: "white" }}
														onClick={handleAddMember}
													>
														{t('add')}
													</Button>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>}
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Row>
					<Col style={{ width: "60%" }}>
						<div >
							<TableRow>
								<TableCell><Button
									variant="contained"
									style={{ backgroundColor: "#5faa39", color: "white", padding: "6px 16px" }}
									onClick={handleOpenInviteMail}
								>
									{t('Open Invite Mail')}
								</Button></TableCell>
								<TableCell><Button
									variant="contained"
									style={{ backgroundColor: "#5faa39", color: "white", padding: "6px 16px" }}
									onClick={handleCopyInviteMail}
								>
									{t('COPY Invite Link')}
								</Button></TableCell>
							</TableRow>
						</div>
					</Col>
					<Col style={{ width: "40%" }}>
						<div>
							{isGenericOrList ? (
								<>
									<TableRow>
										<TableCell>
											<label htmlFor="fileInput">
												<input
													type="file"
													accept=".csv"
													id="fileInput"
													onChange={handleFileChange}
													style={{ display: 'none' }}
												/>
												<div
													style={{
														backgroundColor: "#5faa39",
														color: 'white',
														padding: '10px',
														cursor: 'pointer',
													}}
												>
													Bulk Email upload Click here
												</div>
											</label>

										</TableCell>
										<TableCell>
											<Button
												variant="contained"
												style={{ backgroundColor: "#5faa39", color: "white" }}
												onClick={handleUploadMail}
											>
												{t('Upload List')}
											</Button>
										</TableCell>
									</TableRow>
									<Typography style={{ color: 'red', font: 'small-caption' }}>* Please verify below Email List and click on "UPLOAD LIST" Button.</Typography>
									<Typography style={{ color: 'red', font: 'small-caption' }}>* Red Highlited Email are already in List.</Typography>

									<TableRow><TableCell><Typography style={{ color: 'green' }}>Email List:</Typography>
									</TableCell>
										<TableCell>
											<div>

												{/* <ul>
													{emailList.map((email, index) => (
														// <li key={index} style={{ color: 'green' }}>{email}</li>
														<li key={index} style={{ color: values.some(value => value.eMail === email) ? 'red' : 'green' }}>
															{email}
														</li>
													))}
												</ul> */}

												<ul>
													{emailList
														.filter(email => email && email.trim() !== '')
														.sort((a, b) => a.localeCompare(b))
														.map((email, index) => {
															const lowercaseEmail = email.toLowerCase(); // Convert current email to lowercase
															const isRed = values.some(value => value.eMail.toLowerCase().trim() === lowercaseEmail);

															return (
																<li key={index} style={{ color: isRed ? 'red' : 'green' }}>
																	{email}
																</li>
															);
														})}
												</ul>
											</div>
										</TableCell>
									</TableRow>  </>
							) : null}
						</div>
					</Col>
				</Row>
						</Box>
					</div>
				</Col>
			</Row>
		</div>
	);
};
export default InviteProperties;