import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Settings';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../css/ChargeStationTabs.css"; // You may adjust the CSS for styling
import AddIcon from '@mui/icons-material/Add';
import ChargeStationDetails from './ChargeStationDetails';
import ChargeStationDataContext from './ChargeStationDataContext';
import ChargePointsTabs from './ChargePointsTabs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

// Custom Tab Styles
const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#FFEF3F',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.7) !important',
        '&.Mui-selected': {
            color: '#fff !important',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32) !important',
        },
    }),
);

const ChargeStationTabs = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [data, setData] = useState([]);
    const { t } = useTranslation();
    const [displayedTabs, setDisplayedTabs] = useState([]);
    const [hiddenTabs, setHiddenTabs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [addMenuAnchorEl, setAddMenuAnchorEl] = useState(null);
    const [selectedTabData, setSelectedTabData] = useState(null);
    const [chargeStationData, setChargeStationData] = useState([]);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const [newName, setNewName] = useState('');

    const guid = Cookies.get("ownerGuid");
    const token = Cookies.get("token");

    useEffect(() => {
        axios
            .get(`${BASE_URL}/CpoLocation/Locations/${guid}/${token}`)
            .then(function (response) {
                console.log("chargelocation", response);
                setData(response.data);
                setDisplayedTabs(response.data.slice(0, 3)); // Display the first three stations initially
                setHiddenTabs(response.data.slice(3)); // Hide the rest of the stations
                if (response.data.length > 0) {
                    setSelectedTabData(response.data[0]);
                    setChargeStationData(response.data[0]); // Set data of the first tab
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [guid, token]);

    const handleTabClick = (index) => {
        setSelectedTab(index);
        const selectedTabId = displayedTabs[index].guid;
        console.log("selectedTabId", selectedTabId)
        axios
            .get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${selectedTabId}/${token}`)
            .then((response) => {
                setSelectedTabData(response.data);
                setChargeStationData(response.data); // Set data of the selected tab
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const open = Boolean(anchorEl);
    const addMenuOpen = Boolean(addMenuAnchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddClick = (event) => {
        setAddMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAddMenuAnchorEl(null);
    };

    const handleDuplicateClick = () => {
        setDuplicateDialogOpen(true);
        setAddMenuAnchorEl(null); // Close the menu
    };

    const handleNewClick = () => {
        setNewDialogOpen(true);
        setAddMenuAnchorEl(null); // Close the menu
    };

    const handleDialogClose = () => {
        setDuplicateDialogOpen(false);
        setNewDialogOpen(false);
    };

    const handleDuplicate = () => {
        axios.put(`${BASE_URL}/CpoLocation/DuplicateLocation/${guid}/${selectedTabData.guid}/${newName}/${token}`)
            .then(function (response) {
                console.log("log of update server", response.data);
                // Assuming the response contains the new duplicated tab data, update the displayedTabs
                setDisplayedTabs([...displayedTabs, response.data]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setDuplicateDialogOpen(false); // Close the dialog after API call
            });
    };

    const handleCreateNew = () => {
        axios.put(`${BASE_URL}/CpoLocation/CreateLocationEmpty/${guid}/${newName}/${token}`)
            .then(function (response) {
                console.log("log of update server", response.data);
                // Assuming the response contains the new tab data, update the displayedTabs
                setDisplayedTabs([...displayedTabs, response.data]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                setNewDialogOpen(false); // Close the dialog after API call
            });
    };

    const handleMenuItemClick = (index) => {
        // Move the selected item from hiddenTabs to displayedTabs
        const selectedItem = hiddenTabs[index];
        const newHiddenTabs = hiddenTabs.filter((_, i) => i !== index);
        setHiddenTabs(newHiddenTabs);
        setDisplayedTabs([...displayedTabs, selectedItem]);
        setAnchorEl(null); // Close the menu
    };

    return (
        <ChargeStationDataContext.Provider value={{ chargeStationData, setChargeStationData }}>
            <Box sx={{ bgcolor: "#61993A" }}>
                <div className="tabs-container">
                    <div className="tabs">
                        <StyledTabs value={selectedTab} onChange={(event, newValue) => handleTabClick(newValue)} aria-label="styled tabs example">
                            {displayedTabs.map((station, index) => (
                                <StyledTab key={index} label={station.address2} />
                            ))}
                        </StyledTabs>
                        {hiddenTabs.length > 0 && (
                            <div className="more-tab">
                                <IconButton
                                    aria-label="more"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {hiddenTabs.map((option, index) => (
                                        <MenuItem key={index} onClick={() => handleMenuItemClick(index)}>
                                            {option.address2}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                        )}
                        <div className="add-tab">
                            <IconButton aria-label="add-tab" onClick={handleAddClick}>
                                <AddIcon />
                            </IconButton>
                            <Menu
                                id="add-menu"
                                anchorEl={addMenuAnchorEl}
                                open={addMenuOpen}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleDuplicateClick}>{t('Copy existing Station')}</MenuItem>
                                <MenuItem onClick={handleNewClick}>{t('Create new station')}</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <div className="tab-content">
                        {selectedTabData && (
                            <div>
                                <ChargeStationDetails />
                                <ChargePointsTabs />
                            </div>
                        )}
                    </div>
                </div>
                <Dialog open={duplicateDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>{t('Charge Station')}</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {/*    Please enter the name for the new duplicated charge station.*/}
                        {/*</DialogContentText>*/}
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            variant="standard"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                        <Button onClick={handleDuplicate}>{t('Duplicate')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={newDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>{t('Charge Station')}</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {/*    Please enter the name for the new charge station.*/}
                        {/*</DialogContentText>*/}
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            variant="standard"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                        <Button onClick={handleCreateNew}>{t('Create')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ChargeStationDataContext.Provider>
    );
};

export default ChargeStationTabs;
