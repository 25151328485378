import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../../Settings';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import ChargePointDataContext from './ChargePointDataContext';
import ChargePointDetails from './ChargePointDetails';
import ChargeStationDataContext from './ChargeStationDataContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

// Custom Tab Styles
const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#FFEF3F',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.7) !important',
        '&.Mui-selected': {
            color: '#fff !important',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32) !important',
        },
    }),
);

const ChargePointsTabs = () => {
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    const [selectedTab, setSelectedTab] = useState(0);
    const [data, setData] = useState([]);
    const [displayedTabs, setDisplayedTabs] = useState([]);
    const [hiddenTabs, setHiddenTabs] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTabData, setSelectedTabData] = useState(null);
    const [chargePointData, setChargePointData] = useState([]);
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const { t } = useTranslation();

    const guid = Cookies.get("ownerGuid");
    const token = Cookies.get("token");

    useEffect(() => {
        if (chargeStationData) {
            axios
                .get(`${BASE_URL}/ChargePoint/ChargePointsLocation/${guid}/${chargeStationData.guid}/${token}`)
                .then(response => {
                    console.log("chargePoints", response);
                    setData(response.data);
                    const initialDisplayedTabs = response.data.slice(0, 3);
                    setDisplayedTabs(initialDisplayedTabs); // Display the first three stations initially
                    setHiddenTabs(response.data.slice(3)); // Hide the rest of the stations
                    if (initialDisplayedTabs.length > 0) {
                        setSelectedTabData(initialDisplayedTabs[0]);
                        setChargePointData(initialDisplayedTabs[0]);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [chargeStationData, guid, token]);

    const handleTabClick = (index) => {
        setSelectedTab(index);
        const selectedTabId = displayedTabs[index].guid;
        axios
            .get(`${BASE_URL}/ChargePoint/GetChargePointByGuid/${guid}/${selectedTabId}/${token}`)
            .then(response => {
                setSelectedTabData(response.data);
                setChargePointData(response.data); // Set data of the selected tab
            })
            .catch(error => {
                console.log(error);
            });
    };

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateNewClick = () => {
        const defaultName = `0${data.length + 1}`;
        setNewName(defaultName);
        setNewDialogOpen(true);
    };

    const handleDialogClose = () => {
        setNewDialogOpen(false);
    };

    const handleCreateNew = () => {
        axios.put(`${BASE_URL}/ChargePoint/CreateNewChargePointEmpty/${guid}/${chargeStationData.guid}/${newName}/${token}`)
            .then(response => {
                console.log("log of update server", response.data);
                // Assuming the response contains the new tab data, update the displayedTabs
                const newDisplayedTabs = [...displayedTabs, response.data];
                setDisplayedTabs(newDisplayedTabs);
                setSelectedTab(newDisplayedTabs.length - 1);
                setSelectedTabData(response.data);
                setChargePointData(response.data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setNewDialogOpen(false); // Close the dialog after API call
            });
    };

    const handleMenuItemClick = (index) => {
        // Move the selected item from hiddenTabs to displayedTabs
        const selectedItem = hiddenTabs[index];
        const newHiddenTabs = hiddenTabs.filter((_, i) => i !== index);
        const newDisplayedTabs = [...displayedTabs, selectedItem];
        setHiddenTabs(newHiddenTabs);
        setDisplayedTabs(newDisplayedTabs);
        setSelectedTab(newDisplayedTabs.length - 1);
        setSelectedTabData(selectedItem);
        setChargePointData(selectedItem);
        setAnchorEl(null); // Close the menu
    };

    return (
        <ChargePointDataContext.Provider value={{ chargePointData, setChargePointData }}>
            <Box sx={{ bgcolor: "#61993A" }}>
                <div className="tabs-container">
                    <div className="tabs">
                        <StyledTabs value={selectedTab} onChange={(event, newValue) => handleTabClick(newValue)} aria-label="styled tabs example">
                            {displayedTabs.map((station, index) => (
                                <StyledTab key={index} label={`${t('Charge point')} ${station.name}`} />
                            ))}
                        </StyledTabs>
                        {hiddenTabs.length !== 0 &&
                            <div className="more-tab">
                                <IconButton
                                    aria-label="more"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {hiddenTabs.map((option, index) => (
                                        <MenuItem key={index} onClick={() => handleMenuItemClick(index)}>
                                            {`${t('Charge point')} ${option.name}`}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>}
                        <div className="add-tab">
                            <IconButton aria-label="add-tab" onClick={handleCreateNewClick}>
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className="tab-content">
                        {selectedTabData && (
                            <div>
                                <ChargePointDetails />
                            </div>
                        )}
                    </div>
                </div>
                <Dialog open={newDialogOpen} onClose={handleDialogClose}>
                    <DialogTitle>{t('Charge point')}</DialogTitle>
                    <DialogContent>
                        {/*<DialogContentText>*/}
                        {/*    {t('Designation of charge point')}*/}
                        {/*</DialogContentText>*/}
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Name"
                            fullWidth
                            variant="standard"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                        <Button onClick={handleCreateNew}>{t('Create')}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </ChargePointDataContext.Provider>
    );
};

export default ChargePointsTabs;
