/// <reference path="../account/stripe.jsx" />
import React, { useState, useEffect, useRef } from 'react';
import { Divider } from 'antd';
import { TextField, Box } from '@mui/material';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Button, Select, InputLabel, MenuItem, FormControl } from '@mui/material';
import { json, useLocation, useNavigate } from 'react-router-dom';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import "../../css/ChargeLocation.css";
import QRCode from "./QRCode";
import { QrCode, ThreeDRotationRounded } from '@mui/icons-material';
import ChargePointState from "../services/ChargePointState";
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../Settings';
import {
  Col,
  Row,
} from 'antd';
import MapContainer from './MapContainer';
function ChargePoints({ isOcpp, isManual }) {
  const [connectidValue, SetConnectidValue] = useState(false);
  const [uConnectorId, SetUpdateConnectorId] = useState(false);
  const [getCPGuid, SetCPGuid] = useState(false);

  console.log("connectidValue", connectidValue);

  let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
  const { state } = useLocation();

  console.log("is ocpp",isOcpp.isOcpp)
  let valuesInitialState = Json
  let CPguidJson = JSON.parse(localStorage.getItem('ChargePointJson'));

  const [selectedValue, setSelectedValue] = useState(null);

  const { t } = useTranslation();
  const [chargePoints, setChargePoints] = useState([CPguidJson]);


  console.log("valuesInitialState", valuesInitialState);
  const [showNewForm, setShowNewForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChargePoint, setSelectedChargePoint] = useState(null); // Track the selected charge point for editing
  const [selectedNewChargePoint, setSelectedNewChargePoint] = useState(null); // Track the selected charge point for New Charge Points
  const [isChecked, setIsChecked] = useState(false); // Track the state of the checkbox
  const [isCheckedQRCode, setIsCheckedOrderQRCode] = useState(true); // Track the state of the checkbox
  const [isCheckedAktiv, setIsCheckedAktiv] = useState(false); // Track the state of the checkboxJson
  const [handleFileChangeComplete, setHandleFileChangeComplete] = useState(false);
  const [handleFileChangeCompleteCP, setHandleFileChangeCompleteCP] = useState(false);


  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [base64data, setBase64data] = useState(''); // Declare the state variable
  const [lat, setlat] = useState(valuesInitialState?.lat || Json?.lat);
  const [lon, setlon] = useState(valuesInitialState?.lon || Json?.lon);
  const [createNewNum, setCreateNewNum] = useState(false);
  let chargePointNum = CPguidJson?.length || valuesInitialState?.length;


  var token = Cookies.get('token');
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");
  const initialvalueConnectorId = null

  const navigate = useNavigate();

  const [latLng, setgoogleLatLng] = useState({ lat: valuesInitialState?.lat || Json?.lat, lng: valuesInitialState?.lon || Json?.lon });

  const options = [];
  if (createNewNum) { chargePointNum = connectidValue }
  for (let i = 1; i <= chargePointNum; i++) {
    options.push(i);
  }

  // Event handler to handle changes in the select field
  const handleSelectChange = (event) => {
    SetConnectidValue(event.target.value);

    console.log("event connect id ", event.target.value);
    updateConnectorId(event.target.value);
    //if (uConnectorId == true) {
    //    SetUpdateConnectorId(event.target.value);
    //    updateConnectorId();
    //}

  }



  const fetchData = async () => {
    try {
      const cpLocation = valuesInitialState?.guid || Json?.guid;

      console.log("cpLocation",CPguidJson?.cpLocation)
      const response1 = await axios.get(`${BASE_URL}/ChargePoint/chargePointsLocation/${guid}/${cpLocation}/${token}`);
      if (response1.data) {
        setIsLoading(false);

        localStorage.setItem('ChargePointJson', JSON.stringify(response1.data));
        CPguidJson = JSON.parse(localStorage.getItem('ChargePointJson'));
        console.log("connect id for chargepoints", response1.data.length + 1)

      }


      console.log("connectorid", guid, valuesInitialState.guid, token);
      const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
      if (response.data) {
        const data = response.data;
        console.log(response);
        console.log("test for get chargepoint number", response.data);
        localStorage.removeItem('CpLocationJson');
        localStorage.setItem('CpLocationJson', JSON.stringify(response.data));



      }

      // Assuming the API response has a property 'active' indicating the toggle value
    } catch (error) {
      console.error('Error fetching toggle value:', error);
    }
  };

  // Call the async function
  useEffect(() => {
    fetchData();
    refresh();
  }, []);






  const refresh = () => {

    const cpLocation = CPguidJson?.cpLocation || valuesInitialState?.guid || Json?.guid;
    console.log("cpLocation", cpLocation)

    axios
      .get(`${BASE_URL}/ChargePoint/chargePointsLocation/${guid}/${cpLocation}/${token}`)
      .then(function (response) {
        setIsLoading(false);

        localStorage.setItem('ChargePointJson', JSON.stringify(response.data));
        CPguidJson = JSON.parse(localStorage.getItem('ChargePointJson'));
        console.log("connect id for chargepoints", response.data)
        valuesInitialState = response.data;
        setChargePoints(response.data);

        //// setSelectedValue(CPguidJson[0].connectorId)
        // console.log("+++++++++++++++++++++", CPguidJson[0].connectorId);


        setlat(response.data[0]?.lat || response.data.lat);
        setlon(response.data[0]?.lon || response.data.lon);
        const showNewForm = Object.keys(CPguidJson).length === 1;


        if (showNewForm && chargePoints.length === 1 && response.data[0].thumbnailUrl === null) {
          console.log("1", response.data[0])
          handleEditClick(response.data[0]);
          setSelectedNewChargePoint(response.data[0]);
            console.log("isOcpp", isOcpp)
            console.log("isManual", isManual)
            if (isOcpp == true) {
                setShowNewForm(false);
            } else if (isManual == true) {
                setShowNewForm(true);
            }


        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //useEffect(refresh, []);



  const handleEditClick = (chargePoint) => {

    setSelectedNewChargePoint(chargePoint);
    console.log("handle edit click chargePoint", chargePoint)
    SetConnectidValue(chargePoint.connectorId)
    setShowNewForm(true);
    console.log("active", chargePoint.available)
    SetCPGuid(chargePoint.guid);
    setIsChecked(chargePoint.available); // Set the checkbox state
    setIsCheckedAktiv(chargePoint.available);

    if (chargePoint.thumbnailUrl !== null) {
      setThumbnailUrl(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // Update the checkbox state
  };


  //Create New chargepoint logic start here

  const updateConnectorId = (uConnectorId) => {
    console.log("Get CP GUid", getCPGuid)
    const cpLocation = valuesInitialState?.guid || Json.guid;
    console.log("dropdown menuConnectorId", uConnectorId)

    axios.put(`${BASE_URL}/ChargePoint/UpdateConnectorId/${guid}/${cpLocation}/${token}/${getCPGuid}/${uConnectorId}`)
      .then(function (CreateNewChargePoint) {
        // handle success
        // setShowForm(false); // Hide the existing form
        // Show the new form
        setSelectedNewChargePoint(CreateNewChargePoint.data);
      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }
  const NewCPuploadPhoto = async (e) => {
    try {
      let cpguid = e;
      const API_URL = `${BASE_URL}/ChargePoint/UploadPhotoCp/${guid}/${cpguid}/${token}`;
      // Create a new FormData object and append the selected file to it
      let formData = new FormData();
      formData.append('fileData', document.getElementById('fileDataInputCP').value);
      // Send the form data to the API endpoint using a POST request
      const response = await axios.put(`${BASE_URL}/ChargePoint/UploadPhotoCp/${guid}/${cpguid}/${token}`, formData);
      
      // const response = axios.put(API_URL, formData);
    
      return response.data;
      // Update the state to hide the PhotoCameraIcon
     

    } catch (error) {
      console.error(error);
    }
  }

  const fileUploadRefCP = React.useRef(null);

  const handleImageContainerClickCP = () => {
    fileUploadRefCP.current.click();


  };

  const handleFileChangeCP = (event) => {
    if (event.target.value !== '') {
      const files = event.target.files;
      const length = files.length;
      for (let i = 0; i < length; i++) {
        setFileCP(files[i]);
        

      }
      event.target.value = '';
    }
  };
  const handleSheetDropCP = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files.length) {
      const files = event.dataTransfer.files;
      const length = files.length;
      for (let i = 0; i < length; i++) {
        setFileCP(files[i]);

      }
    }
  };

  const setFileCP = (file) => {
    const fileName = file.name.split('.')[0];
    const fileType = file.type;
    const reader = new FileReader();
    reader.id = new Date() * 1;
    reader.addEventListener('loadend', (event) => {
      if (file.type.indexOf('image') > -1) {
        setInputImageDataCP(event.target.result);
      }
    });
    reader.readAsDataURL(file);
  };
  const setInputImageDataCP = (bin) => {
    const img = new Image();
    img.onload = () => {
      const imgbase64 = bin.split(',')[1];
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const base64data = canvas.toDataURL('image/jpeg', 0.75);
      setBase64data(base64data); // Set the state with the base64 data
        setHandleFileChangeCompleteCP(true);
      document.getElementById('addAssetsCP').setAttribute('src', base64data);

      document.getElementById('fileDataInputCP').setAttribute('data-mimetype', 'image/jpeg');
      document.getElementById('fileDataInputCP').value = base64data.split(',')[1];
      handleBlur();
      const photoSvg = document.getElementById('photoSvg');
      if (photoSvg) {
        photoSvg.style.display = 'none';

      }

      document.getElementById('uploadImageCP').style.backgroundImage = `url(${base64data})`;
    };
    img.src = bin;
      console.log("img.src", img.src)
      
 

  };

  const CPState = ChargePointState.StickerRequested | ChargePointState.Active | ChargePointState.StickerInProduction | ChargePointState.StickerSend;

  const CreateNewChargePoint = () => {
    const cpLocation = valuesInitialState?.guid || Json.guid;
    console.log("create new charge point cpLocation", cpLocation)

    axios.put(`${BASE_URL}/ChargePoint/CreateNewChargePoint/${guid}/${cpLocation}/${token}`)
      .then(function (CreateNewChargePoint) {
        // handle success
        // setShowForm(false); // Hide the existing form
        setShowNewForm(true); // Show the new form
        setSelectedNewChargePoint(CreateNewChargePoint.data);
        SetConnectidValue(CreateNewChargePoint.data.connectorId)
        SetCPGuid(CreateNewChargePoint.data.guid);
        console.log("test", CreateNewChargePoint.data)
        setCreateNewNum(true);
        const newChargePointData = CreateNewChargePoint.data;

        // Set the initial value of connectorId
        newChargePointData.connectorId = initialvalueConnectorId


      })
      .catch(function (error) {
        // handle error

        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    fetchData();
  };
  const handleBlur = () => {
    updateServer();
  };




  // Server update function

  const updateServer = async (isCheckedAktiv) => {

    console.log("update server cpguid", selectedNewChargePoint)

      const thumimage = await NewCPuploadPhoto(selectedNewChargePoint?.guid || Json?.guid);
     
    console.log("thumimage",thumimage);
    const updatedValues = {
      ...selectedNewChargePoint,
      ThumbnailUrl: thumimage,
      available: isCheckedAktiv ? true : false,
      OrderSticker: isCheckedQRCode ? 1 : 0,
      lat: latLng.lat || lat || '',
      lon: latLng.lng || lon || '',

    };


    fetch(`${BASE_URL}/ChargePoint/UpdateChargePoint/${guid}/${selectedNewChargePoint?.guid}/${token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedValues),
    }).then((response) => {
      if (response.ok) {

        console.log('Updated at:', response);
        localStorage.removeItem('ChargePointJson');
        localStorage.setItem('ChargePointJson', JSON.stringify(updatedValues));
        setIsCheckedAktiv(response.available);
        refresh();

        // Server update was successful
      } else {
        console.error('There was an error!');
        // Handle error response from the server
      }
    });
  }

  const downloadQRCode = () => {


    if (selectedNewChargePoint.guid !== null) {
      axios.get(`${BASE_URL}/Sticker/Get/${selectedNewChargePoint.partitionKey}/${selectedNewChargePoint.guid}`, {
        responseType: 'blob' // Set the response type to 'QrCode' to receive binary data
      })
        .then(function (response) {
          console.log("response", response)
          // Create a temporary URL for the PDF QrCode
          const url = window.URL.createObjectURL(new Blob([response.data]));

          // Create a temporary anchor element
          const link = document.createElement('a');
          link.href = url;
          let filename = `ChargeAtFriends_QR_Aufkleber_${selectedNewChargePoint.partitionKey}_${selectedNewChargePoint.guid}`
          link.setAttribute('download', `${filename}.pdf`); // Set the download attribute and the desired file name

          // Simulate a click event to trigger the download
          link.click();

          // Cleanup the temporary URL and anchor element
          window.URL.revokeObjectURL(url);
          link.remove();


        })
        .catch(function (error) {
          // Handle error
          console.log(error);
        });
    }
    else {
      alert("Please contact administrator")
    }

  };



  const handleCheckboxOrderQRCode = (event) => {

    setIsCheckedOrderQRCode(event.target.checked); // CreateCP and update the checkbox state

  };
  const handleCheckboxAktiv = (event) => {
    console.log("checkboxcalue", event.target.checked)
    setIsCheckedAktiv(event.target.checked); // CreateCP and update the checkbox state
    updateServer(event.target.checked); // Pass the checkbox value to the updateServer function

  };

  //End Create New CP Logics

  if (isLoading) {
    return <div style={{
      padding: '2% 20%'
    }}> <h2>Loading...</h2>
    </div>;
  }



  // Create new chargepoint logic end here
  return (
    <>
      <div style={{ width: '100%',margin:'0px !important' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
           
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
            margin:'0px !important'
          }}
        >

          <div style={{ padding: '0% 4vw',margin:'0px !important' }}>

            <div style={{ padding: '2%',margin:'0px !important' }}>
              <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                {t('Charge Point(s)')}
              </h1>

              {

                (showNewForm) ? (
                  <form>
                    {selectedNewChargePoint && (
                      <div ><Row gutter={[10, 35]}>
                        <Col xs={24} md={15} >
                          <Row >
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>{t('Foto')}  </Col>
                            <Col xs={24} md={6}>
                                                          <div onClick={handleImageContainerClickCP}>
                                                              {handleFileChangeCompleteCP || selectedNewChargePoint.thumbnailUrl ? (
                               <>                                
                              <input
                                type="file"
                                id="fileUploadCP"
                                ref={fileUploadRefCP}
                                style={{ display: 'none' }}
                                onChange={handleFileChangeCP}
                              />
                              <div

                                id="sheet-1CP"
                                onDrop={handleSheetDropCP}
                                onDragOver={(event) => event.preventDefault()}
                              >
                              </div>
                                                          <img
                                                              id="addAssetsCP"
                                                              style={{
                                                                  maxWidth: '100%',
                                                                  maxHeight: '100%',
                                                                  border: 'none',
                                                                  display: base64data ? 'block' : 'none',
                                                              }}
                                                              src={selectedNewChargePoint.thumbnailUrl || ''}
                                                          />
                              <img
                                id="addAssetsCP"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  display: base64data ? 'none' : 'block'
                                }}
                                src={selectedNewChargePoint.thumbnailUrl || ''}
                              />
                              <input id="fileDataInputCP" name="fileData" type="hidden" />
                                                                      <div id="uploadImageCP" />
                                                                  </>
                                                              ) : (
                                                                  <div>
                                                                      <PhotoCameraIcon style={{ fontSize: '50px' }} />
                                                                      {t('Click to upload.')}
                                                                     
                                                                                                      
                              <input
                                type="file"
                                id="fileUploadCP"
                                ref={fileUploadRefCP}
                                style={{ display: 'none' }}
                                onChange={handleFileChangeCP}
                              />
                              <div

                                id="sheet-1CP"
                                onDrop={handleSheetDropCP}
                                onDragOver={(event) => event.preventDefault()}
                              >
                              </div>
                                                          <img
                                                              id="addAssetsCP"
                                                              style={{
                                                                  maxWidth: '100%',
                                                                  maxHeight: '100%',
                                                                  border: 'none',
                                                                  display: base64data ? 'block' : 'none',
                                                              }}
                                                              src={selectedNewChargePoint.thumbnailUrl || ''}
                                                          />
                              <img
                                id="addAssetsCP"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  display: base64data ? 'none' : 'block'
                                }}
                                src={selectedNewChargePoint.thumbnailUrl || ''}
                              />
                              <input id="fileDataInputCP" name="fileData" type="hidden" />
                                                                      <div id="uploadImageCP" />
                                                                  
                                                                      </div>
                                                              )}
                                                          </div>


                            </Col>
                          </Row><Divider />
                          <Row gutter={[10, 25]}>
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                              {t('Ladepunkt Beschreibung')}
                            </Col>
                            
                            <Col xs={24} md={6}>
                              <TextField
                                id='Cpname'
                                label={t('Charge Point Name') + '  ' + t('left')}
                                color="success"
                                placeholder={t('left')}
                                fullWidth
                                value={selectedNewChargePoint.name}
                                focused
                                onBlur={handleBlur}
                                onChange={(e) =>
                                  setSelectedNewChargePoint((prevState) => ({
                                    ...prevState,
                                    name: e.target.value,
                                  }))
                                }
                              ></TextField>

                            </Col>

                          </Row>
                          
                          <Divider />
                                                  <Row gutter={[10, 25]}>
                                                      {console.log("isOcpp", isOcpp)}
                                                      {console.log("isManual", isManual) }

                            {isOcpp &&(
                              <>
                                <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                                  <label >{t('Select Connector ID:')}</label>
                                </Col>
                                <Col xs={24} md={6} >

                                  <FormControl sx={{ minWidth: 100 }}>
                                    <InputLabel style={{ color: 'green' }} >Connector ID:</InputLabel>
                                    <Select
                                      label="connector id"
                                      value={connectidValue}
                                      onChange={handleSelectChange}
                                      autoWidth
                                      onBlur={handleBlur}
                                    >
                                      {options.map((optionValue) => (
                                        <MenuItem key={optionValue} value={optionValue}  >
                                          {optionValue}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Col>

                              </>)}
                          </Row>
                          {/*                        <Divider />*/}
                          {/*<Row gutter={[10, 25]}>*/}
                          {/*  <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>{t('Latitude')}*/}
                          {/*                            </Col>*/}

                          {/*  <Col xs={24} md={6}>*/}
                          {/*    <TextField*/}
                          {/*      id="Lat"*/}
                          {/*      fullWidth*/}
                          {/*      label={t('Latitude')}*/}
                          {/*      color="success"*/}
                          {/*      focused*/}
                          {/*      onBlur={handleBlur}*/}
                          {/*      value={Math.round(latLng.lat * 1000) / 1000 || lat || '' }*/}
                          {/*      onChange={(e) =>*/}
                          {/*        setSelectedNewChargePoint((prevState) => ({*/}
                          {/*          ...prevState,*/}
                          {/*          lat: e.target.value,*/}
                          {/*        }))*/}
                          {/*      }*/}
                          {/*    ></TextField>*/}
                          {/*                                </Col>*/}
                          {/*</Row><Divider />*/}
                          {/*                        <Row gutter={[10, 25]}>*/}

                          {/*  <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>*/}

                          {/*    {t('Longitude')} </Col>*/}
                          {/*  <Col xs={24} md={6}>*/}
                          {/*    <TextField*/}
                          {/*      id="lon"*/}
                          {/*      fullWidth*/}
                          {/*      label={t('Longitude')}*/}
                          {/*      color="success"*/}
                          {/*      focused*/}
                          {/*      onBlur={handleBlur}*/}
                          {/*      value={Math.round(latLng.lng * 1000) / 1000 || lon || ''}*/}

                          {/*      onChange={(e) =>*/}
                          {/*        setSelectedNewChargePoint((prevState) => ({*/}
                          {/*          ...prevState,*/}
                          {/*          lon: e.target.value,*/}
                          {/*        }))*/}
                          {/*      }*/}
                          {/*    ></TextField>*/}
                          {/*  </Col>*/}

                          {/*</Row>*/}
                          {/*<Divider />*/}
                          <Row gutter={[10, 25]}>

                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                              QR Code  </Col>
                            <Col xs={24} md={17}>
                              <Row xs={24} md={10} >
                                <p>
                                  {t("The QR code is used to precisely identify a charging point with the app (Android/iOS). For e-car drivers, it is the shortcut to the PlayStore/AppStore for the installation when scanning for the first time. If the app is installed, it is the quick way to call up information about the station, in particular electricity or parking tariffs and charging capacity, and then start a charging process immediately or retrieve a station reservation and start charging. Charge@Friends will be happy to send you a water and UV-resistant sticker, simply select the checkbox for Order Sticker")}
                                </p>
                                <QRCode selectedNewChargePoint={selectedNewChargePoint} ></QRCode>
                              </Row>
                            </Col>
                          </Row><Divider />
                          <Row gutter={[10, 25]}>
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>{t('Aufkleber bestellen')}
                              <Checkbox checked={isCheckedQRCode} onChange={handleCheckboxOrderQRCode} />

                            </Col>
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>{t('Aktiv')}{t('Selbst ausdrucken?')}
                            </Col>
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                              <a onClick={downloadQRCode}>

                                {t('Download QR Code')}
                              </a>
                            </Col>

                          </Row>
                          <Divider />
                          <Row gutter={[10, 25]}>
                            <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>

                              {selectedNewChargePoint.available ? (
                                <span style={{ color: '#51A026' }}>{t('LADEPUNKT AKTIV')}</span>
                              ) : (
                                <span style={{ color: '#CC2412' }}>
                                  {t('LADEPUNKT INAKTIV')} ({t('Aktivierung im Cockpit')})
                                </span>
                              )}
                            </Col>

                          </Row>
                        </Col>
                        <Col xs={10} md={9}>

                          <div className="map-container-wrapper">
                            <MapContainer setLatLng={setgoogleLatLng} />

                          </div>

                        </Col>
                      </Row>
                        <p style={{
                          color: 'green',
                          fontSize: 'small',
                          paddingLeft: "63%",
                          marginTop: '0',
                          paddingTop: '0'
                        }}>
                          Latitude: {latLng.lat || lat}/ Longitude: {latLng.lng || lon}
                        </p>

                      </div>
                    )}
                  </form>
                ) : (
                  <>
                    <Row gutter={[10, 25]}>

                      <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                        {t('Foto')}
                      </Col>
                      <Col xs={24} md={6} style={{ fontWeight: 'bold' }}>
                        {t('Ladepunkt Beschreibung')}
                                              </Col>
                                              {isOcpp &&
                                                  <Col xs={24} md={4} style={{ fontWeight: 'bold' }}>
                                                      {t('Connector Id ')}
                                                  </Col>}
                      <Col xs={24} md={4} style={{ fontWeight: 'bold' }}>
                        {t('Available')}
                      </Col>
                      <Col xs={24} md={4} style={{ fontWeight: 'bold' }}>
                        {t('Edit')}
                      </Col>
                    </Row>

                    <Divider />
                    {chargePoints.map((chargePoint) => {
                        const isChecked = chargePoint?.available;
                        { console.log(chargePoint?.guid) }
                      return (
                        <Row key={chargePoint?.rowKey} >
                          <Col xs={24} md={6} style={{ color: (chargePoint?.thumbnailUrl === "" || chargePoint?.thumbnailUrl === null) ? 'red' : 'inherit' }}>{chargePoint?.thumbnailUrl === "" || chargePoint?.thumbnailUrl === null ? t('No Photo') : <img className="img-thumbnail" src={chargePoint?.thumbnailUrl} alt="" style={{ maxWidth: "45%", maxHeight: "100%" }} />}</Col>
                              <Col xs={24} md={6} style={{ color: chargePoint?.name === null ? 'red' : 'inherit' }}>{chargePoint?.name === null ? t('No Name') : chargePoint?.name}</Col>
                              {isOcpp &&
                                  <Col xs={24} md={4} style={{ color: chargePoint?.connectorId === null ? 'red' : 'inherit' }}>{chargePoint?.connectorId === null ? t('No connector Id ') : chargePoint?.connectorId}</Col>}
                          <Col xs={24} md={4}>
                            <Checkbox checked={isChecked} onChange={handleCheckboxChange} title='Verfügbar' />
                          </Col>
                          <Col xs={24} md={4}>
                            <Button
                              variant="contained"
                              color="success"
                              type="primary"
                              onClick={() => handleEditClick(chargePoint)}
                            >
                              {t('Edit')}

                            </Button>
                          </Col> <Divider />
                        </Row>

                      );
                    })}


                    <Row>
                                              <Col xs={24} md={24}>
                                                  {console.log("isOcpp", isOcpp)}
                                                  {console.log("isManual", isManual)}

                                                 {/* {isOcpp && chargePointNum < 16 && (*/}
                          <Button onClick={CreateNewChargePoint} variant="contained" type="primary" color="primary">
                            {t('Neuen Ladepunkt anlegen')}
                          </Button>{/*)}*/}
                      </Col>
                    </Row>
                  </>
                )}
            </div>
          </div>
        </Box>
      </div>

    </>
  );
}

export default ChargePoints;
