import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Pagination,
    Stack,
    Button, TextField
} from '@mui/material';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';

import "../../css/dashboard.css";
import Cookies from 'js-cookie';
import revenue from "./Energy.png";
const TotalKwh = () => {
    var token = Cookies.get("token");
    const { t } = useTranslation();
    // Create state to manage the collapsed state of each element
    const [isCollapsed, setIsCollapsed] = useState({});
    const [box1Data, setBox1Data] = useState([]);
    var guid = Cookies.get("ownerGuid");
    useEffect(() => {
        let ownerGuid = Cookies.get("ownerGuid");
        // Fetch data for Box 1
        fetch(`${BASE_URL}/Booking/TotalKwhByCpo/${ownerGuid}/${token}`)
            .then(response => response.json())
            .then(data => setBox1Data(data));
    }, []);

    // useEffect(() => {
    //     let ownerGuid = Cookies.get("ownerGuid");
    
    //     // Fetch data for Box 1
    //     fetch(`${BASE_URL}/Booking/TotalKwhByCpo/${ownerGuid}/${token}`)
    //         .then(response => response.json())
    //         .then(data => {
    //             // Assuming 'sum' is the value in cents that needs to be converted to euros
    //             const sumInCents = data.sum;
    //             const sumInEuros = (sumInCents / 100).toFixed(2); // Convert to euros and format to 2 decimal places
    //             setBox1Data(sumInEuros);  // Set the 'sum' value in euros
    //         });
    // }, []);
    
    return (
        
           
            <Col lg="12" md="12" sm="12">
                <Card className="card-stats">
                    <CardBody>
                        <Row className="d-flex align-items-center">
                            <Col md="4" xs="5">
                                <div className="icon-big text-center icon-warning">
                                <img style={{ width: '85px' }} src={revenue} alt="revenue" />
                                </div>
                            </Col>
                            <Col md="8" xs="7">
                                <div className="numbers">
                                <p className="card-category">{t('Energy')}</p>
                                    <CardTitle tag="p">{box1Data} kWh</CardTitle>
                                    <p />
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        
                    </CardFooter>
                </Card>
            </Col>


        

    );
};

export default TotalKwh;
