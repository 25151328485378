import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Divider, Col, Row } from 'antd';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { json, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { TextField, Box } from '@mui/material';
import axios from 'axios';
import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons'; 
import { Popover, Segmented } from 'antd';
import StateButton from './StateButton';
const Meter = () => {

  const { t } = useTranslation();

  const [activeEichrechtskonform, setActiveEichrechtskonform] = useState(false);
  const [activesichtbar, setActivesichtbar] = useState(false);
  const [activeintern, setActiveintern] = useState(false);
  const [activeZähler, setActiveZähler] = useState(false);
  const [CpMeterdata, setCpMeterData] = useState([]);
  const [data, setData] = useState(null)
  
  let CreateMeterJson = JSON.parse(localStorage.getItem('CpLocationJson'));
 
  const { state } = useLocation();
  let [valuesInitialState, setValuesInitialState] = useState(state || CreateMeterJson);
  //let valuesInitialState = state || CreateMeterJson;
 // let valuesInitialState;
  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;
  } 

  else {
    valuesInitialState = CreateMeterJson;
    console.log("state is null or undefined");
  }


  console.log("intitial Meter Json", CreateMeterJson)
 

  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");

  const MeterMask = ChargeLocationType.Calibrated | ChargeLocationType.VisibleMeter | ChargeLocationType.NoMeter | ChargeLocationType.InternalMeter;

  const ConnectionMask = ChargeLocationType.Manual | ChargeLocationType.OCPP16 | ChargeLocationType.OCPP21;


  //Below logic is for update boxes
  const updateServer = (type, mask) => {

    axios.put(`${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${valuesInitialState.guid}/${token}/${type}/${mask}`)
      .then(function (updateServer) {
        // handle success
        setValuesInitialState(updateServer.data);
      
        localStorage.removeItem('CpLocationJson');
       

        localStorage.setItem(
          'CpLocationJson',
          JSON.stringify(updateServer.data)
        );
       
        CreateMeterJson = JSON.parse(localStorage.getItem('CpLocationJson'));
        console.log("after OCPP Update", CreateMeterJson)
        
      
      })
      .catch(function (error) {
        // handle error
        localStorage.removeItem('CreateJsoncpguid');
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  var getBitFromMask = function (value, mask) {
    return value & mask;
    }
    
   
   

  useEffect(() => {
    const fetchToggleValue = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
        const data = response.data;
        console.log("response.data", response.data)
        localStorage.removeItem('CpLocationJson');
       

        localStorage.setItem(
          'CpLocationJson',
          JSON.stringify(response.data)
        );
        setValuesInitialState(response.data);
        valuesInitialState = response.data;
        console.log("after OCPP Update", CreateMeterJson)

        setData(data.available); // Assuming the API response has a property 'active' indicating the toggle value
      } catch (error) {
        console.error('Error fetching toggle value:', error);
      }
    };

    fetchToggleValue();
  }, []);
  useEffect(() => {   
 
    var pureValue = getBitFromMask(CreateMeterJson?.type || valuesInitialState.type, 1666);
    updateButtonState(pureValue);

  }, []);

    

  var updateButtonState = function (bitValue) {
    setActiveEichrechtskonform(false);
    setActivesichtbar(false);
    setActiveintern(false);
    setActiveZähler(false);
    switch (bitValue) {
      case ChargeLocationType.Calibrated: setActiveEichrechtskonform(true); break;
      case ChargeLocationType.VisibleMeter: setActivesichtbar(true); break;
      case ChargeLocationType.InternalMeter: setActiveintern(true); break;
      case ChargeLocationType.NoMeter: setActiveZähler(true); break;
    }
  }

  // var updateBitValue = function(type){
  //   updateButtonState(type);
  //   updateServer(type,MeterMask);
  // }

  const handleClickMeterButton = (input) => (e) => {
    console.log("handle click", input);
    updateButtonState(input);
      updateServer(input, MeterMask);
    
    
    //updateServer(bitValue, MeterMask);

    };
 
  return (
    <>


      <div style={{ width: '100%' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            margin: '1% 0',
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
          }}
        >

          <div style={{ padding: '0% 4vw' }}>

            <div style={{ padding: '2%' }}>
              <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                {t('Electricity meter / power meter')}
              </h1>

              <form>
                              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} >
                                  <Col sx={24} md={10}>
                                  <Row gutter={[24, 24]} justify="left" align="middle">
                                     
                                          <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.Calibrated)} isDisabled={activeEichrechtskonform} isActive={activeEichrechtskonform} buttonTextPlaceholder='Calibration Compliant Meter' popupContentPlaceholder='Meter compliant with legal calibration regulations.' />
                                          <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.VisibleMeter)} isDisabled={activesichtbar} isActive={activesichtbar} buttonTextPlaceholder='MID Meter, Visible' popupContentPlaceholder='MID-compliant meter, visible to users.' />
                                          <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.InternalMeter)} isDisabled={activeintern} isActive={activeintern} buttonTextPlaceholder='MID Meter, Internal' popupContentPlaceholder='MID-compliant meter, internally installed in the charging station.' />


                                          <StateButton
                                              onClickCallback={handleClickMeterButton(ChargeLocationType.NoMeter)}
                                              isDisabled={activeZähler}
                                              isActive={activeZähler}
                                              buttonTextPlaceholder='Other/No Meter'
                                              popupContentPlaceholder='Choose this option if no MID or other meter is present' />
                            
                                      </Row>
                                  </Col>
                

                </div>
              </form>
            </div>
          </div>
        </Box>
      </div>



    </>
  );
};

export default Meter;
