import React, { useState, useEffect, forwardRef } from 'react';
import { FormControl, InputLabel, MenuItem, makeStyles, createStyles, Select } from '@material-ui/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import PlugType from "../services/PlugType";

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            display: 'block',
        },
        select: {
            minWidth: 200, // Adjust as needed
        },
        greenUnderline: {
            '&:after': {
                borderBottomColor: '#4CAF50', // Green underline color when selected
            },
        },
    })
);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(name, selectedPlugs, theme) {
    return {
        fontWeight:
            selectedPlugs.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const ChargeStationModel = forwardRef((isMounted, ref) => {
    const classes = useStyles();
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedManufacturerGUID, setSelectedManufacturerGUID] = useState('');
    const [selectedModelGUID, setSelectedModelGUID] = useState('');
    
    const [models, setModels] = useState([]);
    const [model, setModel] = useState('');
    const [plugs, setPlugs] = useState([]);
    const [selectedPlugs, setSelectedPlugs] = useState([]);
    const [selectedPlugsBitMask, setSelectedPlugsBitMask] = useState('');
    const [errors, setErrors] = useState('');
    let OCPPJson = JSON.parse(localStorage.getItem('CpLocationJson'));
    const api = axios.create({
        baseURL: BASE_URL
    });

    const theme = useTheme();
 

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedPlugs(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
      
        const fetchData = async () => {
            try {
                const response = await api.get(`/StationManufacturerModel/GetstationManufacturer/${token}`);
                // Handle the response here
                setManufacturers(response.data)
                console.log("response.data for manufacturer ", response.data);

            } catch (error) {
                // Handle any errors that occur during the request
                localStorage.removeItem('CpLocationJson');
                console.error(error);
            }
        };
        fetchData();
      

    }, []);

    const fetchModelData = async (selectedManufacturerGUID) => {
        try {
            const response = await api.get(`/StationManufacturerModel/GetStationModelByManufacturerGuid/${selectedManufacturerGUID}/${token}`);
            // Handle the response here


            setModels(response.data)
            console.log("response.data for model ", response.data);
        } catch (error) {
            // Handle any errors that occur during the request           
            console.error(error);
        }
    };
    // Sample data for demonstration
   
  
   

    const handleManufacturerChange = (event) => {
       
        const selectedValue = event.target.value;
        setSelectedManufacturer(selectedValue);
        setSelectedManufacturerGUID(selectedValue.guid);
        fetchModelData(selectedValue.guid);
        // Reset model and plug when manufacturer changes
        setModel('');
        setSelectedPlugs([]);
        
        
    };

    const getAvailablePlugs = (plugType) => {
        const availablePlugs = [];
        if (plugType & PlugType.Schuco) {
            availablePlugs.push('Schuco');
        }
        if (plugType & PlugType.Type1) {
            availablePlugs.push('Type 1');
        }
        if (plugType & PlugType.Type2) {
            availablePlugs.push('Type 2');
        }
        if (plugType & PlugType.Css) {
            availablePlugs.push('CSS');
        }
        if (plugType & PlugType.Chademo) {
            availablePlugs.push('CHAdeMO');
        }
        return availablePlugs;
    };

    const handleModelChange = (event) => {
        const selectedValue = event.target.value;
        setModel(selectedValue);
        console.log("selectedvalue for model", selectedValue)
        setSelectedModelGUID(selectedValue.guid);
        setSelectedPlugsBitMask(selectedValue.plugType);
        const availablePlugs = getAvailablePlugs(selectedValue.plugType);
        setPlugs(availablePlugs);
    };

    const handlePlugChange = (event) => {
        setPlugs(event.target.value);
    };
    console.log("Manufacturer", manufacturers);
  
    console.log("selectedManufacturer", selectedManufacturer)
    console.log("model", model);
    console.log("plug", selectedPlugs);
    console.log("validation", !selectedManufacturer &&!model && selectedPlugs.length == 0)
    const validateForm = () => {
        let isValid = true;
        let newErrors = {};
        
        // Check if any of the button states is not active

        if (!selectedManufacturer || !model || selectedPlugs.length == 0) {
          
            isValid = false;
            newErrors.meterType = t('please select manufacturer and model and plug');

        }




        setErrors(newErrors);
        return isValid;
    };

    const onNext = (callback) => {
        console.log("console log in payoutLaterInStripe")
        let wizardStep = 8;
        const updatedValues = {
            ...OCPPJson, // Include all existing values
            Manufacturer: selectedManufacturerGUID,
            Model: selectedModelGUID// Update power field with new value
        };
        fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${ownerGuid}/${OCPPJson.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),
        }).then((response) => {
            if (response.ok) {


                localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));


                // Server update was successful
            } else {
                console.error('There was an error!');
                // Handle error response from the server
            }
        });
       
        callback();
    };
    React.useImperativeHandle(ref, () => ({
        onNext: onNext,
        validateForm: validateForm
       
    }));

    return (
        <div ref={ref}>
            <FormControl className={classes.formControl} variant="standard" >
                {console.log("selectedManufacturer 2", selectedManufacturer) }
                <InputLabel id="demo-simple-select-standard-label">{t('Manufacturer')}</InputLabel>
                {console.log("selectedManufacturer 2", selectedManufacturer)}
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedManufacturer}
                    onChange={handleManufacturerChange}
                    className={`${classes.select} ${selectedManufacturer && classes.greenUnderline}`}
                  
                >
                   
                    {manufacturers.map((manufacturer) => (
                        <MenuItem key={manufacturer.guid} value={manufacturer}>{manufacturer.fullName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={classes.formControl} variant="standard" sx={{ minWidth: 700 }}>
                <InputLabel id="demo-simple-select-standard-label">{t('Model')}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={model}
                    onChange={handleModelChange}
                    disabled={!selectedManufacturer} // Disable model dropdown until manufacturer is selected
                    className={`${classes.select} ${selectedManufacturer && classes.greenUnderline}`}
                > <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {models.map((model, index) => (
                        <MenuItem key={index} value={model}>{model.modelName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/*<FormControl className={classes.formControl}>*/}
            {/*    <InputLabel>Plug</InputLabel>*/}
            {/*    <Select*/}
            {/*        value={plug}*/}
            {/*        onChange={handlePlugChange}*/}
            {/*        disabled={!model} // Disable plug dropdown until model is selected*/}
            {/*        className={`${classes.select} ${selectedManufacturer && classes.greenUnderline}`}*/}
            {/*    >*/}
            {/*        <MenuItem value="">*/}
            {/*            <em>None</em>*/}
            {/*        </MenuItem>*/}
            {/*        {plugs.map((plug, index) => (*/}
            {/*            <MenuItem key={index} value={plug} style={{ }}>{plug}</MenuItem>*/}
            {/*        ))}*/}
            {/*    </Select>*/}
            {/*</FormControl>*/}
            <div>
                <FormControl className={classes.formControl} >
                    <InputLabel id="demo-simple-select-standard-label" style={{ fontWeight: 300 }} >{t('Plugs')}</InputLabel>
                    <Select style={{ marginTop: "50px", minWidth: 100 }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    
                    value={selectedPlugs}
                        onChange={handleChange}
                        disabled={!model}
                    input={<OutlinedInput id="select-multiple-chip"  />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                        {console.log("plugs", plugs)}
                    {plugs.map((plug) => (
                        <MenuItem
                            key={plug}
                            value={plug}
                            style={getStyles(plug, selectedPlugs, theme)}
                        >
                            {plug}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </div>
        </div>
    );
});

export default ChargeStationModel;
