import React, { useState, useEffect,useContext } from 'react';

// import ChargeatFriends from './ChargeatFriends.png';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { TextField, Box } from '@mui/material';
import { Divider, Col, Row, Switch } from 'antd';
import { useCookies } from "react-cookie";
import { json } from 'react-router-dom';
import ChargeatFriends from './hellocaf.png';
import { BASE_URL } from '../../Settings';
import logo from './sticker_w@1024px.png';
import ChargePointDataContext from './ChargePointDataContext';
import axios from 'axios';
import '../../../src/css/ChargeLocation.css';
import { useTranslation } from 'react-i18next';
const ChargePointDetails = () => {
	const [qrCodeData, setQrCodeData] = useState('');
	var token = Cookies.get("token");
	var guid = Cookies.get("ownerGuid");
	var decoded = jwt_decode(token);
	
	const { t } = useTranslation();
	
	/*var chargePointData = useContext(ChargePointDataContext);*/
	const { chargePointData, setChargePointData } = useContext(ChargePointDataContext);
	console.log("chargePointData in details", chargePointData)

	const [isAvailable, setIsAvailable] = useState(chargePointData.available);
	console.log("chargePointData.available", chargePointData.available)


	const updateChargingStationAvailability = async (isAvailableNew) => {
		try {
			const updatedChargePoint = {
				...chargePointData,
				available: isAvailableNew,
			};
			axios
				.put(`${BASE_URL}/ChargePoint/UpdateChargePoint/${guid}/${chargePointData.guid}/${token}`, updatedChargePoint)
				.then(function (response) {
					console.log("response.data of updated charge point ", response.data);
				

					// refresh();
				})
				.catch(function (error) {
					// handle error
					console.log(error);
				});
		} catch (error) {
			console.log('Error handling new CP save:', error);
		}
	};




	const handleToggleAvailability = async () => {
		const newAvailability = !isAvailable;
		setIsAvailable(newAvailability);

		const updatedChargeStationData = {
			...chargePointData,
			available: newAvailability
		};
		setChargePointData(updatedChargeStationData);
		// Update the availability using the new state
		await updateChargingStationAvailability(newAvailability);

	};
	const downloadQRCode = () => {


		axios.get(`${BASE_URL}/Sticker/Get/${chargePointData.partitionKey}/${chargePointData.guid}`, {
			responseType: 'blob' // Set the response type to 'QrCode' to receive binary data
		})
			.then(function (response) {
				console.log("response", response)
				// Create a temporary URL for the PDF QrCode
				const url = window.URL.createObjectURL(new Blob([response.data]));

				// Create a temporary anchor element
				const link = document.createElement('a');
				link.href = url;
				let filename = `ChargeAtFriends_QR_Aufkleber_${chargePointData.partitionKey}_${chargePointData.guid}`
				link.setAttribute('download', `${filename}.pdf`); // Set the download attribute and the desired file name

				// Simulate a click event to trigger the 
				link.click();

				// Cleanup the temporary URL and anchor element
				window.URL.revokeObjectURL(url);
				link.remove();


			})
			.catch(function (error) {
				// Handle error
				console.log(error);
			});



	};
	
	useEffect(() => {
		const fetchData = async () => {
			try {  //const response = await axios.get(`${BASE_URL}/Sticker/GetQrCode/${guid}/${token}`);
				setQrCodeData(`${BASE_URL}/Sticker/GetQrCode/${chargePointData.guid}/${token}`);
				//console.log(qrCodeData);
				console.log("chargePointData.guid in qr code",chargePointData.guid)
			} catch (error) {

				console.error(error);
			}
		};
		fetchData();
		setIsAvailable(chargePointData.available);
	
	}, [chargePointData]);

	return (
		<div style={{ width: '100%' }} >
		<Box
			component="div"
			sx={{
				backgroundColor: '#f5f5f5',
				borderTop: "none",
				border: '1px solid #e0e0e0',
				borderRadius: '0.3vw',
				margin: "20px"
			
			}}
			><div style={{ padding: '0.5%' }}>
					<Row justify="space-between" align="middle">

						<Col>

							<h1 style={{ fontSize: '1.5rem', lineHeight: 1 }}>
								{t('Charge point')}
							</h1>
						</Col>
						<Col xs="auto">
							<Row gutter={[10, 25]}>
								<Col >
									<h1 style={{ fontSize: '1.5rem', lineHeight: 1 }}>
										{t('Active')}
									</h1>
								</Col>
								<Col>
									<Switch checked={isAvailable} onChange={handleToggleAvailability} />
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div style={{
					margin:"2px",
					padding: '2px',
				
				}}>
		
					{t('Ready to get started? Click the QR code to download and print it yourself. Scanning the code with the ChargeAtFriends app will instantly activate the charging point.') }
		{/*< a onClick = { downloadQRCode } >*/}

		{/*	{ t('(click here to download)') }*/}
		{/*	</a > {t('and scan it to activate station.')}*/}
					</div>
		
			<div style={{ width: "250px", display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "0" }}>
				
				<div id="1" style={{ flex: "0 0 auto", position: "relative", marginRight: "10px" }}>
					<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "20%" }}>
							<img src={logo} alt="Overlay Image" style={{ width: "100%" }} onClick={downloadQRCode} />
					</div>
						<img src={qrCodeData} alt="QR Code" style={{ display: "block", width: "120px" }} onClick={downloadQRCode} />
				</div>

			</div>
			
		
			</Box>
		</div>
	);
};

export default ChargePointDetails;