import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChargeatFriends from './hellocaf.png';
import { EmptyState, EmptyStateHeader, EmptyStateFooter, EmptyStateBody, EmptyStateActions, EmptyStateIcon, Progress, Modal, ModalVariant, Wizard, WizardHeader, WizardStep, WizardFooter, useWizardContext, WizardFooterWrapper, Button, useWizardFooter } from '@patternfly/react-core';
import jwt_decode from "jwt-decode";
import CogsIcon from '@patternfly/react-icons/dist/esm/icons/cogs-icon';
import layout from '@patternfly/react-styles/css/layouts/Bullseye/bullseye';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { json } from 'react-router-dom';
import { BASE_URL } from '../../Settings';
import logo from './sticker_w@1024px.png';
import { useTranslation } from 'react-i18next';
const ValidationProgress = ({ onClose }) => {
    const { t } = useTranslation();
    const [qrCodeData, setQrCodeData] = useState('');
    const [chargepointdata, setChargePointData] = useState({});
    const navigate = useNavigate();
  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");
    const Json = JSON.parse(localStorage.getItem('ChargePointJson'));
    const JsonCL = JSON.parse(localStorage.getItem('CpLocationJson'));
    console.log("QR Code", Json)
    const [percentValidated, setPercentValidated] = React.useState(0);
    const tick = React.useCallback(() => {
        if (percentValidated < 100) {
            setPercentValidated(prevValue => prevValue + 20);
        }
    }, [percentValidated]);
    React.useEffect(() => {
        const interval = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [tick]);
  //useEffect(() => {
  //  const fetchData = async () => {
  //    try {  //const response = await axios.get(`${BASE_URL}/Sticker/GetQrCode/${guid}/${token}`);
  //      await   setQrCodeData(`${BASE_URL}/Sticker/GetQrCode/${Json.guid}/${token}`);
  //      //console.log(qrCodeData);

  //    } catch (error) {

  //      console.error(error);
  //    }
  //    };
  //    const fetchDataCPGuid = async () => {
  //        try {  //const response = await axios.get(`${BASE_URL}/Sticker/GetQrCode/${guid}/${token}`);
  //           await setChargePointData(`${BASE_URL}/ChargePoint/GetChargePointByGuid/${Json.guid}/${token}`);
  //            //console.log(qrCodeData);

  //        } catch (error) {

  //            console.error(error);
  //        }
  //    };
  //    fetchData();
  //    fetchDataCPGuid();
  //}, []);
    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("string query", `${BASE_URL}/Sticker/GetQrCode/${Json.guid}/${token}`);
               
                setQrCodeData(`${BASE_URL}/Sticker/GetQrCode/${Json.guid}/${token}`); // Assuming qrCodeResponse.data is the data you want to set
            } catch (error) {
                console.error("Error fetching QR code data:", error);
            }
        };

        const fetchDataCPGuid = async () => {
            try {
                const cpResponse = await axios.get(`${BASE_URL}/ChargePoint/GetChargePointByGuid/${JsonCL.partitionKey}/${Json.guid}/${token}`);
                setChargePointData(cpResponse.data); // Assuming cpResponse.data is the data you want to set
            } catch (error) {
                console.error("Error fetching charge point data:", error);
            }
        };

       
        fetchDataCPGuid();
        fetchData();
    }, []);
    const downloadQRCode = () => {


        axios.get(`${BASE_URL}/Sticker/Get/${JsonCL.partitionKey}/${Json.guid}`, {
                responseType: 'blob' // Set the response type to 'QrCode' to receive binary data
            })
                .then(function (response) {
                    console.log("response", response)
                    // Create a temporary URL for the PDF QrCode
                    const url = window.URL.createObjectURL(new Blob([response.data]));

                    // Create a temporary anchor element
                    const link = document.createElement('a');
                    link.href = url;
                    let filename = `ChargeAtFriends_QR_Aufkleber_${JsonCL.partitionKey}_${Json.guid}`
                    link.setAttribute('download', `${filename}.pdf`); // Set the download attribute and the desired file name

                    // Simulate a click event to trigger the download
                    link.click();

                    // Cleanup the temporary URL and anchor element
                    window.URL.revokeObjectURL(url);
                    link.remove();


                })
                .catch(function (error) {
                    // Handle error
                    console.log(error);
                });
        
       

    };

    return (
        <div className={layout.bullseye}>
            <EmptyState variant="lg">
                <EmptyStateHeader headingLevel="h4" titleText={percentValidated === 100 ?  t('Validation complete')  : t('Validating credentials')} icon={<EmptyStateIcon icon={CogsIcon} />} />
                {percentValidated !== 100 &&
                    <EmptyStateBody>
                        <Progress value={percentValidated} measureLocation="outside" aria-label={t('Wizard validation progress')} />
                    </EmptyStateBody>}
                {percentValidated == 100 && <EmptyStateBody>
                    {t('Congratulations, your station has been set up successfully!')}
                    <br></br>
                    <br></br>
                    <br></br>
                    <div style={{
                        border: "1px solid red", borderWidth: "0.5px", padding: "0 37px", borderRadius: "50px", width: "350px", marginLeft: "20%", backgroundColor: "white" 
                    }}><div style={{ textAlign: "center", padding: "5px 0px 0px 0px", margin: "0", height: "25px" }}><img src={ChargeatFriends} alt="Overlay Image" style={{ width: "50%" }} /></div>

                        <div style={{ width: "250px", display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "0" }}>
                            <div id="2" style={{ flex: "0 0 auto", marginLeft: "2px", marginRight: "30px" }}  >
                                <img src={logo} alt="Overlay" style={{ width: "120px" }} />
                            </div>
                            <div id="1" style={{ flex: "0 0 auto", position: "relative", marginRight: "10px" }}>
                                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "20%" }}>
                                    <img src={logo} alt="Overlay Image" style={{ width: "100%" }} />
                                </div>
                                <img src={qrCodeData} alt="QR Code" style={{ display: "block", width: "120px" }} />
                            </div>

                        </div>
                        {console.log("chargepointdata", chargepointdata)}
                        <div id="3" style={{ marginLeft: "0px", marginRight: "7px", overflow: "hidden", marginBottom: "7px", textAlign: "center", marginTop: "-4px" }}>
                            <label style={{ fontSize: "x- small", fontFamily: "Arial Narrow", whiteSpace: "pre-wrap", displey: "flex", fontSize: "10px" }}> {chargepointdata.country}*{chargepointdata.zipCode}*{chargepointdata.city}*{chargepointdata.street}-{chargepointdata.streetNumber}*{chargepointdata.cpName}*{chargepointdata.name}</label>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    {t('The QR code sticker will be sent to your address in the next few days. Place the sticker on your charging station (and scan it to activate the station).')}
                    <br></br>
                    <br></br>
                    {t('You want to start right now? Self-print the QR-Code')}
                    <a onClick={downloadQRCode}>

                        {t('(click here to download)')}
                    </a>  {t('and scan it to activate station.')}
                </EmptyStateBody>}

                <EmptyStateFooter>
                    <EmptyStateActions>
                        <Button isDisabled={percentValidated !== 100} onClick={onClose}>
                            {t('Go to Cockpit')}
                        
                        </Button>
                    </EmptyStateActions>
                </EmptyStateFooter>
            </EmptyState>
        </div>
    
  );
};

export default ValidationProgress;