import React,{useEffect,useState} from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { BASE_URL } from './Settings';
import { useNavigate } from 'react-router-dom';
import WizardState from './components/services/WizardState';
const Connect = () => {
    const [responseData, setResponseData] = useState(null);  
    const [data, setData] = useState([]);
  const navigate = useNavigate();
  var token = Cookies.get('token');
    console.log("token", token);

    const api = axios.create({
        baseURL: BASE_URL
    });

    const decoded = jwt_decode(token);
    const guid = decoded.jti;

    const fetchData = async () => {
        try {
            const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
            // Handle the response here
            console.log("Provider in app 2", response.data);
            if ((response.data.wizardStep & WizardState.SetUpStripeLater) == 0) {
                window.location.href = '/Wizard2';
                console.log("stripe is not set back to wizard")
            }
             else {
                console.log("data wizardStep in connect 2", data.wizardStep)
                window.location.href = '/ChargeLocation';
            }
            
            setData(response.data);

            console.log(data);
        } catch (error) {
            // Handle any errors that occur during the request
            console.error(error);
        }

    };

    useEffect(() => {
      const url = window.location.href;
      const tokenResultParam = url.match(/code=([^&]+).*state=([^&]+)/);
      console.log("url/connect",url)
        console.log("tokenResultParam", tokenResultParam)
       
      if (tokenResultParam) {
        const codeValue = decodeURIComponent(tokenResultParam[1]);
        const stateValue = decodeURIComponent(tokenResultParam[2]);
    
        Cookies.set('Code', codeValue);
        console.log("state", stateValue);

            // const codeValue="ac_OFVMFKKFDkwzhAHR672bbS31UDiUEGDM"
            // const stateValue="f6a4e6f9-0a73-466c-8e12-70a7b4722b4a"
         
        
         
        
            if (codeValue && stateValue) {
              
                
                axios
                .put(`${BASE_URL}/Stripe/HandleOAuthRedirect/${stateValue}/${codeValue}/${token}`)
                    .then((response) => {
                     
                        console.log("data.wizardStep in connect", data)
                       
                  // Handle the response from the API
                 /* window.location.href = '/ChargeLocation';*/
                  
                })
                .catch((error) => {
                  // Handle any errors that occur during the API call
                  console.error(error);
                });
            }
           
        } 
        fetchData();
    }, [responseData,token]);



  // Rest of your component code

  return (
    <div>
    <h1>Connect Component</h1>
    {responseData ? (
      <div>
        <p>Data from API:</p>
        <pre>{JSON.stringify(responseData, null, 2)}</pre>
      </div>
    ) : (
      <p>Loading...</p>
    )}
  </div>
  );
};

export default Connect;
