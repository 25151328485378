import React, { useEffect } from 'react';
import {  TextField, Box } from '@mui/material';
import axios from 'axios';
import '../../../src/css/App.css';
import { useState } from "react";
import {  Divider, Col, Row } from 'antd';
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from '../../Header';
function ApproveRegistration() {

	const [company, setCompany] = useState([]);
	const { companyGuid, requestGuid } = useParams();
	const { t } = useTranslation();
	
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});

		const fetchData = async () => {
			try {
				const response = await api.get(`Public/CompanyApprove/${companyGuid}/${requestGuid}`);
				// Handle the response here
				console.log("Company", response.data)
				setCompany(response.data);
				console.log(company);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		
		fetchData();

	}, []);
	

	return (

		<>
			<div style={{ width: '100%' }}>
				<Header></Header>
				<div style={{ padding: '0% 4vw' }}>
					<Box
						component="div"
						sx={{
							backgroundColor: '#f5f5f5',
							margin: '1% 0',
							border: '1px solid #e0e0e0',
							borderRadius: '1vw',
						}}
					>
						<div style={{ padding: '2%' }}>
							<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
								{t('Create Company Headline')}
							</h1>

							<form >
								<TextField id="fullWidth" fullWidth label={t('Firma')} color="success" focused value={company?.name} readonly
								
								/>
								<Divider />
								<TextField value={company?.domain} label={t('Domain')} id="fullWidth" fullWidth color="success" readonly focused

								/>

								<Divider />

								<Row gutter={[10, 25]}>
									<Col xs={24} md={6}>
										<TextField
											id="standard-basic"
											fullWidth
											label={t('Postleitzahl') + '*'} // Add an asterisk (*) to indicate it's a required field
											color="success"
											focused
											value={company?.zipCode}
											readonly
										/>

									</Col>
									<Col xs={24} md={18}>
										<TextField
											id="fullWidth"
											fullWidth
											label={t('Ort') + '*'} // Add an asterisk (*) to indicate it's a required field
											color="success"
											focused
											value={company?.city}
											readonly
										/>

									</Col>
								</Row>

								<Divider />

								<Row gutter={[10, 25]}>
									<Col xs={24} md={16}>
										<TextField
											id="fullWidth"
											fullWidth
											label={t('Straße') + '*'} // Add an asterisk (*) to indicate it's a required field
											color="success"
											focused
											value={company?.street}
											readonly
										/>

									</Col>
									<Col xs={24} md={8}>
										<TextField
											id="standard-basic"
											fullWidth
											label={t('Hausnummer') + '*'} // Add an asterisk (*) to indicate it's a required field
											color="success"
											focused
											value={company?.streetNumber}
											readonly
										/>
									</Col>
								</Row>

								<Divider />

								<Row gutter={[10, 25]}>
									<Col xs={24} md={6}>
										<TextField id="fullWidth" fullWidth label={t('USt.Id*') + '*'} color="success" focused value={company?.vatId}
											readonly
										/>
									</Col>

								</Row>

							</form>
						</div>
					</Box>


				</div>

			</div>


		</>
	);
}

export default ApproveRegistration;