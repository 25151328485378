import { MenuItem, MenuList, Stack, Paper, Box, Typography } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BASE_URL } from '../../Settings';
import { Button } from '@mui/material';
import '../../css/ChargeLocation.css';
import Header from '../../Header';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';


const StripeProfile = () => {

	//var token = Cookies.get("token");
	//var decoded = jwt_decode(token);
	//var guid = decoded.jti;
	const [data, setData] = useState([]);
	const [chargePoints, setAnotherData] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	

	const settings = [
		{ name: t('Contact'), link: 'https://www.chargeatfriends.com/kontakt/' },
	
		{ name: t('Privacy'), link: 'https://www.chargeatfriends.com/privacy' },
		{ name: t('Imprint'), link: 'https://www.chargeatfriends.com/impressum/' },

	];
	const headerStyle = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: 'white',
		color: 'white',
		padding: '10px 20px',
		borderBottom: '1px solid #4CAF50',
	};

	const logoStyle = {
		height: '40px', // Adjust the height as needed
	};

	const buttonStyle = {
		backgroundColor: 'white',
		color: 'black',
		border: 'none',
		padding: '10px 20px',
		cursor: 'pointer',
		fontSize: '16px',
	};
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});
		

		const fetchData = async () => {
			var id = new URLSearchParams(window.location.search).get("id");
			try {
				const response = await api.get(`/Public/GetCpoData/${id}`);
				// Handle the response here
				console.log("response", response)
				setData(response.data);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};
		const fetchAnotherData = async () => {
			var id = new URLSearchParams(window.location.search).get("id");
			console.log(id)
			try {
				const anotherResponse = await api.get(`/Public/GetChargePoints/${id}`);
				// Handle the response here
				console.log("anotherResponse", anotherResponse);
				setAnotherData(anotherResponse.data);
				console.log(chargePoints)
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};
		fetchData();

		fetchAnotherData();
	}, []);
	const handleButtonClick = () => {
		const baseUrl = 'https://cpo.chargeatfriends.com'; 
		window.location.href = baseUrl;
	};

	return (
		<div>
		
	<Header></Header>

			<div style={{ padding: '0% 1vw', margin:"2vw" }}>
				<h1 style={{ fontSize: "3.5rem", fontWeight: "300", lineHeight: "1.2", textAlign: 'center' }}>Charge@Friends Verkauf von Ladestrom</h1>
				<h3 style={{ fontSize: "2rem", fontWeight: "200", lineHeight: "1.2", textAlign: 'center' }}>{data.title}</h3>
				<Box
					component="div"
					sx={{
						backgroundColor: '#F8FaFc',
						margin: '1% 0',
						border: '1px solid #e0e0e0',
						borderRadius: '1vw',
					}}
				>

					

						<div style={{ padding: '1vw' }}>
							
			<col xs={24} md={24}></col>
			<Row gutter={[25, 25]}>
				<Col xs={24} md={6} style={{ fontWeight: 'bold', textAlign: 'center' }}>
					{t('Foto')}
				</Col>
				<Col xs={24} md={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
					{t('Stationsname')}
				</Col>
				<Col xs={24} md={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
					{t('PLZ')}
				</Col>
				<Col xs={24} md={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
					{t('Straße')}
				</Col>
				<Col xs={24} md={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
					{t('Tarif (Ct/kWh)')}
				</Col>
			</Row>

			<Divider />

			{chargePoints.map((chargePoint) => (
				<div key={chargePoint?.guid} style={{ marginBottom: '20px' }}>
					<Row gutter={[25, 25]} style={{ display: 'flex', alignItems: 'center' }}>
						<Col xs={24} md={6} style={{ textAlign: 'center' }}>
							{chargePoint?.thumbnailUrl ? (
								<img
									className="img-thumbnail"
									src={chargePoint?.thumbnailUrl}
									alt=""
									style={{ maxWidth: "70%", height: "auto", maxHeight: "150px" }}
								/>
							) : (
								<div style={{ height: "50px" }} /> 
          )}
						</Col>
						<Col xs={24} md={4} style={{ textAlign: 'center' }}>
							{chargePoint?.name}
						</Col>
						<Col xs={24} md={4} style={{ textAlign: 'center' }}>
							{chargePoint?.zipCode}
						</Col>
						<Col xs={24} md={4} style={{ textAlign: 'center' }}>
							{chargePoint?.street}
						</Col>
						<Col xs={24} md={4} style={{ textAlign: 'center' }}>
							{chargePoint?.moonShineRate}
						</Col>
					</Row>
				</div>
			))}
						</div>
					
			</Box>
			

				
				<Box
					component="div"
					sx={{
						backgroundColor: '#F8FaFc',
						margin: '1% 0',
						border: '1px solid #e0e0e0',
						borderRadius: '1vw',
						padding: "10px"
				}}>
				
				<Row gutter={[25, 25]}>
						<Col span={8}>Betreiber:</Col>
						<Col span={16}>{data.title}</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col span={8}>Kontakt:</Col>
						<Col span={16}>
							<a href={`mailto:${data.email}`}>
								{data.email}
							</a>
						</Col>
					</Row>
					<Row gutter={[25, 25]}>
						<Col span={8}>&#xa0;</Col>
						<Col span={16}>{data.phone}</Col>
					</Row>
				</Box>
			
			<Divider/>
			<footer style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px'}} >
				<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" x="0" /></g><g><g><g><path d="M11.88,9.14c1.28,0.06,1.61,1.15,1.63,1.66h1.79c-0.08-1.98-1.49-3.19-3.45-3.19C9.64,7.61,8,9,8,12.14 c0,1.94,0.93,4.24,3.84,4.24c2.22,0,3.41-1.65,3.44-2.95h-1.79c-0.03,0.59-0.45,1.38-1.63,1.44C10.55,14.83,10,13.81,10,12.14 C10,9.25,11.28,9.16,11.88,9.14z M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8 s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z" /></g></g></g></svg>
				<Typography textAlign="center">2023-Charge@Friends </Typography>
				{settings.map((setting) => (
					<a href={setting.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
						<Typography textAlign="center">{setting.name}</Typography>
					</a>))}
				</footer>
			</div>
		</div>


	
	);
}
export default StripeProfile;