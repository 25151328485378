import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GermanFlag from './Germanflag.png';
import EnglishFlag from './EnglishFlag.png';
import Select from 'react-select';
import ChargingorParkingValue from "../components/services/session";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('de'); // Set the default language to "de"
    ChargingorParkingValue.setSelectedLanguageValue('de');
  }, []);

  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      i18n.changeLanguage(selectedOption.value);
      ChargingorParkingValue.setSelectedLanguageValue(selectedOption.value);
    }
  };

  const options = [
    {
      value: 'de',
      label: (
        <div>
          <img src={GermanFlag} alt="German" style={{ width: '20px', height: '20px' }} />
          <span style={{padding:'2% 2%', verticalAlign:'top' }}>German</span>
        </div>
      ),
    },
    {
      value: 'en',
      label: (
        <div>
          <img src={EnglishFlag} alt="English" style={{ width: '20px', height: '20px' }} />
          <span style={{padding:'2% 5%', verticalAlign:'top' }}>English</span>
        </div>
      ),
    },
  ];

  const defaultLanguage = options.find((option) => option.value === 'de');

  return (
    <div className="language-switcher">
      <Select options={options} onChange={handleLanguageChange} defaultValue={defaultLanguage} />
    </div>
  );
};

export default LanguageSwitcher;
