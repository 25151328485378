import React, { useRef, useState ,useEffect} from 'react';
import { EmptyState, EmptyStateHeader, EmptyStateFooter, EmptyStateBody, EmptyStateActions, EmptyStateIcon, Progress, Modal, ModalVariant, Wizard, WizardHeader, WizardStep, WizardNavItem, WizardFooter, useWizardContext, WizardFooterWrapper, Button, WizardNav,useWizardFooter } from '@patternfly/react-core';
import CogsIcon from '@patternfly/react-icons/dist/esm/icons/cogs-icon';
import layout from '@patternfly/react-styles/css/layouts/Bullseye/bullseye';
import { useNavigate } from 'react-router-dom';
import ConnectMobile from './components/Account/ConnectMobile';
import "./css/patternfly.css";
import axios from 'axios';
import { BASE_URL } from './Settings';
import Cookies from 'js-cookie';
import Profile2 from './components/Chargepoint/Profile2';
import Provider2 from './components/Account/Provider2';
import ChargeStationModel from './components/Chargepoint/ChargeStationModel';
import Meter2 from './components/Chargepoint/Meter2';
import Address from './components/Chargepoint/Address';
import OCPP2 from './components/Chargepoint/OCPP2';
import Parking2 from './components/Chargepoint/Parking2';
import AccessProfiles2 from './components/AccessProfile/AccessProfiles2';
import Stripe2 from './components/Account/Stripe2';
import { QrCode2 } from '../node_modules/@mui/icons-material/index';
import ValidationProgress from './components/Chargepoint/ValidationProgress';
import WizardState from './components/services/WizardState';
import { Navigate } from 'react-router-dom';
import { useRefresh } from './RefreshContext';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';

import UploadPhotoPatternFly from './components/Chargepoint/UploadPhotoPatternFly';

const CustomWizardFooter = () => {
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    return <WizardFooter activeStep={activeStep} onNext={goToNextStep} onBack={goToPrevStep}  isBackDisabled={activeStep.index === 1}/>;
};
const CustomStepTwoFooter = ({ profile2Ref }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();

    useEffect(() => {
        
        // Call validateForm when profile2Ref changes
        if (profile2Ref.current || profile2Ref.current && typeof profile2Ref.current.validateForm === 'function' ) {
            const isValid = profile2Ref.current.validateForm();
            setIsNotValid(!isValid);
            // Update isNotValid based on the result of validateForm
            console.log("hello wizard is not valid value ", isNotValid)

        }
    }, [profile2Ref, isNotValid]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (profile2Ref.current && typeof profile2Ref.current.validateForm === 'function') {
                const isValid = profile2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);

    
    function onNextWizard() {

        console.log("hello wizard", isNotValid)
      
       
       
       
        if (profile2Ref.current && typeof profile2Ref.current.onNext == 'function' && profile2Ref.current.validateForm()) {

            console.log("profile2current", profile2Ref.current)

             profile2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
               

            });
            
          
        }
        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>
        
        
    </WizardFooterWrapper>;

    
}
const CustomStepThreeFooter = ({ provider2Ref }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (provider2Ref.current && typeof provider2Ref.current.validateForm === 'function') {
                const isValid = provider2Ref.current.validateForm();
                setIsNotValid(!isValid);

            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);



    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (provider2Ref.current && typeof provider2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {

            console.log("profile2current", provider2Ref.current)

            provider2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component

               


            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}
const CustomStepLocationPhotoFooter = ({ LocationPhotoRef }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    //useEffect(() => {
    //    const interval = setInterval(() => {
    //        if (LocationPhotoRef.current && typeof LocationPhotoRef.current.validateForm === 'function') {
    //            const isValid = LocationPhotoRef.current.validateForm();
    //            setIsNotValid(!isValid);
    //        }
    //    }, 500); // Check every 500ms for form validation changes

    //    return () => clearInterval(interval); // Clean up the interval
    //}, []);



    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (LocationPhotoRef.current && typeof LocationPhotoRef.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {

            console.log("profile2current", LocationPhotoRef.current)

            LocationPhotoRef.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component

            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard}  >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}
const CustomStepFiveFooter = ({ meter2Ref }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (meter2Ref.current && typeof meter2Ref.current.validateForm === 'function') {
                const isValid = meter2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);



    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (meter2Ref.current && typeof meter2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {

            console.log("profile2current", meter2Ref.current)

            meter2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
                
            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}
const CustomStepSixFooter = ({ ocpp2Ref}) => {
  
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (ocpp2Ref.current && typeof ocpp2Ref.current.validateForm === 'function') {
                const isValid = ocpp2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);



    function onNextWizard() {

      /*  console.log("hello wizard", isNotValid)*/



        if (ocpp2Ref.current && typeof ocpp2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {

            console.log("profile2current", ocpp2Ref.current)

            ocpp2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
                
            });


        }
        goToNextStep();
        
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}
const CustomStepSevenFooter = ({ parking2Ref }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (parking2Ref.current && typeof parking2Ref.current.validateForm === 'function') {
                const isValid = parking2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);



    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (parking2Ref.current && typeof parking2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {
            console.log("profile2current", parking2Ref.current)


            parking2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
               
            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}
const CustomStepNineFooter = ({ stripe2Ref, setIsSubmitted }) => {
    const { triggerRefresh } = useRefresh();
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const navigate = useNavigate();
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (stripe2Ref.current && typeof stripe2Ref.current.validateForm === 'function') {
                const isValid = stripe2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);


    function navigateToChargeLocation()  {
           window.location.href = "/ChargeLocation";

     {console.log("hello charge location in stripe ") }
               
    }
    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (stripe2Ref.current && typeof stripe2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {
            console.log("profile2current", stripe2Ref.current)


            stripe2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
                setIsSubmitted(true)
                
                
            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }
    function payoutLater() {

        console.log("hello wizard", isNotValid)



        if (stripe2Ref.current && typeof stripe2Ref.current.payoutLaterInStripe == 'function' /*&& provider2Ref.current.validateForm()*/) {
            console.log("profile2current", stripe2Ref.current)


            stripe2Ref.current.payoutLaterInStripe(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component
               
                

            });


        }

        
        console.log("hey it's callback payoutLaterInStripe")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid}  >
            {t('Finish')}
        </Button>
        {isNotValid &&
            <Button variant="primary" onClick={payoutLater}   >
                {t('set payout account later')}
            </Button>}
       


    </WizardFooterWrapper>;

}
const CustomStepFourFooter = ({ model2Ref }) => {
    /*const profile2Ref = useRef(null);*/
    const { t } = useTranslation();
    const [isNotValid, setIsNotValid] = useState(true);
    const { activeStep, goToNextStep, goToPrevStep, close } = useWizardContext();
    useEffect(() => {
        const interval = setInterval(() => {
            if (model2Ref.current && typeof model2Ref.current.validateForm === 'function') {
                const isValid = model2Ref.current.validateForm();
                setIsNotValid(!isValid);
            }
        }, 500); // Check every 500ms for form validation changes

        return () => clearInterval(interval); // Clean up the interval
    }, []);



    function onNextWizard() {

        console.log("hello wizard", isNotValid)



        if (model2Ref.current && typeof model2Ref.current.onNext == 'function' /*&& provider2Ref.current.validateForm()*/) {

            console.log("profile2current", model2Ref.current)

            model2Ref.current.onNext(() => {
                // Callback to advance to the next step
                // Assuming you have a function to handle step navigation in your component

            });


        }

        goToNextStep();
        console.log("hey it's callback")

    }

    return <WizardFooterWrapper>
        <Button variant="primary" onClick={onNextWizard} isDisabled={isNotValid} >
            {t('Next')}
        </Button>


    </WizardFooterWrapper>;

}

const Wizard2 = () => {

    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isProfile2Mounted, setIsProfile2Mounted] = useState(false);
    const [isProvider2Mounted, setIsProvider2Mounted] = useState(false);
    const { goToNextStep, goToPrevStep, close, activeStep } = useWizardContext();
    const navigate = useNavigate();
    const onClose = () => window.location.href = "/cockpit";
    const [isWorking, setIsWorking] = useState(false);
    const [currentStep, setCurrentStep] = React.useState();
    const onStepChange = (_event, currentStep) => setCurrentStep(currentStep);
    const [isModelOpen, setIsModalOpen] = useState(true);
    const profile2Ref = useRef(null);
    const provider2Ref = useRef(null);
    const [isStripe, setIsStripeinapp] = useState(false);
   
   
    const model2Ref = useRef(null);
    const LocationPhotoRef = useRef(null);
    const meter2Ref = useRef(null);

    const ocpp2Ref = useRef(null);
    const parking2Ref = useRef(null);
    const accessProfiles2Ref = useRef(null);
    const stripe2Ref = useRef(null);
    const [customerData, setCustomerData] = useState([]);
    const [startIndexIn, setStartIndex] = useState(null);
    var token = Cookies.get("token");

    var decoded = jwt_decode(token);

    // var guid = Cookies.get("ownerGuid");
    let guid = decoded.jti;
    const api = axios.create({
        baseURL: BASE_URL
    });

    const [wizardViewSelected, setWizardViewSelected] = useState(false);

    useEffect(() => {


        const fetchData = async () => {
            try {
                const response = await api.get(`/CpoLocation/Locations/${guid}/${token}`);
                // Handle the response here


                localStorage.setItem('CpLocationJson', JSON.stringify(response.data[0]));

                console.log("response.data for wizard ", response.data[0]);
                fetchDataCustomer();
                fetchDataChargePoint(response.data[0].guid);
                console.log("rsponse.data[0].guid",response.data[0].guid)
                // Call selectWizardView only if it hasn't been called before



            } catch (error) {
                // Handle any errors that occur during the request
                localStorage.removeItem('CpLocationJson');
                console.error(error);
            }
        };
        const fetchDataChargePoint = async (locationGuid) => {
            try {
                const response = await api.get(`/ChargePoint/ChargePointsLocation/${guid}/${locationGuid}/${token}`);
                // Handle the response here


                localStorage.setItem('ChargePointJson', JSON.stringify(response.data[0]));

                console.log("response.data for wizard ", response.data[0]);
               

                // Call selectWizardView only if it hasn't been called before



            } catch (error) {
                // Handle any errors that occur during the request
                localStorage.removeItem('ChargePointJson');
                console.error(error);
            }
        };



        fetchData();



    }, []);
    console.log("isStripe id ",isStripe)
   
        const fetchDataCustomer = async () => {
        try {
                const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
                // Handle the response here
                console.log("Provider in profile 2", response.data);
                setCustomerData(response.data);
                var setIndex = 1;
                if ((response.data.wizardStep & WizardState.AccountProfile) !== 0) {
                    setIndex = 2;
                }
                if ((response.data.wizardStep & WizardState.StationProfile) !== 0) {
                    setIndex = 3;
                 } if ((response.data.wizardStep & WizardState.StationModel) !== 0) {
                    setIndex = 4;
            }
            if ((response.data.wizardStep & WizardState.UploadPhoto) !== 0) {
                setIndex = 5;
            } 
                if ((response.data.wizardStep & WizardState.MeterType) !== 0) {
                    setIndex = 6;
                }
                if ((response.data.wizardStep & WizardState.Connection) !== 0) {
                    setIndex = 7;
                }
                if ((response.data.wizardStep & WizardState.Billing) !== 0) {
                    setIndex = 8;
                    setIsStripeinapp(true);
                }

                setStartIndex(setIndex);
        } catch (error) {
            // Handle any errors that occur during the request
           
            console.error(error);
        }
        };

    const [id, setId] = useState(0); 
  
    
    


    if (isSubmitted) {
        return <ValidationProgress onClose={onClose} />;
    }
    const nav = (isExpanded, steps, activeStep, goToStepByIndex) => <WizardNav isExpanded={isExpanded} iisVisitRequired >
        {steps.map(step => <WizardNavItem key={step.id} id={step.id} content={step.name} isCurrent={activeStep.id === step.id} isDisabled={step.isDisabled} stepIndex={step.index} onClick={() => console.log("hello")} />)}
    </WizardNav>;
    return (
        <>
            
            {startIndexIn !== null &&
                startIndexIn > 0 && (
                <Modal
                    isOpen={isModelOpen}
                    showClose={false}
                    variant={ModalVariant.large}
                    onEscapePress={() => setIsModalOpen(false)}
                    hasNoBodyWrapper
                    aria-describedby="wiz-modal-demo-description"
                    aria-labelledby="wiz-modal-demo-title"
                // Pass setCurrentStep
                   
                > 
                    {console.log("startindex", startIndexIn)}
                    <Wizard isVisitRequired startIndex={startIndexIn} nav={nav} height={700}  onStepChange={onStepChange} header={<WizardHeader onClose={() => navigate("/ChargeLocation")} title={t('Welcome to charge at friends !')} description={t('Set up your first charge station.')} />} footer={<CustomWizardFooter />} >

                        <WizardStep name={t('Contact Details')} id="header-first-step" navItem={{
                            content: <span>{t('Contact Details')}</span>,


                        }} footer={<CustomStepThreeFooter provider2Ref={provider2Ref} />} >
                            {console.log("hello2")}
                          
                            <Provider2 ref={provider2Ref} currentStep={currentStep} onMount={() => setIsProvider2Mounted(true)} />
                        </WizardStep>
                        <WizardStep name={t('Location')} id="header-second-step" navItem={{
                            content: <span>{t('Location')}' </span>,
                          
                        }} footer={<CustomStepTwoFooter profile2Ref={profile2Ref} />} >
                            {console.log("hello2")}
                         

                            <Profile2 ref={profile2Ref} onMount={() => setIsProfile2Mounted(true)} />
                        </WizardStep>

                        <WizardStep name={t('Model')} id="header-third-step" navItem={{
                            content: <span> {t('Model')}</span>
                        }} footer={<CustomStepFourFooter model2Ref={model2Ref} />}  >
                            <ChargeStationModel ref={model2Ref} />
                        </WizardStep>
                        <WizardStep name={t('Location Photo')} id="header-Fourth-step" navItem={{
                            content: <span> {t('Location Photo')}</span>
                        }} footer={<CustomStepLocationPhotoFooter LocationPhotoRef={LocationPhotoRef} />}  >
                            <UploadPhotoPatternFly ref={LocationPhotoRef} />
                        </WizardStep>
                        <WizardStep name={t('Meter & Power')} id="header-Fifth-step" navItem={{
                            content: <span>{t('Meter & Power')}</span>,
                           
                        }} footer={<CustomStepFiveFooter meter2Ref={meter2Ref}  />} >
                            <Meter2 ref={meter2Ref} onMount={() => setIsProvider2Mounted(true)} />
                        </WizardStep>
                        {/*<WizardStep name="Step 5" id="header-Fifth-step"   >*/}
                        {/*    <Address></Address>*/}
                        {/*</WizardStep>*/}
                        <WizardStep name={t('Online Connection')} id="header-sixth-step" navItem={{
                            content: <span> {t('Online Connection')}</span>,
                            
                        }} footer={<CustomStepSixFooter ocpp2Ref={ocpp2Ref}  />}   >
                            <OCPP2 ref={ocpp2Ref} />
                        </WizardStep>
                        <WizardStep name={t('Billing')} id="header-seventh-step" navItem={{
                            content: <span>{t('Billing')}</span>,
                          
                        }} footer={<CustomStepSevenFooter parking2Ref={parking2Ref}  />}   >
                            <Parking2 ref={parking2Ref} />
                        </WizardStep>

                        <WizardStep name={t('Payout Account')} id="header-eighth-step" navItem={{
                            content: <span>{t('Payout Account')}</span>,
                           
                        }} footer={<CustomStepNineFooter stripe2Ref={stripe2Ref} setIsSubmitted={setIsSubmitted} />}  >
                            <Stripe2  ref={stripe2Ref} />
                        </WizardStep>
                    </Wizard>
                </Modal>)}
           
        </>)
}
export default Wizard2;