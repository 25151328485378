import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../Settings';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


function Booking () {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
	var guid = decoded.jti;
	var ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    useEffect(() => {
        axios
			.get(`${BASE_URL}/Booking/Bookings/${ownerGuid}/${token}`)
            .then(function (response) {
                console.log("bookings", response.data)
                setRows(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    const formatNumberWithLeadingZeros = (number, length) => {
        return number.toString().padStart(length, '0');
    };
   

   



    return (
        <Box component="div" sx={{  margin: "5% 10%", borderColor: "white" }}>
            <TableContainer component={Paper} sx={{ borderColor: "white" }}>
                <Table sx={{ minWidth: 650}}>
                    <TableHead sx={{ border: "none" }}>
                        <TableRow sx={{ border: "none" }}>
                            <TableCell sx={{ border: "none"}}>Fahre</TableCell>
                            <TableCell sx={{ border: "none" }} align="right">Startzeit</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Endzeit</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Strompreis (Ct/kWh)</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>kWh</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Summe (€)</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>MWSt</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Nr</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Details</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Anbieter</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>Fahrer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => {
                        const formattedStartTime = new Date(row.startTime).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                        });
                        const formattedEndTime = new Date(row.startTime).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                            hour12: false,
                        });
                        const operatorIdString = formatNumberWithLeadingZeros(row.operatorId, 4);
                        const invoiceIdString = formatNumberWithLeadingZeros(row.invoiceId, 4);

                        // Concatenate the strings with a dash "-"
                        const result = `${operatorIdString}-${invoiceIdString}`;
                        
                        return (
                        <TableRow
                            key={row.name}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                                <TableCell component="th" scope="row" sx={{ border: "none" }} >
                                {row.friendEmail}
                            </TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{formattedStartTime}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{formattedEndTime}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{row.kWhPrice}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{(row.chargedKWh).toFixed(2)}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}> {(row.sumPrice / 100).toFixed(2)}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{(row.sumPrice * (row.mwStFact) / 100).toFixed(2)}</TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>{result}</TableCell>
                               
                                <TableCell align="right" sx={{ border: "none" }}>
                                {/*    <Button onClick={() => navigate(`/bk/${row.rowKey}`, { state: row })} style={row.lat == '0' ? { color: 'red ' } : {}}>Details</Button>*/}
                                    <Link target="_blank" to={`/bk/${row.rowKey}`} > Details</Link>
                                </TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>
                                    <Link target="_blank" to={`https://appbackend.chargeatfriends.com/qr/getpdf?customerguid=${row.friendGuid}&bookingguid=${row.rowKey}&cpo=1`} > Anbieter</Link>
                                </TableCell>
                                <TableCell align="right" sx={{ border: "none" }}>
                                    <Link target="_blank" to={`https://appbackend.chargeatfriends.com/qr/getpdf?customerguid=${row.friendGuid}&bookingguid=${row.rowKey}&cpo=0`} > Fahrer</Link>
                                </TableCell>
                            
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
    );
}
export default Booking;