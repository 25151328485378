
import React, { useState, useEffect } from 'react';
import { Divider, Col, Row } from 'antd';
import { json, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Popover, Segmented } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
const StateButton = ({ onClickCallback, isDisabled, isActive, buttonTextPlaceholder, popupContentPlaceholder }) => {
    const { t } = useTranslation();
    return (
        <>
            <Col xs={20} md={20}>
                <Button
                    onClick={onClickCallback}
                    disabled={isDisabled}
                    style={{
                        backgroundColor: isActive ? '#5faa39' : '#cccccc',
                        color: isActive ? 'white' : 'black',
                        height: 70,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {t(buttonTextPlaceholder)}
                </Button>
            </Col>
            {/* Put conditions is buttonTextPlaceholder not emtpy */}
            
            {popupContentPlaceholder && (
            <Col xs={2} md={4}>
                <Popover
                    placement="rightTop"
                    title={t(buttonTextPlaceholder)}
                    arrowPointAtCenter
                    content={t(popupContentPlaceholder)}
                    trigger="hover"
                >
                    <InfoCircleOutlined style={{ marginLeft: '10px', color: "#5faa39", fontSize: '30px' }} />
                </Popover>
            </Col>
            )}
        </>
    );
}
export default StateButton;