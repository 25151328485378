import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import {  Box } from '@mui/material';
import '../../../src/css/baseDataMenu.css';
import AccountMenu from '../Account/AccountMenu';
import { Height } from '../../../node_modules/@material-ui/icons/index';

const CompanyMenu = () => {

    const [selectedLink, setSelectedLink] = useState('BaseData');
    localStorage.setItem('activePage', 'CompanyMenu');
    const { t } = useTranslation();
    const links = [
        {
            page: t('Base Data'),
			link: 'BaseData',
			enabled: true
        },
        {
            page: t('Admins'),
            link: 'Admins',
			enabled: true
		},
        {
            page: t('Subscription'),
            link: 'ConnectMobile',
			enabled: false
		},
       
    ];

    const handleLinkClick = (link) => {
        setSelectedLink(link);
    };

    return (
        <div style={{ width: '100%', border: "none" }}>
            <Row >
                <Col xs={24} md={5} 
                         >

                    <AccountMenu initialSelectedPage="Company"  ></AccountMenu>

                </Col>
                <Col xs={24} md={19} >

                    <div className="top-menu" style={{
                        width: "100%",
                        border: "none",
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "12px",
                        
                     }} >
                       
                        {links.map(({ link, page, enabled }, index) => (
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    width: "100%",
                                } }
                            >
                                <button 
									className={enabled ? (selectedLink === link ? 'button-link2 active' : 'button-link2') : "button-link2 disabled"}
						key={link}
						 
                        onClick={() => handleLinkClick(link)}
                                
                    >
                        {page}
                                </button>
                               
                            </div>  
                ))}
                
                      
            </div>
            {selectedLink && (
                <DynamicComponentLoader componentName={selectedLink} />
                        )}
                    
                </Col>
                
                </Row>
        </div>
    );
};

const DynamicComponentLoader = ({ componentName }) => {
    const ComponentToLoad = React.lazy(() =>
        import(`./${componentName}`)
    );

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <ComponentToLoad />
        </React.Suspense>
    );
};

export default CompanyMenu;
