var GetSetGlobalVariable = (function () {
  var ChargingByKWhValue = "";
    ///set and get ChargingByKWhValue value and get
    var getChargingByKWhValue = function() {
    return ChargingByKWhValue;    // Or pull this from cookie/localStorage
  };

  var setChargingByKWhValue = function (name) {
    ChargingByKWhValue = name;
    // Also set this in cookie/localStorage
  };
  ///set and get Parking value and get
  var ParkingValue = "";
  var getParkingValue = function () {
    return ParkingValue;    // Or pull this from cookie/localStorage
  };

  var setParkingValue = function (name) {
    ParkingValue = name;
  
      };
  

  var SelectedLanguage = "";
  var getSelectedLanguageValue= function() {
      return SelectedLanguage;    // Or pull this from cookie/localStorage
    };

  var AccessProfileGuid = "";
  var getaccessProfileGuid = function () {
    return AccessProfileGuid;    // Or pull this from cookie/localStorage
  };

  var setaccessProfileGuid = function (name) {
    AccessProfileGuid = name;
    // Also set this in cookie/localStorage
  };

  var AccessProfileOpeningHours = "";
  var getAccessProfileOpeningHours = function () {
    return AccessProfileOpeningHours;    // Or pull this from cookie/localStorage
  };

  var setAccessProfileOpeningHours = function (name) {
    AccessProfileOpeningHours = name;
    // Also set this in cookie/localStorage
  };
  var UserGroupsName = "";
  var getUserGroupsName = function () {
    return UserGroupsName;    // Or pull this from cookie/localStorage
  };

  var setUserGroupsName = function (name) {
    UserGroupsName = name;
    // Also set this in cookie/localStorage
  };
    var setSelectedLanguageValue= function(name) {
      SelectedLanguage = name;     
      // Also set this in cookie/localStorage
    };


  return {
    getChargingByKWhValue: getChargingByKWhValue,
    setChargingByKWhValue: setChargingByKWhValue,

    getParkingValue: getParkingValue,
    setParkingValue: setParkingValue,

    getSelectedLanguageValue: getSelectedLanguageValue,
    setSelectedLanguageValue: setSelectedLanguageValue,    

    getaccessProfileGuid: getaccessProfileGuid,
    setaccessProfileGuid: setaccessProfileGuid,

    getAccessProfileOpeningHours: getAccessProfileOpeningHours,
    setAccessProfileOpeningHours: setAccessProfileOpeningHours,

    getUserGroupsName: getUserGroupsName,
    setUserGroupsName: setUserGroupsName

  }

})();

export default GetSetGlobalVariable;