import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import LanguageSwitcher from './Locale/LanguageSwitcher';
import Logo from './ChargeAtFriends.png';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { BASE_URL, SSO_URL } from './Settings';

import { atom, useAtom, useSetAtom } from 'jotai';
import getSetProperty from './components/services/session';
const isCompanyContextAtom = atom(Cookies.get("isCompanyContext") === "true");
const stripeIdAtom = atom(null);

function ResponsiveAppBar() {
    const { t } = useTranslation();
    const pages = [t('My Account'), t('Charging Stations')];
    const pagesLink = [
        { name: t('My Account'), link: '/Provider' },
        { name: t('Charging Stations'), link: '/ChargeLocation' },
    ];

    const settings = [
        { name: t('Profile'), link: SSO_URL + '/manage/changeprofile' },
     /*   { name: t('My Account'), link: '/Provider' },*/
        { name: t('Logout'), link: SSO_URL + '/accounts/logout' },
       /* { name: t('Booking'), link: '/Booking' },*/
    ];

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElContextMenu, setAnchorElContextMenu] = useState(null);

    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState(null);
    const [hoveredPage, setHoveredPage] = useState(null);
    const [companyGuid, setCompanyGuid] = useState(null);

    var token = Cookies.get('token');
    var decoded = token ? jwt_decode(token) : "";
    var guid = token ? decoded.jti : "";
    let loginusername = token ? decoded.given_name : "";
    let Role = token ? decoded.role : "";
    console.log("Role", Role);
    const [data, setData] = useState([]);
    const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
    const [CustomerGuid, setCustomerGuid] = useState(null);

    const setStripeId = useSetAtom(stripeIdAtom);

    console.log("stripe value data", data);
    let ownerGuid = Cookies.get('ownerGuid');
    console.log("OwnerGuid from header:", ownerGuid);

    if (ownerGuid === undefined || ownerGuid === 'null') {
        ownerGuid = CustomerGuid || guid;
        Cookies.set('ownerGuid', ownerGuid);
        console.log("header.jsx--ownerGuid==undefined", ownerGuid);
    } else {
        ownerGuid = Cookies.get('ownerGuid');
        console.log("OwnerGuid from Cookies:", ownerGuid);
    }

    useEffect(() => {
        const api = axios.create({
            baseURL: BASE_URL
        });

        const fetchData = async () => {
            try {
                const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
                console.log("response.data.stripeId", response.data.stripeId);
                setData(response.data);
                setStripeId(response.data.stripeId);
                setCustomerGuid(response.data.guid);
                setCompanyGuid(response.data.companyGuid);
                console.log("isCompanyContext inside the useeffect", isCompanyContext);
                console.log("response of header data", response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [CustomerGuid]);

    const [companyData, setCompanyData] = useState(null);
    const [CompanyName, setCompanyName] = useState(null);

    const fetchCompanyData = async () => {
        const api = axios.create({
            baseURL: BASE_URL
        });

        try {
            const response = await api.get(`/Company/GetCompany/${companyGuid}/${token}`);
            console.log("response.data.stripeId", response.data.stripeId);
            setCompanyData(response.data);
            setCompanyName(response.data.name);
            console.log("isCompanyContext inside the useeffect", isCompanyContext);
            console.log("response of header data", response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (companyGuid) {
            fetchCompanyData();
        }
    }, [companyGuid]);

    const hasCompanyContext = () => {
        console.log('header.jsx--hasCompanyContext==undefined', Cookies.get('isCompanyContext'));
        return Cookies.get('isCompanyContext') === 'true';
    };

    const switchToCompany = () => {
        ownerGuid = companyGuid;
        console.log("companyGuid inside switch", companyGuid);
        console.log("console log of ownerGuid", ownerGuid);
        Cookies.set('isCompanyContext', true, { expires: 365 });
        Cookies.set('ownerGuid', ownerGuid, { expires: 365 });
        setIsCompanyContext(true);
    };

    const switchToPrivate = () => {
        ownerGuid = CustomerGuid;
        console.log("console log of ownerGuid", ownerGuid);
        Cookies.set('isCompanyContext', false, { expires: 365 });
        Cookies.set('ownerGuid', ownerGuid, { expires: 365 });
        setIsCompanyContext(false);
    };

    const handleMouseEnter = (page) => {
        setHoveredPage(page);
    };

    const handleMouseLeave = () => {
        setHoveredPage(null);
    };

    const handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('ownerGuid');
        localStorage.removeItem('CpLocationJson');
        localStorage.removeItem('ChargePointJson');
        window.location.href = 'https://sso.chargeatfriends.com/accounts/logout';
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleOpenContextMenu = (event) => {
        setAnchorElContextMenu(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleCloseContextMenu = () => {
        setAnchorElContextMenu(null);
    };

    const handlePageClick = (event, page) => {
        setSelectedPage(page);
        const pageValue = page;
        if (pageValue === t('My Account')) {
            navigate('/Provider');
        } else if (pageValue === t('Charging Stations')) {
            if (data === null && data.length === 0) {
                navigate('/Stripe');
            } else {
                navigate('/ChargeLocation');
            }
        } else if (pageValue === 'PartnerAdmin' && Role === 'GlobalAdmin') {
            navigate('/PartnerAdmin');
        } else {
            return console.log('nothing to route');
        }
    };

    const handleButtonClick = () => {
        const baseUrl = 'https://cpo.chargeatfriends.com';
        window.location.href = baseUrl;
    };

    const handleContextSwitch = () => {
        if (isCompanyContext) {
            switchToPrivate();
        } else {
            switchToCompany();
        }
        handleCloseContextMenu();
    };

    console.log(data);

    return (
        <AppBar position="static" color="transparent">
            <Container maxWidth="xl">
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <a href="https://chargeatfriends.com">
                            <Box
                                component="img"
                                sx={{
                                    mr: 90,
                                    display: { xs: 'none', md: 'flex' },
                                    height: 50,
                                    width: 106,
                                }}
                                alt="Charge at Friends"
                                src="/logo.svg"
                            />
                        </a>
                    </Typography>

                    {token !== null && token !== undefined ? (
                        <>
                        {/* removed mobile menu on 26.08.2024 */}
                            {/*<Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}>
                                 <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton> */}
                                {/* <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pagesLink.map((page) => (
                                        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                            <a href={page.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                <Typography textAlign="center">{page.name}</Typography>
                                            </a>
                                        </MenuItem>
                                    ))}
                                </Menu> 
                            </Box>*/}

                            {data.hasCompany !== null && (
                                <Box>
                                    <Button onClick={handleOpenContextMenu}>
                                        {`${t('DirectoryLabel')} ${isCompanyContext ? CompanyName : loginusername}`}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorElContextMenu}
                                        open={Boolean(anchorElContextMenu)}
                                        onClose={handleCloseContextMenu}
                                    >
                                        <MenuItem onClick={handleContextSwitch}>
                                            {isCompanyContext ? `${t('Switch to')} ${loginusername}` : `${t('Switch to')} ${CompanyName}`}
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            )}

                           

                            <Box sx={{ flexGrow: 0, md: 2 }}>
                                <Tooltip>
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 2 }}>
                                        <Avatar
                                            alt={loginusername.substring(0, 2).toUpperCase()}
                                            title={loginusername.toUpperCase()}
                                            style={{ background: '#5faa39' }}
                                            src="/static/images/avatar/2.jpg"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.name} onClick={setting.name === t('Logout') ? handleLogout : handleCloseUserMenu}>
                                            {setting.link.startsWith('http') ? (
                                                <a href={setting.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    <Typography textAlign="center">{setting.name}</Typography>
                                                </a>
                                            ) : (
                                                <RouterLink to={setting.link} component={RouterLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    <Typography textAlign="center">{setting.name}</Typography>
                                                </RouterLink>
                                            )}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Box>
                                <LanguageSwitcher />
                            </Box>
                        </>
                    ) : (
                        <Button onClick={handleButtonClick}>Login</Button>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export { isCompanyContextAtom, stripeIdAtom };
export default ResponsiveAppBar;
