const ChargeLocationType = {

	Manual: 0x1,
	NoMeter: 0x2,
	OCPP16: 0x4,
	AdHoc: 0x10,
	ClosedUserGroupOnly: 0x20,
	OCPP21: 0x40,
	Calibrated: 0x80,
	Parking: 0x100,
	VisibleMeter: 0x200,
	InternalMeter: 0x400,
	ChargingByKWh: 0x800,
	WithEcoTarif: 0x1000
};

export default ChargeLocationType;