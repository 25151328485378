const userGroupsTypes = {

	PublicGroup : 0x1,
	HasPrice : 0x2,
	PayByApp : 0x4,
	Invoice :0x8,
	GenerateReport : 0x10,
	ExternalStations : 0x20,
	RequiresSubscription : 0x40,
	MonthlyOverview : 0x80
};

export default userGroupsTypes;