import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import revenue from "./Revenues.png";
import { BASE_URL } from '../../Settings';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

const Revenues = () => {
    var token = Cookies.get("token");
    const [isCollapsed, setIsCollapsed] = useState({});
    const [box1Data, setBox1Data] = useState('');
    var guid = Cookies.get("ownerGuid");
    const { t } = useTranslation();

    useEffect(() => {
        let ownerGuid = Cookies.get("ownerGuid");

        // Fetch data for Box 1
        fetch(`${BASE_URL}/Booking/RevenuesSumByCpo/${ownerGuid}/${token}`)
            .then(response => response.text())
            .then(data => {
                // Extract the portion of the string before the first '.'
                const truncatedData = data;
                const sumInEuros = Math.round(data / 100);
                setBox1Data(sumInEuros);
            });
    }, [token, guid]);
    // useEffect(() => {
    //     let ownerGuid = Cookies.get("ownerGuid");
    
    //     // Fetch data for Box 1
    //     fetch(`${BASE_URL}/Booking/FinancialDataByCpo/${ownerGuid}/${token}`)
    //         .then(response => response.json()) // Parse the JSON response
    //         .then(data => {
    //             // Extract the 'sum' value from the data and convert it from cents to euros
    //             const sumInCents = data.sum;
    //             const sumInEuros = Math.round(sumInCents / 100); // Convert to euros and format to 2 decimal places
    //             setBox1Data(sumInEuros);  // Set the 'sum' value in euros
    //         });
    // }, [token, guid]);

    return (
        <Col lg="12" md="12" sm="12">
            <Card className="card-stats">
                <CardBody>
                    <Row className="d-flex align-items-center">
                        <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                                <img style={{ width: '85px' }} src={revenue} alt="revenue" />
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="numbers">
                                <p className="card-category">{t('Revenues')}</p>
                                <CardTitle tag="p">
                                    {box1Data} €
                                    
                                </CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter />
            </Card>
        </Col>
    );
};

export default Revenues;
