import React, { useState, useEffect } from 'react';
import { render } from "react-dom";
// import ChargeatFriends from './ChargeatFriends.png';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { json } from 'react-router-dom';
import ChargeatFriends from './hellocaf.png';
import { BASE_URL } from '../../Settings';
import logo from './sticker_w@1024px.png';
const QRCode = (selectedNewChargePoint) => {
	const [qrCodeData, setQrCodeData] = useState('');
	var token = Cookies.get("token");
	var decoded = jwt_decode(token);
	var guid = decoded.jti;
	console.log("selectedNewChargePoint", selectedNewChargePoint.selectedNewChargePoint);
	var chargePointData = selectedNewChargePoint.selectedNewChargePoint;

  const CpLocationJson = JSON.parse(localStorage.getItem('CpLocationJson'));
  console.log("QR Code",CpLocationJson)
  useEffect(() => {
    const fetchData = async () => {
      try {  //const response = await axios.get(`${BASE_URL}/Sticker/GetQrCode/${guid}/${token}`);
          setQrCodeData(`${BASE_URL}/Sticker/GetQrCode/${chargePointData.guid}/${token}`);
        //console.log(qrCodeData);

			} catch (error) {

				console.error(error);
			}
		};
		fetchData();
	}, []);

	return (
		<div style={{
			border: "1px solid red", borderWidth: "0.5px", padding: "0 37px", borderRadius: "50px", width: "270px", margin: "0%", backgroundColor: "white", boxSizing: "content-box"
		}}>
			<div style={{ textAlign: "center", padding: "5px 0px 0px 0px", margin: "0", height: "20px" }}><img src={ChargeatFriends} alt="Overlay Image" style={{ width: "50%" }} /></div>
			<div style={{ width: "250px", display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "0" }}>
				<div id="2" style={{ flex: "0 0 auto", marginLeft: "2px", marginRight: "30px" }}  >
					<img src={logo} alt="Overlay" style={{ width: "120px" }} />
				</div>
				<div id="1" style={{ flex: "0 0 auto", position: "relative", marginRight: "10px" }}>
					<div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "20%" }}>
						<img src={logo} alt="Overlay Image" style={{ width: "100%" }} />
					</div>
					<img src={qrCodeData} alt="QR Code" style={{ display: "block", width: "120px" }} />
				</div>

			</div>
			<div id="3" style={{ marginLeft: "0px", marginRight: "7px", overflow: "hidden", marginBottom: "7px", textAlign: "center", marginTop: "-4px" }}>
				<label style={{ fontSize: "x- small", fontFamily: "Arial Narrow", whiteSpace: "pre-wrap", displey: "flex", fontSize: "10px" }}> {chargePointData.country}*{chargePointData.zipCode}*{chargePointData.city}*{chargePointData.street}-{chargePointData.streetNumber}*{chargePointData.cpName}*{chargePointData.name}</label>
			</div>
		</div>
	);
};

export default QRCode;