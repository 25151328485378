import React, { useState,useEffect } from 'react';
import { Divider, Col, Row, } from 'antd';
import { TextField, Box } from '@mui/material';
import { debounce } from 'lodash'
import MapContainer from './MapContainer';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { useLocation } from 'react-router-dom';
import validationRules from "../services/validationRules";
import Validator from 'validator'; // Import the Validator object
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../node_modules/@mui/material/index';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Segmented } from 'antd';

function Profile() {

  const { t } = useTranslation();
  let Json = JSON.parse(localStorage.getItem('CpLocationJson'));

  const { state } = useLocation();
  let valuesInitialState;

  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;

  } else {
    valuesInitialState = Json;
    console.log("state is null or undefined");
  }

  const [values, setValues] = useState(valuesInitialState);
  console.log("initial Profile json", valuesInitialState)

  // Debounced update function
  const debouncedUpdateServer = debounce(updateServer, 5000); // Adjust the delay as needed

  const [isLoading, setIsLoading] = useState(false);

  const [rowKey, setRowKey] = useState(Json);

  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");

  //#region  google map value and bind this value in textfield
    const [latLng, setgoogleLatLng] = useState({ lat: null, lng: null });
  const [showMap,setShowMap]=useState(false);


  const refresh = () => {
    const rowKey = valuesInitialState?.rowKey || Json.rowKey;
  
    axios
      .get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${rowKey}/${token}`)
      .then(function (response) {
        setIsLoading(false);
        localStorage.setItem('CpLocationJson', JSON.stringify(response.data));
        console.log("int",response.data);
        valuesInitialState = response.data;
        setValues(response.data);
    
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(refresh, []);


  //#endregion
  // Server update function
  function updateServer(filds) {
    //UpdateFields(fields);
    // Perform the server update here using the fields object
console.log("latLng",latLng)
    const updatedValues = {
      ...values,
      // lat: Math.round(latLng.lat * 1000000) / 1000000,
      // lon: Math.round(latLng.lng * 1000000) / 1000000,
      lat: latLng.lat !== null ? latLng.lat  : valuesInitialState?.lat || values.lat,
      lon: latLng.lng !== null ? latLng.lng  : valuesInitialState?.lon || values.lon,
    };
      console.log("old profile", updatedValues)

    
    fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${guid}/${valuesInitialState.guid}/${token}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
        body: JSON.stringify(updatedValues),

    }).then((response) => {
      if (response.ok) {

        console.log('Updated at:', response);
        localStorage.removeItem('CpLocationJson');
        localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));
        Json = JSON.parse(localStorage.getItem('CpLocationJson'));
        
        console.log("after update", Json)
        /*refresh();*/
        // Server update was successful
      } else {
        console.error('There was an error!');
        // Handle error response from the server
      }
    });
  }


  // End validate textfields code
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleChange = (input) => (e) => {
    const value = e.target.value || ''; // Set an empty string if the value is undefined
    console.log("handlechange", value)
    
      setValues({ ...values, [input]: value });
      setgoogleLatLng(latLng);
      

     
    //setErrors({ ...errors, [input]: '' }); // Clear the error for the current field
    // setRowKey({ ...values, [input]: value  }); // Update the rowKey state with the new value

  };

  const handleBlur = () => {
    console.log("profile lat", latLng)
    // const updatedValues = { ...values, lat: Math.round(latLng.lng * 1000) / 1000, lon: latLng.lng };
    // updateServer(updatedValues);
      updateServer();
    // const isValid = validateFields();
    // if (isValid) {
    //   updateServer();
    // }
    };
    useEffect(() => {
        if (latLng.lat !== null && latLng.lng !== null) {
            updateServer();
        }
    }, [latLng.lat, latLng.lng])

  if (isLoading) {
    return <div style={{
      padding: '2% 20%'
    }}> <h2>Loading...</h2>
    </div>;
  }
  return (
    <React.Fragment>

      <div style={{ width: '100%' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            margin: '1% 0',
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
          }}
        >
          <Row>

            <Col xs={24} md={25}>
              <div style={{ padding: '0% 1vw' }}>

                <div style={{ padding: '2%' }}>
                  <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                                      {showMap ? t('Choose Your Location') : t('Charge Point Information.')}
                                  </h1>
                                  

                  <form>
                                      <div style={{ width: "100%" }}>
                                         
                                              {showMap ?
                                                  <div >
                                                      <Row gutter={[10, 25]}>
                                                      
                                                      {/* <Col xs={24} md={24} >*/}
                                                      {/*    <div className="map-container-wrapper" style={{ position: 'relative', width: '100%', paddingBottom: '40%', '@media (min-width: 400px)': { paddingBottom: '400px' } }}>*/}
                                                      {/*        <MapContainer setLatLng={setgoogleLatLng} />*/}
                                                      {/*    </div>*/}

                                                      {/*</Col>*/}
                                                      <div className="map-container-wrapper" style={{ position: 'relative', width: '100%', paddingBottom: '40%' }}>
                                                          <MapContainer setLatLng={setgoogleLatLng} />
                                                      </div>
                                                      
                                                     
                                                      
                                                        <Col xs={24} md={12}>
                                                          <TextField
                                                            id="Lat"
                                                            label={t('Latitude')}
                                                            color="success"
                                                            value={latLng.lat  || values?.lat || valuesInitialState?.lat || ''}

                                                            //value={values?.lat || ''}
                                                             //onChange={handleChange('lat')}
                                                             //onBlur={handleBlur}
                                                            focused
                                                            fullWidth
                                                                  placeholder={t('Choose location on map')}
                                                              readOnly
                                                              disabled
                                                            
                                                          // error={!!errors.lat} // Add error prop if there is an error
                                                          // helperText={errors.lat} // Display the error message^

                                                          ></TextField>  <p style={{ fontSize: '1rem', color: 'gray' }}>{t('Choose location on map')}</p>
                                                        </Col>

                                                        <Col xs={24} md={12}>
                                                          <TextField
                                                            id="lon"
                                                            label={t('Longitude')}
                                                            color="success"
                                                            value={latLng.lng  || values?.lon || valuesInitialState?.lon ||''}
                                                            fullWidth
                                                            //value={values?.lon || ''}
                                                            //onchange={handlechange('lon')}
                                                           //onblur={handleblur}
                                                            focused
                                                            placeholder={t('Choose location on map')}
                                                              readOnly
                                                              disabled
                                                                  
                                                          // error={!!errors.lon} // Add error prop if there is an error
                                                          // helperText={errors.lon} // Display the error message
                                                          />  <p style={{ fontSize: '1rem', color: 'gray' }}>{t('Choose location on map')}</p>
                                                          </Col>
                                                          <Col xs={24} md={12}>
                                                          <Button onClick={() => setShowMap(false)}>{t('Back')}</Button>
                                                          </Col>
                                                          </Row>
                                                      
                                                      </div>:
                                                      <div>                    
                                                      
                                                         
                                                          <Divider />

                                                          <Row gutter={[10, 25]}>
                                                              <Col xs={24} md={12} >
                                                                  <TextField
                                                                      id="ZipCode"
                                                                      label={t('Zip Code')}
                                                                      color="success"
                                                                      value={values?.zipCode || ''}
                                                                      onChange={handleChange('zipCode')}
                                                                      onBlur={handleBlur}
                                                                      focused
                                                                      fullWidth
                                                                      helperText={values.zipCode && values.zipCode.length > 5 ? t('Zip Code should be 5 characters') : ''}
                                                                      error={values.zipCode && values.zipCode.length > 5} // Add error prop if the length is greater than 5

                                                                  // error={!!errors.zipCode} // Add error prop if there is an error
                                                                  // helperText={errors.zipCode} // Display the error message
                                                                  />
                                                              </Col>
                                                              <Col xs={24} md={12} >
                                                                  <TextField
                                                                      id="City"
                                                                      fullWidth
                                                                      label={t('City')}
                                                                      color="success"
                                                                      value={values?.city || ''}
                                                                      onChange={handleChange('city')}
                                                                      onBlur={handleBlur}
                                                                      focused

                                                                  // error={!!errors.city} // Add error prop if there is an error
                                                                  // helperText={errors.city} // Display the error message
                                                                  />
                                                              </Col>
                                                          </Row><Divider />
                                                          <Row gutter={[10, 25]}>
                                                              <Col xs={24} md={12}>
                                                                  <TextField
                                                                      id="Street"
                                                                      fullWidth
                                                                      label={t('street')}
                                                                      color="success"
                                                                      value={values?.street || ''}
                                                                      onChange={handleChange('street')}
                                                                      onBlur={handleBlur}
                                                                      focused
                                                                      helperText={values.street && values.street.length > 40 ? t('Street should be 40 characters') : ''}
                                                                      error={values.street && values.street.length > 40}

                                                                  // error={!!errors.street} // Add error prop if there is an error
                                                                  // helperText={errors.street} // Display the error message
                                                                  />
                                                              </Col>
                                                              <Col xs={24} md={12}>
                                                                  <TextField
                                                                      id="StreetNumber"
                                                                      label={t('streetNumber')}
                                                                      color="success"
                                                                      value={values?.streetNumber || ''}
                                                                      onChange={handleChange('streetNumber')}
                                                                      onBlur={handleBlur}
                                                                      focused
                                                                      fullWidth
                                                                      helperText={values.streetNumber && values.streetNumber.length > 4 ? t('streetNumber should be 4 characters') : ''}
                                                                      error={values.streetNumber && values.streetNumber.length > 4}
                                                                  // error={!!errors.streetNumber} // Add error prop if there is an error
                                                                  // helperText={errors.streetNumber} // Display the error message
                                                                  />
                                                              </Col>
                                                          </Row>
                                                          <Divider />






                                                          
                                                          <Row gutter={[10, 25]}>
                                                              <Col xs={24} md={12}>

                                                              <TextField
                                                                  id="Address2"
                                                              label={
                                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                      {t('Ladeplatz')}
                                                                      <Popover
                                                                          placement="rightTop"
                                                                          title={t("Charge location") }
                                                                          arrowPointAtCenter
                                                                          content=
                                                                              
                                                                                  {t('Name of your station, as it is displayed to users, e.g. „Ella-Power“ (max. 16 digits)') }
                                                                      
                                                                          trigger="hover" // Show popover on hover
                                                                      >
                                                                          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                                                                      </Popover>
                                                                  </div>
                                                              }
                                                                  color="success"
                                                                  value={values?.address2 || valuesInitialState?.address2 || ''}
                                                                  onChange={handleChange('address2')}
                                                                  onBlur={handleBlur}
                                                              helperText={values.address2 && values.address2.length > 16 ? t('Charging point description should be 16 characters') : ''}
                                                                  error={values.address2 && values.address2.length > 16}
                                                                  fullWidth
                                                                  focused
                                                              />
                                                             


                                                              </Col>
                                                              <Col xs={24} md={12}>
                                                          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: '100%' }}>
                                                              <Button
                                                                  onClick={() => setShowMap(true)}
                                                                 
                                                              >
                                                                  {t('Search Position in Map')}
                                                              </Button>
                                                          </div>
                                                              </Col>
                                                          </Row>
                                                      
                                                  </div>}
                                     


                                         

                    </div>
                  </form>
                </div>
              </div>
            </Col>
           
          </Row></Box>
      </div>


    </React.Fragment>
  );
}

export default Profile;
