import { MenuItem as MuiMenuItem, MenuList, Stack, Paper, Box } from '@mui/material';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { stripeIdAtom } from '../../Header';
import { isCompanyContextAtom } from '../../Header';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import '../../../src/css/App.css';

const AccountMenu = (initialSelectedPage) => {
	
    const { t } = useTranslation();
    const navigate = useNavigate();
    const stripeId = useAtomValue(stripeIdAtom);
    const isConnectedToStripe = stripeId !== null && stripeId !== undefined;
    console.log('isConnectedToStripe', isConnectedToStripe);
    const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
    const prevIsCompanyContext = useRef(isCompanyContext);
 
    var token = Cookies.get('token');
    var decoded = token? jwt_decode(token):"";
    let Role = token?decoded.role:""; 
    console.log("tokenasdsdf",token)

    console.log("Role",Role)
    console.log("Role", Role !== 'GlobalAdmin')

   
    const links =  
    isCompanyContext
       ? [
           // Add Company page
           {
               page: t('Company'),
               link: 'CompanyMenu',
               isExpandable: false,
               level: 1,
               isDisabled: false,
               redCssText:false
           },

           {
               page: t('Zahlungskonto'),
               link: 'Stripe',
               isExpandable: true,
               level: 2,
               isDisabled: false,
               redCssText:!isConnectedToStripe
           },
           {
               page: t('Ladestationen'),
               link: 'ChargeLocation',
               isExpandable: false,
               level: 1,
               isDisabled: !isConnectedToStripe,
               redCssText:false
           },
           {
               page: t('Opening Hours'),
               link: 'AccessProfiles',
               isExpandable: false,
               level: 1,
               isDisabled: false,
               redCssText:false
           },
           {
               page: t('Users'),
               link: 'CompanyUsers',
               isExpandable: false,
               level: 1,
               isDisabled: false,
               redCssText:false
           },
           {
               page: t('Groups'),
               link: 'UserGroups',
               isExpandable: false,
               level: 1,
               isDisabled: false,
               redCssText:false
           },
           {
               page: t('Invites'),
               link: 'Invites',
               isExpandable: false,
               level: 1,
               isDisabled: false,
               redCssText:false
           },
           {
            page: t('Flex'),
            link: 'FlexPriceConfigurations',
            isExpandable: false,
            level: 1,    
            isDisabled: false,
            redCssText:false,
        },
            

       ] : [
        
            {
                page: t('Anbieterdaten'),
                link: 'Provider',
                isExpandable: false,
                level: 1,
                isDisabled: false,
                redCssText:false
            },
            {
                page: t('Zahlungskonto'),
                link: 'Stripe',
                isExpandable: true,
                level: 2,
                isDisabled: false,
                redCssText:false
            },
            //{
            //    page: t('Smartphone'),
            //    link: t('ConnectMobile'),
            //    isExpandable: true,
            //    level: 2,
            //    isDisabled: false,
            //    redCssText:false
            //},
            {
                page: t('PartnerAdmin'),
                link: t('PartnerAdmin'),
                isExpandable: false,
                level: 1,
                isDisabled: Role !== 'GlobalAdmin',
                redCssText:false // Disable if the user is not a GlobalAdmin
              },
            {
                page: t('Ladestationen'),
                link: 'ChargeLocation',
                isExpandable: false,
                level: 1,    
                isDisabled: !isConnectedToStripe,
                redCssText:false,
            },
            {
               page: t('Opening Hours'),
               link: 'AccessProfiles',
               isExpandable: false,
               level: 1,
               isDisabled: false
            },
            //,
            //{
            //    page: t('Users'),
            //    link: 'CompanyUsers',
            //    isExpandable: false,
            //    level: 1,
            //    isDisabled: false,
            //    redCssText:false
            //},
            {
               page: t('Groups'),
               link: 'UserGroups',
               isExpandable: false,
               level: 1,
               isDisabled: false
            },
            {
               page: t('Invites'),
               link: 'Invites',
               isExpandable: false,
               level: 1,
               isDisabled: false
            },
            {
                page: t('Flex'),
                link: 'FlexPriceConfigurations',
                isExpandable: false,
                level: 1,    
                isDisabled: false,
                redCssText:false,
            }
        ];

    const submenus = {
        [t('Anbieterdaten')]: [t('Zahlungskonto')],
        [t('Anbieterdaten')]: [t('Smartphone')],
    };

    const [hoveredPage, setHoveredPage] = useState(null);

    console.log("isCompanyContext", isCompanyContext);

    const activePageName = localStorage.getItem('activePage');
    const defaultState = links.map(link => link.link === activePageName);
    const [activeProp, setActiveProp] = useState(defaultState);
    console.log("ACTIVE PAGE", activePageName);
    const [isExpanded, setIsExpanded] = useState(activePageName === 'Provider' || activePageName === 'Stripe' || activePageName === 'ConnectMobile');
    const [children, setChildren] = useState(activePageName);
    const [selectedPage, setSelectedPage] = useState(activePageName);
    const index = links.findIndex(link => link.link === selectedPage);

    const setIndexToTrue = (index) => {
        setActiveProp(prevActiveProp => {
            const newActiveProp = [...prevActiveProp];
            newActiveProp[index] = true;
            return newActiveProp;
        });
    };

    const handlePageClick = (event, link, index) => {
        setIndexToTrue(index);
        setSelectedPage(link);
        setChildren(link);
        if (!index) {
            setIsExpanded(!isExpanded);
        } else {
            setIsExpanded(true);
        }
      
        localStorage.setItem('activePage', link); // Save the selected page name in localStorage
        
    };

    useEffect(() => {
    }, [selectedPage]);
    useEffect(() => {
        if (prevIsCompanyContext.current !== isCompanyContext) {
            const targetPage = isCompanyContext ? '/CompanyMenu' : '/Provider';
            navigate(targetPage);
        }
        prevIsCompanyContext.current = isCompanyContext;
    }, [isCompanyContext, navigate]);


    const handleMouseEnter = (link) => {
        setHoveredPage(link);
    };

    const isSubMenu = (page) => submenus[page].length > 0;

    const handleMouseLeave = () => {
        setHoveredPage(null);
    };

    function MenuItem({
        index,
        link,
        to,
        children,
        selectedPage,
        hoveredPage,
        onPageClick,
        onMouseEnter,
        onMouseLeave,
        isExpanded,
        level,
        isDisabled,
        redCssText
    }) {
        console.log("link", link);
        const isAnbieterdaten = link == 'Provider';
        const isCompany = link ==='CompanyMenu';
        const isSubMenuItem = (isAnbieterdaten || isCompany) && isExpanded;;
        const isSubMenuTrue = link === 'Stripe' || link === 'ConnectMobile';
        const parentMenuItemStyle = {};

        // Apply styles to sub-menu items
        const subMenuItemStyle = {
            paddingLeft: "20px" // Indent sub-menu items
        };
        
        return (
            <MuiMenuItem
                style={{
                    padding: '0px', width: "100%"
                    //...(isAnbieterdaten ? parentMenuItemStyle : {}),
                    //...(isSubMenuTrue ? subMenuItemStyle : {}),
                }}
                onMouseEnter={() => onMouseEnter(children)}
                onMouseLeave={onMouseLeave}
            >
                <Link
                    to={to}
                    className={`nav-link button-link menu-level-${level} ${activeProp[index] ? 'active' : hoveredPage === children ? 'hovered' : ''} ${isDisabled ? 'disabled' : ''}${redCssText ? 'red' : ''}`}
                    onClick={(event) => onPageClick(event, link, index)}
                >
                    {children}
                    {console.log("isAnbieterdaten, isCompany,isSubMenuItem,link", isAnbieterdaten, isCompany, isSubMenuItem,link) }
                    {((isAnbieterdaten || isCompany) && (
                        <span className="">
                            {isSubMenuItem ? <ExpandMoreIcon className="icon-button" /> : <ExpandLessIcon className="icon-button" />}
                        </span>
                    ) )}
                </Link>
            </MuiMenuItem>
        );
    }

    return (<>
        <div style={{ padding: '5% ', paddingRight: '0', height: '100%' }}>
            {/* <Stack direction="row" spacing={12}>
                <Paper style={{ height: '100%', width: '100%' }}>
                    <MenuList>
                        {links.map(({ isExpandable, link, page, level, isDisabled,redCssText}, index) => (
                            (!isExpandable || isExpanded) &&
                            <MenuItem
                                    index={index}
                                    link={link}
                                key={index}
                                to={`../${link}`}
                                selectedPage={selectedPage}
                                hoveredPage={hoveredPage}
                                    onPageClick={handlePageClick}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                isExpanded={isExpanded}
                                level={level}
                                isDisabled={isDisabled}
                                redCssText={redCssText}
                            >
                                {page}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Paper>
            </Stack> */}


{/* below code modified by bhargav for visible menu  Existing code is not working if we set isDisabled value true or false.*/}
<Stack direction="row" spacing={12}>
    <Paper style={{ height: '100%', width: '100%' }}>
        <MenuList>
            {links.map(({ isExpandable, link, page, level, isDisabled, redCssText }, index) => (
                (!isExpandable || isExpanded) &&
                !isDisabled && // Add this condition to prevent rendering when isDisabled is true
                <MenuItem
                    index={index}
                    link={link}
                    key={index}
                    to={`../${link}`}
                    selectedPage={selectedPage}
                    hoveredPage={hoveredPage}
                    onPageClick={handlePageClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    isExpanded={isExpanded}
                    level={level}
                    isDisabled={isDisabled}
                    redCssText={redCssText}
                >
                    {page}
                </MenuItem>
            ))}
        </MenuList>
    </Paper>
</Stack>

        </div>
    </>);
}
export default AccountMenu;