import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Settings";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Cookies from "js-cookie";
import AccessProfileGuid from '../services/session';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
} from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import AccessProfileDetailsCockpit from "./AccessProfileDetailsCockpit";

const AccessProfiles = () => {
	const token = Cookies.get("token");
	const [userGroups, setUserGroups] = useState([]);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const ownerGuid = Cookies.get("ownerGuid");

	const [selectedRow, setSelectedRow] = useState(null); // State to manage selected row

	useEffect(() => {
		const fetchData = async () => {
			try {
				const api = axios.create({ baseURL: BASE_URL });
				const response = await api.get(`AccessProfile/AccessProfiles/${ownerGuid}/${token}`);
				if (response.data === undefined) {
					console.log("No access profiles found");
				} else {
					setUserGroups(response.data);
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetchData();
	}, [ownerGuid, token, selectedRow]);

	const handleRowClick = (row) => {
		AccessProfileGuid.setaccessProfileGuid(row.guid);
		setSelectedRow(row); // Set selected row
	};

	const handleBackClick = () => {
		setSelectedRow(null); // Reset selected row to null
	};
	var getBitFromMask = function (value, mask) {
		return value & mask;
	};
	const accessProfileTitles = [
		"Name",
		t("Days"),
	];
	var getBitFromMask = function (value, mask) {
		return value & mask;
	};

	const weekdays = (weekdayBitmask) => {
		if (weekdayBitmask == 31) { return t('Mo') + "-" + t('Fr'); }
		if (weekdayBitmask == 96) { return t('Sa') + "," + t('Su'); }
		if (weekdayBitmask == 127) { return t('Mo') + "-" + t('Su'); }
		const weekdayValues = {
			Mo: 0x1, Tu: 0x2, We: 0x4, Th: 0x8,
			Fr: 0x10, Sa: 0x20, Su: 0x40
		};
		let weekdayResult = "";
		let first = true;
		Object.entries(weekdayValues).forEach(([key, mask]) => {
			if (getBitFromMask(weekdayBitmask, mask) !== 0) {
				if (!first) { weekdayResult += ","; }
				first = false;
				weekdayResult += t(key);
			}
		});
		return weekdayResult;
	};

	const hours = [];
	const hoursOfDay = () => {
		for (let i = 0; i < 24; i++) {
			hours[i] = 1 << i;
		}
		return hours;
	};
	hoursOfDay();

	const hasHour = (idx, hour) => {
		return (hour & idx) > 0;
	};

	const timeSlotCss = (idx, hour) => {
		if (hasHour(idx, hour)) {
			return "timeslot selected";
		}
		return "timeslot";
	};

	const displayBitmaskValues = (bitmask) => {
		const bitmaskValues = {
			PublicGroup: 0x1, HasPrice: 0x2, PayByApp: 0x4,
			Invoice: 0x8, GenerateReport: 0x10, ExternalStations: 0x20,
			RequiresSubscription: 0x40, MonthlyOverview: 0x80
		};

		const displayedValues = {};

		Object.entries(bitmaskValues).forEach(([key, mask]) => {
			displayedValues[key] = getBitFromMask(bitmask, mask) !== 0;
		});

		return displayedValues;
	};

	const callApiCreate = async () => {
		const api = axios.create({
			baseURL: BASE_URL,
			headers: { 'Content-Type': 'application/json' },
		});

		try {
			const data = { Name: "", RowKey: "", PartitionKey: "" };
			const response = await api.post(`AccessProfile/CreateAccessProfile/${ownerGuid}/${token}`, JSON.stringify(data));
			setUserGroups(prevValues => [...prevValues, response.data]);
			return response.data;
		} catch (error) {
			console.error('Error in API call:', error);
		}
	};

	const handleClick = async () => {
		await callApiCreate();
	};

	const accessProfileTable = (
		<div style={{
			width: "80%",
			border: "none",
			margin: " 5% 10%  "

		}} >
			
					
		<TableContainer component={Paper}>
			<Table size="small" aria-label="a dense table">
								<TableHead sx={{
									backgroundColor: "#5faa39",
									border: "1px solid #e0e0e0",
									borderRadius: "1%",

								}}>
					<TableRow>
						{accessProfileTitles.map((value, index) => (
							<TableCell key={index} align="left">
								{value.includes("(") ? (
									<>
										<div>{value.split(" ")[0]}</div>
										<div>({value.split("(")[1].split(")")[0]})</div>
									</>
								) : (
									value
								)}
							</TableCell>
						))}
						<TableCell colSpan="6" className="timeslotHeader">0</TableCell>
						<TableCell colSpan="6" className="timeslotHeader">6</TableCell>
						<TableCell colSpan="6" className="timeslotHeader">12</TableCell>
						<TableCell colSpan="5" className="timeslotHeader">18</TableCell>
						<TableCell className="timeSlotHeaderEnd">23</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{userGroups.map((row, rowIndex) => (
						<TableRow key={rowIndex}>
							{accessProfileTitles.map((value, colIndex) => (
								<TableCell key={colIndex} align="left" onClick={() => handleRowClick(row)}
									style={{ cursor: 'pointer', borderBottom: "1px solid #e0e0e0" }}>
									{value === "Name" && row.name}
									{colIndex === 1 && weekdays(row.weekdays)}
								</TableCell>
							))}
							{hours.map((value, index) => (
								<TableCell key={index} className={timeSlotCss(value, row.hours)}
									title={`${index}-${index + 1}`} align="left" onClick={() => handleRowClick(row)}
									style={{ cursor: 'pointer' }}>
									{hasHour(value, row.hours)}&#xa0;
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
			</TableContainer>
		</div>
	);

	return (
		<div style={{ width: '100%' }}>
			<Row>
				<Col xs={24} md={24}>
					<div style={{ width: "80%", border: "none", margin: " 5% 10%  " }}>
						
						{selectedRow ? (
							<>
								<Row>
									<Col xs={1}>
										<ArrowBackIosIcon onClick={handleBackClick} style={{ cursor: 'pointer' }} />
									</Col>
								</Row>
								<AccessProfileDetailsCockpit row={selectedRow} />
							</>
						) : (
							<>
								{accessProfileTable}
								<Divider />
								<div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
									<Button variant="contained" style={{ backgroundColor: "#5faa39", color: "white" }}
										type="primary" color="primary" onClick={handleClick}>
											{t("Add Opening Hours")}
									</Button>
								</div>
							</>
						)}
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default AccessProfiles;
