import React, { useEffect } from 'react';
import Header from "./Header";
import ChargeLocation from "./ChargeLocation";
import ChargePoint from "./ChargePoint";
import Connect from "./Connect";
import Provider from "./components/Account/Provider";
import CompanyRegister from "./components/Company/Register";
import ConnectMobile from "./components/Account/ConnectMobile";
import Stripe from "./components/Account/Stripe";
import Booking from "./components/Bookings/Booking";
import Details from "./components/Bookings/Details";
import { Routes, Route, Switch, useNavigate } from 'react-router-dom';
import LocationEditt from "./LocationEditt";
import SignInSide from "./SignInSide";
import Wizard from "./Wizard";
import Wizard2 from "./Wizard2";
import Map from "./components/Chargepoint/MapContainer";
import { useCookies } from "react-cookie";
import Cookies from 'js-cookie';
import axios, { isCancel, AxiosError } from 'axios';
import { useState } from "react";
import { RefreshProvider } from './RefreshContext';
import { Navigate } from "react-router-dom";
import { BASE_URL } from './Settings';
import CompanyMenu from './components/Company/CompanyMenu';
import BaseData from './components/Company/BaseData';
import Admins from './components/Company/Admins';
//Users
import CompanyUsers from './components/Users/CompanyUsers';
import CompanyUsersProperties from './components/Users/CompanyUsersProperties';

//UserGroups
import UserGroups from './components/Users/UserGroups';
import UserGroupProperties from './components/Users/UserGroupProperties';
import UserGroupsMenu from './components/Users/UserGroupsMenu';
import UserGroupMembers from './components/Users/UserGroupMembers';

//AccessProfiles
import AccessProfiles from './components/AccessProfile/AccessProfiles';
import AccessProfileProperties from './components/AccessProfile/Properties';
import AccessProfileDetails from './components/AccessProfile/AccessProfileDetails';
//Invites
import Invites from './components/Invite/Invites';
import InviteProperties from './components/Invite/InviteProperties';
import InviteList from './components/Invite/InviteList';
import AccessProfilesCockpit from './components/Cockpit/AccessProfilesCockpit'
import GotoAppStore from './components/App/GotoAppStore';
import PartnerAdmin from "./components/Partner/PartnerAdmin";
import jwt_decode from "jwt-decode";
import ValidationProgress from './components/Chargepoint/ValidationProgress';
import AddressForm from './components/Chargepoint/Address';
import MapContainer2 from './components/Chargepoint/MapContainer2';

import StationStatus from './components/Cockpit/StationStatus';
import CockpitMainPage from './components/Cockpit/CockpitMainPage';
import ChargeStationTabs from './components/Cockpit/ChargeStationTabs';
import ChargeStationDataContext from './components/Cockpit/ChargeStationDataContext';

import AccessProfilesDetailsCockpit from './components/Cockpit/AccessProfileDetailsCockpit';

import FlexPriceConfigurations from './components/Flex/FlexPriceConfigurations';
import ReportsMenu from './components/Cockpit/ReportsMenu';
import MonthlySessionsReports from './components/Cockpit/MonthlySessionsReports';

import ServiceFeeReports from './components/Cockpit/ServiceFeeReports';


const App = () => {

	const [data, setData] = useState(null);
	const token = Cookies.get("token");
	const [isStripe, setIsStripeinapp] = useState(false);
	const navigate = useNavigate();
	console.log("isStripe in app", isStripe);
	// useEffect(() => {
	// 	const api = axios.create({
	// 		baseURL: BASE_URL
	// 	});

	// 	const decoded = jwt_decode(token);
	// 	const guid = decoded.jti;

	// 	const fetchData = async () => {
	// 		try {
	// 			const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
	// 			setData(response.data);


	// 		} catch (error) {
	// 			// Handle any errors that occur during the request
	// 			console.error(error);
	// 		}
	// 	};
	// 	fetchData();

	// }, []);

    useEffect(() => {
        const api = axios.create({
            baseURL: BASE_URL
        });
		const decoded = jwt_decode(token);
		const guid = decoded.jti;
        const fetchData = async () => {
            try {
                const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
                setData(response.data);


				const url = window.location.href.toLowerCase();
				console.log("url:", url);
				
				if (url === "https://cpo.chargeatfriends.com/" 
					|| url === "http://localhost:3000/" 					
					|| url === "https://cpo.chargeatfriends.com/provider") {
                    navigate("/cockpit");
                } 
				else if (url === "https://cpo.chargeatfriends.com/identity/account/login") {
					window.location.href = "https://cpo.chargeatfriends.com";
				} 
				else {

                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [token, navigate]);

	const handleRefresh = () => {
		window.location.reload(); // Reload the window to refresh the app
	};


	//if (data && data.wizardStep !== null) {

	//	return <Navigate to="/Wizard2" replace />; // Navigate to /wizard if data.wizardStep is not null
	//}


	// const [data, setData] = useState([]);
	// var token = Cookies.get("token");

	//  useEffect(() => { 

	//     axios.get(`${BASE_URL}/login/(ReactTokenValidation)/${token}`, {

	//     })
	//         .then(function (response) {
	//             // handle success
	//             console.log("Login",response)
	//             setData(response.data);

	//         })
	//         .catch(function (error) {
	//             // handle error
	//             console.log(error);
	//         })
	//         .finally(function () {
	//             // always executed
	//         });
	// }, [])

	return (
		<RefreshProvider>
			<div>
				<Header />
				<Routes>
					<Route path="/Provider" element={<Provider />} />
					<Route path="/Stripe" element={<Stripe />} />
					<Route path="/ConnectMobile" element={<ConnectMobile />} />
					<Route path="/Company/Register" element={<CompanyRegister />} />
					<Route path="/ChargeLocation" element={<ChargeLocation />} />
					<Route path="/AccessProfilesCockpit" element={<AccessProfilesCockpit/> }/>
					<Route path="/Wizard" element={<Wizard />} />
					<Route path="/Address" element={<AddressForm />} />
					<Route path="/ChargeLocation/Location" element={<LocationEditt />} />
					<Route path="/ChargePoint" element={<ChargePoint />} />
					<Route path="/CompanyUsers" element={<CompanyUsers />} />
					<Route path="/UserGroups" element={<UserGroups />} />
					<Route path="/userGroup/userGroupProperties" element={<UserGroupProperties />} />
					<Route path="/AccessProfiles" element={<AccessProfiles />} />
					{/*<Route path="/Cockpit/AccessProfiles" element={<Access />} />*/}
					<Route path="/AccessProfileDetails" element={<AccessProfileDetails />} />
					<Route path="/SignInSide" element={<SignInSide />} />
					<Route path="/Booking" element={<Booking />} />
					<Route path="/CompanyMenu" element={<CompanyMenu />} />
					<Route path="/BaseData" element={<BaseData />} />
					<Route path="/Map" element={<Map />} />
					<Route path="/Register" element={<CompanyRegister />} />
					<Route path="/Admins" element={<Admins />} />
					<Route path="/UserGroupsMenu" element={<UserGroupsMenu />} />
					<Route path="/UserGroupMembers" element={<UserGroupMembers />} />
					<Route path="/Invites" element={<Invites />} />
					<Route path="/Invite/Properties" element={<InviteProperties />} />
					<Route path="/Invite/InviteList" element={<InviteList />} />
					<Route path="/PartnerAdmin" element={<PartnerAdmin />} />
					<Route path="/CompanyUsersProperties" element={<CompanyUsersProperties />} />
					<Route path="/ValidationProgress" element={<ValidationProgress />} />
					<Route path="/Wizard2" element={<Wizard2 />} />
					<Route path="/MapContainer2" element={<MapContainer2 />} />

					<Route path="/FlexPriceConfigurations" element={<FlexPriceConfigurations />} />

					<Route path="/cockpit/stationStatus" element={<StationStatus />} />
					<Route path="/AccessProfilesDetailsCockpit" element={<AccessProfilesDetailsCockpit />} />
					<Route path="/ReportsMenu" element={<ReportsMenu />} />
					<Route path="/MonthlySessionsReports" element={<MonthlySessionsReports />} />
					<Route path="/ServiceFeeReports" element={<ServiceFeeReports />} />
					{/*not navigational*/}
					<Route path="/Connect" element={<Connect />} />
					<Route path="/cockpit" element={<CockpitMainPage/> }/>
					<Route path="/ChargeStationDataContext" element={<ChargeStationDataContext/> }/>
					<Route index path=":guid" element={<GotoAppStore />} />
					<Route path="/ChargeStationTabs" element={<ChargeStationTabs />}/>
			
				</Routes>

				{data?.wizardStep !== null && data?.wizardStep < 64 && <Navigate to="/Wizard2" replace />}

			</div>
		</RefreshProvider>
	);

}
export default App;
