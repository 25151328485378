import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Switch, Typography} from '@mui/material';
import Cookies from 'js-cookie';
import "../../css/UsersTable.css";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';



import AccountMenu from '../Account/AccountMenu';

const CompanyUsers = ({ ownerGuid, token }) => {
    const navigate = useNavigate();
    const [adminSwitch, setAdminSwitch] = useState(0);
    const [ownerSwitch, setOwnerSwitch] = useState(0);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const [addUser, setIsAddUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [roleState, setRoleState] = useState(null);
    localStorage.setItem('activePage', 'CompanyUsers');
   
    useEffect(() => {
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });

        const fetchData = async () => {
            try {
                const response = await api.get(`User/GetUsers/${ownerGuid}/${token}`);
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [ownerGuid, token, roleState]);
    const handleCellClick = (user) => {
        console.log("user in cell click",user)
        setSelectedUser(user);
        UserRoleState(user);
        getUserMemberships(user)
        navigate(`/CompanyUsersProperties`, { state: { user: user } });

    };
    const [userMemberships, setUserMemberships] = useState([]);
    const [loading, setLoading] = useState(true);
    const getUserMemberships = async (user) => {
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        try {
            // Make an API request to fetch user memberships
            const response = await api.get(`UserGroup/UserGroupMemberships/${ownerGuid}/${user.guid}/${token}`);
            setUserMemberships(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    const UserRoleState = async (user) => {
        console.log("user in role",user)
        // Update switch values based on user.bitMaskRole
        console.log("user.bitMaskRole", user.rolesBitMask)
        if (user.rolesBitMask === 6) {
            setAdminSwitch(1);
            setOwnerSwitch(1);
        } else if (user.rolesBitMask === 2) {
            setAdminSwitch(1);
            setOwnerSwitch(0);
        } else if (user.rolesBitMask === 4) {
            setAdminSwitch(0);
            setOwnerSwitch(1);
        } else {
            setAdminSwitch(0);
            setOwnerSwitch(0);
        }
        
    };
    const handleSwitchChange = async (switchName, newValue, user) => {
        console.log("user update switch", user)
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        let newAdminSwitch = adminSwitch;
        let newOwnerSwitch = ownerSwitch;

        if (switchName === 'admin') {
            newAdminSwitch = newValue;
        } else {
            newOwnerSwitch = newValue;
        }

        let newRole = 0;

        if (newAdminSwitch === 1) {
            newRole += 2;
            setRoleState(newRole);
        }

        if (newOwnerSwitch === 1) {
            newRole += 4;
            setRoleState(newRole);
        }
        console.log("user.guid",user)

        try {
            // Update the API endpoint and payload as needed
            const response = await api.put(`User/UpdateRole/${ownerGuid}/${user}/${newRole}/${token}`)
            // Handle the response as needed
        } catch (error) {
            console.error(error);
        }

        setAdminSwitch(newAdminSwitch);
        setOwnerSwitch(newOwnerSwitch);
    };
    const handleSwitchToggle = async (membership, user) => {
        const api = axios.create({
            baseURL: BASE_URL, // Make sure you have BASE_URL defined in your code.
        });
        if (membership.isMember) {
            
            const response = await api.delete(`User/RemoveGroupMembership/${ownerGuid}/${user}/${membership.rowKey}/${token}`)
            setMemberships(false)
            
        } else {
            const response = await api.post(`User/AddGroupMembership/${ownerGuid}/${user}/${membership.rowKey}/${token}`)
            setMemberships(true)
        }
        // Update the local state to reflect the new status
        getUserMemberships(selectedUser);
    };

    const [memberships, setMemberships] = useState(null);

    return (
        <div >
            <Row  >
                <Col xs={24} md={5}
                >

                    <AccountMenu initialSelectedPage="Company"  ></AccountMenu>

                </Col>
                <Col xs={24} md={19} >
                   
                            
                          
                            <div >
                                <TableContainer component={Paper} sx={{ width: "80%", margin:"5% 10%", border: "1px solid #e0e0e0", borderRadius: "1%" }}>
                                    <Table  aria-label="a dense table" >
                                        <TableHead style={{
                                            backgroundColor: "#5faa39",
                                            border: "1px solid #e0e0e0",
                                            borderRadius: "1%",
                                            fontWeight: 'bold'
                                        }}>
                    <TableRow>
                                                <TableCell size="small" >Email</TableCell>
                                                <TableCell size="small">Firstname</TableCell>
                                                <TableCell size="small">Lastname</TableCell>
                                                <TableCell size="small" >Role(s)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => (
                        <TableRow key={index}>
                            <TableCell className="rounded-left" onClick={() => handleCellClick(user)} 
                                style={{ cursor: 'pointer' }} size="small">{user.email}</TableCell>
                            <TableCell size="small">{user.firstName}</TableCell>
                            <TableCell size="small">{user.lastName}</TableCell>
                            <TableCell size="small" className="rounded-right" >{Array.isArray(user.roles) ? user.roles.join(', ') : user.roles}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
                                </TableContainer>

                                <Divider />
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
                    variant="contained"
                    type="primary"
                    color="primary"
                    onClick={() => {
                                    }}
                                    disabled
                  
                   
                >
                    {t("Add User")}
          </Button>
                                </div>
                    </div>
                    
                </Col>
            </Row>
        </div>        
        
      

    );
};

export default CompanyUsers;
