import React, { useState, useEffect } from 'react';
import "../../css/StationStatus.css";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Pagination,
    Stack,
    Button, TextField
} from '@mui/material';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

import { BASE_URL } from '../../Settings';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
const StationStatus = () => {
    var token = Cookies.get("token");
    // Create state to manage the collapsed state of each element
    const [isCollapsed, setIsCollapsed] = useState({});
    const [box1Data, setBox1Data] = useState([]);

    console.log("StationStatus", box1Data)
    let ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    useEffect(() => {

        // Fetch data for Box 1
        fetch(`${BASE_URL}/CpoLocation/GetAllChargingStationsWithOcppStatus/${ownerGuid}/${token}`)
            .then(response => response.json())
            .then(data => setBox1Data(data));


    }, []);

    return (
        <Col lg="12" md="12" sm="12">
            <Card className="card-stats">
                <CardBody>

                    <div className="container StationsStatus" >
                        <div className="column">
                            <div className="card-category">{t('Station')}</div>
                            {box1Data.map((item, index) => (
                                <div key={index} className="row">{item.name}</div>
                            ))}
                        </div>
                        <div className="column">
                            <div className="card-category">{t('Charge Points Online')}</div>
                            {box1Data.map((item, index) => (
                                <div key={index} className="row">

                                    {item.available ?
                                        <span >{item.available}</span> :
                                        <span>_</span>
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="column">
                            <div className="card-category">{t('Charging Sessions')}</div>
                            {box1Data.map((item, index) => (
                                <div key={index} className="row">
                                    {item.numChargingSessions !== undefined && item.numChargingSessions >= 0 ? (
                                        `${item.numChargingSessions}`
                                    ) : (
                                        "_"
                                    )}
                                </div>
                            ))}


                        </div>
                    </div>
                </CardBody>
                <CardFooter>

                </CardFooter>
            </Card>
        </Col>


    );
};

export default StationStatus;
