import React, { useState, useEffect, useMemo, useRef ,forwardRef} from 'react';
import { Divider, Col, Row } from 'antd';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { json, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { TextField, Box } from '@mui/material';
import { FormControl, InputLabel, MenuItem, makeStyles, createStyles, Select } from '@material-ui/core';
import axios from 'axios';

import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons'; 
import { Popover, Segmented } from 'antd';
import StateButton2 from './StateButton2';
const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            display: 'block',
        },
        select: {
            minWidth: 200, // Adjust as needed
        },
        greenUnderline: {
            '&:after': {
                borderBottomColor: '#4CAF50', // Green underline color when selected
            },
        },
    })
);
const Meter2 = forwardRef((isMounted, ref) => {

  const { t } = useTranslation();
    const classes = useStyles();

    let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
    const [manufacturer, setManufacturer] = useState('');
  const [activeEichrechtskonform, setActiveEichrechtskonform] = useState(false);
  const [activesichtbar, setActivesichtbar] = useState(false);
  const [activeintern, setActiveintern] = useState(false);
  const [activeZähler, setActiveZähler] = useState(false);
    const [CpMeterdata, setCpMeterData] = useState([]);
    const manufacturers = [t('Calibration Compliant Meter'), t('MID Meter, Visible'), t('MID Meter, Internal'), t('Other/No Meter')];
    const [data, setData] = useState(null)
    

    const handleManufacturerChange = (event) => {
        setManufacturer(event.target.value);
        // Reset model and plug when manufacturer changes
    }
  
  let CreateMeterJson = JSON.parse(localStorage.getItem('CpLocationJson'));
 
  const { state } = useLocation();
  let [valuesInitialState, setValuesInitialState] = useState(state || CreateMeterJson);
  //let valuesInitialState = state || CreateMeterJson;
 // let valuesInitialState;
   
  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;
  } 

  else {
    valuesInitialState = CreateMeterJson;
    console.log("state is null or undefined");
  }


  console.log("intitial Meter Json", CreateMeterJson)
 

  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");

  const MeterMask = ChargeLocationType.Calibrated | ChargeLocationType.VisibleMeter | ChargeLocationType.NoMeter | ChargeLocationType.InternalMeter;

  const ConnectionMask = ChargeLocationType.Manual | ChargeLocationType.OCPP16 | ChargeLocationType.OCPP21;


  //Below logic is for update boxes
    const updateServer = (type, mask) => {
      let wizardStep=16
      console.log("type for meter",type)
        axios.put(`${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${valuesInitialState.guid}/${token}/${type}/${mask}?wizardStep=${wizardStep}`)
      .then(function (updateServer) {
        // handle success
        setValuesInitialState(updateServer.data);
      
        localStorage.removeItem('CpLocationJson');
       

        localStorage.setItem(
          'CpLocationJson',
          JSON.stringify(updateServer.data)
        );
       
        CreateMeterJson = JSON.parse(localStorage.getItem('CpLocationJson'));
          console.log("after OCPP Update", CreateMeterJson)
          updateServerChargeStation(CreateMeterJson);
        
      
      })
      .catch(function (error) {
        // handle error
        localStorage.removeItem('CreateJsoncpguid');
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  var getBitFromMask = function (value, mask) {
    return value & mask;
    }
    
   
   

  useEffect(() => {
    const fetchToggleValue = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
        const data = response.data;
        console.log("response.data", response.data)
        localStorage.removeItem('CpLocationJson');
       

        localStorage.setItem(
          'CpLocationJson',
          JSON.stringify(response.data)
        );
        setValuesInitialState(response.data);
        valuesInitialState = response.data;
        console.log("after OCPP Update", CreateMeterJson)

        setData(data.available); // Assuming the API response has a property 'active' indicating the toggle value
      } catch (error) {
        console.error('Error fetching toggle value:', error);
      }
    };

    fetchToggleValue();
  }, []);
  useEffect(() => {   
 
    var pureValue = getBitFromMask(CreateMeterJson?.type || valuesInitialState.type, 1666);
    updateButtonState(pureValue);

  }, []);    

  var updateButtonState = function (bitValue) {
    setActiveEichrechtskonform(false);
    setActivesichtbar(false);
    setActiveintern(false);
    setActiveZähler(false);
    switch (bitValue) {
      case ChargeLocationType.Calibrated: setActiveEichrechtskonform(true); break;
      case ChargeLocationType.VisibleMeter: setActivesichtbar(true); break;
      case ChargeLocationType.InternalMeter: setActiveintern(true); break;
      case ChargeLocationType.NoMeter: setActiveZähler(true); break;
    }
  }

  // var updateBitValue = function(type){
  //   updateButtonState(type);
  //   updateServer(type,MeterMask);

  // }
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        // Check if any of the button states is not active
        if (!activeEichrechtskonform && !activesichtbar && !activeintern && !activeZähler) {
            isValid = false;
            newErrors.meterType = t('Please select a meter type');
        }

         //Check if power field is not filled
        if (!values.power || valuesInitialState?.power === '') {
            isValid = false;
            newErrors.power = t('Please enter the charging power.');
        }
        if (!(values?.power > 0 && values?.power < 1000)) {
            isValid = false;
            newErrors.power = t('Please enter the charging power.');
        }

        // Check if select field is not filled
        //if (!manufacturer || manufacturer === '') {
        //    isValid = false;
        //    newErrors.manufacturer = 'Please select a manufacturer.';
        //}

        setErrors(newErrors);
        return isValid;
    };
    const [values, setValues] = useState( valuesInitialState );
    const [clickedInput, setClickedInput] = useState(null);
    const handlePowerChange = (input) => (e) => {
        const rawValue = e.target.value || ''; // Set an empty string if the value is undefined
        const cleanedValue = rawValue.replace(",", "."); // Replace commas with dots

        // Convert cleanedValue to a numeric type
        const value = parseFloat(cleanedValue);
        setValues({ ...values, power: cleanedValue });
    }

  const handleClickMeterButton = (input) => (e) => {
    console.log("handle click", input);
      updateButtonState(input);
      setClickedInput(input);
      
    
    
    //updateServer(bitValue, MeterMask);

    };
    const updateServerChargeStation = (updates) => {
        let wizardStep = 0
        const updatedValues = {
            ...updates, // Include all existing values
            power: values.power, // Update power field with new value
        };
        console.log("new", updatedValues)
        fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${guid}/${valuesInitialState.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),
        }).then((response) => {
            if (response.ok) {
                console.log('Updated at:', response);
                localStorage.removeItem('CpLocationJson');
                localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));
                Json = JSON.parse(localStorage.getItem('CpLocationJson'));
                console.log("after update", Json)
            } else {
                console.error('There was an error!');
            }
        });
    };
    const onNext = (callback) => {
        // Add your onNext logic here
        console.log("hello wizard 23 ")
        // Check if all textfield values are provided


        // Submit data

        updateServer(clickedInput, MeterMask);
       
        callback();
    };
    React.useImperativeHandle(ref, () => ({
        onNext: onNext,
        validateForm: validateForm
    }));
 
  return (
    <>


          <div ref={ref} style={{ width: '100%' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            margin: '1% 0',
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
          }}
        >

          <div style={{ padding: '0% 4vw' }}>

            <div style={{ padding: '2%' }}>
              

              <form>
                              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%' }} >
                                  <Col sx={24} md={10}>
                                      <Row gutter={[1, 5]} justify="left" align="middle">
                                     
                                          <StateButton2 onClickCallback={handleClickMeterButton(ChargeLocationType.Calibrated)} isDisabled={activeEichrechtskonform} isActive={activeEichrechtskonform} buttonTextPlaceholder='Calibration Compliant Meter' popupContentPlaceholder='Meter compliant with legal calibration regulations.' />
                                          <StateButton2 onClickCallback={handleClickMeterButton(ChargeLocationType.VisibleMeter)} isDisabled={activesichtbar} isActive={activesichtbar} buttonTextPlaceholder='MID Meter, Visible' popupContentPlaceholder='MID-compliant meter, visible to users.' />
                                          <StateButton2 onClickCallback={handleClickMeterButton(ChargeLocationType.InternalMeter)} isDisabled={activeintern} isActive={activeintern} buttonTextPlaceholder='MID Meter, Internal' popupContentPlaceholder='MID-compliant meter, internally installed in the charging station.' />


                                          <StateButton2
                                              onClickCallback={handleClickMeterButton(ChargeLocationType.NoMeter)}
                                              isDisabled={activeZähler}
                                              isActive={activeZähler}
                                              buttonTextPlaceholder='Other/No Meter'
                                              popupContentPlaceholder='Choose this option if no MID or other meter is present' />


                                          {errors.meterType && <p style={{color:"red"}} className="error">{errors.meterType}</p>}
                                      </Row>
                                      {/*<FormControl className={classes.formControl} variant="standard" >*/}
                                      {/*    <InputLabel id="demo-simple-select-standard-label">   Meter    </InputLabel>*/}
                                      {/*    <Select*/}
                                      {/*        labelId="demo-simple-select-standard-label"*/}
                                      {/*        id="demo-simple-select-standard"*/}
                                      {/*        value={manufacturer}*/}
                                      {/*        onChange={handleClickMeterButton()}*/}
                                      {/*        className={`${classes.select} ${manufacturer && classes.greenUnderline}`}*/}

                                      {/*    >*/}
                                      {/*        <MenuItem value="">*/}
                                      {/*            <em>None</em>*/}
                                      {/*        </MenuItem>*/}
                                      {/*        {manufacturers.map((manufacturer, index) => (*/}
                                      {/*            <MenuItem key={index} value={manufacturer}>{manufacturer}</MenuItem>*/}
                                      {/*        ))}*/}
                                      {/*    </Select>*/}
                                      {/*</FormControl>*/}
                                      <Divider></Divider>
                                      <Row gutter={[1, 5]} justify="left" align="middle">

                                      <TextField
                                          id="Power"
                                          label={t('Leistung (kW)')}
                                          color="success"
                                              value={values?.power || ''}
                                              onChange={handlePowerChange('power')} 
                                             
                                          fullWidth
                                          focused

                                          />
                                          {errors.power && <p style={{ color: "red" }} className="error">{errors.power}</p>}
                                          {/*<Divider></Divider>*/}

                                          {/*<FormControl className={classes.formControl} variant="standard" >*/}
                                          {/*    <InputLabel id="demo-simple-select-standard-label">AC/DC</InputLabel>*/}
                                          {/*    <Select*/}
                                          {/*        labelId="demo-simple-select-standard-label"*/}
                                          {/*        id="demo-simple-select-standard"*/}
                                          {/*        value={manufacturer}*/}
                                          {/*        onChange={handleManufacturerChange}*/}
                                          {/*        className={`${classes.select} ${manufacturer && classes.greenUnderline}`}*/}

                                          {/*    >*/}
                                          {/*        <MenuItem value="">*/}
                                          {/*            <em>None</em>*/}
                                          {/*        </MenuItem>*/}
                                          {/*        {manufacturers.map((manufacturer, index) => (*/}
                                          {/*            <MenuItem key={index} value={manufacturer}>{manufacturer}</MenuItem>*/}
                                          {/*        ))}*/}
                                          {/*    </Select>*/}
                                          {/*</FormControl>*/}
                                      </Row>
                                  </Col>
                                  
                                  
                                  {errors.manufacturer && <p style={{ color: "red" }} className="error">{errors.manufacturer}</p>}

                </div>
              </form>
            </div>
          </div>
        </Box>
      </div>



    </>
  );
});

export default Meter2;
