import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import { BASE_URL } from '../../Settings';
import axios from 'axios';
import ChargeStationDataContext from './ChargeStationDataContext';

const UploadPhotoPatternFly = forwardRef((isMounted, ref) => {
    const token = Cookies.get("token");
    const ownerGuid = Cookies.get("ownerGuid");
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    console.log("chargeStationData in thumbnailurl", chargeStationData);
    let OCPPJson = chargeStationData;
    const [image, setImage] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(chargeStationData.thumbnailUrl);
    const [thumbnailUrlUpdated, setThumbnailUrlUpdated] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (chargeStationData.thumbnailUrl) {
            setThumbnailUrl(chargeStationData.thumbnailUrl);
            console.log("thumbnailurl after update ", chargeStationData.thumbnailUrl);
            setImage(null);  // Clear the selected image
        }
        else {
            setThumbnailUrl(null);  // Set thumbnailUrl to null if no thumbnailUrl exists
        }
       /* setImage(null);*/
    }, [chargeStationData]);

    const onDrop = (acceptedFiles) => {
        const selectedImage = acceptedFiles[0];
        setImage(selectedImage);
        setThumbnailUrl(URL.createObjectURL(selectedImage)); // Update thumbnailUrl when a new image is selected
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const onNext = async () => {
        try {
            if (image) {
                let formData = new FormData();
                formData.append('fileData', image, image.name);
                const response = await axios.put(`${BASE_URL}/CpoLocation/UploadCockpitPhoto/${ownerGuid}/${OCPPJson.guid}/${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    const responseThumbnailUrl = response.data;
                    console.log("data response ", responseThumbnailUrl);
                    const newData = { ...chargeStationData, thumbnailUrl: responseThumbnailUrl };
                    console.log("new data", newData);
                    setThumbnailUrl(responseThumbnailUrl);
                    setChargeStationData(newData);
                    setThumbnailUrlUpdated(true);
                    return response.data;
                } else {
                    console.error("Upload failed");
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div ref={ref}>
            <Row gutter={[10, 25]}>
                <Col xs={24} md={24}>
                    <div>
                        <div {...getRootProps()} style={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
                            <input {...getInputProps()} />
                            <p>{t("Take a picture from the arriving driver's perspective and upload it to make your station easier to find")}</p>
                        </div>
                        {thumbnailUrl && (
                            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                <img src={thumbnailUrl} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            </div>
                        )}
                    </div>
                    {image && (

                        <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                          

                            <Button variant="contained"
                                type="primary"
                                color="primary"
                                style={{ backgroundColor: "#5faa39", color: "white"}}
                                onClick={onNext}>
                                {t("Upload Photo")}
                                </Button>
                       
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
});

export default UploadPhotoPatternFly;
