import React, { useState, useEffect } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { GOOGLE_API_KEY } from '../../Settings';

const MapContainer = ({ google, setLatLng }) => {
    let Json = JSON.parse(localStorage.getItem('CpLocationJson'));

    const { state } = useLocation();
    let valuesInitialState;

    if (state) {
        const { partitionKey: guid, rowKey: clguid } = state;
        valuesInitialState = state;
        console.log(".......", state);

    } else {
        valuesInitialState = Json;
        console.log("state is null or undefined");
    }

    /*const [latLng, setMapLatLung] = useState({ lat: null, lng: null });*/
    const [latLng, setMapLatLung] = useState({
        lat: Json?.lat || valuesInitialState.lat || null,
        lng: Json?.lon || valuesInitialState.lon || null
    });
    console.log(latLng);
    console.log("????????", Json?.lat)
    console.log(Json);


    const [zipCode, setZipCode] = useState(Json?.zipCode || '');
    const [streetAddress, setstreetAddress] = useState(Json?.street || '');

    console.log(zipCode)

    const handleMapClick = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setLatLng({ lat, lng });
        setMapLatLung({ lat, lng });


    };

    const fetchCoordinatesByZipCode = async (zipCode, streetAddress) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${streetAddress}, ${zipCode}&key=${GOOGLE_API_KEY}`);
            console.log(response.data.status)


            if (
                response.data.status === 'OK' &&
                response.data.results.length > 0
            ) {
                const { lat, lng } = response.data.results[0].geometry.location;
                if (!Json.lat && !Json.lon) {
                    setMapLatLung({ lat, lng });
                    setLatLng({ lat, lng })
                }




            } else {
                console.log('No results found.');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
            if (error.response) {
                console.log('Response data:', error.response.data);
                console.log('Response status:', error.response.status);
            } else if (error.request) {
                console.log('Request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        }
    };


    useEffect(() => {
        // Fetch initial coordinates based on initial ZIP code

        fetchCoordinatesByZipCode(zipCode, streetAddress);

    }, [zipCode, streetAddress]);


    return (
        <div>
            {latLng.lat && latLng.lng && (
                <Map
                    google={google}
                    debugger
                    zoom={latLng.lat !== null && latLng.lng !== null ? 20 : 10}
                    initialCenter={{ lat: latLng.lat, lng: latLng.lng }}
                    mapType="satellite"

                    onClick={handleMapClick}
                >

                    <Marker position={{ lat: latLng.lat, lng: latLng.lng }} />

                </Map>)}

            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={latLng.lat || ''}*/}
            {/*    placeholder="Latitude"*/}
            {/*    readOnly*/}
            {/*/>*/}
            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={latLng.lng || ''}*/}
            {/*    placeholder="Longitude"*/}
            {/*    readOnly*/}
            {/*/>*/}

            {/*<input*/}
            {/*    type="text"*/}
            {/*    value={zipCode}*/}
            {/*    placeholder="ZIP Code"*/}
            {/*    onChange={(e) => setZipCode(e.target.value)}*/}
            {/*/>*/}
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
})(MapContainer);
