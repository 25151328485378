import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import WeekDays from "../services/WeekDays";
import { BASE_URL } from "../../Settings";
import AccessProfileGuid from '../services/session';
import BackButton from '../Elements/BackButton';
import '../../css/ChargeLocation.css';
import { Divider, Col, Row } from 'antd';
import AccountMenu from '../Account/AccountMenu';
import Slider from "@mui/material/Slider";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';



const AccessProfileDetailsCockpit = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const ownerGuid = Cookies.get("ownerGuid");
  const token = Cookies.get("token");
  console.log("ownerGuid", ownerGuid);
  let accessProfileGuid = AccessProfileGuid.getaccessProfileGuid();
  console.log("accessProfileGuid", accessProfileGuid);
  const [values, setValues] = useState();
  console.log("values", values);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isAccessProp, setisAccessProp] = useState(false);

  const { pvE, pvS } = values || {};

  // Function to convert seconds to hours
  const secondsToHours = (seconds) => seconds / 3600;

  // Convert pvE and pvS to hours
  const pvEHours = secondsToHours(pvE);
  const pvSHours = secondsToHours(pvS);

  console.log("pvSHours:", pvS);
  console.log("pvEHours:", pvE);
    const handleBackClick = () => {
        navigate(`/AccessProfile`);

    };

  const [weekdays, setWeekdays] = useState({ Mo: false, Di: false, Mi: false, Do: false, Fr: false, Sa: false, So: false, });


  //#region  GetAccessProfile API CALLED and set initial value
  useEffect(() => {
    console.log("Effect is running");
    const api = axios.create({
      baseURL: BASE_URL,
    });

    const fetchData = async () => {
      try {
        const response = await api.get(
          `AccessProfile/GetAccessProfile/${ownerGuid}/${accessProfileGuid}/${token}`
        );
        console.log("response AccessProfile", response.data);
        setValues(response.data);

        if (response.data && response.data.weekdays) {
          const receivedBitmaskValue = response.data.weekdays;
          const newWeekdays = {};
          Object.keys(WeekDays).forEach((day) => {
            newWeekdays[day.toLowerCase()] = (receivedBitmaskValue & WeekDays[day]) !== 0;
          });
          console.log("newWeekdays", newWeekdays);
          console.log("receivedBitmaskValue", receivedBitmaskValue);


          // Set weekdays state
          setWeekdays(newWeekdays);

          // Set bitmaskValue state
          // setBitmaskValue(receivedBitmaskValue);

        } else {
          console.log("No access profiles found");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [ownerGuid, token, accessProfileGuid]);

  //#endregion

  //#region hours and weekdays binding data logis
  const hours = []

  const hoursOfDay = () => {
    for (var i = 0; i < 24; i++) {
      hours[i] = 1 << i;
    }
    console.log("hours", hours)
    return hours;
  }
  hoursOfDay();
  const hasHour = (idx, hour) => {
    return (hour & idx) > 0;
  }



  const DWeekdays = {};
  const weekdayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];

  const WeeksOfDay = () => {
    for (var J = 0; J < 7; J++) {
      DWeekdays[weekdayNames[J]] = 1 << J;
    }
    console.log("WeeksOfDay", DWeekdays);
    return DWeekdays;
  };
  WeeksOfDay();

  const hasWeekDays = (idx, hour) => {
    return (hour & idx) > 0;
  };
  //#endregion

  //#region  handle checkbox events for hours and handle slider change events also handle textbox and weedays change events

  const handleWeekdayChange = (day) => {
    console.log("handleWeekdayChange", day);
    setWeekdays((prevWeekdays) => ({
      ...prevWeekdays,
      [day]: !prevWeekdays[day],
    }));

    setValues((prevValues) => ({
      ...prevValues,
      weekday: prevValues.weekday ^ day, // XOR to toggle the bit
    }));

  };
  const handleTextboxChange = (event) => {
    // Do something with the changed value, for example:
    const newValue = event.target.value;
    setValues((prevValues) => ({
      ...prevValues,
      name: newValue, // XOR to toggle the bit
    }));

  };


  const handleCheckboxChange = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      hours: prevValues.hours ^ value, // XOR to toggle the bit
    }));
  };
  const handleSliderChange = (event, newValue) => {
    console.log("Slider value changed:", newValue);
    const hoursToSeconds = (hours) => hours * 3600;
    // Convert hours to seconds
    const pvSSeconds = hoursToSeconds(newValue[0]);
    const pvESeconds = hoursToSeconds(newValue[1]);

    console.log("pvESeconds", pvESeconds);
    setValues((prevValues) => ({
      ...prevValues,
      pvS: pvSSeconds,
      pvE: pvESeconds
    }));
    console.log("handleCheckboxChange2", values);
  };
  //#endregion

  //#region  Handle publish button event and Called UPDATE api.
  const handleButtonClick = () => {
    console.log("Latest Json to update in database ", values)
    updateAccessProfile();
  };

  const updateAccessProfile = async () => {
    try {
      console.log("accessProfileGuid",accessProfileGuid)
      // const response = await axios.put(`${BASE_URL}/AccessProfile/UpdateAccessProfile/${ownerGuid}/${accessProfileGuid}/${token}`, values);
      const response = await axios.put(`${BASE_URL}/AccessProfile/UpdateAccessProfile/${ownerGuid}/${token}`, values);

      console.log("Access profile updated successfully", response.data);
      setSuccessMessage("Access profile updated successfully");
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000); // Adjust the delay as needed
    } catch (error) {
      console.error("Error updating access profile", error);
    }
  };

  //#endregion
  const timeSlotCss = (idx, hour) => {
    console.log("{0}, {1}, {2}", idx, hour, (idx & hour));
    if (hasHour(idx, hour)) {
      return "timeslot selected";
    }
    return "timeslot";
  }

  return (
    <div style={{ width: '100%' }}>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Row>
        
              <Col xs={24} md={19} >
                  {/*<div style={{*/}
                  {/*    display: 'flex',*/}
                  {/*    alignItems: 'center',*/}
                  {/*    justifyContent: 'center',*/}
                  {/*    marginRight: '10px'*/}
                  {/*}}>*/}
                  {/*    <Button startIcon={<ArrowBackIosIcon />} onClick={handleBackClick}>*/}
                  {/*        Back*/}
                  {/*    </Button>*/}
                  {/*</div>*/}
                 
          <div style={{
            width: "100%",
            border: "none",
            margin: '3% 0'

          }} >
            <Box
              component="div"
              sx={{
                margin: '1% 2%',
              }}
                      > 
              <TextField
                id="Accessprofiles"
                label={t("Accessprofiles")}
                color="success"
                focused
                value={values?.name || ''}
                onChange={handleTextboxChange}
              />
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={weekdayNames.length + 1}>

                        <h2>{t("Weekdays")}</h2>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* {Object.keys(weekdays).map((day) => (
                        <TableCell key={day}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={weekdays[day]}
                                onChange={() => handleWeekdayChange(day)}
                              />
                            }
                            label={t(day)}
                          />
                        </TableCell>
                      ))} */}
                      {weekdayNames.map((day) => (
                        <TableCell key={DWeekdays[day]}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hasWeekDays(DWeekdays[day], values?.weekday)}
                                onChange={() => handleWeekdayChange(DWeekdays[day])}
                              />
                            }
                            label={day}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead sx={{
                    backgroundColor: "#5faa39",
                    border: "1px solid #e0e0e0",
                    borderRadius: "1%",
                  }}>
                    <TableRow>
                      <TableCell colSpan={hours.length + 1} sx={{
                        backgroundColor: "white !important",
                        border: "1px solid #e0e0e0",
                        borderRadius: "1%",
                      }}>
                        <h2>{t("Opening hours")}</h2>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan="6" class="timeslotHeader">0</TableCell>
                      <TableCell colSpan="6" class="timeslotHeader">6</TableCell>
                      <TableCell colSpan="6" class="timeslotHeader">12</TableCell>
                      <TableCell colSpan="6" class="timeslotHeader">18</TableCell>
                      <TableCell class="timeSlotHeaderEnd">24</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* {hours.map((value, index) => (
                        <TableCell key={value}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={hasHour(value, values?.hours)}
                                onChange={() => handleCheckboxChange(value)}
                              />
                            }
                            label={index}

                          />
                         </TableCell>
                      ))}
                    </TableRow>   */}

                      {hours.map((value, index) => (
                        <TableCell class={timeSlotCss(value, values?.hours)} align="left">
                          <input type="checkbox" style={{ color: "#5faa39" }} onChange={() => handleCheckboxChange(value)} checked={hasHour(value, values?.hours)} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={hours.length + 1}>
                        <h2>{t("Select Eco Tarrif")}</h2>
                        <Slider
                          value={[pvSHours, pvEHours]}
                          onChange={handleSliderChange}
                          min={0}
                          max={24}
                          valueLabelDisplay="auto"
                          sx={{ color: '#5faa39' }} // Set the color to green

                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={hours.length + 1}>
                        <Button onClick={handleButtonClick} variant="contained" type="primary" style={{ background: "#5faa39" }}>{t("Publish")}</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default AccessProfileDetailsCockpit;