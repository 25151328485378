import React, { useState, useEffect, useContext } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GOOGLE_API_KEY } from '../../Settings';
import ChargeStationDataContext from './ChargeStationDataContext';
const ChargeStationMapContainer = ({ updateServer,google, setLatLng, zipcode, streetaddress, streetnumber }) => {
    /*const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);*/
    /*let Json = JSON.parse(localStorage.getItem('CpLocationJson'));*/
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    const { t } = useTranslation();
    console.log("zipcode in mapcontainer", zipcode);

    console.log("address in mapcontainer", streetaddress);

    /*const [latLng, setMapLatLung] = useState({ lat: null, lng: null });*/
    const [latLng, setMapLatLung] = useState({
        lat: chargeStationData.lat  ,
        lng: chargeStationData.lon });
    const [mapCenter, setMapCenter] = useState({
        lat: null,
        lng: null });


    const [zipCode, setZipCode] = useState(zipcode);
    const [streetAddress, setStreetAddress] = useState(streetaddress);

    console.log(" mapcontainer", latLng, mapCenter, chargeStationData?.lat, chargeStationData?.lon);


    const handleMapClick = (mapProps, map, clickEvent) => {
        const { latLng } = clickEvent;
        const lat = latLng.lat();
        const lng = latLng.lng();

        setLatLng({ lat, lng });
        setMapLatLung({ lat, lng });
        setMapCenter({ lat, lng });
        const updatedChargeStationData = {
            ...chargeStationData,
            lat: lat,
            lon: lng
        };
        setChargeStationData(updatedChargeStationData);
        updateServer({ lat, lng });
        console.log("lat and lon when click",lat, lng)
      

    };

    const fetchCoordinatesByZipCode = async (zipCode, streetAddress, streetNumber) => {
        try {
            console.log("zipcode in mapcontainer in fetch ", zipCode);
            const address = `${streetAddress} ${streetNumber}`;
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${address},${zipCode}&key=${GOOGLE_API_KEY}`);
            console.log("test for address ", response.data.status)


            if (
                response.data.status === 'OK' &&
                response.data.results.length > 0
            ) {
                const { lat, lng } = response.data.results[0].geometry.location;

                console.log("test for address for lat", { lat, lng })
                if (chargeStationData.lat==0 && chargeStationData.lon==0) {
                    console.log ("mapcontainer hello")
                    setMapLatLung({ lat, lng });

                    setLatLng({ lat, lng });
                    const updatedChargeStationData = {
                        ...chargeStationData,
                        lat: lat,
                        lon: lng
                    };
                    setChargeStationData(updatedChargeStationData);
                   
                    updateServer({ lat, lng });
                }



            } else {

                console.log('No results found.');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
            if (error.response) {
                console.log('Response data:', error.response.data);
                console.log('Response status:', error.response.status);
            } else if (error.request) {
                console.log('Request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        }
    };


    useEffect(() => {
        // Fetch initial coordinates based on initial ZIP code
        console.log("update and fetch")

        fetchCoordinatesByZipCode(zipcode, streetaddress, streetnumber);
      

    }, [zipcode, streetaddress, streetnumber]);






    return (
        <div>
        

          

            {latLng.lat && latLng.lng ? (
                <>
                  
                    <Map
                        google={google}
                        debugger
                        zoom={latLng.lat !== null && latLng.lng !== null ? 20 : 10}
                        initialCenter={{ lat: latLng.lat, lng: latLng.lng }}
                        mapType="satellite"
                        onClick={handleMapClick}
                    >
                        <Marker position={{ lat: latLng.lat, lng: latLng.lng }} />
                    </Map>
                </>
            ): 
            (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                        {t("Please enter the complete address.")}
                </div>
            )}

          
        </div>

    );
};

export default GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
})(ChargeStationMapContainer);
