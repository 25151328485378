import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, Checkbox } from 'antd';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import { BASE_URL } from '../../Settings';
import axios from 'axios';

const UploadPhotoPatternFly = forwardRef((isMounted, ref) => {
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    let OCPPJson = JSON.parse(localStorage.getItem('CpLocationJson'));
    const [image, setImage] = useState(null);
    const { t } = useTranslation();
    const onDrop = (acceptedFiles) => {
        const selectedImage = acceptedFiles[0];
        setImage(selectedImage);

        // If you need to perform an action immediately on image selection, you can call a function here.
     

    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const onNext = async () => {
        try {
            if (image) {
                console.log("guid", OCPPJson.guid)

                let formData = new FormData();
                formData.append('fileData', image, image.name); // Append a File object with the correct name
                const response = await axios.put(`${BASE_URL}/CpoLocation/UploadLocationPhoto/${ownerGuid}/${OCPPJson.guid}/${token}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Set the correct content type
                    },
                });
                console.log("uploadphoto", response.data);


                if (response.ok) {
                    const updatedValues = {
                        ...OCPPJson, // Include all existing values
                        thumbnailUrl: response.data.thumbnailUrl,
                        // Update thumbnailUrl field with new value
                    };
                    localStorage.removeItem('CpLocationJson');
                    localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));
                    return response.data;
                } else {
                    console.error("Upload failed");
                    // Handle the failure scenario here
                }
            } else {

                fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${ownerGuid}/${OCPPJson.guid}/${token}?wizardStep=${512}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(OCPPJson),
                }).then((response) => {
                    if (response.ok) {

                    } else {
                        console.error('There was an error!');
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
   
    React.useImperativeHandle(ref, () => ({
        onNext: onNext,
       
    }));
    console.log("image", image);
	return (
        <div ref={ref}>
            <Row gutter={[10, 25]}>
                <Col xs={24} md={24}>

                    <div>
                        <div {...getRootProps()} style={{ border: '2px dashed #aaa', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
                            <input {...getInputProps()} />
                            <p>{t("Take a picture from the arriving driver's perspective and upload it to make your station easier to find")}</p>
                        </div>
                        {image && (
                            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                <img src={URL.createObjectURL(image)} alt="Selected" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            </div>
                        )}
                        
                    </div>
                </Col>
            </Row>
        </div>
                       
	
);


});
export default UploadPhotoPatternFly;