import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import deTranslation from './translations/de.json';

const resources = {
  en: {
    translation: enTranslation
  },
  de: {
    translation: deTranslation
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Set the default language here
  fallbackLng: 'en', // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false // React already escapes variables
  }
});

export default i18n;
