// import React, { useState, useEffect } from 'react';
// import "../../css/StationStatus.css";
// import {
//     Card,
//     CardHeader,
//     CardBody,
//     CardFooter,
//     CardTitle,
//     Row,
//     Col,
// } from "reactstrap";
// import revenue from "./Payout.png";
// import { BASE_URL } from '../../Settings';
// import { useTranslation } from 'react-i18next';
// import Cookies from 'js-cookie';
// import Revenues from './Revenues'; // Import the Revenues component

// const PayoutAccount = () => {
//     var token = Cookies.get("token");
//     const [box1Data, setBox1Data] = useState(null); // Updated initial state to null
//     var guid = Cookies.get("ownerGuid");
//     const { t } = useTranslation();
//     useEffect(() => {
//         let ownerGuid = Cookies.get("ownerGuid");
//         fetch(`${BASE_URL}/Customer/GetOwner/${ownerGuid}/${token}`)
//             .then(response => response.json())
//             .then(data => setBox1Data(data.stripeId));
//     }, []);

//     if (box1Data) {
//         return <Revenues />; // Render Revenues component if box1Data is set
//     }

//     return (
//         <Col lg="12" md="12" sm="12">
//             <Card className="card-stats">
//                 <CardBody>
//                     <Row className="d-flex align-items-center">
//                         <Col md="4" xs="5">
//                             <div className="icon-big text-center icon-warning">
//                                 <img style={{ width: '85px' }} src={revenue} alt="revenue" />
//                             </div>
//                         </Col>
//                         <Col md="8" xs="7">
//                             <div className="numbers">
//                                 <p className="card-category">{t('Payout Account')}</p>
//                                 <CardTitle tag="p" style={{ color: !box1Data ? 'red' : 'black' }}>
//                                     {!box1Data ? t('not set') : t('set')}
//                                 </CardTitle>
//                                 <p />
//                             </div>
//                         </Col>
//                     </Row>
//                 </CardBody>
//                 <CardFooter>
//                 </CardFooter>
//             </Card>
//         </Col>
//     );
// };

// export default PayoutAccount;


import React, { useState, useEffect } from 'react';
import "../../css/StationStatus.css";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";
import revenue from "./Payout.png";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import Revenues from './Revenues'; // Import the Revenues component

const PayoutAccount = () => {
    const token = Cookies.get("token");
    const [box1Data, setBox1Data] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const ownerGuid = Cookies.get("ownerGuid");
        fetch(`${BASE_URL}/Customer/GetOwner/${ownerGuid}/${token}`)
            .then(response => response.json())
            .then(data => {
                setBox1Data(data.stripeId);
                setLoading(false);  // Move setLoading(false) inside the .then block
            })
            .catch(() => setLoading(false));  // In case of error, stop loading as well
    }, [token]);

    if (loading) {
        return (
            <tr>
                <td>Loading...</td>
            </tr>
        );
    }

    if (box1Data) {
        return <Revenues />; // Render Revenues component if box1Data is set
    }

    return (
        <Col lg="12" md="12" sm="12">
            <Card className="card-stats">
                <CardBody>
                    <Row className="d-flex align-items-center">
                        <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                                <img style={{ width: '85px' }} src={revenue} alt="revenue" />
                            </div>
                        </Col>
                        <Col md="8" xs="7">
                            <div className="numbers">
                                <p className="card-category">{t('Payout Account')}</p>
                                <CardTitle tag="p" style={{ color: 'red' }}>
                                    {t('not set')}
                                </CardTitle>
                                <p />
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </Col>
    );
};

export default PayoutAccount;
