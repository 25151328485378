import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { BASE_URL } from '../../Settings';
import '../../../src/css/Flex.css';
import { useTranslation } from 'react-i18next';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const FlexPriceDisplay = ({ values }) => {


  console.log("CpLocations Json", values)

  const [token, setToken] = useState('');
  var OwnerGuid = Cookies.get("ownerGuid");
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedProviderName, setSelectedProviderName] = useState('');
  const [importPowerPrices, setImportPowerPrices] = useState([]);
  const [chartColor, setChartColor] = useState('rgba(95,170,57,1)');
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  //Below code for select Pricing dropdown

  const [pricingData, setPricingData] = useState([]);
  const [selectedPricingData, setSelectedPricingData] = useState('');
  const [ExportPrices, setExportPrices] = useState([]);
  const [selectedPricingDataLabel, setSelectedPricingDataLabel] = useState('');

  console.log("pricingData", pricingData)

  useEffect(() => {
    const fetchToken = Cookies.get("token");
    setToken(fetchToken);
  }, []);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/FlexPrice/GetProviderSourcesByOwnerGuid/${OwnerGuid}/${token}`);       

        // const response = await axios.get(`${BASE_URL}/FlexPrice/GetAllProviderSources/${token}`);
        console.log("FlexCLresponse", response.data)
        setProviders(response.data);
      } catch (error) {
        console.error("Error fetching providers:", error);
      }
    };
    const fetchPricing = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuid/${OwnerGuid}/${token}`);
        // const response = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuidAndPriceCalcGuid/${OwnerGuid}/${values.priceCalcGuid}/${token}`);
        console.log("GetPriceCalcByOwnerGuid", response.data)
        setPricingData(response.data);

        // Preselect the desired option based on priceCalcGuid
        const preselectedData = response.data.find(pricing => pricing.guid === values.priceCalcGuid);

        console.log("preselectedData",preselectedData)
        if (preselectedData) {
          setSelectedPricingData(preselectedData);
          handleFetchImportAndExportData(preselectedData);

          // handleFetchExportData({ target: { value: JSON.stringify(selectedPricingData) } });
        }
     
      } catch (error) {
        console.error("Error fetching providers:", error);
      }
    };
    if (token) {
      fetchProviders();
      fetchPricing();
    }
  }, [token]);
  const handleFetchImportAndExportData = async (preselectedData) => {
    await handleFetchImportData();
    await handleFetchExportData({ target: { value: JSON.stringify(preselectedData) } });
  };
  // below code is for select provider 
  const handleFetchImportData = async (providerGuid) => {
    console.log("providerSourceGuid",providerGuid)
   
    const selectedProvider = providerGuid;
    setSelectedProvider(selectedProvider);
    const provider = providers.find(provider => provider.guid === selectedProvider);
    setSelectedProviderName(provider ? provider.rowKey : '');
    console.log("selectedProvider",selectedProvider)
    if (selectedProvider === "") {
      setImportPowerPrices([]);
      return;
    }  

    try {
      const response = await axios.post(`${BASE_URL}/FlexPrice/CreateorUpdateImportPowerPrices/${token}/${providerGuid}`);

      // const response = await axios.post(`${BASE_URL}/FlexPrice/CreateorUpdateImportPowerPrices/${token}/${selectedProvider}`);
      console.log("CreateorUpdateImportPowerPrices", response.data);

      setImportPowerPrices(response.data.map(price => ({
        label: `${new Date(price.startTime).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`,
        value: price.price
      })));

      // const colors = ["rgba(95, 170, 57, 8)", "rgba(0,43,73,1)", "rgba(95, 170, 57, 0.3)"];
      // setChartColor(colors[Math.floor(Math.random() * colors.length)]);

    } catch (error) {
      console.error("Error fetching import power prices:", error);
    }
  };

  const BarGraphdata = {
    labels: importPowerPrices.map(price => price.label), // Use the import power prices labels
    datasets: [
      {
        label: 'Preis Import', // Label for import prices
        data: importPowerPrices.map(price => price.value), // Import prices data
        backgroundColor: 'rgba(57,95,170,0.8)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,

      },
      {
        label: 'Kunden Endpreis', // Label for export prices
        data: ExportPrices.map(price => price.value), // Export prices data
        backgroundColor: 'rgba(95,170,57,1)',  // You can choose a different color for export prices
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 1,

      }
    ]
  };
  // End code for provider logics.

  // below code is for configure pricing

  const handleFetchExportData = async (event) => {

const parsedValue = JSON.parse(event.target.value);
  const providerSourceGuid = parsedValue.providerGuid;
  console.log("providerSourceGuid", providerSourceGuid);

    handleFetchImportData(providerSourceGuid);
    // handleFetchImportData("d499dc04-bdcf-8ada-0235-c06b2a1bc8c9");
    const selectedPricingJson = JSON.parse(event.target.value);// Get the JSON string of the selected pricing
    setSelectedPricingData(selectedPricingJson); // Set the selected JSON string as the state
    const selectedPricingJsonforGainAndBaseFee = selectedPricingJson; // Parse the JSON string to an object
    setSelectedPricingDataLabel(selectedPricingJson);
    if (selectedPricingJson === "") {
      setExportPrices([]);
      return;
    }
    const selectedPricing = selectedPricingJson; // Parse the JSON string to get the object
    const { guid, providerGuid } = selectedPricing;

    try {
      const UpdateCpLocationPriceCalcGuid = await axios.put(`${BASE_URL}/FlexPrice/UpdateCpLocationPriceCalcGuid/${OwnerGuid}/${values.guid}/${selectedPricingJsonforGainAndBaseFee.guid}/${token}`);
      const response = await axios.post(`${BASE_URL}/FlexPrice/CreateAndUpdateExportEndPrice/${OwnerGuid}/${guid}/${providerGuid}/${token}`);
      console.log("API response:", response.data);
      // Update export prices state with the new data
      const exportPrices = response.data.map(price => ({
        label: `${new Date(price.startTime).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' })}`,
        value: price.marketPrice
      }));
      console.log("Export Prices:", exportPrices);

      setExportPrices(exportPrices);

      // Optionally close the popup or show a success message
    } catch (error) {
      console.error("Error updating or creating price calculation:", error);
      // Handle error, show error message, etc.
    }
  };


  // const handleSubmit = async () => {
  //   const name = document.getElementById('name').value;
  //   const gain = document.getElementById('gain').value;
  //   const baseFee = document.getElementById('baseFee').value;

  //   const priceCalc = {
  //     Name: name,
  //     Gain: parseFloat(gain),
  //     BaseFee: parseFloat(baseFee),
  //     ProviderGuid: selectedProvider,
  //     OwnerGuid: OwnerGuid,
  //     CalcType: 0, // Set the calculation type as needed
  //   };
  //   console.log("priceCalc", priceCalc)
  //   try {
  //     const response = await axios.post(`${BASE_URL}/FlexPrice/UpdateOrCreatePriceCalc/${token}`, priceCalc);
  //     setShowPopup(false);


  //     console.log("API response:", response.data);


  //     // Fetch updated pricing data after successfully adding the new price calculation
  //     const updatedPricingResponse = await axios.get(`${BASE_URL}/FlexPrice/GetPriceCalcByOwnerGuid/${OwnerGuid}/${token}`);
  //     console.log("GetPriceCalcByOwnerGuid", updatedPricingResponse.data);
  //     setPricingData(updatedPricingResponse.data);
  //     setSelectedPricingData('');
  //     setExportPrices([]);
  //   } catch (error) {
  //     console.error("Error updating or creating price calculation:", error);
  //   }
  // };
	const hasZeroExportPrice = ExportPrices.some(price => price.value === 0);

  return (
    <div style={{ padding: '20px' }}>
          < h1 style={{ fontSize: '1.5rem', marginBottom: '5px', lineHeight: 1, textAlign: 'center' }}>{t("Select Flex Price scheme")}</h1>

      <div style={{ marginBottom: '20px' }}>
        
        <select
          onChange={handleFetchExportData}
          value={selectedPricingData ? JSON.stringify(selectedPricingData) : ''}
          style={{ width: '100%' }}
            className="flex-dropdown"
        >
          <option value="">{t("Pricing Scheme")}</option>
          {pricingData.map(pricing => (
            <option key={pricing.guid} value={JSON.stringify(pricing)}>{pricing.name}</option>
          ))}
        </select>
      </div>


      {(importPowerPrices.length > 0 || ExportPrices.length > 0) &&  (
        <div style={{ maxWidth: '90%', margin: '0 auto', marginBottom: '20px' }}>
                  <h1 style={{ fontSize: '1rem', marginBottom: 0, lineHeight: 1 }}>Quellen- und Kundenendpreise für <strong>{selectedProviderName}</strong> and selected <strong>{selectedPricingDataLabel.name}</strong> and Marge : <strong>{selectedPricingDataLabel.gain}%</strong>, Basis : <strong>{selectedPricingDataLabel.baseFee}</strong></h1>
                  {hasZeroExportPrice && <h1 style={{ fontSize: '1rem', marginBottom: 0, lineHeight: 1 }}>{t("NegativeFlexPrice")}</h1>}

          <div style={{ width: '100%', height: '400px', position: 'relative' }}> {/* Adjust height as needed */}
            <Bar
              data={BarGraphdata}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                title: {
                  display: true,
                  text: 'Import and Export Power Prices',
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: 'right',
                },

              }}
            />
          </div>
        </div>
      )}

    </div>
  );
};
export default FlexPriceDisplay;
