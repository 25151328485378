import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Divider,
  Col,
  Row,
} from 'antd';
import { TextField, Box } from '@mui/material';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import Meter from "./Meter";
import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Segmented } from 'antd';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import StateButton from './StateButton';
function OCPP({ onOcpp, onManual, activeOCPP, activeOffline, showOnlineDiv })  {

 

  const [data, setData] = useState(null); // Change initial value to null

  console.log("Data", data)

    // below code is for getting new cpguid
    let OCPPJson = JSON.parse(localStorage.getItem('CpLocationJson'));
    console.log("OCPPJson", OCPPJson)
    const OCPPMask = ChargeLocationType.OCPP16 | ChargeLocationType.Manual;
    var getBitFromMask = function (value, mask) {
        console.log("occp value,mask", value, mask)
        return value & mask;

    }
    var updateButtonState = function (bitValue) {


        // Set onOcpp and onManual based on bitValue
        const shouldSetOcpp = bitValue === ChargeLocationType.OCPP16;
        console.log("shouldSetOcpp", shouldSetOcpp)

        const shouldSetManual = bitValue === ChargeLocationType.Manual;
        onOcpp(shouldSetOcpp);
        onManual(shouldSetManual);

        console.log("shouldSetManual", shouldSetManual)
        console.log("activeocpp222", activeOCPP)







    };
    var mainButtonValue = getBitFromMask(OCPPJson?.type, OCPPMask);// type ? 129 => pureVluae ? 128
    updateButtonState(mainButtonValue)
    console.log("mainButtonValue", mainButtonValue)
    const { state } = useLocation();
    let valuesInitialState = state || OCPPJson;

    if (state) {
        const { partitionKey: guid, rowKey: clguid } = state;
        valuesInitialState = state;
    } else {
        valuesInitialState = OCPPJson;
        console.log("state is null or undefined");
    }
    const [values, setValues] = useState(valuesInitialState);

    const [cookies, setCookie] = useCookies();
    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");
    const { t } = useTranslation();

  // below logic is update OCPP and offline
 

    useEffect(() => {
        const fetchToggleValue = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
                const data = response.data;

                localStorage.removeItem('CpLocationJson');


                localStorage.setItem(
                    'CpLocationJson',
                    JSON.stringify(response.data)
                );
                setValues(response.data);
                valuesInitialState = response.data;



            } catch (error) {
                console.error('Error fetching toggle value:', error);
            }
        };

        fetchToggleValue();
        console.log("OCPPJson", OCPPJson)
        setValues(OCPPJson);
        console.log("OCPPJson.type", OCPPJson.type)
        console.log("ChargeLocationType.Manual", ChargeLocationType.Manual)
        console.log("ChargeLocationType.OCPP16", ChargeLocationType.OCPP16)
       
        let rowKey = valuesInitialState?.guid || OCPPJson.guid
        axios.get(`${BASE_URL}/CpoLocation/OccpSettings/${guid}/${rowKey}/${token}`)
            .then(function (response) {
                // handle success

                console.log("useEffect", response.data)
                setData(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });


    }, []);
  //Below logic is for update boxes
    const updateServer = (type, mask) => {
       
        let rguid = valuesInitialState?.guid || OCPPJson.guid || values.guid
    axios.put(`${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${rguid}/${token}/${type}/${mask}`)
      .then(function (updateServer) {
        // handle success
        localStorage.removeItem('CpLocationJson');
        setValues(updateServer.data);

        localStorage.setItem(
          'CpLocationJson',
          JSON.stringify(updateServer.data)
        );

       // OCPPJson = JSON.parse(localStorage.getItem('CpLocationJson'));
       // console.log("after OCPP Update", OCPPJson)
       ///* valuesInitialState = updateServer.data;*/


      })
      .catch(function (error) {
        // handle error
        localStorage.removeItem('CreateJsoncpguid');
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
    };
   

 
  

    
    //var updateButtonState = function (bitValue) {
    //    setShowOnlineDiv(false);
    //    setActiveOCPP(false);
    //    setActiveOffline(false);
       
    //    if (bitValue === ChargeLocationType.OCPP16) {
    //        setActiveOCPP(true);
    //        setShowOnlineDiv(true);
    //        onOcpp(true);
            
    //      ;
    //    } else if (bitValue === ChargeLocationType.Manual) {
    //        setActiveOffline(true);
    //       onManual(true);
    //    }
    //};
    const handleClickOCPPButton = (input) => (e) => {
        updateServer(input, OCPPMask);
        updateButtonState(input);
       
       
  
        

    };
    const handleClickManualButton = (input) => (e) => {
       
        updateServer(input, OCPPMask);
        updateButtonState(input);
       
        




    };
    const [OcppDataStatus, setOcppData] = useState(null);

    useEffect(() => {
        const fetchOcppData = async () => {
            let rowKey = valuesInitialState?.guid || OCPPJson.guid
            try {
                const response = await axios.get(`${BASE_URL}/CpoLocation/OccpStatus/${guid}/${rowKey}/${token}`);
                setOcppData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Call fetchData initially
        fetchOcppData();

        // Call fetchData every 5 seconds
        const intervalId = setInterval(fetchOcppData, 5000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

 

  const handleCopyToClipboard = (value, title) => {
    console.log("copy", title)
    navigator.clipboard.writeText(value)

      .then(() => {
        console.log('Text copied to clipboard:', value);
        alert(`Text copied to clipboard: ${title}`)

      })
      .catch((error) => {
        alert('Failed to copy text to clipboard:', error)
        console.error('Failed to copy text to clipboard:', error);
      });
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            margin: '1% 0',
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
          }}
        >
          <div style={{ padding: '0% 4vw' }}>

            <div style={{ padding: '2%' }}>
              <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
                {t('OCPP Setting')}
              </h1>

                          <form>
                              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%' }} >
                              <Row>
                                 
                                          <Row gutter={[24, 24]} justify="left" align="middle">
                                         

                                          <StateButton onClickCallback={handleClickOCPPButton(ChargeLocationType.OCPP16)} isDisabled={activeOCPP} isActive={activeOCPP} buttonTextPlaceholder=' OCPP (1.6 JSON)' popupContentPlaceholder='Select this option if the station supports the Open Charge Point Protocol in version 1.6 JSON.'  />
                                             
                                          <StateButton onClickCallback={handleClickManualButton(ChargeLocationType.Manual)} isDisabled={activeOffline} isActive={activeOffline} buttonTextPlaceholder='Other or offline' popupContentPlaceholder='Choose this option if the station uses no or another connection.' />
                                          {console.log("activeOCCP1", activeOCPP)}
                                         
                                      </Row>
                                 
                                   {showOnlineDiv && data && (
                                        <>
                                          
                                              <Col xs={24} md={24}  >  
                                                 
                                                  
                                              <Divider />
                      
                                              <div id="Online">
                                               <h3>{t('OCPPInfo')}</h3>
                                                 <Row gutter={[15, 25]} style={{ marginBottom: '5px' }} > 
                                                    <Col xs={24} md={3}>
                                                    <label htmlFor="ocppUrl">{t('Server URL:')}</label>
                                                    </Col> 
                                                    <Col xs={24} md={{ span: 9 }}>
                                                       <Input
                                                                  id="ocppUrl"
                                                                  value={data?.ocppUrl}
                                
                                                                  readOnly
                                                                  style={{ backgroundColor: '#cccccc', cursor: 'pointer', opacity: 0.7 }}

                                                               />
                                                    </Col>
                                                          <Button style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={() => handleCopyToClipboard(data?.ocppUrl, 'Server URL')}><ContentCopyRoundedIcon style={{ fontSize: "small" }} /></Button>
                                                 </Row>
                                                      <Row gutter={[15, 25]} style={{ marginBottom: '5px' }} > 
                                                          <Col xs={24} md={3}>
                                                              <label htmlFor="ocppUrl">{t('Server Domain')}:</label>
                                                          </Col> 
                                                          <Col xs={24} md={9}>
                                                              <Input
                                                                  id="ocppUrl"
                                                                  value={data?.ocppUrl?.substring(6, data?.ocppUrl.length - 6) || ''}
                                                                  
                                                                  readOnly
                                                                  style={{ backgroundColor: '#cccccc', cursor: 'pointer', opacity: 0.7 }}

                                                              />
                                                          </Col> 
                                                          <Button style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={() => handleCopyToClipboard(data?.ocppUrl?.substring(6, data?.ocppUrl.length - 6) || '', 'Server Domain')}><ContentCopyRoundedIcon style={{ fontSize: "small" }} /></Button>
                                                      
                                                         
                                                          <Col xs={24} md={{span:2,offset:1}}>
                                                              <label htmlFor="ocppUrl">Pfad:</label>
                                                          </Col> 
                                                          <Col xs={24} md={4}>
                                                              <Input
                                                                  id="ocppUrl"
                                                                  value={"OCPP" || ''}
                                                                  
                                                                  readOnly
                                                                  style={{ backgroundColor: '#cccccc', cursor: 'pointer', opacity: 0.7 }}

                                                              />
                                                                  </Col>
                                                          <Button style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={() => handleCopyToClipboard("OCPP" || '', 'Pfad')}><ContentCopyRoundedIcon style={{ fontSize: "small" }} /></Button>

                                                          </Row>
                           
                                                      <Row gutter={[10, 25]} style={{ marginBottom: '5px' }}>
                    
                                                              <Col xs={24} md={3}>
                              
                                                                  <label htmlFor="chargePointId">{t('ChargePoint-ID')}</label>
                                                                  </Col> 
                                                                  <Col xs={24} md={9}>
                                                                  <Input
                                                                    id="chargePointId"
                                                                    value={data?.chargePointId}
                                                                    readOnly
                                                                    style={{ backgroundColor: '#cccccc', cursor: 'pointer', opacity: 0.7 }}
                                                                  />

                                                               </Col>
                                                          <Button style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={() => handleCopyToClipboard(data?.chargePointId, 'chargePointId')}><ContentCopyRoundedIcon style={{ fontSize: "small" }} /></Button>

                                                      </Row>
                                                      <Row gutter={[15, 25]} style={{ marginBottom: '5px' }}>
                                                              <Col xs={24} md={3}>

                                                                  <label htmlFor="password">{t('Authorisierungs-Schlüssel')}</label>
                                                                  </Col> 
                                                                  <Col xs={24} md={9}> 
                                                                  <Input
                                                                    id="password"
                                                                    type="password"
                                                                    value={data?.password}
                            
                                                                    readOnly
                                                                    style={{ backgroundColor: '#cccccc', cursor: 'pointer', opacity: 0.7 }}
                                                                  />
                                                              </Col>
                                                          <Button style={{ border: "none", backgroundColor: "transparent", padding: 0 }} onClick={() => handleCopyToClipboard(data?.password, 'password')}><ContentCopyRoundedIcon style={{ fontSize: "small" }} /></Button>

                                                      </Row>

                                              </div>
                        
                      
                                                  <Divider />
                                                  <Row gutter={[10, 25]}>
                                                      <Col xs={24} md={10} style={{ fontWeight: 'bold' }}>

                                                          {OcppDataStatus?.isConnected ? (
                                                              <span style={{ color: '#79BF24', border: '2px solid #79BF24', boxShadow: '0px 0px 10px #79BF24', borderRadius: '12px', padding: '8px' }}>{t('ONLINE')}</span>
                                                          ) : (
                                                                  <span style={{ color: 'rgb(225, 64, 46)', border: '2px solid   ', boxShadow: '0px 0px 10px rgb(225, 64, 46)', borderRadius: '12px', padding: '8px'}}>
                                                                  {t('OFFLINE')} 
                                                              </span>
                                                          )}
                                                      </Col>

                                                  </Row>
                                              
                                              </Col>
                                          
                                      </>)}
                           
                           

                                  </Row>
                      </div>
                  </form>
            </div>
          </div>

        </Box>
      </div>
    </>
  );
}

export default OCPP;
