import React, { useState, useEffect, useContext } from 'react';
import { Divider, Col, Row } from 'antd';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { TextField, Box } from '@mui/material';
import { makeStyles, createStyles } from '@material-ui/core';
import axios from 'axios';

import ChargeStationDataContext from './ChargeStationDataContext';
import ChargeLocationType from "../services/ChargeLocationType";
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import StateButton from '../Chargepoint/StateButton';

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            display: 'block',
        },
        select: {
            minWidth: 200, // Adjust as needed
        },
        greenUnderline: {
            '&:after': {
                borderBottomColor: '#4CAF50', // Green underline color when selected
            },
        },
    })
);

function ChargeStationMeter() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    const [activeEichrechtskonform, setActiveEichrechtskonform] = useState(false);
    const [activesichtbar, setActivesichtbar] = useState(false);
    const [activeintern, setActiveintern] = useState(false);
    const [activeZahler, setActiveZahler] = useState(false);
    const [values, setValues] = useState(null);

    useEffect(() => {
        // Update local state when chargeStationData changes
        if (chargeStationData) {
            setValues(chargeStationData);
            updateButtonState(getBitFromMask(chargeStationData.type, MeterMask));
        }
    }, [chargeStationData]);

    const token = Cookies.get("token");
    const decoded = jwt_decode(token);
    const guid = Cookies.get("ownerGuid");

    const MeterMask = ChargeLocationType.Calibrated | ChargeLocationType.VisibleMeter | ChargeLocationType.NoMeter | ChargeLocationType.InternalMeter;

    const updateServer = (type, mask) => {
        const wizardStep = 0;
        axios.put(`${BASE_URL}/CpoLocation/UpdateCockpitLocationType/${guid}/${chargeStationData.guid}/${token}/${type}/${mask}?wizardStep=${wizardStep}`)
            .then((response) => {
                const updatedData = response.data;
                setValues(updatedData);
                setChargeStationData(updatedData);
                console.log("updatedData in meter", updatedData)// Update context data
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getBitFromMask = (value, mask) => value & mask;

    const updateButtonState = (bitValue) => {
        setActiveEichrechtskonform(false);
        setActivesichtbar(false);
        setActiveintern(false);
        setActiveZahler(false);

        switch (bitValue) {
            case ChargeLocationType.Calibrated:
                setActiveEichrechtskonform(true);
                break;
            case ChargeLocationType.VisibleMeter:
                setActivesichtbar(true);
                break;
            case ChargeLocationType.InternalMeter:
                setActiveintern(true);
                break;
            case ChargeLocationType.NoMeter:
                setActiveZahler(true);
                break;
            default:
                break;
        }
    };

    const [errors, setErrors] = useState({});
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!activeEichrechtskonform && !activesichtbar && !activeintern && !activeZahler) {
            isValid = false;
            newErrors.meterType = t('Please select a meter type');
        }

        if (!(values?.power > 0 && values?.power < 1000)) {
            isValid = false;
            newErrors.power = t('Please enter a valid charging power between 0 and 1000.');
        }

        setErrors(newErrors);
        return isValid;
    };

    const handlePowerChange = (input) => (e) => {
        const rawValue = e.target.value || '';
        const cleanedValue = rawValue.replace(",", ".");
        setValues({ ...values, [input]: cleanedValue });
    };

    const handleClickMeterButton = (input) => () => {
        updateButtonState(input);
        updateServer(input, MeterMask);
    };

    const updateServerChargeStation = () => {
        const wizardStep = 0;

        fetch(`${BASE_URL}/CpoLocation/UpdateCockpitLocation/${guid}/${chargeStationData.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((response) => {
            if (response.ok) {
                return response.json(); // Parse the JSON response body
            } else {
                throw new Error('There was an error!');
            }
        }).then((updatedData) => {
            /* setValues(updatedData);*/
            setChargeStationData(updatedData);
            console.log("updatedData in meter", updatedData); // Update context data
        }).catch((error) => {
            console.error(error.message);
        });
    };

    const handleBlur = () => {
        updateServerChargeStation();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            handleBlur(); // Trigger server update
        }
    };

    return (
        <>
            <div style={{ width: '100%' }}>
                <Box component="div">
                    <div>
                        <form>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%' }}>
                                <Col sx={24} md={10}>
                                    <Row gutter={[1, 5]} justify="left" align="middle">
                                        <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.Calibrated)} isDisabled={activeEichrechtskonform} isActive={activeEichrechtskonform} buttonTextPlaceholder='Calibration Compliant Meter' popupContentPlaceholder='Meter compliant with legal calibration regulations.' />
                                        <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.VisibleMeter)} isDisabled={activesichtbar} isActive={activesichtbar} buttonTextPlaceholder='MID Meter, Visible' popupContentPlaceholder='MID-compliant meter, visible to users.' />
                                        <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.InternalMeter)} isDisabled={activeintern} isActive={activeintern} buttonTextPlaceholder='MID Meter, Internal' popupContentPlaceholder='MID-compliant meter, internally installed in the charging station.' />
                                        <StateButton onClickCallback={handleClickMeterButton(ChargeLocationType.NoMeter)} isDisabled={activeZahler} isActive={activeZahler} buttonTextPlaceholder='Other/No Meter' popupContentPlaceholder='Choose this option if no MID or other meter is present' />
                                        {/*   {errors.meterType && <p style={{ color: "red" }} className="error">{errors.meterType}</p>}*/}
                                    </Row>
                                    <Divider></Divider>
                                    <Row gutter={[1, 5]} justify="left" align="middle">
                                        <TextField
                                            id="power"
                                            label={t('Power (kW)')}
                                            value={values?.power || ''}
                                            onChange={handlePowerChange('power')}
                                            onBlur={handleBlur}
                                            onKeyDown={handleKeyDown} // Handle Enter key
                                            fullWidth
                                            focused
                                        />
                                        {/*  {errors.power && <p style={{ color: "red" }} className="error">{errors.power}</p>}*/}
                                    </Row>
                                </Col>
                                {/* Additional error handling */}
                            </div>
                        </form>
                    </div>
                </Box>
            </div>
        </>
    );
}

export default ChargeStationMeter;
