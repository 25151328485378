import React, { useEffect } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, TextField, MenuItem as MuiMenuItem, MenuList, Stack, Box } from '@mui/material';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import axios, { isCancel, AxiosError } from 'axios';
import '../../../src/css/App.css';
import { useState } from "react";
import { Button } from '../../../node_modules/@mui/material/index';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import PhoneInput from 'react-phone-input-2'
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import AccountMenu from "./AccountMenu";
function Provider() {
	const [cookies, setCookie] = useCookies();
	localStorage.setItem('activePage', 'Provider');
	var token = Cookies.get("token");
	var decoded = jwt_decode(token);
	var guid = decoded.jti;
	const [data, setData] = useState([]);
	const [phone, setPhone] = useState('');
	const { t } = useTranslation();
	const navigate = useNavigate()
	const [connectedByMobile, setConnectedByMobile] = useState(false);
	const [subjectToVAT, setSubjectToVAT] = useState(false);
	const [connectedToStripe, setConnectedToStripe] = useState(false);
	const [hasCompany, setHasCompany] = useState(false);
	console.log("guid", guid);
	console.log("token", token);
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});

		const fetchData = async () => {
			try {
				const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
				// Handle the response here
				console.log("Provider", response.data)
				setSubjectToVAT(!(response.data.isSmallBusiness))
				setData(response.data);
				setPhone(response.data.phone);
				setHasCompany(response.data.hasCompany);
				console.log(data);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchData();
	}, []);

	const handleCreateCompany = (event) => {
		navigate("../Company/Register");
	}


	const handleSubmit = (event) => {
		event.preventDefault();
		// Check for errors in each input field
		const hasFirstNameError = data.firstName === '' || (data.firstName && data.firstName.length > 40);
		const hasLastNameError = data.lastName === '' || (data.lastName && data.lastName.length > 40);
		const hasZipCodeError = data.zipCode === '' || (data.zipCode && data.zipCode.length > 8);
		const hasCityError = data.city === '' || (data.city && data.city.length > 40);
		const hasStreetError = data.street === '' || (data.street && data.street.length > 40);
		const hasStreetNumberError = data.streetNumber === '' || (data.streetNumber && data.streetNumber.length > 5);

		// If any of the fields have errors, do not proceed with the form submission
		if (hasFirstNameError || hasLastNameError || hasZipCodeError || hasCityError || hasStreetError || hasStreetNumberError) {
			console.log('Form has errors. Please check the input fields.');
			// Optionally, you can show an error message to the user here if needed.
			return;
		}
		// If data.name is blank, replace it with null
		if (data.name === '') {
			data.name = '';
			console.log("name", data.name)
		}
		if (data.vatId === '') {
			data.vatId = '';
		}

		onFinish(data);
	};

	const onFinish = async (data) => {

		console.log("small bunisses", subjectToVAT)
		let formData = new FormData();
		formData.append('Name', data.name);
		formData.append('FirstName', data.firstName);
		formData.append('LastName', data.lastName);
		formData.append('ZipCode', data.zipCode);
		formData.append('City', data.city);
		formData.append('Street', data.street);
		formData.append('StreetNumber', data.streetNumber);
		formData.append('VatId', data.vatId);
		formData.append('Phone', phone);
		formData.append('IsSmallBusiness', subjectToVAT ? 0 : 1);
		const config = {
			headers: { 'content-type': 'multipart/form-data' }
		};

		try {
			console.log("BASE_URL", BASE_URL)
			const response = await axios.put(`${BASE_URL}/customer/UpdateAndCreateCustomer/${guid}/${token}`, formData, config);
			// Handle the response here
			console.log('Updated at:', response.data);
			console.log(data.stripeId, data.id);
			if (data.stripeId) {
				if (data.id) {
					navigate('/ChargeLocation');
					setConnectedByMobile(true);
					setConnectedToStripe(true);
				} else {
					navigate("/ConnectMobile");
					setConnectedToStripe(false);
				}
			} else {
				navigate("/Stripe");
			}
		} catch (error) {
			// Handle any errors that occur during the request
			console.error('There was an error!', error);
		}
	};
	return (

		<>
			<div style={{ width: '100%' }}>
				<Row>
					<Col xs={24} md={5}>

						<AccountMenu initialSelectedPage="Anbieterdaten"  ></AccountMenu>

					</Col>

					<Col xs={24} md={16}>
						<div style={{ padding: '0% 4vw' }}>
							<Box
								component="div"
								sx={{
									backgroundColor: '#f5f5f5',
									margin: "5% 10%",
									border: '1px solid #e0e0e0',
									borderRadius: '1vw',
								}}
							>
								<div style={{ padding: '2%' }}>
									<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 2.5 }}>
										{t('Anbieterdaten')} - {data.name}
									</h1>

									<form >
										<TextField id="fullWidth" fullWidth label={t('Firma')} color="success" focused value={data.name}

											onChange={(e) => setData({ ...data, name: e.target.value })}
										/>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={12}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Vorname') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.firstName}
													onChange={(e) => setData({ ...data, firstName: e.target.value })}
													helperText={
														(data.firstName && data.firstName.length > 40) ? 'firstName should be 40 characters' :
															data.firstName === '' ? 'Please enter your first name' : ''
													}
													error={data.firstName === '' || (data.firstName && data.firstName.length > 40)} // Add error prop for both empty and length check
												/>
											</Col>
											<Col xs={24} md={12}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Nachname') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.lastName}
													onChange={(e) => setData({ ...data, lastName: e.target.value })}
													helperText={
														(data.lastName && data.lastName.length > 40) ? 'lastName should be 40 characters' :
															data.lastName === '' ? 'Please enter your last name' : ''
													}
													error={data.lastName === '' || (data.lastName && data.lastName.length > 40)} // Add error prop for both empty and length check
												/>
											</Col>
										</Row>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={12}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Postleitzahl') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.zipCode}
													onChange={(e) => setData({ ...data, zipCode: e.target.value })}
													helperText={
														(data.zipCode && data.zipCode.length > 8) ? 'zipCode should be 8 characters' :
															data.zipCode === '' ? 'Please enter the zip code' : ''
													}
													error={data.zipCode === '' || (data.zipCode && data.zipCode.length > 8)} // Add error prop for both empty and length check
												/>

											</Col>
											<Col xs={24} md={12}>
												<TextField
													id="fullWidth"
													fullWidth
													label={t('Ort') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.city}
													onChange={(e) => setData({ ...data, city: e.target.value })}
													helperText={
														(data.city && data.city.length > 40) ? 'city should be 40 characters' :
															data.city === '' ? 'Please enter the city' : ''
													}
													error={data.city === '' || (data.city && data.city.length > 40)} // Add error prop for both empty and length check
												/>

											</Col>
										</Row>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={8}>
												<TextField
													id="fullWidth"
													fullWidth
													label={t('Straße') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.street}
													onChange={(e) => setData({ ...data, street: e.target.value })}
													helperText={
														(data.street && data.street.length > 40) ? 'street should be 40 characters' :
															data.street === '' ? 'Please enter the street' : ''
													}
													error={data.street === '' || (data.street && data.street.length > 40)} // Add error prop for both empty and length check
												/>

											</Col>
											<Col xs={24} md={8}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Hausnummer') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={data.streetNumber}
													onChange={(e) => setData({ ...data, streetNumber: e.target.value })}
													helperText={
														(data.streetNumber && data.streetNumber.length > 5) ? 'streetNumber should be 5 characters' :
															data.streetNumber === '' ? 'Please enter the street number' : ''
													}
													error={data.streetNumber === '' || (data.streetNumber && data.streetNumber.length > 5)} // Add error prop for both empty and length check
												/>
											</Col>
											<Col xs={24} md={8}>
												<PhoneInput
													specialLabel={''}
													country={'de'}
													value={data.phone}
													onChange={setPhone}
												/>
											</Col>
										</Row>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={8}>
												{/* <Checkbox label={t('SubjectToVAT')} /> */}
												<Checkbox
													checked={subjectToVAT}
													onChange={(e) => setSubjectToVAT(e.target.checked)}
													style={{ fontSize: '20px' }}
												/>

												<span style={{ marginLeft: '8px', fontSize: '20px' }}>{t('SubjectToVAT') + '**'}</span>

											</Col>
											<Col xs={24} md={16}>
												<TextField id="fullWidth" fullWidth label={t('USt.Id*') + '*'} color="success" focused value={data.vatId}
													onChange={(e) => setData({ ...data, vatId: e.target.value })}   disabled={!subjectToVAT}
												/>
											</Col>

										</Row>

										<Divider />

										<p>  {t('VATInfo')}</p>
										<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

											{connectedByMobile && connectedToStripe ? (
												<Button
													variant="contained"
													type="primary"
													color="primary"
													onClick={handleSubmit}
												>
													{t('Continue to the charging station')}
												</Button>
											) : (
												<Button
													variant="contained"
													type="primary"
													color="primary"
													onClick={handleSubmit}
												>
													{t("Save AND Next")}
												</Button>
											)}
										</div>
									</form>
								</div>
							</Box>
							
							{/* {(!hasCompany && data.isCompanyRegisterable )? (
								<>
									<Box
										component="div"
										sx={{
											backgroundColor: '#f5f5f5',
											margin: '1% 0',
											border: '1px solid #e0e0e0',
											borderRadius: '1vw',
											padding: '10px 30px'
										}}
									>
										<Button
											variant="contained"
											type="primary"
											color="primary"
											onClick={handleCreateCompany}>

											{t("Create Company")}
										</Button>

									</Box>
								</>) : (<></>)
							} */}
					</div>
				</Col>
			</Row>
		</div>


		</>
	);
}

export default Provider;