import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from "react-cookie";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ConditionalRoute from './ConditionalRoute';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import SignInSide from './SignInSide';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { I18nextProvider } from 'react-i18next';
import i18n from './Locale/i18n';
import Profile from "./components/Account/StripeProfile";
import Details from "./components/Bookings/Details";
import ApproveRegistration from "./components/Company/ApproveRegistration";
//const root = ReactDOM.createRoot(document.getElementById('root'));
import LanguageSwitcher from './Locale/LanguageSwitcher';
import WelcomePage from "./components/Partner/WelcomePage";     

import OAuthCaFHome  from './OAuthCaFHome';

import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  
  <CookiesProvider>
    <Router>
            <Routes>
      
        // Token exists and is not null, render your App component or any other component
                    <Route path="*" element={<ConditionalRoute />} />
      
        // Token is undefined or null, render SignInSide component
       
               {/* <Route path="/login" element={<OAuthLogin />} />*/}
                
                <Route path="/home/profile" element={<Profile />} />
                <Route path="/bk/:id" element={<Details />} />
                <Route path="/Public/CompanyApprove/:companyGuid/:requestGuid" element={<ApproveRegistration />} />
                <Route path="/Partner/:partnername" element={<WelcomePage />} />
					      <Route path="/Identity/Account/Register" element={<OAuthCaFHome />} />
             
            </Routes>
        {/* Hide the LanguageSwitcher component using CSS */}
        <div style={{ display: 'none' }}>
        <LanguageSwitcher />
                </div>
           
    </Router>
  </CookiesProvider>,
  document.getElementById('root')
);

reportWebVitals();
