import React, { useEffect } from 'react';
import {  TextField, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, MenuItem as MuiMenuItem, MenuList, Stack, Box } from '@mui/material';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios, { isCancel, AxiosError } from 'axios';
import '../../../src/css/App.css';
import { useState } from "react";
import { Button } from '../../../node_modules/@mui/material/index';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
//import PhoneInput from 'react-phone-input-2'
import { BASE_URL } from '../../Settings';
import { useTranslation } from 'react-i18next';
import AccountMenu from '../Account/AccountMenu';
import { Alert, Snackbar } from '@mui/material';

import { isCompanyContextAtom } from '../../Header';
import MenuLeft from "../Nav/MenuLeft";
import { useAtom, useAtomValue } from 'jotai';
function Register() {
	const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
	var token = Cookies.get("token");
	var ownerGuid = '00000000-0000-0000-0000-000000000000';
	if (isCompanyContext) {
		ownerGuid = Cookies.get("ownerGuid");
	
	}
	var decoded = jwt_decode(token);
	var guid = decoded.jti;
	const [company, setCompany] = useState([]);
	const [domainOwners, setDomainOwners] = useState([]);
	const [domainValue, setDomainValue] = useState(null);
	const [phone, setPhone] = useState('');
	const { t } = useTranslation();
	const navigate = useNavigate()
	const [isKleinunternehmer, setIsKleinunternehmer] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	
	console.log("guid", guid);
	console.log("token", token);
	useEffect(() => {
		const api = axios.create({
			baseURL: BASE_URL
		});
		
		

		const fetchData = async () => {
			try {
				const response = await api.get(`/Company/GetCompany/${ownerGuid}/${token}`);
				// Handle the response here
				console.log("Company", response.data)
				setCompany(response.data);
				setApproverName(response.data.requestApproverName);
				setPhone(response.data.phone);
				setDomainValue(`${response.data.requestApproverName}@${response.data.domain}`);
				
				
				if (company.state == 1) {
					navigate("/Company/BaseData");
				}
				console.log(company);
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		const fetchDomainOwners = async () => {
			try {
				const response = await api.get(`/Company/GetDomainOwners/${token}`);
				// Handle the response here
				console.log("DomainOwners", response.data)
				setDomainOwners(response.data);
				console.log(company);
				
				
			} catch (error) {
				// Handle any errors that occur during the request
				console.error(error);
			}
		};

		fetchDomainOwners();
		fetchData();
		
	
	}, []);
	const [requestApproverName, setApproverName] = React.useState("");
	const [resendNotifTitle, setResendNotifTitle] = useState("");
	console.log("domainValue in useEffect", domainValue);
	const handleChange = (event) => {
		setApproverName(event.target.value);
		company.requestApproverName = event.target.value;

	};

	const handleBlur = (e) => {
		console.log('company')
		console.log(company)
	}
	useEffect(() => {
		if (domainValue) {
			setResendNotifTitle(t('CompanyRequestSendTo') + domainValue);
		}
	}, [domainValue, t]);


	const handleSubmit = async (event) => {
		event.preventDefault();
		// Check for errors in each input field
		const hasFirstNameError = company.firstName === '' || (company.firstName && company.firstName.length > 40);
		const hasLastNameError = company.lastName === '' || (company.lastName && company.lastName.length > 40);
		const hasZipCodeError = company.zipCode === '' || (company.zipCode && company.zipCode.length > 8);
		const hasCityError = company.city === '' || (company.city && company.city.length > 40);
		const hasStreetError = company.street === '' || (company.street && company.street.length > 40);
		const hasStreetNumberError = company.streetNumber === '' || (company.streetNumber && company.streetNumber.length > 5);

		// If any of the fields have errors, do not proceed with the form submission
		if (hasFirstNameError || hasLastNameError || hasZipCodeError || hasCityError || hasStreetError || hasStreetNumberError) {
			console.log('Form has errors. Please check the input fields.');
			// Optionally, you can show an error message to the user here if needed.
			return;
		}

		console.log("company",company)
		// If data.name is blank, replace it with null
		if (company.name === '') {
			company.name = '';
			console.log("name", company.name)
		}
		if (company.vatId === '') {
			company.vatId = '';
		}


		console.log(company);
		try {
			await onFinish(company);
			const newDomainValue = `${company.requestApproverName}@${company.domain}`;
			setDomainValue(newDomainValue);
			setSuccessMessage(`the request for create company is sent  successfully , please wait until it's approved by ${company.requestApproverName} ` );
			setTimeout(() => {
				setSuccessMessage(null);
			}, 5000); // Adjust the delay as needed

		} catch (error) {
			console.error("An error occurred during onFinish:", error);
			// Handle the error as needed
		}
	};

	const cancelRequest = (event) => {

	}

	const onFinish = async (_company) => {
		console.log("_company", _company)

		console.log("small bunisses", isKleinunternehmer)
		//let formData = new FormData();
		//formData.append('Name', _company.name);
		//formData.append('ZipCode', _company.zipCode);
		//formData.append('City', _company.city);
		//formData.append('Street', _company.street);
		//formData.append('StreetNumber', _company.streetNumber);
		//formData.append('VatId', _company.vatId);
		//formData.append('RequestApproverName', _company.vatId);
		//const config = {
		//	headers: { 'content-type': 'multipart/form-data' }
		//};

		try {
			console.log("BASE_URL", BASE_URL)
			if (company.state == 0) {
				const response = await axios.post(`${BASE_URL}/Company/Register/${token}`, _company);
				setCompany(response.data);
				console.log('Updated at:', response.data);
			} else {
				const response = await axios.put(`${BASE_URL}/Company/ResendRegistration/${token}`, _company);
				setCompany(response.data);
				console.log('Updated at:', response.data);
			}
		} catch (error) {
			// Handle any errors that occur during the request
			console.error('There was an error!', error);
		}
	};
	const onCancel = async (_company) => {


		try {
			console.log("BASE_URL", BASE_URL)
			var api = 'Company/CancelRequest';
			const response = await axios.delete(`${BASE_URL}/${api}/${token}`);
			// Handle the response here
			console.log('Updated at:', response.data);
			navigate("/Provider");
		} catch (error) {
			// Handle any errors that occur during the request
			console.error('There was an error!', error);
		}
	};

	return (

		<>
			<div style={{ width: '100%' }}>
				
					<Snackbar
						open={!!successMessage}
						autoHideDuration={6000}
						onClose={() => setSuccessMessage(null)}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
						<Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
							{successMessage}
						</Alert>
					</Snackbar>
				
				<Row >
					<Col xs={24} md={5}
					>

						<AccountMenu initialSelectedPage="Company"  ></AccountMenu>

					</Col>
					<Col xs={24} md={19} >
				
						<div style={{ padding: '0% 4vw' }}>
							<Box
								component="div"
								sx={{
									backgroundColor: '#f5f5f5',
									margin: "5% 10%",
									border: '1px solid #e0e0e0',
									borderRadius: '1vw',
								}}
							>
								<div style={{ padding: '2%' }}>
									<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 1 }}>
										{t('Create Company Headline')}
									</h1>

									<form >
										<TextField id="fullWidth" fullWidth label={t('Firma')} color="success" focused value={company.name}
											onChange={(e) => setCompany({ ...company, name: e.target.value })}
										/>
										<Divider />
										<TextField value={company.domain} label={t('Domain')} id="fullWidth" fullWidth color="success" readOnly="readonly" focused

										/>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={6}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Postleitzahl') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={company.zipCode}
													onChange={(e) => setCompany({ ...company, zipCode: e.target.value })}
													helperText={
														(company.zipCode && company.zipCode.length > 8) ? 'zipCode should be 8 characters' :
															company.zipCode === '' ? 'Please enter the zip code' : ''
													}
													error={company.zipCode === '' || (company.zipCode && company.zipCode.length > 8)} // Add error prop for both empty and length check
												/>

											</Col>
											<Col xs={24} md={18}>
												<TextField
													id="fullWidth"
													fullWidth
													label={t('Ort') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={company.city}
													onChange={(e) => setCompany({ ...company, city: e.target.value })}
													helperText={
														(company.city && company.city.length > 40) ? 'city should be 40 characters' :
															company.city === '' ? 'Please enter the city' : ''
													}
													error={company.city === '' || (company.city && company.city.length > 40)} // Add error prop for both empty and length check
												/>

											</Col>
										</Row>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={16}>
												<TextField
													id="fullWidth"
													fullWidth
													label={t('Straße') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={company.street}
													onChange={(e) => setCompany({ ...company, street: e.target.value })}
													helperText={
														(company.street && company.street.length > 40) ? 'street should be 40 characters' :
															company.street === '' ? 'Please enter the street' : ''
													}
													error={company.street === '' || (company.street && company.street.length > 40)} // Add error prop for both empty and length check
												/>

											</Col>
											<Col xs={24} md={8}>
												<TextField
													id="standard-basic"
													fullWidth
													label={t('Hausnummer') + '*'} // Add an asterisk (*) to indicate it's a required field
													color="success"
													focused
													value={company.streetNumber}
													onChange={(e) => setCompany({ ...company, streetNumber: e.target.value })}
													helperText={
														(company.streetNumber && company.streetNumber.length > 5) ? 'streetNumber should be 5 characters' :
															company.streetNumber === '' ? 'Please enter the street number' : ''
													}
													error={company.streetNumber === '' || (company.streetNumber && company.streetNumber.length > 5)} // Add error prop for both empty and length check
												/>
											</Col>
										</Row>

										<Divider />

										<Row gutter={[10, 25]}>
											<Col xs={24} md={6}>
												<TextField id="fullWidth" fullWidth label={t('USt.Id*') + '*'} color="success" focused value={company.vatId}
													onChange={(e) => setCompany({ ...company, vatId: e.target.value })}
												/>
											</Col>

										</Row>

										<Divider />

										<FormControl>
											{(company.state == 0) ? (<FormLabel id="demo-controlled-radio-buttons-group">
												{t('DomainApproverExplanation') }):(
											</FormLabel>) : (<div>
													<h3>{resendNotifTitle}</h3>
												<FormLabel id="demo-controlled-radio-buttons-group">
														{t('ReRegister')}
												</FormLabel></div>)}
											
											<RadioGroup
												aria-labelledby="demo-controlled-radio-buttons-group"
												value={requestApproverName}
												name="radio-buttons-group"
												onChange={handleChange}
											>
												{domainOwners.map((domainOwner) => (
													<FormControlLabel key={domainOwner} value={domainOwner} control={<Radio />} label={domainOwner + '@' + company.domain} />
												))}
											</RadioGroup>
										</FormControl>
										<Divider />
										{(company.state == 0) ? (
											<div style={{ display: 'flex', justifyContent: 'center' }}>
												<Button
													variant="contained"
													type="primary"
													color="primary"
													onClick={handleSubmit}
												>
													{t("Register")}
												</Button>
											</div>) : (
											<Row gutter={[10, 6]} style={{ display: 'flex', justifyContent: 'center' }}>
												<Col xs={24} md={8}>
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<Button
															variant="contained"
															type="primary"
															color="primary"
															onClick={handleSubmit}
														>
															{t("ReRegister")}
														</Button>
													</div>
												</Col>
												<Col xs={24} md={8}>
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<Button
															variant="contained"
															type="primary"
															color="primary"
															onClick={cancelRequest}
														>
															{t("CancelRegistration")}
														</Button>
													</div>
												</Col>
											</Row>
										)
										}
									</form>
								</div>
							</Box>


						</div>

					</Col>
				</Row>
			</div>


		</>
	);
}

export default Register;