const WizardState = {
	/// <summary>
	/// set flag during creation of inital chargingStation, chargePoint and accessProfile
	/// </summary>
	InitialChargingStationCreated : 0x1,
	/// <summary>
	/// CPO Profile
	/// </summary>
	AccountProfile : 0x2,
	/// <summary>
	/// ChargingStation Postion and Name
	/// </summary>
	StationProfile : 0x4,
	/// <summary>
	/// Manufacturer and Model - postponed
	/// </summary>
	StationModel : 0x8,
	/// <summary>
	/// MeterType: calibrated, visible or not
	/// </summary>
	MeterType : 0x10,
	/// <summary>
	/// OCPP or offline
	/// </summary>
	Connection: 0x20,
	/// <summary>
	/// MeterType: calibrated, visible or not
	/// </summary>
	UploadPhoto: 0x200,
	/// <summary>
	/// Upload photo for cpLocation 
	/// </summary>
	Billing : 0x40,
	/// <summary>
	/// Initial Opening Hours - should be hidden for the time being
	/// </summary>
	OpeningProfile : 0x80,
	/// <summary>
	/// ChargePoint Page for the initial first ChargePoint of a Charging Station
	/// </summary>
	QrCode: 0x100,
	/// <summary>
	/// Set Stripe Later
	/// </summary>
	SetUpStripeLater :0x400,
	/// <summary>
	/// Stripe
	/// </summary>
	Stripe : 0x800,
	/// <summary>
	/// This CPO Account is considered active in Hubspot, this requires correct Account Profile, Station Setup, Stripe Setup and at least one station active on the map
	/// </summary>
	Active : 0x1000
};

export default WizardState;
