import React, { useState, useEffect } from 'react';
import './WelcomePage.css'; // Import your custom CSS file for styling
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { AUTHORIZATION_ENDPOINT, REDIRECT_URI, CLIENT_ID } from '../../Settings';
import { v4 as uuidv4 } from 'uuid';
import CAF from './partnerImage/Logo-Charge-at-Friends.svg';

import axios from 'axios';
import { BASE_URL } from '../../Settings';
const WelcomePage = () => {

  const [partnerName, setPartnerName] = useState('');
  const [data, setData] = useState([]);
  const [codeChallenge, setCodeChallenge] = useState('');
  const [nonce, setNonce] = useState('');
  var token = Cookies.get("token");
  console.log("token", token);

  console.log("data", data);


  useEffect(() => {

    if (token) {
      // window.location.href = "/Provider";
      
      console.log("Token deleted");
    } else {
      console.log("Token not found");
    }
    const url = window.location.href.toLowerCase();
    const partnerNameMatch = url.match(/\/partner\/([^/]+)/i);
    console.log("partnerNameMatch", partnerNameMatch);

    const domain = partnerNameMatch[1].toLowerCase();

    axios.get(`${BASE_URL}/Public/GetPartner/${domain}`)
      .then(function (response) {
        // handle success
        console.log("GetPartner", response.data)
        setData(response.data);
        setPartnerName(response.data.domain);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });

    if (data) {
      console.log("data", data)

    } else {
      console.log("data", data)
      console.error('Partner name not found in the URL');
    }
  }, []);


  const generateCodeChallenge = (codeVerifier) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    return window.crypto.subtle.digest('SHA-256', data)
      .then(buffer => {
        const hashArray = Array.from(new Uint8Array(buffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
      })
      .catch(error => {
        console.error('Error generating code challenge:', error);
      });
  };

  const generateCodeVerifier = () => {
    const codeVerifierLength = 64;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

    let codeVerifier = '';
    for (let i = 0; i < codeVerifierLength; i++) {
      codeVerifier += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return codeVerifier;
  };
  const handleSSOLogin = () => {

    const authorizationEndpoint = AUTHORIZATION_ENDPOINT;
    const redirectUri = REDIRECT_URI;
    console.log("redirectUri {0}", redirectUri)
    const scopes = ['openid', 'profile'];
    const newNonce = uuidv4();
    setNonce(newNonce);
    const state = uuidv4(); // Generate a random UUID as the state
    const codeVerifier = generateCodeVerifier();
    const codeChallengePromise = generateCodeChallenge(codeVerifier);
    
    codeChallengePromise.then(codeChallenge => {
      setCodeChallenge(codeChallenge);

      const params = {
        response_type: 'code',
        client_id: CLIENT_ID,
        redirect_uri: redirectUri,
        scope: scopes.join(' '),
        nonce: newNonce,
        state: state,
        code_challenge: codeChallenge,
        partnerName: partnerName,

      };
      const url = `${authorizationEndpoint}?${new URLSearchParams(params)}`;
      window.location.href = url;
    });
  };

  const handleCPOLogin = () => {
    window.location.href = "/Provider";

  }

  // Function to format the partner name
  const formatPartnerName = (name) => {
    const formattedName = name
      .toLowerCase() // Convert to lowercase
      .replace(/%20/g, ' ') // Replace %20 with space
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first character
    return formattedName;
  };
  return (

    <div className="welcome-container">
      {data ? (
        <div className="full-width-content">
          <div className="text-center">
            {/* <h1>Welcome to Our Partner Page: <span style={{ color: 'green' }}>{partnerName}</span></h1> */}
            <p><b>
              Lieber {data.name}  Kunde herzlich willkommen in der Charge at Friends Community.<p></p> Bitte halte für die Registrierung Deiner Wallbox folgende Daten bereit:
            </b></p>
            Die Adresse der Ladestation  <p></p>
            Deine IBAN (Auszahlungskonto)<p></p>
            Die Zugangsdaten zu Deiner Wallbox<p></p>
            Ein Foto Deiner Wallbox (zur leichteren Identifizierung des Stellplatzes)<p></p>


            <button className="login-button" onClick={handleSSOLogin}>
              Jetzt hier registrieren
            </button>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <img src={CAF} alt="CAF" style={{ marginRight: '0px', marginTop: '50px', height: '100px', width: '150px'}} />

            {/* <img src={data.thumbnailUrl} alt="Wallbox" style={{ marginRight: '0px', marginTop: '50px',height: '100px', width: '150px' }} /> */}
            <div alt="PartnerLogo" style={{
              marginRight: '0px',
              marginTop: '50px',
              height: '100px',
              width: '150px',
              backgroundImage: `url(${data.thumbnailUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPositionY: 'center',
            }}></div>
          </div>
        </div>

      ) : (
        <div className="full-width-content">
          {/* <div className="text-center">
            <p>
              Please check the partner name in the URL and try again. If you believe this is an error, contact Charge at Friends Teams.
            </p>
            <button className="login-button" onClick={handleCPOLogin}>
              registrieren
            </button>

          </div> */}

<div className="text-center">
            {/* <h1>Welcome to Our Partner Page: <span style={{ color: 'green' }}>{partnerName}</span></h1> */}
            <p><b>
              Lieber  Kunde herzlich willkommen in der Charge at Friends Community.<p></p> Bitte halte für die Registrierung Deiner Wallbox folgende Daten bereit:
            </b></p>
            Die Adresse der Ladestation  <p></p>
            Deine IBAN (Auszahlungskonto)<p></p>
            Die Zugangsdaten zu Deiner Wallbox<p></p>
            Ein Foto Deiner Wallbox (zur leichteren Identifizierung des Stellplatzes)<p></p>

            <button className="login-button" onClick={handleCPOLogin}>
              registrieren
            </button>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>

            <img src={CAF} alt="CAF" style={{ marginRight: '0px', marginTop: '20px', height: '100px', width: '150px'}} />

           
          </div>
        </div>
      )}

    </div>
  );
};

export default WelcomePage;
