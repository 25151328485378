import React, { useEffect, useState } from 'react';
import "../../css/Details.css";
import Header from '../../Header';

function GotoAppStore() {
	const [guid, setGuid] = useState([]);




	const getMobileOperatingSystem = function () {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		//if (/windows phone/i.test(userAgent)) {
		//	return "Windows Phone";
		//}

		if (/android/i.test(userAgent)) {
			window.location.href = 'https://play.google.com/store/apps/details?id=com.chargeatfriends.android';
		}
		//window.location.href = 'https://play.google.com/store/apps/details?id=com.chargeatfriends.android';
		console.log('not android');
		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			window.location.href = 'https://apps.apple.com/de/app/charge-friends/id1534894906';
		}
		console.log('not ios');

		return "unknown";
	}

	getMobileOperatingSystem();

	return (
		<div>
			<Header />
			<h1 style={{
				margin: "3% 5%", borderColor: "white", fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
				fontSize: '3.5rem',
				fontWeight: 300,
				lineHeight: 1.2,
			}} >Lade die App runter!</h1>
		</div>
	);

}
export default GotoAppStore;