import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Switch, Typography, Box } from '@mui/material';
import { Divider, Col, Row } from 'antd';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import axios from "axios";
import { BASE_URL } from "../../Settings";
import Cookies from "js-cookie";
import { userGuidAtom, userGroupsAtom } from './UserGroups';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AccountMenu from '../Account/AccountMenu';
import SetGetUserGroupName from '../services/session';

import FlexPriceUserGroup from "../Flex/FlexPriceUserGroups";


const bitmaskValues = {
    PublicGroup: 0x1,
    HasPrice: 0x2,
    PayByApp: 0x4,
    Invoice: 0x8,
    GenerateReport: 0x10,
    ExternalStations: 0x20,
    RequiresSubscription: 0x40,
    MonthlyOverview: 0x80,
};

const UserGroupProperties = (userGuid1) => {
    const [isCharging, setIsCharging] = useState(false);
    const [canPayUsingApp, setCanPayUsingApp] = useState(false);
    const [canCreateInvoice, setCanCreateInvoice] = useState(false);
    const [canGenerateReport, setCanGenerateReport] = useState(false);
    const [canActiveorInactive, setCanActiveorInactive] = useState(false);
    const [isFlex, setFlex] = useState(false);


    const { t } = useTranslation();
    const { state } = useLocation()
    console.log("Received data:", state);
    console.log("state.guid", state?.guid)
    const userGuid = useAtomValue(userGuidAtom);
    const [values, setValues] = useState();
    console.log("userGuid", userGuid1.userGuid1);
    const [bitmask, setBitmask] = useState(0);
    const [gridTariff, setGridTariff] = useState(0);
    const [pvTariff, setPvTariff] = useState(0);
    const [name, setName] = useState("");

    useEffect(() => {
        console.log("Effect is running");
        const api = axios.create({
            baseURL: BASE_URL,
        });

        const fetchData = async () => {
            try {
                let userGroupGuid = state?.guid || userGuid1.userGuid1;
                const response = await api.get(
                    `UserGroup/GetUserGroup/${ownerGuid}/${userGroupGuid}/${token}`
                );
                console.log("response", response.data);
                if (response.data === undefined) {
                    // Display a proper message when response.data is null
                    console.log("No user groups found");
                } else {
                    setValues(response.data);
                    setBitmask(response.data.groupType);
                    SetGetUserGroupName.setUserGroupsName(response.data.name);
                    // Set initial switch states based on the bitmask
                    setIsCharging((response.data.groupType & bitmaskValues.HasPrice) !== 0);
                    setCanPayUsingApp((response.data.groupType & bitmaskValues.PayByApp) !== 0);
                    setCanCreateInvoice((response.data.groupType & bitmaskValues.Invoice) !== 0);
                    setCanGenerateReport((response.data.groupType & bitmaskValues.GenerateReport) !== 0);
                    setCanActiveorInactive(response.data.isActive)
                    setFlex(response.data.isFlex)
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    const setUserGroups = useSetAtom(userGroupsAtom);
    const updatedUserGroups = useAtomValue(userGroupsAtom);
    console.log("updatedUserGroups", updatedUserGroups)



    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");

    useEffect(() => {
        let newBitmask = 0;
        newBitmask |= isCharging ? bitmaskValues.HasPrice : 0;
        newBitmask |= canPayUsingApp ? bitmaskValues.PayByApp : 0;
        newBitmask |= canCreateInvoice ? bitmaskValues.Invoice : 0;
        newBitmask |= canGenerateReport ? bitmaskValues.GenerateReport : 0;

        setBitmask(newBitmask);
        setValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                groupType: newBitmask,
            };
            console.log("updatedValues", updatedValues)
            updateUserGroup(updatedValues);
            return updatedValues;
        });

        console.log("newBitmask", newBitmask)
    }, [isCharging, canPayUsingApp, canCreateInvoice, canGenerateReport]);
    //}, []);


    const updateUserGroupSettings = async (currentBitmask) => {
        const payload = {
            groupType: currentBitmask,
            name: name,
            description: name,
            moonShineRate: gridTariff,
            sunShineRate: pvTariff,
            guid: state?.guid
        };
        setUserGroups(current => ({ ...current, ...payload }));
        console.log("updatedUserGroups", updatedUserGroups)
        const api = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        console.log("bitmask", bitmask)
        try {

            const response = await api.put(`UserGroup/UpdateUserGroup/${ownerGuid}/${token}`, payload);

            console.log("Update successful", response.data);
        } catch (error) {
            console.error("Error updating user group", error);
        }
    };

    const handleToggle = (setter) => async (event) => {
        setter(event.target.checked);
        console.log("handleToggle", event.target.checked)

    };

    const handlePriceChange = (setter) => (event) => {
        console.log("setter", event.target.value)
        setter(event.target.value);
    };

    //#region  Bhargav new code

    const handleTextboxChange = (event) => {
        console.log("handleTextboxChange", event.target.value)
        const newValue = event.target.value;
        SetGetUserGroupName.setUserGroupsName(newValue);
        setValues((prevValues) => {
            const textboxValue = {
                ...prevValues,
                name: newValue, // XOR to toggle the bit
            };

            console.log("handlePayByAppToggle values", textboxValue);
            updateUserGroup(textboxValue);
            return textboxValue; // Make sure to return the updated values
        });
    };

    const [gridTariffError, setGridTariffError] = useState(false);
    const handleTextboxGridChange = (event) => {
        const newValue = event.target.value.trim(); // Remove leading/trailing whitespaces
        setGridTariff(newValue == "" ? 0 : parseFloat(newValue)); // Set 0 if empty, otherwise parse the value
        setGridTariffError(newValue == ""); // Display error if the input is empty

        setValues((prevValues) => {
            const textboxValue = {
                ...prevValues,
                moonShineRate: newValue, // Update the sunShineRate with the new value
            };
            updateUserGroup(textboxValue);
            return textboxValue; // Make sure to return the updated values
        });
    };
    //const handleTextboxGridChange = (event) => {
    //     const newValue = event.target.value;
    //    setValues((prevValues) => {
    //        const textboxValue = {
    //            ...prevValues,
    //            sunShineRate: newValue, // XOR to toggle the bit
    //        };      
    //        updateUserGroup(textboxValue);
    //        return textboxValue; // Make sure to return the updated values
    //    });
    //};
    const handleTextboxPvTariffChange = (event) => {

        const newValue = event.target.value;
        setValues((prevValues) => {
            const textboxValue = {
                ...prevValues,
                sunShineRate: newValue, // XOR to toggle the bit
            };

            updateUserGroup(textboxValue);
            return textboxValue; // Make sure to return the updated values
        });
    };
    const handleActiveorInactive = (event) => {
        const newValue = event.target.checked;
        console.log("newValue", newValue);

        setValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                isActive: newValue,
            };

            updateUserGroup(updatedValues);
            return updatedValues;
        });
    };
    const handleFixorFlex = (event) => {
        const newValue = event.target.checked;
        console.log("newValue", newValue);
        setFlex(newValue)
        setValues((prevValues) => {
            const updatedValues = {
                ...prevValues,
                isFlex: newValue,
            };

            updateUserGroup(updatedValues);
            return updatedValues;
        });
    };

    const updateUserGroup = async (updatedValue) => {
        try {
            const response = await axios.put(`${BASE_URL}/UserGroup/UpdateUserGroup/${ownerGuid}/${token}`, updatedValue);

            console.log("Access profile updated successfully", response.data);

        } catch (error) {
            console.error("Error updating access profile", error);
        }
    };

    //#endregion
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
                <div style={{
                    width: "100%",
                    border: "none",
                    margin: '3% 0'

                }} >

                    <Box
                        component="div"
                        sx={{
                            margin: '1% 2%',
                        }}
                    >
                        <Divider></Divider>
                        <TextField
                            id="Name"
                            label={t("Name")}
                            color="success"
                            focused
                            value={values?.name || ''}
                            onChange={handleTextboxChange}
                        />
                        {/*<div>*/}
                        {/*    <Typography>Charging/Parking does cost?</Typography>*/}
                        {/*    <Switch*/}
                        {/*        checked={isCharging}*/}
                        {/*        onChange={handleToggle(setIsCharging)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/* <div>
                            <Typography>Pay using the app</Typography>
                            <Switch
                                checked={canPayUsingApp}
                                onChange={handleToggle(setCanPayUsingApp)}
                            />
                        </div>
                        <div>
                            <Typography>Create invoice</Typography>
                            <Switch
                                checked={canCreateInvoice}
                                onChange={handleToggle(setCanCreateInvoice)}
                            />
                        </div>
                        <div>
                            <Typography style={{ color: 'green' }}>Business features</Typography>
                            <Typography>Generate report (Excel, CSV)</Typography>
                            <Switch
                                checked={canGenerateReport}
                                onChange={handleToggle(setCanGenerateReport)}
                            />
                        </div> */}

                        <div>
                            <Typography style={{ color: 'green' }}>Active / Inactive</Typography>
                            <Switch
                                checked={Boolean(values?.isActive)} // Ensure the default value is boolean

                                onChange={handleActiveorInactive}
                            />
                        </div>

                        {/*<div>*/}
                        {/*    <Typography style={{ color: 'green' }}>Fix / Flex</Typography>*/}
                        {/*    <Switch*/}
                        {/*        checked={Boolean(values?.isFlex)}*/}
                        {/*        onChange={handleFixorFlex}*/}
                        {/*    />*/}
                        {/*</div>{isFlex ? null : (*/}
                        {/*    <>*/}
                        {/*        <TextField*/}
                        {/*            label="Grid Tariff (Ct/kWh) *"*/}
                        {/*            value={values?.moonShineRate || ''}*/}
                        {/*            onChange={handleTextboxGridChange}*/}
                        {/*            error={gridTariffError}*/}
                        {/*            helperText={gridTariffError ? "Grid Tariff is mandatory (0 is acceptable as value)" : ""}*/}
                        {/*            style={{ marginRight: '1rem' }} // Added spacing between fields*/}
                        {/*        />*/}
                        {/*        <TextField*/}
                        {/*            label="PV Tariff (Ct/kWh)"*/}
                        {/*            value={values?.sunShineRate || ''}*/}
                        {/*            onChange={handleTextboxPvTariffChange}*/}
                        {/*        />*/}
                        {/*    </>*/}
                        {/*)}*/}

                    </Box>

                </div>
            </div>
            <div style={{ flex: 2 }}>
                <Divider></Divider>
                <div>
                    <Typography>Charging/Parking does cost?</Typography>
                    <Switch
                        checked={isCharging}
                        onChange={handleToggle(setIsCharging)}
                    />
                </div>
                {isCharging && (
                    <>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography style={{ color: 'green' }}>Fix / Flex</Typography>
                        <Switch
                            checked={Boolean(values?.isFlex)}
                            onChange={handleFixorFlex}
                        />
                        {isFlex ? null : (
                            <>
                                <TextField
                                    label="Grid Tariff (Ct/kWh) *"
                                    value={values?.moonShineRate || ''}
                                    onChange={handleTextboxGridChange}
                                    error={gridTariffError}
                                    helperText={gridTariffError ? "Grid Tariff is mandatory (0 is acceptable as value)" : ""}
                                    style={{ marginTop: '1rem', marginBottom: '1rem' }} // Added spacing between fields
                                />
                                <TextField
                                    label="PV Tariff (Ct/kWh)"
                                    value={values?.sunShineRate || ''}
                                    onChange={handleTextboxPvTariffChange}
                                    style={{ marginBottom: '1rem' }} // Added spacing between fields
                                />
                            </>
                        )}
                        </div>
                    </>
                )}


                {isFlex && isCharging && (
                    <FlexPriceUserGroup values={values} setValues={setValues} />
                )}
            </div>
        </div>
    );
};
export default UserGroupProperties;