import React, { useState, useEffect } from 'react';
import { Divider, Col, Row } from 'antd';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router'; // Import from 'react-router'
import { Box } from '@mui/material';
import axios from 'axios';
import PieChart from './Charts';
import { useTranslation } from 'react-i18next';
import { useToggleSlider } from "react-toggle-slider";
import { styled } from '@mui/system'; // Import from '@mui/system', not '@mui/material/styles'

import Paper from '@mui/material/Paper';
import { BASE_URL } from '../../Settings';
import MapContainer from './MapContainer';
import ActiveChargePoint from './ActiveChargePoint';
import ChargingorParkingValue from '../services/session';
const Review = () => {
  const { t } = useTranslation();

  // const messages = {
  //   en: {
  //     greeting: 'Hello',
  //   },
  //   de: {
  //     greeting: 'Hallo',
  //   },
  // };

  // const LanguageSwitcher = ({ onChange }) => {
  //   return (
  //     <div>
  //       <button onClick={() => onChange('en')}>English</button>
  //       <button onClick={() => onChange('de')}>German</button>
  //     </div>
  //   );
  // };

  // const [currentLanguage, setCurrentLanguage] = useState('de');

  // const handleLanguageChange = (language) => {
  //   setCurrentLanguage(language);
  // };
  let finalJson = JSON.parse(localStorage.getItem('CpLocationJson'));
  const { state } = useLocation();
  let valuesInitialState = state || finalJson;

  if (state) {
    const { partitionKey: guid, rowKey: clguid } = state;
    valuesInitialState = state;


  } else {
    valuesInitialState = finalJson;
    console.log("state is null or undefined");
  }

  const [values, setValues] = useState(valuesInitialState);
  console.log("intitial Review Json", valuesInitialState)
  const [latLng, setgoogleLatLng] = useState({ lat: valuesInitialState.lat, lng: valuesInitialState.lon });
  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
    var guid = Cookies.get("ownerGuid");

  ChargingorParkingValue.getChargingByKWhValue();
  ChargingorParkingValue.getParkingValue();
  // var updateBitValue = function(type){
  //   updateButtonState(type);
  //   updateServer(type,MeterMask);
  // }
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${valuesInitialState.lat},${valuesInitialState.lon}&embedded=true`;
  const [toggleSlider, active] = useToggleSlider({ barBackgroundColorActive: "#5faa39" });
  const [toggleValue, setToggleValue] = useState(false);
  const [DataCPlocation, setDataCPLocation] = useState(false);
  const [refreshPieChart, setRefreshPieChart] = useState(false);

  console.log("getChargingByKWhValue",  ChargingorParkingValue.getChargingByKWhValue())
  console.log("getParkingValue",  ChargingorParkingValue.getParkingValue())
  

  //#region  start update and fetch data logic
  useEffect(() => {
    const fetchToggleValue = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/CpoLocation/GetCpLocation/${guid}/${valuesInitialState.guid}/${token}`);
        const data = response.data;
        console.log("review json", response.data)
        valuesInitialState = response.data;
        setDataCPLocation(response.data);
        setToggleValue(data.available); // Assuming the API response has a property 'active' indicating the toggle value
      } catch (error) {
        console.error('Error fetching toggle value:', error);
      }
    };

    fetchToggleValue();
  }, []);

console.log("DataCPlocation",DataCPlocation)
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleRefreshPieChart = () => {
    // Logic to refresh the PieChart component...
    // For example, you can set a state to trigger a re-render of the PieChart component
    // For simplicity, let's use a boolean state called refreshPieChart
    setRefreshPieChart((prevRefresh) => !prevRefresh);
    console.log("refreshPieChart", refreshPieChart)
  };
  //#endregion
  return (
    <>
      {/* <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
      <div>
        <h1>
          <FormattedMessage id="greeting" />
        </h1>
        <LanguageSwitcher onChange={handleLanguageChange} />
      </div>
    </IntlProvider> */}

      <div style={{ width: '100%' }}>
        <Box
          component="div"
          sx={{
            backgroundColor: '#f5f5f5',
            margin: '1% 0',
            border: '1px solid #e0e0e0',
            borderRadius: '1vw',
          }}
        >

          <div style={{ padding: '0% 4vw' }}>

            <div style={{ padding: '2%' }}>


              <form>
                <Row gutter={25}>
                <Col xs={24} sm={12} md={8} lg={5}>

                    <div>
                      <h1>{t('greeting')}</h1>

                      {/* <h1>{t('Details')}</h1> */}
                      <p><strong>{t('Address')}:</strong> {DataCPlocation.street} {DataCPlocation.streetNumber}</p>
                      <p><strong>{t('Ladeplatz')}:</strong> {DataCPlocation.address2}</p>
                      <p><strong>{t('City')}:</strong> {DataCPlocation.city}</p>
                      <p><strong>{t('Zip Code')}:</strong> {DataCPlocation.zipCode}</p>
                      <p><strong>{t('Country')}:</strong> {DataCPlocation.country}</p>
                      <p><strong>{t('Latitude')}:</strong> {DataCPlocation.lat}</p>
                      <p><strong>{t('Longitude')}:</strong> {DataCPlocation.lon}</p>
                      <p><strong>{t('Power')}:</strong> {DataCPlocation.power}</p>
                      {ChargingorParkingValue.getParkingValue() ? (
                            <>
                      <p><strong>{t('MoonShine Parking Rate')}:</strong> {DataCPlocation.moonShineParkingRate} €/h</p>
                      <p><strong>{t('SunShine Parking Rate')}:</strong> {DataCPlocation.sunShineParkingRate} €/h</p>
                      </>
                      ) : ChargingorParkingValue.getChargingByKWhValue() ?(
                        <>
                      <p><strong>{t('MoonShine Rate')}:</strong> {DataCPlocation.moonShineRate} Ct/kWh</p>
                      <p><strong>{t('SunShine Rate')}:</strong> {DataCPlocation.sunShineRate} Ct/kWh</p>
                      </>
                        ) : (
                          <>
                      
                          </>
                      )}
                    </div>

                  </Col>


                  <Col xs={24} sm={12} md={8} lg={8}>
                    <ActiveChargePoint handleRefreshPieChart={handleRefreshPieChart} /> {/* Pass the prop to ActiveChargePoint */}
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={5}>
                    <PieChart refreshPieChart={refreshPieChart} /> {/* Pass the prop to PieChart */}
                  </Col>
                  
                  <Col xs={24} sm={12} md={8} lg={6}><div className="map-container-wrapper">
                    <MapContainer setLatLng={setgoogleLatLng} />
                    </div></Col>
                  
                </Row>

              </form>
              <br />
            </div>
          </div>
        </Box>
      </div>
      <Divider />
    </>
  );
};

export default Review;
