import React, { useState, useEffect, forwardRef ,useRef} from 'react';

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GOOGLE_API_KEY } from '../../Settings';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import { Divider, Col, Row, } from 'antd';
import { TextField, Box, MenuItem, FormControlLabel,Switch } from '@mui/material';
import { debounce } from 'lodash'
import MapContainer2 from './MapContainer2';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { useLocation } from 'react-router-dom';
import validationRules from "../services/validationRules";
import Validator from 'validator'; // Import the Validator object
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../node_modules/@mui/material/index';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Segmented } from 'antd';


const Profile2 = forwardRef((isMounted, ref ) => {

    var token = Cookies.get("token");
   
    var guid = Cookies.get("ownerGuid");
    console.log("guid in ownerGuid", guid)
    const [data, setData] = useState([]);
   
    const [errors, setErrors] = useState({});
    localStorage.setItem('activePage', ref);
    const [address, setAddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [customerData, setCustomerData] = useState([]);
    const [useAccountData, setUseAccountData] = useState(false);
    const [useToogleData, setuseToogleData] = useState(false);
  
    const [latLng, setLatLng] = useState({ lat: null, lng: null });


    const [autocomplete, setAutocomplete] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [predictions, setPredictions] = useState([]);


    
    const { t } = useTranslation();
    let Json = JSON.parse(localStorage.getItem('CpLocationJson'));
    const [values, setValues] = useState(Json);
    console.log("customerData", customerData)


    useEffect(() => {
        // Load Google Maps JavaScript API script dynamically
        const googleScript = document.createElement('script');
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAAHm7DEwE1mM01dLkR8vzJLq4QcSHMdRM&libraries=places`;
        googleScript.onload = () => {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            setAutocomplete(autocompleteService);
        };
        document.body.appendChild(googleScript);

        return () => {
            // Clean up script tag on unmount
            document.body.removeChild(googleScript);
        };
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (autocomplete && value) {
            console.log("autocomplete",autocomplete)
            autocomplete.getPlacePredictions({ input: value }, (predictions, status) => {
                if (status === 'OK') {
                    setPredictions(predictions);
                } else {
                    setPredictions([]);
                }
            });
        } else {
            setPredictions([]);
        }
    };

  const handlePredictionClick = (prediction) => {
    setInputValue(prediction.description);
    setPredictions([]);

    const request = {
        placeId: prediction.place_id,
        fields: ['address_components']
    };

    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails(request, (placeResult, status) => {
        if (status === 'OK') {
            const extractedValues = extractAddressComponents(placeResult);
            setValues({
                ...values,
                ...extractedValues
            });
        }
    });
      
    setUseAccountData(true);
};

const extractAddressComponents = (placeResult) => {
    if (!placeResult || !placeResult.address_components) return {};

    let street = '';
    let postcode = '';
    let city = '';
    let streetNum = '';
    let countrySearch = '';

    const addressComponents = placeResult.address_components;
    for (let i = 0; i < addressComponents.length; i++) {
        const component = addressComponents[i];
        if (component.types.includes('postal_code')) {
            postcode = component.long_name;
        } else if (component.types.includes('locality')) {
            city = component.long_name;
        } else if (component.types.includes('street_number')) {
            streetNum = component.long_name;
        } else if (component.types.includes('country')) {
            countrySearch = component.long_name;
        } else if (component.types.includes('route')) {
            street = component.long_name;
        }
    }

    return {
        street: street,
        zipCode: postcode,
        city: city,
        streetNumber: streetNum,
        country: countrySearch
        // You can add other fields here if needed
    };
};

    useEffect(() => {
        const api = axios.create({
            baseURL: BASE_URL
        });

        const fetchData = async () => {
            try {
                const response = await api.get(`/Customer/GetCustomer/${guid}/${token}`);
                // Handle the response here
                console.log("Provider in profile 2", response.data);
               
            
                setCustomerData(response.data);
                setValues({
                    ...values,
                    street: response.data.street,
                    zipCode: response.data.zipCode,
                    city: response.data.city,
                    streetNumber: response.data.streetNumber,
                    country:response.data.country
                   
                });
               
                
                setAddress(true);
               
                console.log(data);
            } catch (error) {
                // Handle any errors that occur during the request
                console.error(error);
            }
        };

        if (useToogleData) {
            fetchData();

            // Replace form values with customer data


        } else { setAddress(false);  }
    }, [useToogleData, latLng,data]);


    const handleToggle = () => {
        setuseToogleData(!useToogleData);


        console.log("Toggle", useToogleData)
        //if (useAccountData == true) {

        //    setAddress(true);
        //}
        if (useToogleData == true) {
            setValues(Json);
          
            //setCustomerData({
            //    streetNumber: '',
            //    // other fields to clear if needed
            //});
        

        }
        else {
            console.log("Handle Toogle Not working");
        }
        // You can access the value of the switch using event.target.checked
       
       

    };



    const handleStreetSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            const selectedAddress = results[0];
            setSelectedAddress(selectedAddress);
            updateFields(selectedAddress);
        } catch (error) {
            console.error('Error fetching location', error);
        }
    };
    const inputRef = useRef();


    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            console.log("place", place.formatted_address);
            console.log("place details", place);
            setAddress(true);
            updateFields(place.address_components)
        }

    };

    const handleChange2 = (value) => {
        setAddress(value);
    };

    const updateFields = (addressComponents) => {


        let street = '';
        let postcode = '';
        let city = '';
        let streetNum = '';
        let countrySearch = '';
        // Loop through address components to find relevant fields
        addressComponents.forEach(component => {
            const types = component.types;
            if (types.includes('route')) {
                street = component.long_name;
            } else if (types.includes('postal_code')) {
                postcode = component.long_name;
            } else if (types.includes('locality')) {
                city = component.long_name;
            } else if (types.includes('street_number')) {
                streetNum = component.long_name;
            } else if (types.includes('country')) {
                countrySearch = component.long_name;
            }
        });
        setValues({
            ...values,
            street: street,
            zipCode: postcode,
            city: city,
            streetNumber: streetNum,
            country: countrySearch
            // You can add other fields here if needed
        });

        // Optionally, you can update the selectedAddress state with the formatted address
        // setSelectedAddress({...selectedAddress, formatted_address: formattedAddress});
    };
    
    console.log("latLngnpm", latLng)
    
    // Debounced update function
    

    const handleMapChange = (newLatLng) => {
        setLatLng(newLatLng);
    };


    //#region  google map value and bind this value in textfield
  
    const [showMap, setShowMap] = useState(false);


  

    //#endregion
    // Server update function
   
    function updateServer(filds) {
        //UpdateFields(fields);
        // Perform the server update here using the fields object
        let wizardStep = 4;
        console.log("latLngnpm", latLng)
        const updatedValues = {
            ...values,
            lat:  latLng.lat ,
            lon: latLng.lng ,
        };

        console.log("new", updatedValues)
        fetch(`${BASE_URL}/CpoLocation/UpdateCLocation/${guid}/${Json.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),

        }).then((response) => {
            if (response.ok) {

                console.log('Updated at:', response);
                localStorage.removeItem('CpLocationJson');
                localStorage.setItem('CpLocationJson', JSON.stringify(updatedValues));
                Json = JSON.parse(localStorage.getItem('CpLocationJson'));

                console.log("after update", Json)
                /*refresh();*/
                // Server update was successful
            } else {
                console.error('There was an error!');
                // Handle error response from the server
            }
        });
    }


    // End validate textfields code
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleChange = (input) => (e) => {
        const value = e.target.value || ''; // Set an empty string if the value is undefined
        console.log("handlechange", value);
        if (useToogleData) {
            // If toggle is true, update only specific fields from customerData and the field based on the input parameter
            setValues(prevValues => ({
                ...prevValues,
                street: customerData.street || '', // Update street field from customerData if available
                streetNumber: customerData.streetNumber || '', // Update streetNumber field from customerData if available
                zipCode: customerData.zipCode || '', // Update zipCode field from customerData if available
                city: customerData.city || '', // Update city field from customerData if available
                country: customerData.country || '', // Update country field from customerData if available
                [input]: value // Update the field specified by the input parameter
            }));
        } else {
            // If toggle is false, update the field based on the input parameter
            setValues({ ...values, [input]: value });
        }

        /*setValues({ ...values, [input]: value });*/
       
        /* setgoogleLatLng(latLng);*/
        if (!value) {
            setErrors({ ...errors, [input]: t('This field is required') });

        } else {
            setErrors({ ...errors, [input]: '' });
        }


        //setErrors({ ...errors, [input]: '' }); // Clear the error for the current field
        // setRowKey({ ...values, [input]: value  }); // Update the rowKey state with the new value

    };


   


    const validateForm = (callback) => {
        let isValid = true;
        const newErrors = {};

        if ( !values.zipCode) {
            newErrors.zipCode = t('Please enter the zip code');
            isValid = false;
        }

        if ( !values.city) {
            newErrors.city = t('Please enter the city');
            isValid = false;
        }

        if ( !values.street) {
            newErrors.street = t('Please enter the street');
            isValid = false;
        }

        if ( !values.streetNumber) {
            newErrors.streetNumber = t('Please enter the house number');
            isValid = false;
        }

        if ( !values.country) {
            newErrors.country = t('Please enter the country');
            isValid = false;
        }
        if (values?.address2?.length > 16) {
            newErrors.address2 = t('Please enter name of your station, as it is displayed to users, e.g. „Ella-Power„Max 16 characters');
            isValid = false;
        }
        if (!values.address2) {
            newErrors.address2 = t('Please enter name of your station, as it is displayed to users, e.g. „Ella-Power');
            isValid = false;
        }
        if (!useToogleData && !inputValue) {
          
            newErrors.address = t('Please enter full address of your station');
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
       
    };

   
    const onNext = (callback) => {
        // Add your onNext logic here
        console.log("hello wizard 23 ")
        // Check if all textfield values are provided
        
       
            // Submit data
           
            updateServer();
       
        callback();
    };
   
    React.useImperativeHandle(ref, () => ({
        onNext: onNext,
        validateForm: validateForm
    }));
    const [disabledInput, setDisabledInput] = useState(false);

    return (


            <div ref={ref} style={{ width: '100%', margin: '1% 0', }}>
                
                    <Row>

                        <Col xs={24} md={25}>
                            <div style={{ padding: '0% 1vw' }}>

                                <div >
                                    


                                    <form>
                                        <div style={{ width: "100%" }}>
                                        
                                            
                                       
                                            <Row gutter={[10, 25]}>
                                                <Col xs={24} md={24}>

                                                    <TextField
                                                        id="Address2"
                                                        label={
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {t('Ladeplatz')}
                                                                <Popover
                                                                    placement="rightTop"
                                                                    title={t("Charge location")}
                                                                    arrowPointAtCenter
                                                                    content=

                                                                    {t('Name of your station, as it is displayed to users, e.g. „Ella-Power“ (max. 16 digits)')}

                                                                    trigger="hover" // Show popover on hover
                                                                >
                                                                    <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                                                                </Popover>
                                                            </div>
                                                        }
                                                        color="success"
                                                        value={values?.address2 || ''}
                                                        onChange={handleChange('address2')}

                                                        error={!!errors.address2}
                                                        helperText={errors.address2}
                                                        fullWidth
                                                        focused
                                                    />



                                            </Col>
                                    </Row> 
                                    <Divider />
                                    <Row gutter={[10, 25]}>
                                        <Col xs={24} md={12}>
                                            <FormControlLabel
                                                control={<Switch checked={useToogleData} onChange={handleToggle} />}
                                                label={t('Same address as contact details')}
                                            />
                                        </Col>

                                    </Row>
                                    {/*{console.log("test", useToogleData)}*/}
                                    {/*{!useToogleData && (*/}

                                    {/*        <>*/}
                                    {/*            <Divider />*/}
                                    {/*                <Row>*/}
                                    {/*                <Col xs={24} md={24}>*/}


                                    {/*                <LoadScript*/}
                                    {/*                    googleMapsApiKey="AIzaSyAAHm7DEwE1mM01dLkR8vzJLq4QcSHMdRM"*/}
                                    {/*                        libraries={["places"]}*/}
                                    {/*                    >*/}
                                    {/*                    <StandaloneSearchBox*/}
                                    {/*                        onLoad={ref => (inputRef.current = ref)}*/}
                                    {/*                        onPlacesChanged={handlePlaceChanged}*/}

                                    {/*                    ><TextField*/}
                                    {/*                            focused*/}
                                    {/*                            label={t("Address Search")}*/}
                                    {/*                            fullWidth*/}
                                                          
                                    {/*                                error={!!errors.address}*/}
                                    {/*                                helperText={errors.address}*/}
                                    {/*                            placeholder="Enter Location" />*/}
                                                           
                                    {/*                    </StandaloneSearchBox>*/}



                                    {/*                </LoadScript>*/}

                                                        
                                    {/*                </Col>*/}
                                    {/*        </Row>*/}
                                            
                                    {/*        </>)}*/}


                                    {!useToogleData && (
                                        <>
                                          <Divider />
                                        <div>
                                            <TextField
                                                    type="text"
                                                    label={t('Address Search')}
                                                    focused
                                                placeholder="Enter your address"
                                                value={inputValue}
                                                    onChange={handleInputChange}
                                                    error={!!errors.address}
                                                    helperText={errors.address}
                                                fullWidth
                                            />
                                            <ul>
                                                {predictions.map((prediction) => (
                                                    <li key={prediction.place_id} onClick={() => handlePredictionClick(prediction)}>
                                                        {prediction.description}
                                                     
                                                        {console.log("test",prediction.zipCode)}
                                                    </li>
                                                ))}
                                            </ul>
                                            </div>
                                        </>)}



                                     

                                                

                                          

                                    {(address == true || inputValue) && (
                                            <>
                                                <Divider />
                                                <Row gutter={[10, 25]}>
                                                    <Col xs={24} md={12} >
                                                        <TextField
                                                            id="street"
                                                            fullWidth
                                                            label={t('street')}
                                                        color="success"
                                                        value={useToogleData ? (customerData?.street || '') : (values?.street || '')}
                                                        disabled={useToogleData ?true:false}
                                                            onChange={handleChange('street')}
                                                            error={!!errors.street}
                                                            helperText={errors.street}
                                                            focused

                                                        // error={!!errors.city} // Add error prop if there is an error
                                                        // helperText={errors.city} // Display the error message
                                                        />
                                                    </Col>

                                                    <Col xs={24} md={12}>
                                                        <TextField
                                                            id="StreetNumber"
                                                            label={t('streetNumber')}
                                                        color="success"
                                                            value={useToogleData ? (customerData?.streetNumber || '') : (values?.streetNumber || '')}
                                                            onChange={handleChange('streetNumber')}
                                                        disabled={useToogleData ? true : false}
                                                            focused
                                                            fullWidth
                                                            error={!!errors.streetNumber}
                                                            helperText={errors.streetNumber}
                                                        // error={!!errors.streetNumber} // Add error prop if there is an error
                                                        // helperText={errors.streetNumber} // Display the error message
                                                        />
                                                    </Col>
                                                </Row>
                                             <Divider />
                                            <Row gutter={[10, 25]}>
                                            <Col xs={24} md={8} >
                                                <TextField
                                                    id="ZipCode"
                                                    label={t('Zip Code')}
                                                    color="success"
                                                        
                                                        value={useToogleData ? (customerData?.zipCode || '') : (values?.zipCode || '')}

                                                    onChange={handleChange('zipCode')}
                                                        disabled={useToogleData ? true : false}
                                                    focused
                                                    fullWidth
                                                    error={!!errors.zipCode}
                                                    helperText={errors.zipCode} // Add error prop if the length is greater than 5

                                                // error={!!errors.zipCode} // Add error prop if there is an error
                                                // helperText={errors.zipCode} // Display the error message
                                                />
                                            </Col>
                                            <Col xs={24} md={8} >
                                                <TextField
                                                    id="City"
                                                    fullWidth
                                                    label={t('City')}
                                                        color="success"
                                                        value={useToogleData ? (customerData?.city || '') : (values?.city || '')}

                                                        disabled={useToogleData ? true : false}
                                                    onChange={handleChange('city')}
                                                    error={!!errors.city}
                                                    helperText={errors.city}
                                                    focused

                                                // error={!!errors.city} // Add error prop if there is an error
                                                // helperText={errors.city} // Display the error message
                                                />
                                            </Col>
                                            <Col xs={24} md={8} >
                                                <TextField
                                                    id="Country"
                                                    fullWidth
                                                    label={t('Country')}
                                                        color="success"
                                                        value={useToogleData ? (customerData?.country || '') : (values?.country || '')}

                                                        disabled={useToogleData ? true : false}
                                                    onChange={handleChange('country')}
                                                    error={!!errors.country}
                                                    helperText={errors.country}
                                                    focused

                                                // error={!!errors.city} // Add error prop if there is an error
                                                // helperText={errors.city} // Display the error message
                                                />
                                            </Col>
                                            </Row>
                                        <Divider/>
                                                <Row>
                                                <div className="map-container-wrapper" style={{ position: 'relative', width: '100%', paddingBottom: '40%' }}>
                                                    <MapContainer2 setLatLng={handleMapChange} zipcode={values.zipCode} streetaddress={values.street} streetnumber={values.streetNumber} />
                                                    </div>
                                                </Row>
                                         
                                                    </>
                                            
                                                    ) }
                                        







                                               

                                           

                                        </div>





                                    </form>
                                </div>
                            </div>
                        </Col>

                    </Row>
            </div>

    );
});

export default Profile2;
