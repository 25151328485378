import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input, InputNumber, Divider, Col, Row } from 'antd';
import { Box } from '@mui/material';
import '../../../src/css/baseDataMenu.css';
import AccountMenu from '../Account/AccountMenu';
import { Height } from '../../../node_modules/@material-ui/icons/index';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';

import BackButton from '../Elements/BackButton';

const UserGroupsMenu = ({ setAddUser  ,userGuid1}) => {
    console.log("userguid1",userGuid1)

    const [selectedLink, setSelectedLink] = useState('UserGroupProperties');
   
    const { t } = useTranslation();
    const links = [
        {
            page: t('Properties'),
            link: 'UserGroupProperties',
            enabled: true
        },
        {
            page: t('Opening Hours'),
            link: 'UserGroupProfiles',
            enabled: true
        },
        {
            page: t('Members'),
            link: 'UserGroupMembers',
            enabled: true
        },
        {
            page: t('ChargingStations'),
            link: 'UserGroupChargingStations',
            enabled: true
        },

    ];

    const handleLinkClick = (link) => {
        setSelectedLink(link);
    };
    const handleBackClick = () => {
        setAddUser(false);
    };

    return (
        <div style={{ width: '100%', border: "none" }}>
            <Row >
               
                <Col xs={24} md={19} >
                    <div className="top-menu" style={{
                        width: "100%",
                        border: "none",
                        display: "flex",
                   
                        flexDirection: "row",
                        paddingTop: "12px"
                        
                    }}>
                        {/* Back Button */}
                        
                         <Row>
                             <BackButton
                                navigateLink={"/UserGroups"} setAddUser={setAddUser}></BackButton></Row>
                    

                        {links.map(({ link, page, enabled }, index) => (
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                    width: "100%",
                                }}
                            >
                                <button
                                    className={enabled ? (selectedLink === link ? 'button-link2 active' : 'button-link2') : "button-link2 disabled"}
                                    key={link} onClick={() => handleLinkClick(link)}>
                                    {page}
                                </button>
                            </div>
                        ))}
                    </div>
                    {selectedLink && (
                        <DynamicComponentLoader componentName={selectedLink} userGuid1={userGuid1} />
                    )}

                </Col>

            </Row>
        </div>
    );
};

const DynamicComponentLoader = ({ componentName ,userGuid1}) => {
    const ComponentToLoad = React.lazy(() =>
        import(`./${componentName}`)
    );

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <ComponentToLoad userGuid1={userGuid1} />
        </React.Suspense>
    );
};

export default UserGroupsMenu;
