import React, { useState,useRef } from 'react';


import { StandaloneSearchBox,LoadScript } from '@react-google-maps/api';

const AddressForm = () => {
    const inputRef = useRef();
    

    const handlePlaceChanged =  () => {
        const [place] = inputRef.current.getPlaces();
        if (place) {
            console.log("place", place.formatted_address);
            console.log("place details", place);
        }
       
    };

    

   
        // Use 'renderTarget' prop to render a component/target we want the suggestions to popover

        return (
            <LoadScript
                googleMapsApiKey="AIzaSyAAHm7DEwE1mM01dLkR8vzJLq4QcSHMdRM"
                libraries={["places"] }
            >
                <StandaloneSearchBox
                    onLoad={ref => (inputRef.current = ref)}
                    onPlacesChanged={handlePlaceChanged}

                ><input 
                        type="text"
                        classeName="form-control"
                        placeholder="Enter Location" />

                </StandaloneSearchBox>
                
                     
              
            </LoadScript>
        )
    
}

export default AddressForm;