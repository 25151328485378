import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Cookies from 'js-cookie';
import { BASE_URL } from './Settings';
import { useTranslation } from 'react-i18next'; 
import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
    Divider
} from 'antd';
import { useNavigate } from '../node_modules/react-router-dom/dist/index';

const LocationEditt = () => {
    const { state } = useLocation()
    const navigate = useNavigate()
    const ocppInitialValue = (type) => {
        const value = (type & (0x2 | 0x4 | 0x8 | 0x40)) > 0;
       
        return value;
    }
    const { t } = useTranslation();

    const { partitionKey: guid, rowKey: clguid } = state
    const hoverStyle = {
        width: '100px',
        height: '100px'
    };

    const [is_ocpp, setIs_ocpp] = useState(ocppInitialValue(state?.type))
    const [is_Available, setIsAvailable] = useState(state?.isAvailable)
    const [data, setData] = useState([]);
    var token = Cookies.get("token");

    useEffect(() => {

        axios.get(`${BASE_URL}/ChargePoint/chargePointsLocation/${guid}/${clguid}`)
            .then(function (response) {
                // handle success

                setData(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, [])
    const handleCheckboxChange = (e) => {
        // e.target.checked contains the updated value of the Checkbox
        setIs_ocpp(e.target.checked);
    };
    const onChangeIsAvailable = (e) => {
        // e.target.checked contains the updated value of the Checkbox
        setIsAvailable(e.target.checked);
    };

    const defaultValues = {
        ...state, ocpp: ocppInitialValue(state?.type)
    }

    const onFinish = async formValues => {
      let  formData = new FormData();    //formdata object
        console.log({ formValues })
        //formData.append('Street',"Promenadestr.");
        //formData.append('StreetNumber', "7");
        //formData.append('Address2', "UZB-02");
        //formData.append('Lat', 40.67577324587506);
        //formData.append('Lon', 72.56839789596232);
        //formData.append('ZipCode', 85521 );
        //formData.append('City', "Xo?jaobod");
        //formData.append('isOCPP',"false" );   //append the values with key, value pair
        formData.append('Street', formValues.street);
        formData.append('StreetNumber', formValues.streetNumber);
        formData.append('Address2', formValues.address2);
        formData.append('Lat', formValues.lat);
        formData.append('Lon', formValues.lon);
        formData.append('ZipCode', formValues.zipCode);
        formData.append('City', formValues.city);
        formData.append('isOCPP', formValues.ocpp);
        console.log(formData)

         
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        console.log("update:",token)
        await axios.put(`${BASE_URL}/CpoLocation/UpdateCpLocation/${guid}/${clguid}/${token}`, formData, config).then((res) => console.log("yourData", res.data)).catch(err => console.log(err))
    };

    return (
        <> <div style={{
            padding: "0% 2%"
        }}>
            <h1 style={{
                fontSize: "3.5rem",
                fontWeight: 300,
                lineHeight: 1.2
            }} >Ladestation</h1>
            <Form
                layout="vertical"
                onFinish={onFinish}
                initialValues={defaultValues}

            >


                <Row justify="start">
                    <Col xs={{ span: 3 }} lg={{ span: 3 }}>
                        <Form.Item
                            name="lat"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Latitude is required!',
                                },
                            ]}
                            label="Latitude">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                            name="lon"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Longitude is required!'
                                },
                            ]}
                            label="longitude">
                            <Input />
                        </Form.Item>
                    </Col>
                    {/*<Col xs={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}>*/}
                    {/*    <Form.Item*/}
                    {/*        name="google-maps"*/}
                    {/*        rules={[*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: 'Please input the google-maps is required!'*/}
                    {/*            },*/}
                    {/*        ]}*/}
                    {/*        label="Google Maps">*/}
                    {/*        <Input />*/}

                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                </Row>
                <Row>
                    <Col xs={{ span: 3 }} lg={{ span: 3 }}>
                        <Form.Item
                            name="zipCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Postleitzahl is required!'
                                },
                            ]}
                            label="Postleitzahl">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }}>
                        <Form.Item
                            name="city"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Ort is required!'
                                },
                            ]}
                            label="Ort">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, }} lg={{ span: 10, }}>
                        <Form.Item
                            name="street"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Strasse is required!'
                                },
                            ]}
                            label="Straße">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                            name="streetNumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Hausnummer is required!'
                                },
                            ]}
                            label="Hausnummer">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, }} lg={{ span: 10, }}>
                        <Form.Item
                            name="address2"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the name is required!'
                                },
                            ]}
                            label="Name">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item label="Ladestation OCPP?" name="ocpp" valuePropName="checked">
                            <Checkbox onChange={handleCheckboxChange}></Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item >
                    <Button type="primary" htmlType="submit" className="login-form-button">{t('Save')}</Button>
                </Form.Item>
            </Form>
            <Divider />
            <div style={{ display: !is_ocpp && "none" }}>
                <h2 style={{
                    marginBottom: "0.5rem",
                    fontSize: "2rem",
                    fontWeight: 500,
                    lineHeight: 1.2,
                }} >OCPP Settings</h2>
                <sup style={{
                    color: "grey",
                }}  >Zum Kopieren der Werte einfach drauf klicken</sup>
                <Form
                    layout="vertical"
                    style={{ marginTop: "10px" }}
                >
                    <Col xs={{ span: 10 }} lg={{ span: 10 }}>
                        <Form.Item
                            name="name"

                            label="Server URL">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 10 }}>
                        <Form.Item
                            name="name"

                            label="ChargePoint-ID">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 10 }} lg={{ span: 10 }}>
                        <Form.Item
                            name="name"

                            label="Authorisierungs-Schlüssel">
                            <Input type="password" disabled />
                        </Form.Item>
                    </Col>
                </Form>
            </div>

            <Divider />
            <h2 style={{
                marginBottom: "0.5rem",
                fontSize: "2rem",
                fontWeight: 500,
                lineHeight: 1.2,
            }} >Ladepunkte</h2>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>

                            <TableCell >Foto</TableCell>
                            <TableCell >Ladepunkt Beschreibung</TableCell>
                            <TableCell >Strom-Tarif (Ct/kWh)</TableCell>
                            <TableCell >Power</TableCell>
                            <TableCell >Verfügbar</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell  ><img style={{ width: "30px", height: "30px" }}
                                    onMouseOver={(e) => {
                                        e.target.style.width = hoverStyle.width;
                                        e.target.style.height = hoverStyle.height;

                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.width = "30px";
                                        e.target.style.height = "30px";

                                    }}
                                    src={row.thumbnailUrl}></img></TableCell>
                                <TableCell >{row.name}</TableCell>
                                <TableCell >{row.moonShineRate}</TableCell>
                                <TableCell >{row.power} kW</TableCell>
                                <TableCell ><Checkbox value={is_Available} onChange={onChangeIsAvailable} disabled></Checkbox></TableCell>

                                <TableCell ><Button onClick={() => navigate("/ChargePoint", { state: row })}>{t('Edit')}</Button></TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        </>
    );
};
export default () => <LocationEditt />;