import React, { useState, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Box, Typography, IconButton } from '@mui/material';
import { Divider } from 'antd';
import axios from "axios";
import { BASE_URL } from "../../Settings";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SetGetUserGroupName from '../services/session';

const UserGroupMembers = (userGuid1) => {
    var token = Cookies.get("token");
    var ownerGuid = Cookies.get("ownerGuid");
    const { t } = useTranslation();
    const [values, setValues] = useState([]);
    const { state } = useLocation();

    useEffect(() => {
        const api = axios.create({ baseURL: BASE_URL });

        const fetchData = async () => {
            try {
                let userGroupGuid = state?.guid || userGuid1.userGuid1;
                const response = await api.get(`UserGroup/Users/${ownerGuid}/${userGroupGuid}/${token}`);

                if (response.data) {
                    setValues(response.data);
                } else {
                    console.log("No user groups found");
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [userGuid1, state, ownerGuid, token]);

    const handleRemoveGroupMember = async (value) => {
        try {
            const response = await axios.delete(`${BASE_URL}/UserGroup/RemoveGroupMember/${ownerGuid}/${value}/${token}`);

            if (response.data) {
                setValues(prevValues => prevValues.filter(item => item.id !== value.id));
            } else {
                console.log('Deletion failed');
            }
        } catch (error) {
            console.error("Error removing group member", error);
        }
    };

    return (
        <div style={{ width: "100%", border: "none", margin: '3% 0' }}>
            <Box component="div" sx={{ margin: '1% 2%' }}>
                <Divider />
                <TextField
                    id="Name"
                    label={t("Name")}
                    color="success"
                    focused
                    disabled
                    value={SetGetUserGroupName.getUserGroupsName()}
                    sx={{ marginBottom: '20px' }}
                />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead sx={{ backgroundColor: "#5faa39", border: "1px solid white", borderRadius: "1%" }}>
                            <TableRow>
                                <TableCell sx={{ width: '40%', color: "white", borderRight: "1px solid white" }}>Email</TableCell>
                                <TableCell sx={{ width: '30%', color: "white", borderRight: "1px solid white" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: "1px solid white", borderRadius: "1%" }}>
                            {values.map((value, index) => (
                                <TableRow key={value.id}>
                                    <TableCell sx={{ borderRight: "1px solid white", width: '20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                        {value.email}
                                    </TableCell>
                                    <TableCell sx={{ borderRight: "1px solid white", width: '20%', backgroundColor: index % 2 === 0 ? 'rgba(95, 170, 57, 0.3)' : 'rgba(185, 234, 159, 0.2)' }}>
                                        <Typography
                                            variant="body2"
                                            color="error"
                                            sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                                            onClick={() => handleRemoveGroupMember(value.id)}
                                        >
											{ t('remove')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
};

export default UserGroupMembers;
