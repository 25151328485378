import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



import {
    Button,
    Checkbox,
    Form,
    Input,
    InputNumber,
   
  
    Divider
} from 'antd';
import { useNavigate } from '../node_modules/react-router-dom/dist/index';
import { Upload, message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';





const ChargePoint = () => {
    const { state } = useLocation()
    
    const navigate = useNavigate()
    const AdHocInitialValue = (type) => {
        const value = (type & (0x10)) > 0;
        
        return value;
    }
    const ParkingInitialValue = (type) => {
        const value = (type & (0x100)) > 0;
        
        return value;
    }
   
    const defaultValues = {
        ...state, AdHocOnly: AdHocInitialValue(state?.type), ParkingOnly: ParkingInitialValue(state?.type)
    }
    const [is_AdHoc, setIsAdHoc] = useState((state?.type))
    const [is_Parking, setIsParking] = useState((state?.type))
    const [is_Available, setIsAvailable] = useState(state?.isAvailable)
    const [checked, setChecked] = React.useState(true);
   
    const fileUploadRef = React.useRef(null);

    //const [selectedFile, setSelectedFile] = useState(null);
    //const handleFileChange = (event) => {
    //    setSelectedFile(event.target.files[0]);
    //}
    const handleImageContainerClick = () => {
        fileUploadRef.current.click();
    };

    const handleFileChange = (event) => {
        if (event.target.value !== '') {
            const files = event.target.files;
            const length = files.length;
            for (let i = 0; i < length; i++) {
                setFile(files[i]);
            }
            event.target.value = '';
        }
    };
    const handleSheetDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files.length) {
            const files = event.dataTransfer.files;
            const length = files.length;
            for (let i = 0; i < length; i++) {
                setFile(files[i]);
            }
        }
    };

    const setFile = (file) => {
        const fileName = file.name.split('.')[0];
        const fileType = file.type;
        const reader = new FileReader();
        reader.id = new Date() * 1;
        reader.addEventListener('loadend', (event) => {
            if (file.type.indexOf('image') > -1) {
                setInputImageData(event.target.result);
            }
        });
        reader.readAsDataURL(file);
    };
    const setInputImageData = (bin) => {
        const img = new Image();
        img.onload = () => {
            const imgbase64 = bin.split(',')[1];
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const base64data = canvas.toDataURL('image/jpeg', 0.75
           );
            document.getElementById('addAssets').setAttribute('src', base64data);
            document.getElementById('fileDataInput').setAttribute('data-mimetype', 'image/jpeg');
            document.getElementById('fileDataInput').value = base64data.split(',')[1];
            document.getElementById('photoSvg').style.display = 'none';
            document.getElementById('uploadImage').style.backgroundImage = `url(${base64data})`;
        };
        img.src = bin;
    };
       
    const onChangeAdHocValue = (e) => {
        // e.target.checked contains the updated value of the Checkbox
        setIsAdHoc(e.target.checked);
    };
    const onChangeParkingValue = (e) => {
        // e.target.checked contains the updated value of the Checkbox
        setIsParking(e.target.checked);
    };
    const onChangeIsAvailable = (e) => {
        // e.target.checked contains the updated value of the Checkbox
        setIsAvailable(e.target.checked);
    };
    

   
    return (
         <> <div style={{
            padding: "0% 2%"
        }}>
            <h1 style={{
                fontSize: "3.5rem",
                fontWeight: 300,
                lineHeight: 1.2
            }} >Ladepunkt</h1>
            <Form
                layout="vertical"
                
                initialValues={defaultValues}

            >


                <Row justify="start">
                    <Col xs={{ span: 11}} lg={{ span: 11 }}>
                        <Form.Item
                            name="cpName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Latitude is required!',
                                },
                            ]}
                            label="Ladepunkt Beschreibung">
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }}>
                        <Form.Item label="Station is reservable" name="AdHocOnly" valuePropName="checked">
                            <Checkbox onChange={onChangeAdHocValue}></Checkbox>
                        </Form.Item>
                    </Col>
                   
                </Row>
                <Row>
                    <Col xs={{ span: 3 }} lg={{ span: 3 }}>
                        <Form.Item
                            
                            name="moonShineRate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Latitude is required!',
                                },
                            ]}
                            label="Netz/Nacht-Tarif (Ct)">
                            <Input style={{ backgroundColor: "#C0E0FF" } } />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                            name="sunShineRate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Latitude is required!',
                                },
                            ]}
                            label="Tages/PV-Tarif (Ct)">
                            <Input style={{ backgroundColor: "#C0FFC0" }} />
                        </Form.Item>
                        
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item
                            name="power"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input the Latitude is required!',
                                },
                            ]}
                            label="Leistung (kW)">
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item label="Parken" name="ParkingOnly" valuePropName="checked">
                            <Checkbox onChange={onChangeParkingValue}></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        <Form.Item label="Aktiv" name="isAvailable" valuePropName="checked">
                            <Checkbox onChange={onChangeIsAvailable}></Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, }} lg={{ span: 10, }}>
                       
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                       
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, }} lg={{ span: 10, }}>
                        
                    </Col>
                    <Col xs={{ span: 3, offset: 1 }} lg={{ span: 3, offset: 1 }}>
                        
                    </Col>
                </Row>
               
            </Form>
            <Divider />
            
               
            <sup>Ladepunkt Foto (klicken für Upload)</sup>
            
            {/*<form onSubmit={handleSubmit} >*/}
            {/*    <input type="file" onChange={handleFileChange} />*/}
            {/*    <button type="submit">Upload Photo</button>*/}
            {/*</form>*/}
            <div>
                <button onClick={handleImageContainerClick}>Click to Upload</button>
                <input
                    type="file"
                    id="fileUpload"
                    ref={fileUploadRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <div
                    id="sheet-1"
                    onDrop={handleSheetDrop}
                    onDragOver={(event) => event.preventDefault()}
                >
                    Drop Zone
                </div>
                <img id="addAssets" alt="Uploaded Image" />
                <input id="fileDataInput" name="fileData" type="hidden"/>
                <input id="fileDataInput" name="fileData" type="hidden"/>
                <div id="photoSvg">Photo SVG</div>
                <div id="uploadImage" />
            </div>
           
            
        </div>
        
        </> 
);
            
               
   
}



export default ChargePoint;