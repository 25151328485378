import React, { useState, useEffect } from 'react';
import { Divider, Col, Row } from 'antd';
import { json, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Popover, Segmented } from 'antd';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
const StateButton = ({ onClickCallback, isDisabled, isActive, buttonTextPlaceholder, popupContentPlaceholder }) => {
    const { t } = useTranslation();
    return (
        <>
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} md={24}>
                    <Button
                        onClick={onClickCallback}
                        disabled={isDisabled}
                        style={{
                            backgroundColor: isActive ? '#93ba65' : '#a4a4a4',
                            color: isActive ? 'white' : 'black',

                            height: 30,
                            width: 350,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between', // Align text to the left
                            paddingLeft: '10px', // Add padding for text
                            paddingRight: isActive ? 'px' : '10px', // Add padding if active for icon
                        }}
                    >
                        {t(buttonTextPlaceholder)}
                        <div>{isActive && <CheckOutlined style={{ fontSize: '20px', marginLeft: '50px' }} />}
                            {!isActive &&
                                <Popover
                                    placement="rightTop"
                                    title={t(buttonTextPlaceholder)}
                                    arrowPointAtCenter
                                    content={t(popupContentPlaceholder)}
                                    trigger="hover"
                                >
                                    <InfoCircleOutlined style={{
                                        color: isActive ? 'white' : "#93ba65",
                                        fontSize: '15px'
                                    }} />
                                </Popover>}</div>
                    </Button>
                </Col>

            </Row>
        </>
    );
}
export default StateButton;