import React, { useState, useEffect, useContext } from 'react';
import { FormControl, InputLabel, makeStyles, createStyles, Select, Chip, OutlinedInput, Box, MenuItem } from '@material-ui/core';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../Settings';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PlugType from "../services/PlugType";
import ChargeStationDataContext from './ChargeStationDataContext';

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            display: 'block',
        },
        select: {
            minWidth: 200,
        },
        greenUnderline: {
            '&:after': {
                borderBottomColor: '#4CAF50',
            },
        },
    })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selectedPlugs, theme) {
    return {
        fontWeight:
            selectedPlugs.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const ChargeStationModel = () => {
    const classes = useStyles();
    const token = Cookies.get("token");
    const ownerGuid = Cookies.get("ownerGuid");
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    console.log("chargeStationData in ModelchargeStationData in Model", chargeStationData)
    const { t } = useTranslation();
    const [manufacturers, setManufacturers] = useState([]);
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedManufacturerGUID, setSelectedManufacturerGUID] = useState('');
    const [selectedModelGUID, setSelectedModelGUID] = useState('');
    const [models, setModels] = useState([]);
    const [model, setModel] = useState('');
    const [plugs, setPlugs] = useState([]);
    const [selectedPlugs, setSelectedPlugs] = useState([]);
    const [selectedPlugsBitMask, setSelectedPlugsBitMask] = useState('');
    const [errors, setErrors] = useState('');
    const OCPPJson = chargeStationData;
    const api = axios.create({
        baseURL: BASE_URL
    });
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedPlugs(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!chargeStationData.guid) return;
            try {
                const manufacturerResponse = await api.get(`/StationManufacturerModel/GetstationManufacturer/${token}`);
                setManufacturers(manufacturerResponse.data);

                if (ownerGuid && OCPPJson) {
                    const initialManufacturerResponse = await api.get(`/StationManufacturerModel/GetstationManufacturerByCpLocation/${ownerGuid}/${OCPPJson.guid}/${token}`);
                    const initialManufacturer = initialManufacturerResponse.data;

                    if (initialManufacturer) {
                        setSelectedManufacturer(initialManufacturer.guid);
                        setSelectedManufacturerGUID(initialManufacturer.guid);
                        await fetchModelData(initialManufacturer.guid);

                        const initialModelResponse = await api.get(`/StationManufacturerModel/GetStationModelByCpLocation/${ownerGuid}/${OCPPJson.guid}/${token}`);
                        const initialModel = initialModelResponse.data;
                        console.log("initial model is set", initialModel)
                        if (initialModel) {
                            console.log("initial model is set")
                            setModel(initialModel.guid);
                            setSelectedModelGUID(initialModel.guid);
                            setSelectedPlugsBitMask(initialModel.plugType);
                            const availablePlugs = getAvailablePlugs(initialModel.plugType);
                            console.log("available plugs", availablePlugs);
                            setSelectedPlugs(
                                typeof availablePlugs === 'string' ? availablePlugs(',') : availablePlugs,
                            );
                            setPlugs(availablePlugs);
                        }
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    const fetchModelData = async (selectedManufacturerGUID) => {
        try {
            const response = await api.get(`/StationManufacturerModel/GetStationModelByManufacturerGuid/${selectedManufacturerGUID}/${token}`);
            setModels(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getAvailablePlugs = (plugType) => {
        const availablePlugs = [];
        if (plugType & PlugType.Schuco) {
            availablePlugs.push('Schuco');
        }
        if (plugType & PlugType.Type1) {
            availablePlugs.push('Type 1');
        }
        if (plugType & PlugType.Type2) {
            availablePlugs.push('Type 2');
        }
        if (plugType & PlugType.Css) {
            availablePlugs.push('CSS');
        }
        if (plugType & PlugType.Chademo) {
            availablePlugs.push('CHAdeMO');
        }
        return availablePlugs;
    };

    const handleManufacturerChange = async (event) => {
        const selectedValue = event.target.value;
        console.log("selected manufacturer in menuItem", selectedValue);
        setSelectedManufacturer(selectedValue);
        setSelectedManufacturerGUID(selectedValue);
        await fetchModelData(selectedValue);
        setModel('');
        setSelectedPlugs([]);
        onNextManufacturer(event.target.value);
    };

    const handleModelChange = (event) => {
        const selectedValue = models.find(model => model.guid === event.target.value);
        console.log("selected value Model", selectedValue);
        setModel(selectedValue.guid);
        setSelectedModelGUID(selectedValue.guid);
        setSelectedPlugsBitMask(selectedValue.plugType);
        const availablePlugs = getAvailablePlugs(selectedValue.plugType);
        setPlugs(availablePlugs);
        onNextModel(selectedValue.guid);
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        if (!selectedManufacturer || !model || selectedPlugs.length === 0) {
            isValid = false;
            newErrors.meterType = t('please select manufacturer and model and plug');
        }

        setErrors(newErrors);
        return isValid;
    };

    const onNextManufacturer = (selectedManufacturerIntern) => {
        let wizardStep = 0;
        console.log("selected value manufacturer", selectedManufacturerIntern);
        console.log("OCPPJson.guid", OCPPJson.guid);

        const updatedValues = {
            ...OCPPJson,
            manufacturer: selectedManufacturerIntern,
         
          
        };
        fetch(`${BASE_URL}/CpoLocation/UpdateCockpitLocation/${ownerGuid}/${OCPPJson.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),
        }).then((response) => {
            if (response.ok) {
                return response.json(); // Parse the JSON response body
            } else {
                throw new Error('There was an error!');
            }
        }).then((updatedData) => {

            setChargeStationData(updatedData);
            console.log("updatedData in manufacturer", updatedData); // Update context data
        }).catch((error) => {
            console.error(error.message);
        });
    };
    const onNextModel = (selectedModelIntern) => {
        let wizardStep = 0;
        console.log("selected value Model", selectedModelIntern);
        const updatedValues = {
            ...OCPPJson,
           
            Model: selectedModelIntern
        };
        fetch(`${BASE_URL}/CpoLocation/UpdateCockpitLocation/${ownerGuid}/${OCPPJson.guid}/${token}?wizardStep=${wizardStep}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedValues),
        }).then((response) => {
            if (response.ok) {
                return response.json(); // Parse the JSON response body
            } else {
                throw new Error('There was an error!');
            }
        }).then((updatedData) => {

            setChargeStationData(updatedData);
            console.log("updatedData in model", updatedData); // Update context data
        }).catch((error) => {
            console.error(error.message);
        });
    };

    return (
        <div >
            <FormControl className={classes.formControl} variant="standard">
                <InputLabel id="demo-simple-select-standard-label">{t('Manufacturer')}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedManufacturer}
                    onChange={handleManufacturerChange}

                    className={`${classes.select} ${selectedManufacturer && classes.greenUnderline}`}
               
                    disablePortal
                >
                    <MenuItem value="" disabled>
                        {t('Select Manufacturer')}
                    </MenuItem>
                    {manufacturers.map((manufacturer) => (
                        <MenuItem key={manufacturer.guid} value={manufacturer.guid} 
                    >{manufacturer.fullName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl className={classes.formControl} variant="standard" sx={{ minWidth: 700 }}>
                <InputLabel id="demo-simple-select-standard-label">{t('Model')}</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={model}
                    onChange={handleModelChange}
                    disabled={!selectedManufacturer}
                    className={`${classes.select} ${selectedManufacturer && classes.greenUnderline}`}
                  
                    disablePortal
                   
                >
                    <MenuItem value="" disabled >
                        {t('Select Model')}
                    </MenuItem>
                    {models.map((model, index) => (
                        <MenuItem key={index} value={model.guid} >{model.modelName}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <div>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-multiple-chip-label" style={{ fontWeight: 300 }}>{t('Plugs')}</InputLabel>
                    <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={selectedPlugs}
                        onChange={handleChange}
                        disabled={!model}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                      
                        disablePortal
                       
                    >
                        {plugs.map((plug) => (
                            <MenuItem
                                key={plug}
                                value={plug}
                                style={getStyles(plug, selectedPlugs, theme)}
                               
                            >
                                {plug}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default ChargeStationModel;
