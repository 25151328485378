import React, { useEffect } from 'react';
import axios from 'axios';
import { useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../Settings';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


function MonthlySessionsReports() {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Using the translation hook
    const currentLocale = i18n.language; // Get the current language from i18next

    var token = Cookies.get("token");
    var decoded = jwt_decode(token);
    var guid = decoded.jti;
       var ownerGuid = Cookies.get("ownerGuid");

    useEffect(() => {
        axios
            .get(`${BASE_URL}/Invoice/InvoicesByCpo/${ownerGuid}/${token}`)
            .then(function (response) {
                console.log("bookings", response.data)
                setRows(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const formatNumber = (number) => {
        // Use 'de-DE' for German and 'en-US' for English based on the current locale
        const locale = currentLocale === 'de' ? 'de-DE' : 'en-US';
        return number.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    return (
        <Box component="div" sx={{ margin: "5% 10%", borderColor: "white" }}>
            <TableContainer component={Paper} sx={{ borderColor: "white" }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead sx={{ border: "none" }}>
                        <TableRow sx={{ border: "none" }}>
                            {/*<TableCell sx={{ border: "none" }}>Fahre</TableCell>*/}
                            <TableCell sx={{ border: "none" }} align="right">{t('Year')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Month')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Amount')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('Vat')}</TableCell>
                            <TableCell align="right" sx={{ border: "none" }}>{t('pdf')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow
                                    key={row.name}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell align="right" sx={{ border: "none" }}>{row.year}</TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>{row.month}</TableCell>

                                    <TableCell align="right" sx={{ border: "none" }}>
                                        {formatNumber(row.amount)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>
                                        {formatNumber(row.vat)}
                                    </TableCell>
                                    <TableCell align="right" sx={{ border: "none" }}>
                                        <Link target="_blank" to={`${row.monthlyInvoicesLink}`}>Download</Link>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default MonthlySessionsReports;
