import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Settings";
import { useTranslation } from 'react-i18next';
import { Divider, Col, Row } from 'antd';
import AccountMenu from '../Account/AccountMenu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import Cookies from "js-cookie";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    styled,

} from "@material-ui/core";
import { Alert, Snackbar } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import InviteProperties from "./InviteProperties";

const Invites = () => {
    var token = Cookies.get("token");
    const [invites, setInvites] = useState([]);
    const [addInvite, setAddInvite] = useState(false);
    const [editInvite, setEditInvite] = useState(false);
    const [invite, setInvite] = useState([]);
    const { t } = useTranslation();
    localStorage.setItem('activePage', 'Invites');
    const [successMessage, setSuccessMessage] = useState(null);

    const navigate = useNavigate();
    const api = axios.create({
        baseURL: BASE_URL,
    });




    var ownerGuid = Cookies.get("ownerGuid");

    const readInvites = async () => {
        try {
            const response = await api.get(
                `Invite/Invites/${ownerGuid}/${token}`
            );
            console.log("response", response.data);
            if (response.data === undefined) {
                // Display a proper message when response.data is null
                console.log("No access profiles found");
            } else {
                setInvites(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        console.log("Effect is running");
        readInvites();
    }, [ownerGuid, token]);

    // useEffect(() => {
    //     readInvites();
    // });

    var getBitFromMask = function (value, mask) {
        return value & mask;
    };

    // var pureValue = getBitFromMask(userGroups[0].groupType,GroupTypeMask);


    const InviteTitles = [
        t("Invite-Title"),
		t("for Group"),
        t("List/Generic"),
        //t("#"),
        t("Expires"),
        // "External Stations",
    ];
    //   const allUserGroupNames = [...userGroupNames, ...(userGroups.map(group => group.name))];

    const iconValues = {
        true: "✅", // You can replace these with appropriate icons
        false: "❌",
    };

    const weekdays = (weekdayBitmask) => {
        if (weekdayBitmask == 31) { return t('Mo') + "-" + t('Fr'); }
        if (weekdayBitmask == 96) { return t('Sa') + "," + t('Su'); }
        if (weekdayBitmask == 127) { return t('Mo') + "-" + t('Su'); }
        const weekdayValues = {
            Mo: 0x1,
            Tu: 0x2,
            We: 0x4,
            Th: 0x8,
            Fr: 0x10,
            Sa: 0x20,
            Su: 0x40
        };
        var weekdayResult = "";
        var first = true
        Object.entries(weekdayValues).forEach(([key, mask]) => {
            if (getBitFromMask(weekdayBitmask, mask) !== 0) {
                if (!first) { weekdayResult += ","; }
                first = false;
                weekdayResult += t(key);
            }
        });

        return weekdayResult;
    }

    const hours = [];
    const hoursOfDay = () => {
        for (var i = 0; i < 24; i++) {
            hours[i] = 1 << i;
        }
        return hours;
    }
    hoursOfDay();
    const hasHour = (idx, hour) => {
        return (hour & idx) > 0;
    }

    const timeSlotCss = (idx, hour) => {
        console.log("{0}, {1}, {2}", idx, hour, (idx & hour));
        if (hasHour(idx, hour)) {
            return "timeslot selected";
        }
        return "timeslot";
    }


    const displayBitmaskValues = (bitmask) => {
        const bitmaskValues = {
            PublicGroup: 0x1,
            HasPrice: 0x2,
            PayByApp: 0x4,
            Invoice: 0x8,
            GenerateReport: 0x10,
            ExternalStations: 0x20,
            RequiresSubscription: 0x40,
            MonthlyOverview: 0x80,
        };

        const displayedValues = {};

        Object.entries(bitmaskValues).forEach(([key, mask]) => {
            displayedValues[key] = getBitFromMask(bitmask, mask) !== 0;
        });

        return displayedValues;
    };

    const currentRows = invites; // Assuming userGroups is the data for your table
    async function createUserGroup() {
        // Replace with your actual API URL


        try {



        } catch (error) {
            console.error('Error creating user group:', error);
            // Handle error appropriately
        }
    }
    const [triggerApiCall, setTriggerApiCall] = useState(false);

    const callApiCreate = async () => {
        const api = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json', // Explicitly setting Content-Type header
            },
        });
        console.log("api", api)
        try {
            const data = {
                Name: "",
                // userGroupId: "359ee415-2451-4e17-afe9-0068b1835527",
                RowKey: "",
                Description: "",
                PartitionKey: "",
                isDomainOnly: true,
                domain: ""

            };
            console.log("data", data)

            const response = await api.post(`Invite/CreateInvite/${ownerGuid}/${token}`, JSON.stringify(data));
            console.log("response of create invite", response.data)
            return response.data;

            // Reset trigger to avoid repeated calls
            setTriggerApiCall(false);

            // Optional: Additional logic after successful API call
        } catch (error) {
            console.error('Error in API call:', error);
            // Handle the error appropriately
        }
    };

    const callApiRead = async (InviteGuid) => {
        const api = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json', // Explicitly setting Content-Type header
            },
        });
        console.log("api", api)
        try {
            const data = {
                Name: "",
                RowKey: "",
                Description: "",
                PartitionKey: ""
            };
            const response = await api.get(`Invite/Invite/${ownerGuid}/${InviteGuid}/${token}`, JSON.stringify(data));
            setEditInvite(true);

            console.log("response of read invite", response.data)
            return response.data;
            // Reset trigger to avoid repeated calls
            setTriggerApiCall(false);

            // Optional: Additional logic after successful API call
        } catch (error) {
            console.error('Error in API call:', error);
            // Handle the error appropriately
        }
    }

    var handleClickSelect = function (invite) {
        console.log("handleClickSelect", invite)
        setEditInvite(false);
        setInvite(invite);
        callApiRead(invite.id);
        navigate(`/Invite/Properties`, { state: { invite: invite } });
        //navigate("/UserGroupsMenu");
        //setEditInvite(true)
    };
    const handleClickCreate = async function () {

        await callApiCreate(); // Wait for the API call to complete

        readInvites(); // Now, read the updated invites data

        setSuccessMessage("Create Invite successfully");
        setTimeout(() => {
            setSuccessMessage(null);
        }, 5000); // Adjust the delay as needed

        //navigate("/UserGroupsMenu");
        //setAddInvite(true)
    };
    const handleBackClick = () => {
        setEditInvite(false);

    };
    //const handleClick = () => {
    //	callApiCreate();
    //	navigate("/Invite/Properties");
    //};
    return (

        <div>
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Row>
                <Col xs={24} md={5}>


                    <AccountMenu initialSelectedPage="Invites"  ></AccountMenu>

                </Col>
                <Col xs={24} md={19} >
                    <div>
                        {/*{(addInvite || editInvite) ?*/}
                        {/*    (<div>*/}
                               
                        {/*        <InviteProperties invite={invite} />*/}
                        {/*    </div>*/}
                        {/*    ) : (*/}
                                <div style={{
                                    width: "80%",
                                    border: "none",
                                    margin: '5% 10% ',

                                }} >
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
										<TableHead
										className="list-table-header"
                                                style={{
                                                    backgroundColor: "#5faa39",
                                                    border: "1px solid #e0e0e0",
                                                    borderRadius: "1%",
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                <TableRow>
                                                    {InviteTitles.map((value, index) => (
                                                        <TableCell key={index} align="left" style={{ fontWeight: 'bold' }}>
                                                            {value.includes("(") ? (
                                                                <>
                                                                    <div>{value.split(" ")[0]}</div>
                                                                    <div>({value.split("(")[1].split(")")[0]})</div>
                                                                </>
                                                            ) : (
                                                                value
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {currentRows.map((row, rowIndex) => (
                                                    <TableRow key={rowIndex} onClick={() => handleClickSelect(row)}>
                                                        {InviteTitles.map((value, colIndex) => (
                                                            <TableCell align="left" style={{ cursor: 'pointer' }}>
                                                                {colIndex === 0 && row.name}
                                                                {colIndex === 1 && row.userGroup}
                                                                {colIndex === 2 && (row.usesList ? t("List") : t("Generic"))}
                                                                {colIndex === 3 && row.invitesUsed + "/" + row.maxNum}
                                                                {colIndex === 4 && row.expiryDate.toString().substring(0, 10)}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <Divider />

                                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
                                        <Button
                                            variant="contained"
                                            type="primary"
                                            color="primary"
                                            onClick={handleClickCreate}
                                            style={{ backgroundColor: "#5faa39", color: "white" }}
                                        >
                                            {t("Create Invite")}
                                        </Button>
                                    </div>
                                </div>
                            
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Invites;
