import React, { useState, useEffect, useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Divider, Col, Row } from 'antd';
import axios from "axios";
import { BASE_URL } from "../../Settings";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import ChargeStationDataContext from "./ChargeStationDataContext";
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../src/css/baseDataMenu.css';

const ChargingStationProfiles = () => {
    const token = Cookies.get("token");
    const { chargeStationData, setChargeStationData } = useContext(ChargeStationDataContext);
    const [allAccessProfile, setAccessProfile] = useState([]);
    const [selectedStations, setSelectedStations] = useState([]);
    const [unselectedStations, setUnselectedStations] = useState([]);
    const { t } = useTranslation();
    const ownerGuid = Cookies.get("ownerGuid");

    useEffect(() => {
        const fetchData = async () => {
            if (!chargeStationData.guid) return; // Don't proceed if chargeStationData is not ready

            try {
                const api = axios.create({ baseURL: BASE_URL });

                const alldata = await api.get(`ChargeStationProfile/ValidAccessProfiles/${ownerGuid}/${chargeStationData.guid}/${token}`);
                setAccessProfile(alldata.data);

                const selectedData = await api.get(`ChargeStationProfile/GetChargeStationProfiles/${ownerGuid}/${chargeStationData.guid}/${token}`);
                const selectedStationsArray = [];

                for (let i = 0; i < selectedData.data.length; i++) {
                    const currentData = selectedData.data[i];
                    if (currentData.accessProfileGuid) {
                        const selectedDataMapAccessProfile = await api.get(`AccessProfile/GetGroupProfileAccess/${ownerGuid}/${currentData.accessProfileGuid}/${token}`);
                        selectedStationsArray.push(...selectedDataMapAccessProfile.data);
                    } else {
                        selectedStationsArray.push(currentData);
                    }
                }

                setSelectedStations(selectedStationsArray);

                const selectedGuids = selectedData.data.map(item => item.guid);
                const unselectedData = alldata.data.filter(item => !selectedGuids.includes(item.guid));
                setUnselectedStations(unselectedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [chargeStationData, selectedStations]); // Only run when chargeStationData changes

    const getBitFromMask = (value, mask) => value & mask;

    const accessProfileTitles = [
        "Name",
        t("Days"),
    ];

    const weekdays = (weekdayBitmask) => {
        if (weekdayBitmask === 31) return t('Mo') + "-" + t('Fr');
        if (weekdayBitmask === 96) return t('Sa') + "," + t('Su');
        if (weekdayBitmask === 127) return t('Mo') + "-" + t('Su');

        const weekdayValues = { Mo: 0x1, Tu: 0x2, We: 0x4, Th: 0x8, Fr: 0x10, Sa: 0x20, Su: 0x40 };
        return Object.entries(weekdayValues).reduce((result, [key, mask]) => {
            if (getBitFromMask(weekdayBitmask, mask)) {
                if (result) result += ",";
                result += t(key);
            }
            return result;
        }, "");
    };

    const hours = Array.from({ length: 24 }, (_, i) => 1 << i);
    const hasHour = (idx, hour) => (hour & idx) > 0;
    const timeSlotCss = (idx, hour) => hasHour(idx, hour) ? "timeslot selected" : "timeslot";

    const handleRemoveGroupMember = async (value) => {
        try {
            const accessProfileGuid = value.guid || value.accessProfileGuid;
            await axios.delete(`${BASE_URL}/ChargeStationProfile/DeleteChargeStationProfile/${ownerGuid}/${chargeStationData.guid}/${accessProfileGuid}/${token}`);

            setSelectedStations(prev => prev.filter(station => station.guid !== value.guid));
            setUnselectedStations(prev => [...prev, value]);
        } catch (error) {
            console.error('Error removing station:', error);
        }
    };

    const handleAddStation = async (value) => {
        try {
            const isAlreadySelected = selectedStations.some(station => station.guid === value.guid);
            if (!isAlreadySelected) {
                setSelectedStations(prev => [...prev, value]);
                setUnselectedStations(prev => prev.filter(station => station.guid !== value.guid));

                await axios.post(`${BASE_URL}/ChargeStationProfile/AddChargeStationProfile/${ownerGuid}/${chargeStationData.guid}/${value.guid}/${token}`);
            }
        } catch (error) {
            console.error('Error adding station:', error);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Row>
                <Col xs={24} md={24}>
                    <div style={{ width: "100%", border: "none", margin: '3% 0' }}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead sx={{ backgroundColor: "#5faa39", border: "1px solid #e0e0e0", borderRadius: "1%" }}>
                                    <TableRow>
                                        {accessProfileTitles.map((value, index) => (
                                            <TableCell key={index} align="left">
                                                {value.includes("(") ? (
                                                    <>
                                                        <div>{value.split(" ")[0]}</div>
                                                        <div>({value.split("(")[1].split(")")[0]})</div>
                                                    </>
                                                ) : value}
                                            </TableCell>
                                        ))}
                                        <TableCell colSpan="6" className="timeslotHeader">0</TableCell>
                                        <TableCell colSpan="6" className="timeslotHeader">6</TableCell>
                                        <TableCell colSpan="6" className="timeslotHeader">12</TableCell>
                                        <TableCell colSpan="6" className="timeslotHeader">18</TableCell>
                                        <TableCell className="timeSlotHeaderEnd">24</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedStations.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {accessProfileTitles.map((value, colIndex) => (
                                                <TableCell key={colIndex} align="left" style={{ cursor: 'pointer', borderBottom: "1px solid #e0e0e0" }}>
                                                    {value === "Name" && row.name}
                                                    {colIndex === 1 && weekdays(row.weekdays)}
                                                </TableCell>
                                            ))}
                                            {hours.map((value) => (
                                                <TableCell key={value} className={timeSlotCss(value, row.hours)} align="left" style={{ cursor: 'pointer' }}>
                                                    {hasHour(value, row.hours)}&#xa0;
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <IconButton color="primary" onClick={() => handleRemoveGroupMember(row)}>
                                                    ❌
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                    <TableRow>
                                        <TableCell colSpan={2} sx={{ borderBottom: "1px solid white", fontWeight: "bold", textAlign: "center" }}>Nicht ausgewählt</TableCell>
                                    </TableRow>
                                    {unselectedStations.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {accessProfileTitles.map((value, colIndex) => (
                                                <TableCell key={colIndex} align="left" style={{ cursor: 'pointer', borderBottom: "1px solid #e0e0e0" }}>
                                                    {value === "Name" && row.name}
                                                    {colIndex === 1 && weekdays(row.weekdays)}
                                                </TableCell>
                                            ))}
                                            {hours.map((value) => (
                                                <TableCell key={value} className={timeSlotCss(value, row.hours)} align="left" style={{ cursor: 'pointer' }}>
                                                    {hasHour(value, row.hours)}&#xa0;
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <IconButton color="primary" onClick={() => handleAddStation(row)}>
                                                    ➕
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                      
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ChargingStationProfiles;
