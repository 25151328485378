import React, { useState, useEffect } from 'react';
import {makeStyles } from '@material-ui/core';
import { Stepper, Step, StepLabel } from '@mui/material';

import { Button } from '@mui/material';

import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import { useCookies } from "react-cookie";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EvStationIcon from '@mui/icons-material/EvStation';
import clsx from 'clsx';

import Profile from "./components/Chargepoint/Profile";
import ChargePoints from "./components/Chargepoint/ChargePoints";
import OCPP from "./components/Chargepoint/OCPP";
import Parking from "./components/Chargepoint/Parking";
import Meter from "./components/Chargepoint/Meter";
import Review from "./components/Chargepoint/Review";


import PreviewIcon from '@mui/icons-material/Preview';

import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ChargingStationIcon from '@mui/icons-material/ChargingStation';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import TaskIcon from '@mui/icons-material/Task';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountMenu from './components/Account/AccountMenu';

import { Col, Row } from 'antd';
import BackButton from './components/Elements/BackButton';
import { Divider } from '../node_modules/@mui/material/index';
function Wizard() {

  const [cookies, setCookie] = useCookies();
  var token = Cookies.get("token");
  var decoded = jwt_decode(token);
  var guid = decoded.jti;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
    const [activeOCPP, setActiveOCPP] = useState(false);
  
    const [activeOffline, setActiveOffline] = useState(false);
    const [showOnlineDiv, setShowOnlineDiv] = useState(false);

    const handleOcpp = (value) => {
        setActiveOCPP(value);
        setShowOnlineDiv(value)
    };

    const handleManual = (value) => {
        setActiveOffline(value);

    };
  // Handle fields change
  const handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };


  const [isLoading, setIsLoading] = useState(false);


  const [fileName, setFileName] = useState('');

  const handleFiles = (files) => {
    if (files && files.length > 0) {
      const name = files[0].name;
      setFileName(name);
      console.log('File name:', name);
    }
  };


  const useStyles = makeStyles(() => ({
    root: {
      backgroundColor: '#eaeaf0',
      padding: 8,
      borderRadius: '50%',
    },
    active: {
      color: 'red',

      border: '1px solid black',

    },
    completed: {
      color: 'green',
    },
  }));
  const CustomStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;

    const stepIcons = {
      1: <GroupAddIcon />,
      2: <ElectricMeterIcon />,
      3: <EvStationIcon />,
      4: <ChargingStationIcon />,
      5: <ElectricalServicesIcon />,
      6: <TaskIcon />,
      7: <PreviewIcon />,
    };
    if (isLoading) {
      return <div style={{
        padding: '20% 20%'
      }}> <h2>Loading...</h2>
      </div>;
    }
    return (

      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcons[String(props.icon)]}
      </div>
    );
  };
  const steps = ['Profile', 'Meter', 'OCPI', 'Charging/Parking', 'Charge Point(s)', 'Review']; // Define the steps of the wizard
  const [activeStep, setActiveStep] = useState(0); // Track the active step

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
        navigate("/ChargeLocation");
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Step1Content />;
      case 1:
        return <Step2Content />;
      case 2:
        return <Step3Content />;
      case 3:
        return <Step4Content />;
      case 4:
        return <Step5Content />;
      case 5:
        return <Step6Content />;
      default:
        return null;
    }
  };

  //Profile setting component logic start here
  const Step1Content = () => {
    return (
      <>
        <Profile />
      </>
    )
  };
  // Meter steps content logic start here

  const Step2Content = () => {
    return (
      <>
        <Meter />
      </>
    )
  };

  //OCPP steps logics
  const Step3Content = () => {
    return (
      <>
            <OCPP onOcpp={handleOcpp} onManual={handleManual} activeOCPP={activeOCPP}
                activeOffline={activeOffline} showOnlineDiv={showOnlineDiv} />

      </>
    )
  };
  //Parking Steps logic
  const Step4Content = () => {
    return (
      <>
        <Parking ></Parking>
      </>
    )
  };

  //Tarif and plan steps logic
  const Step5Content = () => {
    return (
      <>
            <ChargePoints isOcpp={activeOCPP} isManual={activeOffline}></ChargePoints>
      </>
    )
  };
  
  //REview logic
  const Step6Content = () => {
    return (
      <>   <Review ></Review>

      </>
    )
  };

  return (
      <div style={{ margin: 8, maxWidth: "100%", padding: "0% 1%" }}>
          <Row>
          <Col xs={24} md={5}>
              <AccountMenu></AccountMenu>
          </Col>

              <Col xs={24} md={19} >
                  <div style={{
                      margin: '1% 2%',
                  }} >
                  <Row>
                      <BackButton navigateLink={"/ChargeLocation"}></BackButton>
                  </Row>
                      
                  </div>
                  <div className="map-container-wrapper" style={{ padding: "0% 6%", margin: "2% 10%" }}>
                     
        <Stepper activeStep={activeStep} alternativeLabel >
          <Step >
            <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(0)}>{t('Profile')}</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(1)}>{t('Meter')}</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon} color="inherit" onClick={handleStep(2)}>{t('OCPP')}</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(3)}>{t('Charging/Parking')}</StepLabel>
          </Step>
          <Step>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(4)}>{t('Charge Point(s)')}</StepLabel>
          </Step>
           <Step>
            <StepLabel StepIconComponent={CustomStepIcon} onClick={handleStep(5)}>{t('Review')}</StepLabel>
          </Step>
        </Stepper>
      </div>
      <div >
        {activeStep === steps.length ? (
          <div>
            <p>All steps completed - You're done!</p>
          </div>
        ) : (
                              <div style={{  margin: "5%" }}>
            {/* Render the current step content */}
            {renderStepContent(activeStep)}

            {/* Step navigation buttons */}
            <div style={{ padding: "0%  20%" }}>
              <Button disabled={activeStep === 0} onClick={handleBack} variant="contained" color="primary" >
                {t('Back')}
              </Button>
              &nbsp;&nbsp;
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? t('Finish') : t('next')}
              </Button>
            </div>
          </div>
        )}
      </div>
              </Col>
          </Row>
      </div>
  );

};
export default Wizard;