import React, { useEffect, useState, Suspense } from 'react';
import {
    Box,
} from '@mui/material';
import { Col, Row } from 'antd';
import StationStatus from './StationStatus';
import TotalKwh from './TotalKwh';
import PayoutAccount from './PayoutAccount';
import ChargingSession from './ChargingSession';
import GroupsWidget from './GroupsWidget';
import Collapsible from 'react-collapsible';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { isCompanyContextAtom } from '../../Header';

// Lazy load components for each section
const ChargeStationTabs = React.lazy(() => import('./ChargeStationTabs'));
const UserGroups = React.lazy(() => import('./UserGroups'));
const AccessProfiles = React.lazy(() => import('./AccessProfilesCockpit'));
const FlexPriceConfigurations = React.lazy(() => import('../Flex/FlexPriceConfigurations'));
const ReportsMenu = React.lazy(() => import('./ReportsMenu'));
const Provider = React.lazy(() => import('./Provider'));

const CockpitMainPage = () => {
    const [openSection, setOpenSection] = useState(null);
    const [isCompanyContext, setIsCompanyContext] = useAtom(isCompanyContextAtom);
    const [refreshKey, setRefreshKey] = useState(0);
    const { t } = useTranslation();

    const handleTriggerClick = (section) => {
        setOpenSection(openSection === section ? null : section);
        if (openSection !== section) {
            document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const collapsibleTriggerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        background: '#3a7714',
        height: '50px',
        padding: '0 16px',
        borderRadius: '8px',
        transition: 'box-shadow 0.3s ease-in-out',
        margin: '0 0 15px 0',
    };
    const collapsibleTriggerStyleFlexOpHours = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        background: '#61993A',
        height: '50px',
        padding: '0 16px',
        borderRadius: '8px',
        transition: 'box-shadow 0.3s ease-in-out',
        margin: '0 0 15px 0',
    };

    const collapsibleTriggerHoverStyle = {
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    };

    useEffect(() => {
        // Trigger a re-render or any side effects here when isCompanyContext changes
        console.log('isCompanyContext changed:', isCompanyContext);
        setRefreshKey(prevKey => prevKey + 1);
    }, [isCompanyContext]);

    return (
        <div style={{ width: '100%' }} key={refreshKey}>
            <Box
                component="div"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                sx={{
                    margin: '0 0',
                    border: '0px solid',
                    borderRadius: '0',
                }}
            >
                <div style={{ width: '100%', padding: '1% ' }}>
                    <Row gutter={[16, 16]} style={{ padding: '20px' }}>
                        <Col xs={24} md={12}>
                            <StationStatus />
                        </Col>
                        <Col xs={24} md={12}>
                            <PayoutAccount />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ padding: '20px' }}>
                        <Col xs={24} md={8}>
                            <TotalKwh />
                        </Col>
                        <Col xs={24} md={8}>
                            <ChargingSession />
                        </Col>
                        <Col xs={24} md={8}>
                            <GroupsWidget />
                        </Col>
                    </Row>
                </div>

                <Col xs={24} md={24} style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="ChargeStationSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyle}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyle)}
                                onClick={() => handleTriggerClick('ChargeStationSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Charge Stations')}
                                </span>
                                {openSection === 'ChargeStationSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'ChargeStationSection'}
                    >
                        {openSection === 'ChargeStationSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <ChargeStationTabs />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>

                <Col style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="OpeningHoursSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyleFlexOpHours}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyleFlexOpHours)}
                                onClick={() => handleTriggerClick('OpeningHoursSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Opening Hours')}
                                </span>
                                {openSection === 'OpeningHoursSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'OpeningHoursSection'}
                    >
                        {openSection === 'OpeningHoursSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <AccessProfiles />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>

                <Col style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="FlexPriceSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyleFlexOpHours}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyleFlexOpHours)}
                                onClick={() => handleTriggerClick('FlexPriceSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Flex')}
                                </span>
                                {openSection === 'FlexPriceSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'FlexPriceSection'}
                    >
                        {openSection === 'FlexPriceSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <FlexPriceConfigurations />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>

                <Col style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="GroupsSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyle}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyle)}
                                onClick={() => handleTriggerClick('GroupsSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Groups')}
                                </span>
                                {openSection === 'GroupsSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'GroupsSection'}
                    >
                        {openSection === 'GroupsSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <UserGroups />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>

                <Col style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="BookingsSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyle}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyle)}
                                onClick={() => handleTriggerClick('BookingsSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Reports')}
                                </span>
                                {openSection === 'BookingsSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'BookingsSection'}
                    >
                        {openSection === 'BookingsSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <ReportsMenu />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>

                <Col style={{ marginBottom: '8px', marginLeft: '3px', marginRight: '3px', alignItems: 'center' }} id="ProviderSection">
                    <Collapsible
                        trigger={
                            <div
                                style={collapsibleTriggerStyle}
                                onMouseEnter={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerHoverStyle)}
                                onMouseLeave={(e) => Object.assign(e.currentTarget.style, collapsibleTriggerStyle)}
                                onClick={() => handleTriggerClick('ProviderSection')}
                            >
                                <span className="sectionTitle">
                                    {t('Providers')}
                                </span>
                                {openSection === 'ProviderSection' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </div>
                        }
                        open={openSection === 'ProviderSection'}
                    >
                        {openSection === 'ProviderSection' && (
                            <Suspense fallback={<div>Loading...</div>}>
                                <Provider />
                            </Suspense>
                        )}
                    </Collapsible>
                </Col>
            </Box>
        </div>
    );
};

export default CockpitMainPage;
